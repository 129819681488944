import { ApiMetaDataDTO, ErrorDTO, UserAuthDTO } from 'declarations'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { client, queryClient } from 'services/helpers/apiConfig'

export type PasswordSetDataDTO = {
  user_id: number
  password: string
  send_email: boolean
  force_password_change: boolean
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const usePasswordSetMutation = () =>
  useMutation<unknown, ErrorDTO, PasswordSetDataDTO>({
    mutationFn: async (values: PasswordSetDataDTO) => {
      try {
        const res = await client.put<ApiResponseDTO>(
          `/user/passwordSet`,
          values
        )

        return res.data.data
      } catch (err) {
        throw err.response.data
      }
    },
  })
