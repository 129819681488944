import { ApiMetaDataDTO, UserDTO } from 'declarations'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

type QueryResponseDTO = {
  users: UserDTO[]
  addUser: boolean
}

type ApiResponseDTO = {
  code: number
  data: QueryResponseDTO
  api_metadata: ApiMetaDataDTO
}

export const useUserListQuery = (): UseQueryResult<
  QueryResponseDTO,
  unknown
> => {
  return useQuery({
    queryKey: ['user', 'list'],
    queryFn: async () => {
      try {
        const res = await client.get<ApiResponseDTO>('/user/list')
        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
}
