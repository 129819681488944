import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { client } from 'services/helpers/apiConfig'

const useRfqStatusListQuery = () => {
  return useQuery({
    queryKey: ['rfq-status-list'],
    queryFn: async () => {
      const res = await client.get('rfq/status/list')

      return res.data.data
    },
  })
}

export { useRfqStatusListQuery }
