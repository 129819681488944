import React, { useEffect, useState } from 'react'
import { Box, IconButton, Grid, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _find from 'lodash/find'
import { useParams } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'

import AssignDropdown from '../../../components/assignDropdown'
import TimelineSlider from '../../../components/timelineSlider'
import StatusDropdown from '../../../components/modalStatusDropdown'
import SupplierPopover from '../../../components/supplierPopover'

import RfqTabs from './components/rfqTabs'
import { useRfqReadQuery } from 'services/hooks/rfq/useRfqReadQuery'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'

const RfqReadModal = ({ userList, onClose }) => {
  const queryClient = useQueryClient()
  const { id: rfqId }: any = useParams()
  const [supplier, setSupplier] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const {
    data: rfqData,
    status: rfqReadStatus,
    isFetching: isReadingRfq,
  } = useRfqReadQuery(rfqId)
  const { data: suppliersList } = useSuppliersQuery()

  useEffect(() => {
    if (suppliersList && rfqData) {
      const selectedSupplier = _find(suppliersList, {
        id: rfqData.supplier_id,
      })

      setSupplier(selectedSupplier)
    }
  }, [suppliersList, rfqData])

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  // useEffect(() => {
  //   if (sentRfqId) {
  //     // callApis()
  //     onRfqSendConfirmed()
  //   }
  // }, [sentRfqId])

  const onStatusUpdated = () => {
    queryClient.invalidateQueries({ queryKey: ['rfq', 'read'] })
  }

  return (
    <Box sx={{ minHeight: 500, position: 'relative' }}>
      {rfqData && (
        <>
          <Box className='title'>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 2,
              }}
            >
              <Box display='flex' alignItems='center' gap={2}>
                <Typography
                  variant='h5'
                  sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                >
                  RFQ {rfqData.id}
                </Typography>
                <AssignDropdown
                  userList={userList}
                  selected={rfqData}
                  assign={rfqData.permissions.can_assign}
                  componentType='menu'
                  updateFor='rfq'
                />
              </Box>
              <Box display='flex' alignItems='center'>
                {supplier && (
                  <>
                    <Box
                      className='supplier-label'
                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup='true'
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      {supplier.name}
                    </Box>
                    <SupplierPopover
                      open={open}
                      anchorEl={anchorEl}
                      handlePopoverClose={handlePopoverClose}
                      supplier={supplier}
                    />
                  </>
                )}
                {rfqData && (
                  <StatusDropdown
                    statuses={rfqData.status_list}
                    statusId={rfqData.status_id}
                    uuid={rfqData.uuid}
                    onStatusUpdated={onStatusUpdated}
                    loadingStatuses={isReadingRfq}
                    statusFor='rfq'
                  />
                )}
                <IconButton
                  className='btn-close'
                  aria-label='Close'
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TimelineSlider timeline={rfqData.timeline} />
            </Grid>
            <Grid item xs={12}>
              <RfqTabs rfq={rfqData} />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

export default RfqReadModal
