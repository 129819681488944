import { useLocation } from 'react-router-dom'
import { Box, Button, TextField, Typography } from '@mui/material'
import { BrowserView, MobileView } from 'react-device-detect'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link as RouterLink } from 'react-router-dom'
import { toast } from 'react-toastify'

import AuthWrapper from '../authWrapper'

const QRCode = () => {
  const location: any = useLocation()
  const qrImage = location.state.authData.mfa_codes.qr_code
  const secretKey = location.state.authData.mfa_codes.key

  return (
    <AuthWrapper>
      <BrowserView>
        <img src={qrImage} alt='QR code' />
      </BrowserView>
      <Typography variant='body1' align='left' gutterBottom>
        Scan this code with the Google Authenticator App
      </Typography>
      <MobileView>
        <CopyToClipboard
          text={secretKey}
          onCopy={() => toast.info('🦄 Wow so easy!')}
        >
          <TextField value={secretKey} />
        </CopyToClipboard>
      </MobileView>
      <Box>
        <Button
          component={RouterLink}
          to='/googleAuthenticatorCode'
          variant='contained'
          color='primary'
          disabled={!qrImage}
        >
          Next
        </Button>
      </Box>
    </AuthWrapper>
  )
}

export default QRCode
