import { Box, Grid } from '@mui/material'
import { useParams } from 'react-router'
import { useUserTypeReadQuery } from 'services/hooks/user/useUserTypeReadQuery'
import UserTypeProfile from './components/typeProfileNew'

const UserTypeUpdate = () => {
  const { type }: any = useParams()
  const { data: userTypeData } = useUserTypeReadQuery(type)

  // console.log(userTypeData.scopes)
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {userTypeData && <UserTypeProfile userTypeData={userTypeData} />}
        </Grid>
      </Grid>
    </Box>
  )
}

export default UserTypeUpdate
