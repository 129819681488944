import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ApiMetaDataDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type SearchPartNumberRulesQueryParamsDTO = {
  term: string
  supplierIds: string
  partNumberClassIds: string
}

type SearchResultDTO = {
  search: {
    more_than_100: boolean
    record_count: number
    term_count: number
    terms: string[]
  }
}
type ApiResponseDTO = {
  code: number
  data: SearchResultDTO
  api_metadata: ApiMetaDataDTO
}

const useSearchPartNumberRulesQuery = (
  params: SearchPartNumberRulesQueryParamsDTO
): UseQueryResult<SearchResultDTO> => {
  return useQuery({
    queryKey: [
      'search-part-number-rules',
      params.term,
      params.supplierIds,
      params.partNumberClassIds,
    ],
    queryFn: async () => {
      try {
        const reqParams = {
          term: params.term,
          supplier_id: params.supplierIds,
          part_number_class_id: params.partNumberClassIds,
        }

        const res = await client.get<ApiResponseDTO>(
          `/part_number_rules/search`,
          {
            params: reqParams,
          }
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    enabled: params.term !== '',
  })
}

export { useSearchPartNumberRulesQuery }
