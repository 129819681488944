import axios from 'axios'
import { getToken } from './utility'
import { QueryClient } from '@tanstack/react-query'

const API_ROOT = process.env.REACT_APP_API_URL

const client = axios.create({
  baseURL: API_ROOT,
})

client.defaults.headers.common = {
  Authorization: `Bearer ${getToken().accessToken}`,
}

export const queryClient = new QueryClient()
export { client }
