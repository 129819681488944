import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AppBar,
  Badge,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material'
import {
  Home as HomeIcon,
  Brightness7 as Brightness7Icon,
  Brightness4 as Brightness4Icon,
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  LocalShipping as LocalShippingIcon,
  ShoppingCart as ShoppingCartIcon,
  List as ListIcon,
  ExitToApp as ExitToAppIcon,
  Settings as SettingsIcon,
  Menu as MenuIcon,
  Description as DescriptionIcon,
} from '@mui/icons-material'
import { useColorScheme } from '@mui/material/styles'

import logo from '../assets/images/small_white_turnerfox_logo.svg'
import { useLogoutMutation } from 'services/hooks/auth/useLogoutMutation'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useUserQuery } from 'services/hooks/auth/useUserQuery'

const drawerWidth = 240

const dashboardPages = [
  {
    name: 'home',
    route: '/home',
  },
  {
    name: 'requisitions',
    route: '/requisitions/list',
  },
  {
    name: 'rfqs',
    route: '/rfq/list',
  },
  {
    name: 'pos',
    route: '/po/list',
  },
  {
    name: 'shipments',
    route: '/shipment/list',
  },
  {
    name: 'files',
    route: '/files',
  },
  {
    name: 'settings',
    route: '/admin/user/list',
  },
]

const AppDrawer = () => {
  const { data: userData, isFetching, error, isSuccess } = useUserQuery()
  const { mutateAsync: logoutMutateAsync, status } = useLogoutMutation()
  const [selectedPageIndex, setSelectedPageIndex] = useState(0)
  const [selectedPageName, setSelectedPageName] = useState('')
  const { mode, setMode } = useColorScheme()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const currentPath = location.pathname
    const pageRoute = currentPath.split('/')[1]

    switch (pageRoute) {
      case 'home':
        setSelectedPageIndex(0)
        setSelectedPageName(dashboardPages[0].name)
        break
      case 'requisitions':
        setSelectedPageIndex(1)
        setSelectedPageName(dashboardPages[1].name)
        break
      case 'rfq':
        setSelectedPageIndex(2)
        setSelectedPageName(dashboardPages[2].name)
        break
      case 'po':
        setSelectedPageIndex(3)
        setSelectedPageName(dashboardPages[3].name)
        break
      case 'shipment':
        setSelectedPageIndex(4)
        setSelectedPageName(dashboardPages[4].name)
        break
      case 'files':
        setSelectedPageIndex(5)
        setSelectedPageName(dashboardPages[5].name)
        break
      case 'admin':
        setSelectedPageIndex(6)
        setSelectedPageName(dashboardPages[6].name)
        break
      default:
        break
    }
  }, [location])

  const onClickLogout = async () => {
    try {
      await logoutMutateAsync({})
    } catch (err) {
      console.log(err)
    }
  }

  const onSelectPage = useCallback((index, route) => {
    setSelectedPageIndex(index)
    setSelectedPageName(dashboardPages[index].name)
    history.push(route)
  }, [])

  return (
    <>
      <AppBar
        position='fixed'
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <img src={logo} alt='TurnerFox' style={{ height: 30 }} />
          <Typography
            variant='h4'
            sx={{ marginLeft: 5, textTransform: 'capitalize', flexGrow: 1 }}
          >
            {selectedPageName}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography>
              Welcome back, {userData.data.first_name} {userData.data.last_name}
            </Typography>
            <Tooltip title='Toggle light/dark theme'>
              <IconButton
                onClick={() => {
                  if (mode === 'light') {
                    setMode('dark')
                  } else {
                    setMode('light')
                  }
                }}
                aria-label='Toggle light/dark theme'
                style={{ color: 'white' }}
              >
                {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <List sx={{ flexGrow: 1 }}>
            {dashboardPages.map((page, i) => (
              <ListItem disablePadding key={'dashboard-page' + i}>
                <ListItemButton
                  key={page.name}
                  onClick={() => onSelectPage(i, page.route)}
                  selected={selectedPageIndex === i}
                  // css={ListItemStyle}
                >
                  <ListItemIcon>
                    {page.name === 'home' && (
                      <Badge color='secondary' badgeContent={4}>
                        <HomeIcon sx={{ fontSize: 40, flexShrink: 0 }} />
                      </Badge>
                    )}
                    {page.name === 'requisitions' && (
                      <ListIcon sx={{ fontSize: 40, flexShrink: 0 }} />
                    )}
                    {page.name === 'rfqs' && (
                      <ChatBubbleOutlineIcon
                        sx={{ fontSize: 40, flexShrink: 0 }}
                      />
                    )}
                    {page.name === 'pos' && (
                      <ShoppingCartIcon sx={{ fontSize: 40, flexShrink: 0 }} />
                    )}
                    {page.name === 'shipments' && (
                      <LocalShippingIcon sx={{ fontSize: 40, flexShrink: 0 }} />
                    )}
                    {page.name === 'files' && (
                      <DescriptionIcon sx={{ fontSize: 40, flexShrink: 0 }} />
                    )}
                    {page.name === 'settings' && (
                      <SettingsIcon sx={{ fontSize: 40, flexShrink: 0 }} />
                    )}
                  </ListItemIcon>

                  <ListItemText
                    primary={page.name}
                    sx={{ textTransform: 'capitalize' }}
                  />
                </ListItemButton>
              </ListItem>
            ))}

            <ListItem disablePadding>
              <ListItemButton
                onClick={onClickLogout}
                disabled={status === 'pending'}
              >
                <ListItemIcon>
                  <ExitToAppIcon style={{ fontSize: 40 }} />
                </ListItemIcon>
                <ListItemText primary='Log out' />
              </ListItemButton>
            </ListItem>
          </List>
          <List>
            <ListItem disablePadding sx={{ paddingX: 2 }}>
              <Typography variant='caption'>
                API {userData.api_metadata.api_version} Build | ©2020 Turner &
                Fox Ltd.
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  )
}

export default AppDrawer
