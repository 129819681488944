import React, { useState } from 'react'
import { TextField, Button, Box, Typography, Paper } from '@mui/material'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import {
  getGoogleAuthKeyAPI,
  deleteGoogleAuthCodeAPI,
  validateGoogleAuthKeyAPI,
} from '../../../../../services/helpers/apis/user'
import { UserDTO } from 'declarations'
import { ModalStyle } from '.'
import { useGetGoogleAuthKeyMutation } from 'services/hooks/user/useGetGoogleAuthKeyMutation'
import { useDeleteGoogleAuthCodeMutation } from 'services/hooks/user/useDeleteGoogleAuthCodeMutation'

type MfaModalContentProps = {
  mfaUpdateMode: string
  userData: UserDTO
  onClose: () => void
}

const MfaModalContent = ({
  mfaUpdateMode,
  userData,
  onClose,
}: MfaModalContentProps) => {
  // const params: any = useParams()
  // const { userName } = params
  const [step, setStep] = useState(0)
  const [password, setPassword] = useState('')
  const [qrCode, setQrCode] = useState('')
  const [mfaCode, setMfaCode] = useState('')
  const [submitPassword, setSubmitPassword] = useState(false)
  const { mutate: getGoogleAuthKeyMutate } = useGetGoogleAuthKeyMutation()
  const { mutate: deleteGoogleAuthCodeMutation } =
    useDeleteGoogleAuthCodeMutation()

  const onConfirm = (event) => {
    event.preventDefault()

    if (mfaUpdateMode === 'enable') {
      if (step === 0) {
        setSubmitPassword(true)

        getGoogleAuthKeyMutate(
          { password },
          {
            onSuccess(data, variables, context) {
              console.log(data)
              setStep(step + 1)
              // setQrCode(data.qr_code)
              setSubmitPassword(false)
            },
          }
        )
      } else if (step === 1) {
      } else if (step === 2) {
      }
    } else {
      deleteGoogleAuthCodeMutation(
        { user_id: userData.id, password: password },
        { onSuccess(data, variables, context) {} }
      )
    }

    //     event.preventDefault()

    //     const { step, password, mfaCode } = this.state
    //     const { mfaUpdateMode, userData } = this.props

    //     if (mfaUpdateMode === 'enable') {
    //       if (step === 0) {
    //         this.setState({
    //           submitPassword: true,
    //         })
    //         getGoogleAuthKeyAPI(password)
    //           .then((res) => {
    //             const mfaCodes = res.data.data.mfa_codes

    //             this.setState((prevState) => ({
    //               step: prevState.step + 1,
    //               qrCode: mfaCodes.qr_code,
    //               submitPassword: false,
    //             }))
    //           })
    //           .catch((err) => {
    //             this.props.onApiError(err)
    //             this.setState({
    //               submitPassword: false,
    //             })
    //           })
    //       } else if (step === 1) {
    //         this.setState((prevState) => ({
    //           step: prevState.step + 1,
    //         }))
    //       } else if (step === 2) {
    //         validateGoogleAuthKeyAPI(mfaCode, password)
    //           .then(() => {
    //             toast.success('MFA has been enabled')
    //             this.props.onMFAEnabled()
    //             this.props.onClose(mfaUpdateMode)
    //           })
    //           .catch((err) => {
    //             this.props.onApiError(err)
    //           })
    //       }
    //     } else {
    //       deleteGoogleAuthCodeAPI(password)
    //         .then(() => {
    //           this.setState((prevState) => ({
    //             step: prevState.step + 1,
    //           }))
    //           this.props.onMFARemoved()
    //           if (!userData.require_mfa) {
    //             this.props.onClose(mfaUpdateMode)
    //             toast.warn(
    //               'Multifactor Authentication has been removed from this account'
    //             )
    //           }
    //         })
    //         .catch((err) => {
    //           this.props.onApiError(err)
    //         })
    //     }
  }

  const onCancel = () => {
    onClose()
    // const { step, password } = this.state
    // const { mfaUpdateMode } = this.props
    // if (mfaUpdateMode === 'enable') {
    //   if (step === 0) {
    //     this.props.onClose(mfaUpdateMode)
    //   } else if (step === 1 || step === 2) {
    //     deleteGoogleAuthCodeAPI(password)
    //       .then(() => {
    //         toast.warn(
    //           'Multifactor Authentication has been removed from this account'
    //         )
    //         this.props.onClose(mfaUpdateMode)
    //       })
    //       .catch((err) => {
    //         this.props.onApiError(err)
    //       })
    //   }
    // } else if (mfaUpdateMode === 'remove') {
    //   if (step === 0) {
    //     this.props.onClose()
    //   } else {
    //     this.props.onClose(mfaUpdateMode)
    //     toast.warn(
    //       'Multifactor Authentication has been removed from this account'
    //     )
    //   }
    // }
  }

  return (
    <Box sx={ModalStyle} component={Paper}>
      <form onSubmit={onConfirm}>
        {step === 0 && (
          <Box>
            <Typography sx={{ mb: 2 }}>Please Confirm Your Password</Typography>
            <TextField
              label='Password'
              type='password'
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value)
              }}
              fullWidth
            />
          </Box>
        )}
        {step === 1 && mfaUpdateMode === 'enable' && (
          <Box>
            <Typography sx={{ mb: 2 }}>
              Scan this code with your MFA App
            </Typography>
            <img src={qrCode} alt='qrCode' />
          </Box>
        )}
        {step === 1 && mfaUpdateMode === 'remove' && userData.require_mfa && (
          <Box>
            <Typography>
              Multifactor Authenticaton is enabled. Because you are required to
              use MFA with this app, you will be logged out upon removing MFA,
              and you will be required to add MFA back to your account when you
              log in again.
            </Typography>
          </Box>
        )}
        {step === 2 && mfaUpdateMode === 'enable' && (
          <Box>
            <Typography sx={{ mb: 2 }}>
              Enter the Code From Your MFA App
            </Typography>
            <TextField
              label='Code'
              type='text'
              value={mfaCode}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMfaCode(event.target.value)
              }}
              fullWidth
            />
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ mt: 2 }}>
            <Button variant='contained' color='secondary' onClick={onCancel}>
              {mfaUpdateMode === 'remove' && step === 1 ? 'Close' : 'Cancel'}
            </Button>
          </Box>
          {mfaUpdateMode === 'enable' && (
            <Button
              variant='contained'
              color='primary'
              type='submit'
              disabled={
                (step === 0 && password === '') ||
                submitPassword ||
                (step === 2 && mfaCode === '')
              }
              sx={{ mt: 2 }}
            >
              {step === 0 || step === 2 ? 'Confirm' : 'Next >'}
            </Button>
          )}
          {mfaUpdateMode === 'remove' && step === 0 && (
            <Button
              variant='contained'
              color='primary'
              type='submit'
              disabled={password === ''}
              sx={{ mt: 2 }}
            >
              Confirm
            </Button>
          )}
        </Box>
      </form>
    </Box>
  )
}

export default MfaModalContent
