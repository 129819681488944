import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type PoLineUpdateParamsDTO = {
  uuid: string
  updateBody: {
    quantity?: number
    item?: string
    supplier_part_number?: string
    dispatch_schedule?: {
      quantity: number
      lead_time: string
    }[]
    manufacturer_name?: string
    unit_cost?: number
    lead_time?: string
  }
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const usePoLineUpdateMutation = () =>
  useMutation<any, ErrorDTO, PoLineUpdateParamsDTO>({
    mutationFn: async (values: PoLineUpdateParamsDTO) => {
      try {
        const res = await client.put<ApiResponseDTO>(
          `/po/line/update/${values.uuid}`,
          values.updateBody
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
