import React, { useState, useEffect } from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  Fab,
  Typography,
  Box,
  Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import EditIcon from '@mui/icons-material/Edit'
import { orange, lightGreen } from '@mui/material/colors'
import _isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
import NumberFormat from 'react-number-format'
import moment from 'moment'

import SendMessageTemplateModal from '../../../components/sendMessageTemplateModal'

import ConfirmedDateTime from './components/confirmtedDateTime'
import ChangeStatusModalContent from './components/changeStatusModalContent'
import LineTable from './lineTable'
import { useCarriersListQuery } from 'services/hooks/carriers/useCarriersListQuery'
import { useCarriersServicesListQuery } from 'services/hooks/carriers/useCarriersServicesListQuery'
import { usePoUpdateMutation } from 'services/hooks/purchaseOrders/usePoUpdateMutation'
import { usePoMessageTemplateQuery } from 'services/hooks/purchaseOrders/usePoMessageTemplateQuery'
import { usePoDeleteMutation } from 'services/hooks/purchaseOrders/usePoDeleteMutation'
import { usePoReadQuery } from 'services/hooks/purchaseOrders/usePoReadQuery'
import { useHistory, useParams } from 'react-router'
import { usePoSuplierShipByDateQuery } from 'services/hooks/purchaseOrders/usePoSuplierShipByDateQuery'
import { useQueryClient } from '@tanstack/react-query'

const LineItems = () => {
  const { id: poId }: any = useParams()
  const { data: poData } = usePoReadQuery(poId)
  const [isAddNewLine, setIsAddNewLine] = useState(false)
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false)
  const [changeStatusFor, setChangeStatusFor] = useState('')
  const [arrivesOnDate, setArrivesOnDate] = useState<any>()
  const [shipByDate, setShipByDate] = useState<any>()
  const history = useHistory()
  const { data: carriersList } = useCarriersListQuery()
  const queryClient = useQueryClient()

  const { data: poConfirmMessage, refetch: refetchPoConfirmMessage } =
    usePoMessageTemplateQuery(poData.uuid, false)
  const { mutate: poUpdateMutation } = usePoUpdateMutation()
  const { mutate: deletePoMutation } = usePoDeleteMutation()
  const [submitPoConfirmModalOpen, setSubmitPoConfirmModalOpen] =
    useState(false)

  const [
    isDispatchScheduleAfterShipByDate,
    setIsDispatchScheduleAfterShipByDate,
  ] = useState(false)

  const [selectedCarrierService, setSelectedCarrierService] = React.useState(
    poData?.carrier_service?.id
  )
  const [selectedCarrier, setSelectedCarrier] = React.useState(
    poData?.carrier_service?.carrier_id
  )
  const { data: carriersServiceList } = useCarriersServicesListQuery(
    { carrierId: selectedCarrier },
    !!selectedCarrier
  )
  const { data: poSupplierShipByDateData } = usePoSuplierShipByDateQuery(
    {
      uuid: `${poData.supplier_id}`,
      receive_by_date: arrivesOnDate
        ? moment(arrivesOnDate.timestamp).format('YYYY-MM-DD')
        : '',
      carrierServiceId: selectedCarrier,
    },
    !!arrivesOnDate
  )

  const getShippingTimeFrames = () => {
    let arrMustArriveByDate = []
    poData.lines.lines.map((line) => {
      if (!_isEmpty(line.requisition_detail.must_arrive_by_date)) {
        arrMustArriveByDate = [
          ...arrMustArriveByDate,
          line.requisition_detail.must_arrive_by_date,
        ]
      }
    })

    if (arrMustArriveByDate.length > 0) {
      arrMustArriveByDate = arrMustArriveByDate.sort(function (a, b) {
        // Convert timestamps to Date objects for comparison
        const dateA = new Date(a.timestamp)
        const dateB = new Date(b.timestamp)

        // Compare dates and return the appropriate value
        if (dateA < dateB) {
          return -1 // a should come before b
        } else if (dateA > dateB) {
          return 1 // b should come before a
        } else {
          return 0 // dates are equal
        }
      })
      setArrivesOnDate(arrMustArriveByDate[0])
    }
  }

  useEffect(() => {
    if (selectedCarrier !== '') {
      getShippingTimeFrames()
    }
  }, [poData, selectedCarrier])

  useEffect(() => {
    if (poSupplierShipByDateData) {
      setShipByDate(poSupplierShipByDateData.ship_date)
      let allDisptachSchedules = []
      for (let i = 0; i < poData.lines.lines.length; i++) {
        for (
          let j = 0;
          j < poData.lines.lines[i].dispatch_schedule.length;
          j++
        ) {
          allDisptachSchedules = [
            ...allDisptachSchedules,
            poData.lines.lines[i].dispatch_schedule[j],
          ]
        }
      }

      for (let i = 0; i < allDisptachSchedules.length; i++) {
        if (
          moment(allDisptachSchedules[i].expected_ship_date.timestamp).isAfter(
            moment(poSupplierShipByDateData.ship_date.timestamp)
          )
        ) {
          setIsDispatchScheduleAfterShipByDate(true)
          break
        }
      }
    }
  }, [poSupplierShipByDateData])

  const handleChange = async (event, field) => {
    if (field === 'carrier') {
      setSelectedCarrier(event.target.value)
    } else {
      setSelectedCarrierService(event.target.value)

      if (event.target.value !== '') {
        poUpdateMutation(
          {
            uuid: poData.uuid,
            reqBody: {
              carrier_service_id: event.target.value,
            },
          },
          {
            onSuccess(data, variables, context) {
              toast.success('Success')
            },
          }
        )
      }
    }
  }

  const onDeleteClicked = async () => {
    if (poData.permissions.can_delete) {
      deletePoMutation(
        { uuid: poData.uuid },
        {
          onSuccess(data, variables, context) {
            toast.success(`Po ${poData.id} is deleted.`)
            queryClient.invalidateQueries({ queryKey: ['po', 'list'] })
            history.push(`/po/list`)
          },
        }
      )
    } else {
      setOpenChangeStatusModal(true)
      setChangeStatusFor('delete this purchase order')
    }
  }

  const onEditClicked = () => {
    setOpenChangeStatusModal(true)
    setChangeStatusFor('edit this purchase order')
  }

  const onSubmitPo = async () => {
    refetchPoConfirmMessage()
    setSubmitPoConfirmModalOpen(true)
  }

  const onClosePoConfirmDialog = () => {
    setSubmitPoConfirmModalOpen(false)
  }

  const onAddNewLine = () => {
    setIsAddNewLine(true)
  }

  const onCloseAddNewLine = () => {
    setIsAddNewLine(false)
  }

  const onCloseChangeStatusModal = () => {
    setOpenChangeStatusModal(false)
    setChangeStatusFor(null)
  }

  const onUpdateDate = async (date) => {
    poUpdateMutation(
      {
        uuid: poData.uuid,
        reqBody: {
          date_confirmed: moment(date).format('YYYY-MM-DD HH:mm:ss'),
        },
      },
      {
        onSuccess(data, variables, context) {
          // modalContext.fetchPoApis()
          toast.success('Confirmed Date is updated')
        },
      }
    )
  }

  return (
    <Box sx={{ position: 'relative', pb: 1.5 }}>
      <div className='lineItem'>
        <LineTable
          isAddNewLine={isAddNewLine}
          closeAddNewLine={onCloseAddNewLine}
          poData={poData}
        />
      </div>

      <Box
        className='lines-table-bottom'
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
        mt={1}
      >
        <Box>
          <Box display='flex' alignItems='center'>
            {carriersList && (
              <FormControl variant='outlined' sx={{ margin: 1, minWidth: 120 }}>
                <InputLabel id='carrier-select-outlined-label'>
                  Carrier
                </InputLabel>
                <Select
                  labelId='carrier-select-outlined-label'
                  id='carrier-select-outlined'
                  value={selectedCarrier}
                  onChange={(e) => handleChange(e, 'carrier')}
                  label='Carrier'
                >
                  {carriersList.map((carrier) => (
                    <MenuItem value={carrier.id} key={carrier.id}>
                      {carrier.carrier}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {carriersServiceList && (
              <FormControl
                variant='outlined'
                sx={{ margin: 1, minWidth: 120 }}
                disabled={selectedCarrier === ''}
              >
                <InputLabel
                  shrink
                  id='service-select-outlined-label'
                  sx={{ padding: '0 4px' }}
                >
                  Service
                </InputLabel>
                <Select
                  labelId='service-select-outlined-label'
                  id='service-select-outlined'
                  value={selectedCarrierService}
                  onChange={(e) => handleChange(e, 'service')}
                  label='Service'
                  displayEmpty
                >
                  <MenuItem value='' disabled>
                    <em>Select...</em>
                  </MenuItem>
                  {carriersServiceList.map((service) => (
                    <MenuItem value={service.id} key={service.id}>
                      {service.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {Object.keys(poData.date_confirmed).length !== 0 &&
              !poData.permissions.can_edit && (
                <Box ml={1}>
                  <ConfirmedDateTime
                    date={poData.date_confirmed}
                    disabled={!poData.permissions.can_edit_date_confirmed}
                    onUpdateDate={onUpdateDate}
                  />
                </Box>
              )}
          </Box>
          <Box>
            <Typography
              color={
                isDispatchScheduleAfterShipByDate ? 'secondary' : 'initial'
              }
            >
              <b>Ship By:</b>{' '}
              {shipByDate ? (
                `${shipByDate.human_date.precise.long.day} ${shipByDate.human_date.precise.short.date}`
              ) : (
                <span>&mdash;</span>
              )}
              {isDispatchScheduleAfterShipByDate && ` ⚠️`}
            </Typography>
            <Typography>
              <b>Arrives on:</b>{' '}
              {arrivesOnDate ? (
                `${arrivesOnDate.human_date.precise.long.day} ${arrivesOnDate.human_date.precise.short.date}`
              ) : (
                <span>&mdash;</span>
              )}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ width: '200px' }}>
          <Typography
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            gutterBottom
          >
            <span>Total Pieces:</span> <span>{poData.lines.total_pieces}</span>
          </Typography>
          <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Total Price:</span>{' '}
            <span>
              <NumberFormat
                value={parseFloat(poData.lines.total_price)}
                displayType='text'
                thousandSeparator
                prefix='$'
                decimalScale={3}
              />
            </span>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          right: 0,
          bottom: 20,
          display: 'flex',
          gap: 1.5,
        }}
      >
        {!poData.permissions.can_edit && (
          <Tooltip title='Edit PO' placement='top'>
            <span>
              <Fab
                size='small'
                aria-label='edit'
                className='btn-edit'
                onClick={onEditClicked}
                // disabled={!poData.permissions.can_delete}
                sx={{
                  color: '#fff',
                  backgroundColor: lightGreen[500],
                  '&:hover': {
                    backgroundColor: lightGreen[700],
                  },
                }}
              >
                <EditIcon />
              </Fab>
            </span>
          </Tooltip>
        )}
        <Tooltip title='Delete PO' placement='top'>
          <span>
            <Fab
              size='small'
              color='secondary'
              aria-label='add'
              className='btn-delete'
              onClick={onDeleteClicked}
            >
              <DeleteIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title='Send' placement='top'>
          <span>
            <Fab
              size='small'
              aria-label='add'
              className='btn-submit'
              onClick={onSubmitPo}
              disabled={!poData.permissions.can_submit}
              sx={{
                color: '#fff',
                backgroundColor: orange[500],
                '&:hover': {
                  backgroundColor: orange[700],
                },
              }}
            >
              <ArrowForwardIosIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title='Add New Line Item' placement='top'>
          <span>
            <Fab
              size='small'
              color='primary'
              aria-label='add'
              className='btn-add'
              onClick={onAddNewLine}
              disabled={!poData.permissions.can_edit_lines}
            >
              <AddIcon />
            </Fab>
          </span>
        </Tooltip>
      </Box>
      <Dialog
        onClose={onClosePoConfirmDialog}
        open={submitPoConfirmModalOpen && !!poConfirmMessage}
      >
        {poConfirmMessage && (
          <SendMessageTemplateModal
            message={poConfirmMessage}
            id={poData.id}
            onCloseDialog={onClosePoConfirmDialog}
            modalFor='po'
          />
        )}
      </Dialog>
      <Dialog onClose={onCloseChangeStatusModal} open={openChangeStatusModal}>
        <ChangeStatusModalContent
          changeStatusFor={changeStatusFor}
          onClose={onCloseChangeStatusModal}
          onDeleteLine={() => {}}
        />
      </Dialog>
    </Box>
  )
}

export default LineItems
