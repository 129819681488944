import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { client } from '../../services/helpers/apiConfig'

type MimeTypes = {
  mime_types: {
    download: boolean
    mime_type: string
    plain_language: string
  }[]
}

type FileTypes = {
  file_types: {
    controlled_term: string
    display: string
  }[]
}

async function fetchMimeTypes(): Promise<MimeTypes> {
  const res = await client.get('/file/mimetypes/list')
  return res.data.data
}

async function fetchPoFileTypes(): Promise<FileTypes> {
  const res = await client.get('/po/file/types/list')
  return res.data.data
}

async function fetchRfqFileTypes(): Promise<FileTypes> {
  const res = await client.get('/rfq/file/types/list')
  return res.data.data
}

function useMimeTypes<TData = MimeTypes>(
  options?: UseQueryOptions<MimeTypes, AxiosError, TData>
) {
  return useQuery({ queryKey: ['mimeTypes'], queryFn: fetchMimeTypes })
}

function usePoFileTypes<TData = FileTypes>(
  options?: UseQueryOptions<MimeTypes, AxiosError, TData>
) {
  return useQuery({ queryKey: ['poFileTypes'], queryFn: fetchPoFileTypes })
}

function useRfqFileTypes<TData = FileTypes>(
  options?: UseQueryOptions<MimeTypes, AxiosError, TData>
) {
  return useQuery({ queryKey: ['rfqFileTypes'], queryFn: fetchRfqFileTypes })
}

export { useMimeTypes, usePoFileTypes, useRfqFileTypes }
