import React from 'react'
import { FormControl, Select, InputLabel } from '@mui/material'

import { useRfqQuoteUpdateMutation } from 'services/hooks/rfq/useRfqQuoteUpdateMutation'

type EditableSelectProps = {
  quoteLineUUID: string
  selectList: any
  selectValue: any
  selectType: any
  disabled?: boolean
  isNewLine?: boolean
  updateSelectValue?: any
}

const EditableSelect = ({
  quoteLineUUID = '',
  selectList,
  selectValue,
  selectType,
  disabled,
  isNewLine = false,
  updateSelectValue,
}: EditableSelectProps) => {
  const [value, setValue] = React.useState(selectValue)
  const { mutate: rfqQuoteUpdateMutation } = useRfqQuoteUpdateMutation()

  const onChangeSelect = async (e) => {
    setValue(e.target.value)

    if (!isNewLine) {
      rfqQuoteUpdateMutation({
        uuid: quoteLineUUID,
        body: {
          [selectType]: e.target.value,
        },
      })
    } else {
      updateSelectValue(selectType, e.target.value)
    }
  }

  return (
    <FormControl
      variant='outlined'
      className='formControl editable-select'
      fullWidth
    >
      <InputLabel htmlFor='editable-select'>
        {selectType === 'lead_time' ? 'Lead Time' : 'Manufacturer'}
      </InputLabel>
      <Select
        native
        value={value}
        onChange={onChangeSelect}
        inputProps={{
          name: 'editable',
          id: 'editable-select',
        }}
        disabled={disabled}
        label={selectType === 'lead_time' ? 'Lead Time' : 'Manufacturer'}
      >
        <option value=''></option>
        {selectList.map((item, i) => (
          <option
            value={selectType === 'lead_time' ? item.value : item.id}
            key={'editable-item' + i}
          >
            {selectType === 'lead_time' ? item.label : item.name}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default EditableSelect
