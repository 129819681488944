import React from 'react'
import { Box, Grid, Icon, Typography } from '@mui/material'

const Tracking = ({ data }) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant='h4' sx={{ fontWeight: 'bold' }} gutterBottom>
        {data.delivery_summary}
      </Typography>
      <Typography gutterBottom>{data.delivery_summary_subtext}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              Location
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              Local Time
            </Typography>
          </Grid>
        </Grid>
        {data.activity.map((activity, index) => (
          <Grid container key={'tracking' + index}>
            <Grid item xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  justifyContent: 'center',
                }}
              >
                <Icon style={{ color: `#${activity.color}` }}>
                  {activity.icon}
                </Icon>{' '}
                {activity.status}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {activity.address.city}, {activity.address.state}
                {activity.address.country_code
                  ? `, ${activity.address.country_code}`
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>{activity.timestamp.formatted_date}</Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  )
}

export default Tracking
