import React from 'react'
import { Box, Icon, Typography } from '@mui/material'

import TimelineSliderContainer from './index.style'

const TimelineSlider = ({ timeline }) => {
  const numberOfBoldLines = timeline.filter((x) => x.line_bold === true).length

  return (
    <TimelineSliderContainer>
      <Box
        sx={{
          position: 'absolute',
          left: `calc(${100 / timeline.length / 2}%)`,
          right: `calc(${100 / timeline.length / 2}%)`,
          top: 13,
          border: '1px solid #000',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            height: 4,
            background: '#000',
            width: `calc(${
              (100 / (timeline.length - 1)) * numberOfBoldLines
            }% - 12px)`,
          }}
        ></Box>
      </Box>

      {timeline.map((time, index) => (
        <Box
          className='time-marker'
          key={index}
          sx={{ width: `${100 / timeline.length}%` }}
        >
          <div className='pointer'>
            {time.icon ? (
              <Icon
                style={{ color: time.icon_color }}
                sx={{ bgcolor: 'background.paper', borderRadius: '100%' }}
              >
                {time.icon}
              </Icon>
            ) : (
              <span className='dot' />
            )}
          </div>
          <Typography>{time.line1}</Typography>
          <Typography>{time.line2}</Typography>
          <Typography>{time.line3}</Typography>
        </Box>
      ))}
    </TimelineSliderContainer>
  )
}

export default TimelineSlider
