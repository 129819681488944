import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ThreadDraftCreateParamsDTO = {
  subject?: string
  body?: string
  author_id?: number
  is_internal?: boolean
  'reply-to'?: string
  'to-email'?: string
}

export const useThreadDraftCreateMutation = () =>
  useMutation<any, ErrorDTO, ThreadDraftCreateParamsDTO>({
    mutationFn: async (params) => {
      try {
        const res = await client.post('/thread/draft/create', params)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
