import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ApiMetaDataDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

export type LogicFunction = {
  arguments: string[]
  default: boolean
  description: string
  id: number
}

type ApiResponseDTO = {
  code: number
  data: LogicFunction[]
  api_metadata: ApiMetaDataDTO
}

const useSourceRulesListingLogicFunctionsQuery = (): UseQueryResult<
  LogicFunction[]
> => {
  return useQuery({
    queryKey: ['sourcing_rules', 'listing_logic_functions'],
    queryFn: async () => {
      try {
        const res = await client.get<ApiResponseDTO>(
          '/sourcing_rules/logic_functions/list'
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
}

export { useSourceRulesListingLogicFunctionsQuery }
