import { useQueryClient } from '@tanstack/react-query'
import { ShipmentListItemDTO } from 'declarations'
import moment from 'moment'

import { client } from 'services/helpers/apiConfig'

type RequisitionListQueryParamsDTO = {
  pageIndex: number
  rowsCount: number
  filterValues?: {
    statuses: string[]
    startDate: Date
    endDate: Date
  }
}

type ResponseDTO = {
  shipments: ShipmentListItemDTO[]
  total_rows: number
}

export const useLazyShipmentListUpcomingQuery = () => {
  const queryClient = useQueryClient()
  const fetchData = (date: Date) => {
    return queryClient.fetchQuery({
      queryKey: ['shipment', 'list_upcoming', date],
      queryFn: async () => {
        try {
          const res = await client.get(
            `shipment/list_upcoming?start_date=${moment(date).format(
              'YYYY-MM-DD'
            )}&end_date=${moment(date).format('YYYY-MM-DD')}`
          )

          return res.data.data
        } catch (err) {
          console.log(err)
          throw err.response.data
        }
      },
    })
  }

  return [fetchData]
}
