import React, { useState } from 'react'
import moment from 'moment'
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
  TextField,
  Box,
} from '@mui/material'
import _indexOf from 'lodash/indexOf'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
// import { DateRangePicker } from '../../assets/modules/react-date-range/src'

import FilterBoxContainer from './index.style'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const FilterSelect = ({
  label,
  filterType,
  filterList,
  selectValues,
  onChange,
}) => {
  const [values, setValues] = useState(selectValues)

  const onChangeSelect = (e) => {
    const selectedValues =
      _indexOf(e.target.value, 'none') !== -1 ? [] : e.target.value
    setValues(selectedValues)
    onChange(filterType, selectedValues)
  }

  return (
    <FormControl variant='outlined' sx={{ width: 200 }}>
      <InputLabel htmlFor='select-multiple-outlined' id='select-multiple-label'>
        {label}
      </InputLabel>
      <Select
        labelId='select-multiple-label'
        id='select-multiple'
        multiple
        value={values}
        onChange={onChangeSelect}
        input={<OutlinedInput id='select-multiple-outlined' label={label} />}
        MenuProps={MenuProps}
      >
        <MenuItem value='none'>None</MenuItem>
        {filterList.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {label === 'Status' ? item.status : item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const ListTableFilterBox = ({
  suppliersList = null,
  statusList = null,
  userList = null,
  filterSuppliers = null,
  filterStatuses = null,
  filterUsers = null,
  defaultStartDate = null,
  defaultEndDate = null,
  hasDateRange = false,
  handleChange,
  handleDateRangeChange,
}) => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: defaultStartDate
      ? moment(defaultStartDate).toDate()
      : moment().subtract(1, 'years').toDate(),
    endDate: defaultEndDate
      ? moment(defaultEndDate).toDate()
      : moment().toDate(),
    key: 'selection',
  })
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  // const handleClose = () => {
  //   setAnchorEl(null)
  // }
  // const open = Boolean(anchorEl)
  // const id = open ? 'simple-popover' : undefined

  // const onChangeRange = (ranges) => {
  //   setSelectionRange(ranges.selection)
  //   handleDateRangeChange(
  //     moment(ranges.selection.startDate),
  //     moment(ranges.selection.endDate)
  //   )
  // }

  return (
    <FilterBoxContainer>
      <Typography>Filter By:</Typography>
      {userList && userList.length > 0 && (
        <FilterSelect
          label='Assignee'
          filterType='users'
          filterList={userList}
          selectValues={filterUsers}
          onChange={handleChange}
        />
      )}
      {suppliersList && suppliersList.length > 0 && (
        <FilterSelect
          label='Supplier'
          filterType='suppliers'
          filterList={suppliersList}
          selectValues={filterSuppliers}
          onChange={handleChange}
        />
      )}
      {statusList && statusList.length > 0 && (
        <FilterSelect
          label='Status'
          filterType='statuses'
          filterList={statusList}
          selectValues={filterStatuses}
          onChange={handleChange}
        />
      )}

      {hasDateRange && (
        <Box style={{ marginLeft: 16 }}>
          <TextField
            id='date-range'
            label='Date Range'
            variant='outlined'
            value={`${moment(selectionRange.startDate).format(
              'YYYY/MM/DD'
            )} - ${moment(selectionRange.endDate).format('YYYY/MM/DD')}`}
            onClick={handleClick}
          />

          {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={onChangeRange}
            />
          </Popover> */}
        </Box>
      )}
    </FilterBoxContainer>
  )
}

export default ListTableFilterBox
