import React, { useCallback, useEffect, useState } from 'react'
import {
  TextField,
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Fab,
  Paper,
  SelectChangeEvent,
} from '@mui/material'
import { useParams, useHistory } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import { toast } from 'react-toastify'
import _debounce from 'lodash/debounce'

import LoadingSpinner from '../../../../components/common/loadingSpinner'
import TestPreferenceRuleModal from './testRuleModal'
import EditLogicModal from './editLogicModal'
import { useSprStringEvaluationTypesQuery } from 'services/hooks/supplierPreferenceRules/useSprStringEvaluationTypesQuery'
import { useSprPartNumberClassesQuery } from 'services/hooks/supplierPreferenceRules/useSprPartNumberClassesQuery'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'
import { useManufacturersListQuery } from 'services/hooks/others/useManufacturersListQuery'
import { useSprReadQuery } from 'services/hooks/supplierPreferenceRules/useSprReadQuery'
import { SupplierPreferenceRuleDTO } from 'declarations'
import { useSprUpdateMutation } from 'services/hooks/supplierPreferenceRules/useSprUpdateMutation'
import { useSprDeleteMutation } from 'services/hooks/supplierPreferenceRules/useSprDeleteMutation'

const DEBOUNCE_TIME_MS = 500

const SupplierPreferenceEdit = () => {
  const { uuid }: any = useParams()
  const history = useHistory()

  const [ruleInfo, setRuleInfo] = useState<SupplierPreferenceRuleDTO>()
  const [showTestModal, setShowTestModal] = useState(false)
  const [showEditLogicModal, setShowEditLogicModal] = useState(false)
  const [logicIndex, setLogicIndex] = useState(null)
  const [selectedLogic, setSelectedLogic] = useState(null)
  const { data: stringEvaluationTypes } = useSprStringEvaluationTypesQuery()
  const { data: partNumberClasses } = useSprPartNumberClassesQuery()
  const { data: suppliers } = useSuppliersQuery()
  const { data: manufacturers } = useManufacturersListQuery()
  const { data: sprData, refetch } = useSprReadQuery(uuid)
  const { mutate: updateSprMutation } = useSprUpdateMutation()
  const { mutate: deleteSprMutation } = useSprDeleteMutation()

  useEffect(() => {
    if (sprData) {
      setRuleInfo(sprData)
    }
  }, [sprData])

  const updateRule = async (updateObj, resetField) => {
    updateSprMutation(
      {
        uuid,
        updateBody: updateObj,
      },
      {
        onSuccess(data, variables, context) {
          setShowEditLogicModal(false)
          setLogicIndex(null)
          refetch()
        },
        onError(error, variables, context) {
          resetField()
        },
      }
    )
  }

  const debouncedUpdateData = useCallback(
    _debounce(updateRule, DEBOUNCE_TIME_MS),
    []
  )

  const onDelete = async () => {
    deleteSprMutation(
      {
        uuids: [uuid],
      },
      {
        onSuccess(data, variables, context) {
          toast.success('Part Number Deleted Successfully')
          history.push('/admin/supplier_preference_rules')
        },
      }
    )
  }

  const onCheckBtnClicked = () => {
    setShowTestModal(true)
  }

  const onCloseTestModal = () => {
    setShowTestModal(false)
  }

  const onEditLogicBtnClicked = (logicObj, index) => {
    setSelectedLogic(logicObj)
    setLogicIndex(index)
    setShowEditLogicModal(true)
  }

  const onCloseEditLogicModal = () => {
    setSelectedLogic(null)
    setLogicIndex(null)
    setShowEditLogicModal(false)
  }

  const onDeleteLogic = (logicIndex) => {
    const tempLogics = [...ruleInfo.logic_object]
    const logics = [...ruleInfo.logic_object]
    logics.splice(logicIndex, 1)

    setRuleInfo({
      ...ruleInfo,
      logic_object: logics,
    })
    updateRule(
      {
        logic_object: logics,
      },
      () => {
        setRuleInfo({
          ...ruleInfo,
          logic_object: tempLogics,
        })
      }
    )
  }

  const onUpdateLogic = (logicObj) => {
    const tempLogics = [...ruleInfo.logic_object]
    let logics = [...ruleInfo.logic_object]
    if (selectedLogic !== null && logicIndex !== null) {
      logics[logicIndex] = { ...logicObj }
    } else {
      logics = [...logics, logicObj]
    }

    setRuleInfo({
      ...ruleInfo,
      logic_object: logics,
    })

    updateRule(
      {
        logic_object: logics,
      },
      () => {
        setRuleInfo({
          ...ruleInfo,
          logic_object: tempLogics,
        })
      }
    )
  }

  const onAddLogicBtnClicked = () => {
    setShowEditLogicModal(true)
  }

  const onSelectChange = (optionName: string, event: SelectChangeEvent) => {
    const tempFieldValue = ruleInfo[optionName]
    setRuleInfo({
      ...ruleInfo,
      [optionName]: event.target.value,
    })
    debouncedUpdateData(
      {
        [optionName]: event.target.value,
        logic_objects: ruleInfo.logic_object,
      },
      () => {
        setRuleInfo({ ...ruleInfo, [optionName]: tempFieldValue })
      }
    )
  }

  const onTextFieldChange = (
    inputName: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const tempFieldValue = ruleInfo[inputName]
    setRuleInfo({
      ...ruleInfo,
      [inputName]: event.target.value,
    })

    debouncedUpdateData(
      {
        [inputName === 'evaluation_string' ? 'string' : inputName]:
          event.target.value,
        logic_objects: ruleInfo.logic_object,
      },
      () => {
        setRuleInfo({ ...ruleInfo, [inputName]: tempFieldValue })
      }
    )
  }

  return (
    <Box component={Paper} sx={{ padding: 2 }}>
      {stringEvaluationTypes &&
      partNumberClasses &&
      suppliers &&
      manufacturers &&
      ruleInfo ? (
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Box mb={1}>
              <TextField
                id='name-for-rule'
                label='Name for Rule'
                variant='outlined'
                value={ruleInfo.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onTextFieldChange('name', e)
                }
                fullWidth
              />
            </Box>
            <Box display='flex' alignItems='center'>
              <Box
                width='60%'
                display='flex'
                alignItems='center'
                justifyContent='flex-end'
              >
                <Typography>For any part number that</Typography>
                {stringEvaluationTypes && (
                  <FormControl
                    variant='outlined'
                    sx={{ margin: 1, width: 200 }}
                  >
                    <InputLabel id='string-evaluation-types-select-filled-label'>
                      String Evaluation Type
                    </InputLabel>
                    <Select
                      labelId='string-evaluation-types-select-filled-label'
                      id='string-evaluation-types-select-filled'
                      value={`${ruleInfo.string_evaluation_type_id}`}
                      onChange={(event: SelectChangeEvent) =>
                        onSelectChange('string_evaluation_type_id', event)
                      }
                      label='String Evaluation Type'
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {stringEvaluationTypes.map((type) => (
                        <MenuItem value={type.id} key={type.id}>
                          {type.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
              <Box flex='1'>
                <TextField
                  id='search-text'
                  label='add search text here...'
                  variant='outlined'
                  value={ruleInfo.evaluation_string}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onTextFieldChange('evaluation_string', e)
                  }
                  fullWidth
                />
              </Box>
            </Box>
            <Box display='flex' alignItems='center'>
              <Box
                width='60%'
                display='flex'
                alignItems='center'
                justifyContent='flex-end'
              >
                <Typography>and is a</Typography>
                {partNumberClasses && (
                  <FormControl
                    variant='outlined'
                    sx={{ margin: 1, width: 200 }}
                  >
                    <InputLabel id='part-number-class-select-filled-label'>
                      Part Number Class
                    </InputLabel>
                    <Select
                      labelId='part-number-class-select-filled-label'
                      id='part-number-class-select-filled'
                      value={`${ruleInfo.part_number_class_id}`}
                      onChange={(event: SelectChangeEvent) =>
                        onSelectChange('part_number_class_id', event)
                      }
                      label='Part Number Class'
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {partNumberClasses.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>

              <Typography>part</Typography>
            </Box>
            <List>
              {ruleInfo.logic_object &&
                ruleInfo.logic_object.map((obj, index) => (
                  <ListItem
                    button
                    key={index}
                    sx={{ alignItems: 'flex-start' }}
                  >
                    <ListItemText primary={obj.display_text} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label='delete'
                        onClick={() => onEditLogicBtnClicked(obj, index)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label='delete'
                        onClick={() => onDeleteLogic(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
            <Box display='flex' justifyContent='flex-end' mt={3}>
              <Fab
                color='primary'
                aria-label='add'
                style={{ marginRight: '1em' }}
                onClick={onAddLogicBtnClicked}
              >
                <AddIcon />
              </Fab>
              <Fab
                color='primary'
                aria-label='edit'
                style={{ marginRight: '1em' }}
                onClick={onDelete}
              >
                <DeleteIcon />
              </Fab>
              <Fab
                color='primary'
                aria-label='delete'
                onClick={onCheckBtnClicked}
              >
                <CheckIcon />
              </Fab>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id='outlined-multiline-static'
              label='Notes...'
              multiline
              rows={20}
              defaultValue='Default Value'
              variant='outlined'
              fullWidth
              value={ruleInfo.notes}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onTextFieldChange('notes', e)
              }
            />
          </Grid>
          <TestPreferenceRuleModal
            open={showTestModal}
            handleClose={onCloseTestModal}
            uuid={ruleInfo.uuid}
          />
          <EditLogicModal
            open={showEditLogicModal}
            handleClose={onCloseEditLogicModal}
            suppliers={suppliers}
            manufacturers={manufacturers}
            logic={selectedLogic}
            onUpdateLogic={onUpdateLogic}
          />
        </Grid>
      ) : (
        <LoadingSpinner />
      )}
    </Box>
  )
}

export default SupplierPreferenceEdit
