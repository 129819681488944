import { Grid, Card, CardHeader, CardContent, Box } from '@mui/material'
import { useUserReadQuery } from 'services/hooks/user/useUserReadQuery'
import { useParams } from 'react-router'
import { MetaData, ProfileForm } from './components'
import ChangePasswordForm from './components/changePasswordForm'
import ProfileView from './components/profileView'
// import AuthenticatedBrowsers from './components/authenticatedBrowsers'

const EditUser = ({ userName, editable = true }) => {
  const params: any = useParams()
  const { userName: routeUserName } = params
  const { data: userData, isLoading } = useUserReadQuery(
    userName || routeUserName
  )

  return (
    <Box>
      {userData && (
        <Box>
          {editable ? (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Card sx={{ height: '100%' }}>
                  <CardHeader
                    title={`User Profile: ${userData?.data.username}`}
                  />
                  <CardContent sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <ProfileForm
                      initialValues={{
                        first_name: userData.data.first_name,
                        last_name: userData.data.last_name,
                        email: userData.data.email,
                        phone: userData.data.phone,
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={{ height: '100%' }}>
                  <CardHeader title='Change Password' />
                  <CardContent sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <ChangePasswordForm
                      userId={userData.data.id}
                      isSelf={userName === 'self'}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={{ height: '100%' }}>
                  <CardHeader title='Authenticated Browser' />
                  <CardContent sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {/* <AuthenticatedBrowsers /> */}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={{ height: '100%' }}>
                  <CardHeader title='User Metadata' />
                  <CardContent sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <MetaData userData={userData.data} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Card sx={{ height: '100%' }}>
                  <CardHeader
                    title={`User Profile: ${userData?.data.username}`}
                  />
                  <CardContent sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <ProfileView userData={userData} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={{ height: '100%' }}>
                  <CardHeader title='Authenticated Browser' />
                  <CardContent sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {/* <AuthenticatedBrowsers
                      tokenObject={userData.token_object}
                      userId={userId}
                      self={self}
                      editable={false}
                    /> */}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </Box>
  )
}

export default EditUser
