import React, { useState, useEffect } from 'react'
import { Paper, Box } from '@mui/material'
import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'

import QuantityInput from './quantityInput'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid'

const PartNumberTable = ({ lines, onSelectLine }) => {
  const [tableRows, setTableRows] = useState(lines)

  const columns: GridColDef[] = [
    {
      field: 'part_number',
      headerName: 'Part Number',
      flex: 1,
    },
    {
      field: 'manufacturer',
      headerName: 'Manufacturer',
      valueGetter: (value, row) => row.manufacturer.abbreviation,
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      renderCell: (params: GridRenderCellParams) => (
        <QuantityInput line={params.row} onChange={handleChangeQuantity} />
      ),
      display: 'flex',
      flex: 1,
      minWidth: 200,
    },
  ]
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])

  useEffect(() => {
    const linesWithQuantity = lines.map((line) => ({
      ...line,
      quantity: line.total_difference,
    }))
    setTableRows(linesWithQuantity)
  }, [])

  const handleChangeQuantity = (line) => {
    const index = _findIndex(tableRows, { part_number: line.part_number })

    const arr = [...tableRows]
    arr[index].quantity = line.quantity
    setTableRows(arr)
  }

  useEffect(() => {
    if (rowSelectionModel.length > 0) {
      const selectedLines = []
      rowSelectionModel.map((partNumber) => {
        const selectedRow = _find(tableRows, { part_number: partNumber })
        selectedLines.push(selectedRow)

        return selectedLines
      })
      onSelectLine(selectedLines)
    }
  }, [rowSelectionModel, tableRows])

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <DataGrid
          rows={tableRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          getRowId={(row) => row.part_number}
          rowHeight={100}
        />
      </Paper>
    </Box>
  )
}

export default PartNumberTable
