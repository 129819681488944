import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Input,
} from '@mui/material'

import { useShipmentRecevingUpdateMutation } from 'services/hooks/shipment/useShipmentRecevingUpdateMutation'
import { useShipmentReadQuery } from 'services/hooks/shipment/useShipmentReadQuery'

const ReceiveModal = ({ shipment, handleClose }) => {
  const [shipmentLines, setShipmentLines] = useState<any[] | null>()
  const [note, setNote] = useState('')
  const [receiveConfirmModalOpen, setReceiveConfirmModalOpen] = useState(false)
  const history = useHistory()
  const { mutate: updateShipmentReceivingMutation } =
    useShipmentRecevingUpdateMutation()
  const { data: shipmentData } = useShipmentReadQuery(shipment.tracking_number)

  useEffect(() => {
    if (shipmentData) {
      const aggregatedShipmentLines = shipmentData
        ? Object.values(shipmentData.lines).map((line: any) => {
            let total_quantity_ordered = 0
            let total_quantity_received = 0
            line.map((x) => {
              total_quantity_ordered += parseInt(x.shipped_quantity, 10)
              total_quantity_received += parseInt(x.shipped_quantity, 10)
            })

            return {
              shipment_line_id: line[0].shipment_line_id,
              total_quantity_ordered,
              total_quantity_received,
              supplier_part_number: line[0].supplier_part_number,
              part_number: line[0].requisition?.part_numbers || [],
              manufacturer: line[0].manufacturer,
            }
          })
        : []

      setShipmentLines(aggregatedShipmentLines)
    }
  }, [shipmentData])

  const onChangeQuantityReceived = (event, i) => {
    const arr = [...shipmentLines]
    arr[i].total_quantity_received = event.target.value
    setShipmentLines(arr)
  }

  const onConfirmReceive = async () => {
    const receivedLines = shipmentLines.map((line) => ({
      shipment_line_id: line.shipment_line_id,
      quantity: line.total_quantity_received,
    }))

    updateShipmentReceivingMutation(
      {
        trackingNumber: shipment.tracking_number,
        body: {
          received_lines: receivedLines,
          note,
        },
      },
      {
        onSuccess(data, variables, context) {
          setReceiveConfirmModalOpen(true)
        },
      }
    )
  }

  const onChangeNote = (e) => {
    setNote(e.target.value)
  }

  const onCloseModal = () => {
    handleClose()
  }

  const onClickViewShipment = () => {
    history.push(`/shipment/${shipment.tracking_number}`)
  }

  return (
    <>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Quantity Expected</TableCell>
                <TableCell>Quantity Received</TableCell>
                <TableCell>Part Number</TableCell>
                <TableCell>Supplier Part Number</TableCell>
                <TableCell>Manufacturer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shipmentLines &&
                shipmentLines.map((row, i) => (
                  <TableRow key={'shipment-line' + i}>
                    <TableCell component='th' scope='row'>
                      {row.total_quantity_ordered}
                    </TableCell>
                    <TableCell>
                      <Input
                        value={row.total_quantity_received}
                        onChange={(e) => onChangeQuantityReceived(e, i)}
                        inputProps={{ 'aria-label': 'Quantity Received' }}
                        type='number'
                      />
                    </TableCell>
                    <TableCell>{row.part_number.join(',')}</TableCell>
                    <TableCell>{row.supplier_part_number}</TableCell>
                    <TableCell>{row.manufacturer.name}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2} width={500}>
          <TextField
            id='outlined-multiline-static'
            label='Receiving Notes'
            multiline
            rows={4}
            variant='outlined'
            fullWidth
            value={note}
            onChange={onChangeNote}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal} variant='contained'>
          Cancel
        </Button>
        <Button
          onClick={onConfirmReceive}
          color='primary'
          variant='contained'
          autoFocus
        >
          Receive {'>'}
        </Button>
      </DialogActions>

      <Dialog
        open={receiveConfirmModalOpen}
        onClose={() => {
          setReceiveConfirmModalOpen(false)
        }}
        aria-labelledby='receive confirm dialog'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            You have successfully received the {shipment.carrier}{' '}
            {shipment.carrier_service_name} package from{' '}
            {shipment.supplier.name} with tracking number{' '}
            {shipment.tracking_number}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setReceiveConfirmModalOpen(false)
            }}
            variant='contained'
          >
            Close
          </Button>
          <Button
            onClick={onClickViewShipment}
            variant='contained'
            color='primary'
            autoFocus
          >
            View Shipment {'>'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ReceiveModal
