import React, { useCallback, useEffect, useState } from 'react'
import {
  TextField,
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Fab,
  Paper,
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useParams, useHistory } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import _remove from 'lodash/remove'
import _debounce from 'lodash/debounce'
import { toast } from 'react-toastify'

import LoadingSpinner from '../../../../components/common/loadingSpinner'

import TestPartNumberRuleModal from './testPartNumberRuleModal'
import EditLogicModal from './editLogicModal'
import NewLogicModal from './newLogicModal'
import { useReadPartNumberRuleQuery } from 'services/hooks/partNumberRules/useReadPartNumberRuleQuery'
import { PartNumberRuleDTO } from 'declarations'
import { useStringEvaluationTypesQuery } from 'services/hooks/partNumberRules/useStringEvaluationTypesQuery'
import { usePartNumberRulesClassListQuery } from 'services/hooks/partNumberRules/usePartNumberRulesClassListQuery'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'
import { useSourceRulesListingLogicFunctionsQuery } from 'services/hooks/useListingLogicFunctionsQuery'
import { useDeletePartNumberRulesMutation } from 'services/hooks/partNumberRules/useDeletePartNumberRulesMutation'
import { useUpdatePartNumberRuleMutation } from 'services/hooks/partNumberRules/useUpdatePartNumberRuleMuation'

const DEBOUNCE_TIME_MS = 500

const SupplierPartNumberEdit = () => {
  const { uuid }: any = useParams()
  const history = useHistory()
  const { data: partNumberInformation, refetch } =
    useReadPartNumberRuleQuery(uuid)
  const { data: stringEvaluationTypes } = useStringEvaluationTypesQuery()
  const { data: partNumberClasses } = usePartNumberRulesClassListQuery()
  const { data: suppliers } = useSuppliersQuery()
  const { data: logics } = useSourceRulesListingLogicFunctionsQuery()
  const { mutate: deletePartNumberRulesMutation } =
    useDeletePartNumberRulesMutation()
  const { mutate: updatePartNumberRuleMutation } =
    useUpdatePartNumberRuleMutation()

  const [partNumberInfo, setPartNumberInfo] = useState<PartNumberRuleDTO>()
  const [showTestModal, setShowTestModal] = useState(false)
  const [showEditLogicModal, setShowEditLogicModal] = useState(false)
  const [showAddLogicModal, setShowAddLogicModal] = useState(false)
  const [logicId, setLogicId] = useState<number | ''>('')
  const [logicIndex, setLogicIndex] = useState(null)

  useEffect(() => {
    if (partNumberInformation) {
      setPartNumberInfo(partNumberInformation)
    }
  }, [partNumberInformation])

  const updatePartNumber = (updateObj, resetField) => {
    updatePartNumberRuleMutation(
      { uuid, updateBody: updateObj },
      {
        onSuccess(data, variables, context) {
          setShowEditLogicModal(false)
          setShowAddLogicModal(false)
          refetch()
        },
        onError(error, variables, context) {
          resetField()
        },
      }
    )
  }

  const debouncedUpdateData = useCallback(
    _debounce(updatePartNumber, DEBOUNCE_TIME_MS),
    []
  )

  const onSelectChange = (optionName: string, event: SelectChangeEvent) => {
    const tempFieldValue = partNumberInfo[optionName]
    setPartNumberInfo({
      ...partNumberInfo,
      [optionName]: event.target.value,
    })
    // debouncedUpdateData.cancel()
    debouncedUpdateData(
      {
        [optionName]: event.target.value,
        logic_function_objects: partNumberInfo.logic.workflow,
      },
      () => {
        setPartNumberInfo({
          ...partNumberInfo,
          [optionName]: tempFieldValue,
        })
      }
    )
  }

  const onTextFieldChange = (
    inputName: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const tempFieldValue = partNumberInfo[inputName]
    setPartNumberInfo({
      ...partNumberInfo,
      [inputName]: event.target.value,
    })

    debouncedUpdateData(
      {
        [inputName === 'evaluation_string' ? 'string' : inputName]:
          event.target.value,
        logic_function_objects: partNumberInfo.logic.workflow,
      },
      () => {
        setPartNumberInfo({ ...partNumberInfo, [inputName]: tempFieldValue })
      }
    )
  }

  const onDelete = async () => {
    deletePartNumberRulesMutation(
      { uuids: [uuid] },
      {
        onSuccess(data, variables, context) {
          toast.success('Part Number Deleted Successfully')
          history.push('/admin/supplier_part_number_rules')
        },
      }
    )
  }

  const onCheckBtnClicked = () => {
    setShowTestModal(true)
  }

  const onCloseTestModal = () => {
    setShowTestModal(false)
  }

  const onEditLogicBtnClicked = (logicId, index) => {
    setLogicId(logicId)
    setLogicIndex(index)
    setShowEditLogicModal(true)
  }

  const onCloseEditLogicModal = () => {
    setShowEditLogicModal(false)
  }

  const onDeleteLogic = (logicId) => {
    const tempLogics = [...partNumberInfo.logic.workflow]
    const logics = _remove(partNumberInfo.logic.workflow, (n) => {
      return n.logic_function_id !== logicId
    })

    setPartNumberInfo({
      ...partNumberInfo,
      logic: {
        workflow: logics,
      },
    })

    updatePartNumber(
      {
        logic_function_objects: logics,
      },
      () => {
        setPartNumberInfo({
          ...partNumberInfo,
          logic: {
            workflow: tempLogics,
          },
        })
      }
    )
  }

  const onUpdateLogic = (workflow, logicIndex) => {
    const tempLogics = [...partNumberInfo.logic.workflow]
    const logics = [...partNumberInfo.logic.workflow]
    logics[logicIndex] = { ...workflow }
    setPartNumberInfo({
      ...partNumberInfo,
      logic: {
        workflow: logics,
      },
    })

    updatePartNumber(
      {
        logic_function_objects: logics,
      },
      () => {
        setPartNumberInfo({
          ...partNumberInfo,
          logic: {
            workflow: tempLogics,
          },
        })
      }
    )
  }

  const onAddLogicBtnClicked = () => {
    setShowAddLogicModal(true)
  }

  const onCloseAddLogicModal = () => {
    setShowAddLogicModal(false)
  }

  const onAddLogic = (workflow) => {
    const tempLogics = [...partNumberInfo.logic.workflow]
    const logics = [...tempLogics, workflow]
    setPartNumberInfo({
      ...partNumberInfo,
      logic: {
        workflow: logics,
      },
    })
    updatePartNumber(
      {
        logic_function_objects: logics,
      },
      () => {
        setPartNumberInfo({
          ...partNumberInfo,
          logic: {
            workflow: tempLogics,
          },
        })
      }
    )
  }

  return stringEvaluationTypes &&
    partNumberClasses &&
    suppliers &&
    partNumberInfo ? (
    <Box component={Paper} sx={{ padding: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Box mb={1}>
            <TextField
              id='name-for-rule'
              label='Name for Rule'
              variant='outlined'
              value={partNumberInfo.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onTextFieldChange('name', e)
              }
              fullWidth
            />
          </Box>
          <Box display='flex' alignItems='center'>
            <Box
              width='60%'
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
            >
              <Typography>For any part number that</Typography>
              {stringEvaluationTypes && (
                <FormControl variant='outlined' sx={{ margin: 1, width: 200 }}>
                  <InputLabel id='string-evaluation-types-select-filled-label'>
                    String Evaluation Type
                  </InputLabel>
                  <Select
                    labelId='string-evaluation-types-select-filled-label'
                    id='string-evaluation-types-select-filled'
                    value={`${partNumberInfo.string_evaluation_type_id}`}
                    onChange={(event: SelectChangeEvent) =>
                      onSelectChange('string_evaluation_type_id', event)
                    }
                    label='String Evaluation Type'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {stringEvaluationTypes.map((type) => (
                      <MenuItem value={type.id} key={type.id}>
                        {type.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            <Box flex='1'>
              <TextField
                id='search-text'
                label='add search text here...'
                variant='outlined'
                value={partNumberInfo.evaluation_string}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onTextFieldChange('evaluation_string', e)
                }
                fullWidth
              />
            </Box>
          </Box>
          <Box display='flex' alignItems='center'>
            <Box
              width='60%'
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
            >
              <Typography>and is a</Typography>
              {partNumberClasses && (
                <FormControl variant='outlined' sx={{ margin: 1, width: 200 }}>
                  <InputLabel id='part-number-class-select-filled-label'>
                    Part Number Class
                  </InputLabel>
                  <Select
                    labelId='part-number-class-select-filled-label'
                    id='part-number-class-select-filled'
                    value={`${partNumberInfo.part_number_class_id}`}
                    onChange={(e) => onSelectChange('part_number_class_id', e)}
                    label='Part Number Class'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {partNumberClasses.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>

            <Typography>part</Typography>
          </Box>
          <Box display='flex' alignItems='center'>
            <Box
              width='60%'
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
            >
              <Typography>that is purchased from</Typography>
              {suppliers && (
                <FormControl variant='outlined' sx={{ margin: 1, width: 200 }}>
                  <InputLabel id='supplier-select-filled-label'>
                    Supplier
                  </InputLabel>
                  <Select
                    labelId='supplier-select-filled-label'
                    id='supplier-select-filled'
                    value={`${partNumberInfo.supplier_id}`}
                    onChange={(event: SelectChangeEvent) =>
                      onSelectChange('supplier_id', event)
                    }
                    label='Part Number Class'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {suppliers.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </Box>
          <Typography>
            apply the following supplier part number rules:
          </Typography>
          <List>
            {partNumberInfo.logic &&
              partNumberInfo.logic.workflow.map((flow, index) => (
                <ListItem
                  button
                  key={flow.logic_function_id}
                  sx={{ alignItems: 'flex-start' }}
                >
                  <ListItemText primary={flow.display_text} />
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label='delete'
                      onClick={() =>
                        onEditLogicBtnClicked(flow.logic_function_id, index)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label='delete'
                      onClick={() => onDeleteLogic(flow.logic_function_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
          <Box display='flex' justifyContent='flex-end' mt={3}>
            <Fab
              color='primary'
              aria-label='add'
              style={{ marginRight: '1em' }}
              onClick={onAddLogicBtnClicked}
            >
              <AddIcon />
            </Fab>
            <Fab
              color='primary'
              aria-label='edit'
              style={{ marginRight: '1em' }}
              onClick={onDelete}
            >
              <DeleteIcon />
            </Fab>
            <Fab
              color='primary'
              aria-label='delete'
              onClick={onCheckBtnClicked}
            >
              <CheckIcon />
            </Fab>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id='outlined-multiline-static'
            label='Notes...'
            multiline
            rows={20}
            defaultValue='Default Value'
            variant='outlined'
            fullWidth
            value={partNumberInfo.notes}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onTextFieldChange('notes', e)
            }
          />
        </Grid>
        <TestPartNumberRuleModal
          open={showTestModal}
          handleClose={onCloseTestModal}
          uuid={partNumberInfo.uuid}
        />
        <EditLogicModal
          open={showEditLogicModal}
          handleClose={onCloseEditLogicModal}
          logicId={logicId}
          logicIndex={logicIndex}
          workflow={partNumberInfo.logic.workflow}
          onUpdateLogic={onUpdateLogic}
          logics={logics}
        />
        <NewLogicModal
          open={showAddLogicModal}
          handleClose={onCloseAddLogicModal}
          logics={logics}
          onAddLogic={onAddLogic}
        />
      </Grid>
    </Box>
  ) : (
    <LoadingSpinner />
  )
}

export default SupplierPartNumberEdit
