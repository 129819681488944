import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material'

import { OrangeButton } from 'components/common/button'
import { usePartNumberRuleProcessMutation } from 'services/hooks/partNumberRules/usePartNumberRuleProcessMutation'

type TestPartNumberRuleModalProps = {
  open: boolean
  handleClose: () => void
  uuid: string
}

const TestPartNumberRuleModal = ({
  open,
  handleClose,
  uuid,
}: TestPartNumberRuleModalProps) => {
  const [partNumber, setPartNumber] = useState('')
  const [processedMessages, setProcessedMessages] = useState(null)
  const [showTestButton, setShowTestButton] = useState(true)
  const { mutate: partNumberRuleProcessMutate } =
    usePartNumberRuleProcessMutation()

  const onChangePartNumber = (e) => {
    setPartNumber(e.target.value)
  }

  const onTest = async () => {
    partNumberRuleProcessMutate(
      { uuid, partNumber },
      {
        onSuccess(data, variables, context) {
          const messages = data.message.map((line) => {
            let str = line
            str = str.replace('{part_number_class}', data.part_number_class)
            str = str.replace('{part_number}', data.part_number)
            str = str.replace('{supplier_name}', data.supplier_name)
            str = str.replace(
              '{supplier_part_number}',
              data.supplier_part_number
            )

            return str
          })

          setShowTestButton(false)
          setProcessedMessages(messages)
        },
      }
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id='form-dialog-title'>
        Test Supplier Part Number Rule
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          label='Part Number Rule'
          variant='outlined'
          fullWidth
          value={partNumber}
          onChange={onChangePartNumber}
        />

        {processedMessages && (
          <Box textAlign='center' my={3}>
            {processedMessages.map((line, i) => (
              <Typography
                key={'process-message' + i}
                variant={i === 3 ? 'h5' : 'body1'}
                gutterBottom
              >
                {line}
              </Typography>
            ))}
          </Box>
        )}

        <Box mt={3} display='flex' justifyContent='flex-end'>
          <OrangeButton
            onClick={handleClose}
            className='btn-orange'
            color='primary'
            variant='contained'
            style={{ marginRight: '1em' }}
          >
            Close
          </OrangeButton>
          {showTestButton && (
            <Button onClick={onTest} color='primary' variant='contained'>
              Test
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default TestPartNumberRuleModal
