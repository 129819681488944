import React, { useState, useEffect, useCallback } from 'react'
import { TextField, Autocomplete, CircularProgress } from '@mui/material'
import _debounce from 'lodash/debounce'

import { useSnvSearchQuery } from 'services/hooks/supplierNameVariants/useSnvSearchQuery'
import { useMnvSearchQuery } from 'services/hooks/manufacturerNameVariants/useMnvSearchQuery'

const DEBOUNCE_TIME_MS = 1000

const AutocompleteSearch = ({ onChangeTerm, tableFor }) => {
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [term, setTerm] = useState('')
  const {
    data: snvSearchResult,
    refetch: refetchSnvResult,
    isFetching: isSnvFetching,
  } = useSnvSearchQuery({ term })
  const {
    data: mnvSearchResult,
    refetch: refetchMnvResult,
    isFetching: isMnvFetching,
  } = useMnvSearchQuery({ term })

  useEffect(() => {
    if (tableFor === 'supplier') {
      refetchSnvResult()
    } else {
      refetchMnvResult()
    }
  }, [term])

  const debouncedUpdateData = useCallback(
    _debounce((value: string) => {
      setTerm(value)
    }, DEBOUNCE_TIME_MS),
    []
  )

  const onChangeSearchField = async (e) => {
    setSearchValue(e.target.value)
    debouncedUpdateData(e.target.value)
  }

  const onChangeValue = (event, value) => {
    onChangeTerm(value)
  }

  return (
    <Autocomplete
      id='asynchronous-demo'
      style={{ width: 200 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      options={
        tableFor === 'supplier'
          ? snvSearchResult?.search || []
          : mnvSearchResult?.search || []
      }
      loading={isSnvFetching || isMnvFetching}
      onChange={onChangeValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Search'
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isSnvFetching || isMnvFetching ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          value={searchValue}
          onChange={onChangeSearchField}
        />
      )}
    />
  )
}

export default AutocompleteSearch
