import { useQueryClient } from '@tanstack/react-query'
import { ApiMetaDataDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useLazySprProcessQuery = () => {
  const queryClient = useQueryClient()
  const fetchData = (partNumber: string, partNumberClass: string) => {
    return queryClient.fetchQuery({
      queryKey: ['spr', 'process', partNumber, partNumberClass],
      queryFn: async () => {
        try {
          const res = await client.get<ApiResponseDTO>(
            `/supplier_preference_rules/process?part_number=${partNumber}&part_number_class=${partNumberClass}`
          )

          return res.data.data
        } catch (err) {
          toast.error(err.response.data.message)
          throw err.response.data
        }
      },
    })
  }
  return [fetchData]
}
