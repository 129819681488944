import { styled } from '@mui/material/styles'

const FilterBoxContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .filter-selector {
    width: 200px;
    margin-left: 16px;
  }

  .react-daterange-picker {
    margin-left: 16px;
    height: 56px;

    .react-daterange-picker__wrapper {
      border-radius: 4px;
      border: 1px solid rgb(196 196 196);

      .react-daterange-picker__inputGroup__input {
        outline: none;
      }
    }
  }

  .react-daterange-picker__calendar {
    z-index: 9999;
  }
`

export default FilterBoxContainer
