import React, { useState, useEffect } from 'react'
import { Tabs, Tab, AppBar } from '@mui/material'
import { useHistory, useParams } from 'react-router'

import TabContainer, { a11yProps } from '../../../../components/tabContainer'

import TabPricing from './tabPricing'
import TabShipments from './tabShipments'
import TabInventory from './tabInventory'
import TabRfq from './tabRfq'
import TabHistory from './tabHistory'
import TabRecommendations from './tabRecommendations'

const RequisitionTabs = ({ requisition }) => {
  const { id: requisitionId, tabName }: any = useParams()
  const { type, uuid } = requisition.requisition
  const [tabValue, setTabValue] = useState(0)
  const history = useHistory()

  useEffect(() => {
    switch (tabName) {
      case 'pricing':
        setTabValue(0)
        break
      case 'inventory':
        setTabValue(1)
        break
      case 'rfqpo':
        setTabValue(2)
        break
      case 'shipments':
      case 'recommendations':
        setTabValue(3)
        break
      case 'history':
        setTabValue(4)
        break
      default:
        break
    }
  }, [tabName])

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        history.push(`/requisitions/${requisitionId}/pricing`)
        break
      case 1:
        history.push(`/requisitions/${requisitionId}/inventory`)
        break
      case 2:
        history.push(`/requisitions/${requisitionId}/rfqpo`)
        break
      case 3:
        history.push(
          type === 'q4q'
            ? `/requisitions/${requisitionId}/recommendations`
            : `/requisitions/${requisitionId}/shipments`
        )
        break
      case 4:
        history.push(`/requisitions/${requisitionId}/history`)
        break
      default:
        break
    }
  }

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='Shipment Tabs'
        >
          <Tab label='Pricing' {...a11yProps(0)} />
          <Tab label='Inventory' {...a11yProps(1)} />
          <Tab label={type === 'q4q' ? 'RFQ' : 'RFQ/PO'} {...a11yProps(2)} />
          <Tab
            label={type === 'q4q' ? 'Recommendations' : 'Shipments'}
            {...a11yProps(3)}
          />
          <Tab label='History' {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      {tabValue === 0 && (
        <TabContainer>
          <TabPricing uuid={uuid} />
        </TabContainer>
      )}
      {tabValue === 1 && (
        <TabContainer>
          <TabInventory uuid={uuid} />
        </TabContainer>
      )}
      {tabValue === 2 && (
        <TabContainer>
          <TabRfq uuid={uuid} />
        </TabContainer>
      )}
      {tabValue === 3 && (
        <TabContainer>
          {type === 'q4q' ? (
            <TabRecommendations requisition={requisition} />
          ) : (
            <TabShipments uuid={uuid} />
          )}
        </TabContainer>
      )}
      {tabValue === 4 && (
        <TabContainer>
          <TabHistory uuid={uuid} />
        </TabContainer>
      )}
    </div>
  )
}

export default RequisitionTabs
