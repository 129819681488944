import AuthWrapper from '../authWrapper'
import { Typography, Box, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

const ForgotPasswordConfirm = () => {
  return (
    <AuthWrapper>
      <Typography variant='body1' align='left' gutterBottom>
        A temporary password has been sent to your email. Please use it to
        login.
      </Typography>

      <div className='card-actions'>
        <Box sx={{ mt: 2 }}>
          <Link
            component={RouterLink}
            to={{
              pathname: '/login',
            }}
          >
            Login
          </Link>
        </Box>
      </div>
    </AuthWrapper>
  )
}

export default ForgotPasswordConfirm
