import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const QuantityChartContainer = styled(Box)`
  width: 100%;

  .summary {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    p.first {
      font-size: 1.2em;
      font-weight: bold;
    }

    &.chart {
      h2 {
        position: absolute;
        top: 46%;
        transform: translate(-50%, -50%);
        margin: 0;
        left: 50%;
      }
    }
  }

  .status {
    text-align: center;
  }
`

export { QuantityChartContainer }
