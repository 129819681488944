import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { client } from '../../services/helpers/apiConfig'

export type File = {
  id: number
  date_added: string
  date_last_updated: string
  deleted: number
  file_type: string
  filename: string
  md5: string
  mime_type: string
  path: string
  uuid: string
}

export type FileList = {
  file: File[]
}

async function fetchPoFileList(uuid: string): Promise<FileList> {
  const res = await client.get(`/po/${uuid}/file/list`)
  return res.data.data
}

async function fetchRfqFileList(uuid: string): Promise<FileList> {
  const res = await client.get(`/rfq/${uuid}/file/list`)
  return res.data.data
}

const useTabFiles = (uuid: string, usageFor: string) => {
  function getPoFileList<TData = FileList>() {
    return useQuery({
      queryKey: ['poFileList', uuid],
      queryFn: () => fetchPoFileList(uuid),
    })
  }

  function getRfqFileList<TData = FileList>() {
    return useQuery({
      queryKey: ['rfqFileList', uuid],
      queryFn: () => fetchRfqFileList(uuid),
    })
  }

  return usageFor === 'po'
    ? {
        ...getPoFileList(),
      }
    : {
        ...getRfqFileList(),
      }
}

export default useTabFiles
