import React from 'react'
import { Box } from '@mui/material'

type TabContainerProps = {
  children?: React.ReactNode
}

const TabContainer = (props: TabContainerProps) => {
  return <Box sx={{ py: 3 }}>{props.children}</Box>
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default TabContainer
