import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { PriceHistoryDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type ResponseDTO = {
  historical_average: number
  price_history: Record<string, PriceHistoryDTO[]>
}

const useRequisitionPriceHistoryQuery = (
  requisitionId,
  enabled = true
): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: ['requisition', 'price_history', requisitionId],
    queryFn: async () => {
      try {
        const res = await client.get(
          `/requisitions/price_history/${requisitionId}`
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data)
        throw err.response.data
      }
    },
    enabled: enabled,
  })
}

export { useRequisitionPriceHistoryQuery }
