import { ApiMetaDataDTO, ErrorDTO, UserAuthDTO } from 'declarations'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { client, queryClient } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  password: string
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useGetGoogleAuthKeyMutation = () =>
  useMutation<unknown, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        const res = await client.get(
          `/user/getGoogleAuthKey?password=${values.password}`
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
