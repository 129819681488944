import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { HistoryDTO, RfqDTO, StatusDTO, TimelineDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

export type RfqHistoryResponseDTO = {
  history: HistoryDTO[]
  rfq: RfqDTO
  status_list: StatusDTO[]
  timeline: TimelineDTO[]
}

const useRfqHistoryQuery = (
  uuid: string | number,
  enabled = true
): UseQueryResult<RfqHistoryResponseDTO> => {
  return useQuery({
    queryKey: ['rfq', 'history', uuid],
    queryFn: async () => {
      const res = await client.get(`/rfq/history/${uuid}`)

      return res.data.data
    },
    enabled,
  })
}

export { useRfqHistoryQuery }
