import React from 'react'

import PurchaseOrderHistory from 'components/purchaseOrderHistory'
import { useRequisitionHistoryQuery } from 'services/hooks/requisition/useRequisitionHistoryQuery'

type TabHistoryProps = {
  uuid: string
}
const TabHistory = ({ uuid }: TabHistoryProps) => {
  const { data: historyData } = useRequisitionHistoryQuery(uuid)

  return (
    <div>
      {historyData && <PurchaseOrderHistory histories={historyData.history} />}
    </div>
  )
}

export default TabHistory
