import { css } from '@emotion/react'

import bgLogin from '../../assets/images/background/login_hero.jpg'

export const AuthContainerCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url(${bgLogin});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const AuthCardContainerCSS = css`
  width: 320px;

  .card-content {
    text-align: center;

    .logo {
      width: 250px;
      margin-bottom: 10px;
    }

    .card-actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 10px 0;

      .tooltip-password {
        margin-bottom: 5px;
      }

      .btn-submit {
        width: 80px;
        height: 36px;
        margin-bottom: 10px;
      }
    }
  }
`
