import React, { useEffect, useState } from 'react'
import { Typography, Box } from '@mui/material'
import { toast } from 'react-toastify'
import _find from 'lodash/find'

import Container from './index.style'
import ScheduleField from './scheduleField'
import moment from 'moment'
import { useRfqQuoteUpdateMutation } from 'services/hooks/rfq/useRfqQuoteUpdateMutation'
import { useQueryClient } from '@tanstack/react-query'
import { usePoLineUpdateMutation } from 'services/hooks/purchaseOrders/usePoLineUpdateMutation'
import { useParams } from 'react-router'
import { usePoReadQuery } from 'services/hooks/purchaseOrders/usePoReadQuery'

type DispatchSchedulesProps = {
  lineIndex?: any
  line: any
  scheduleFor: string
  showExpectedScheduleDate?: boolean
  disabled?: boolean
}

const DispatchSchedules = ({
  lineIndex,
  line,
  scheduleFor,
  showExpectedScheduleDate = true,
  disabled = false,
}: DispatchSchedulesProps) => {
  const { id: poId }: any = useParams()
  const { data: poData } = usePoReadQuery(poId)
  const [schedules, setSchedules] = useState(line.dispatch_schedule)
  const { mutate: rfqQuoteUpdateMutation } = useRfqQuoteUpdateMutation()
  const { mutate: poLineUpdateMutation } = usePoLineUpdateMutation()
  const queryClient = useQueryClient()

  useEffect(() => {
    setSchedules(line.dispatch_schedule)
  }, [line])

  const orderPermissions = poData?.status_list?.find(
    (status) => status.id === poData.status_id
  )?.permissions
  const canSetExplicitDispatchScheduleDates =
    orderPermissions?.can_set_explicit_dispatch_schedule_dates || false

  const updateQuote = async (updatedSchedules) => {
    if (scheduleFor === 'rfq') {
      const lineWithEmptyLeadTime = _find(updatedSchedules, {
        lead_time_menu: '',
      })
      const lineWithEmptyQuantity = _find(updatedSchedules, {
        quantity: NaN,
      })
      if (!lineWithEmptyLeadTime && !lineWithEmptyQuantity) {
        rfqQuoteUpdateMutation(
          {
            uuid: line.rfq_quote_line_uuid,
            body: {
              dispatch_schedule: updatedSchedules,
            },
          },
          {
            onSuccess(data, variables, context) {
              toast.success('Dispatch schedule is updated successfully')
            },
          }
        )
      }
    } else if (scheduleFor === 'po') {
      const poDispatchScheduleBody = updatedSchedules.map((schedule) => {
        if (
          schedule.expected_ship_date?.timestamp &&
          canSetExplicitDispatchScheduleDates
        ) {
          return {
            quantity: schedule.quantity,
            expected_ship_date: moment(
              schedule.expected_ship_date.timestamp
            ).format('YYYY-MM-DD'),
          }
        } else {
          return {
            quantity: schedule.quantity,
            lead_time: schedule.lead_time_menu || '',
          }
        }
      })

      const lineWithEmptyLeadTime = _find(poDispatchScheduleBody, {
        lead_time: '',
      })
      const lineWithEmptyQuantity = _find(poDispatchScheduleBody, {
        quantity: NaN,
      })

      if (!lineWithEmptyLeadTime && !lineWithEmptyQuantity) {
        poLineUpdateMutation(
          {
            uuid: line.uuid,
            updateBody: {
              dispatch_schedule: poDispatchScheduleBody,
            },
          },
          {
            onSuccess(data, variables, context) {
              setSchedules(data.line.dispatch_schedule)
              toast.success('Dispatch schedule is updated successfully')

              queryClient.invalidateQueries({ queryKey: ['po', 'read'] })
            },
          }
        )
      }
    }
  }

  const onChangeQuantity = (quantity: number, key: number) => {
    let newSchedules = [...schedules]
    newSchedules[key].quantity = quantity

    let totalQuantity = 0

    if (newSchedules.length > 1) {
      newSchedules.map((schedule, index) => {
        if (key === newSchedules.length - 1) {
          totalQuantity += schedule.quantity
        } else if (index < newSchedules.length - 1) {
          totalQuantity += schedule.quantity
        }

        return totalQuantity
      })
    } else {
      totalQuantity += newSchedules[0].quantity
    }

    if (totalQuantity > line.quantity) {
      toast.error(
        'The total quantity in the dispatch schedule is greater than the quoted quantity of 5,500. Please correct the quantities.'
      )
    } else if (totalQuantity < line.quantity) {
      const remainingQuantity = line.quantity - totalQuantity
      if (key === newSchedules.length - 1) {
        const newSchedule = { quantity: remainingQuantity, lead_time_menu: '' }
        newSchedules = [...newSchedules, newSchedule]
      } else {
        newSchedules[newSchedules.length - 1] = {
          quantity: remainingQuantity,
          lead_time_menu: '',
        }
      }
    }

    setSchedules(newSchedules)
    updateQuote(newSchedules)
  }

  const onDeleteSchedule = (key: number) => {
    const newSchedules = [...schedules]

    newSchedules[key - 1] = {
      ...newSchedules[key - 1],
      quantity: newSchedules[key - 1].quantity + newSchedules[key].quantity,
    }
    newSchedules.splice(key, 1)
    setSchedules(newSchedules)
    updateQuote(newSchedules)
  }

  const onChangeLeadTime = (key: number, leadTime: string) => {
    const newSchedules = [...schedules]
    newSchedules[key].lead_time_menu = leadTime
    setSchedules(newSchedules)
    updateQuote(newSchedules)
  }

  const onChangeExplicitCheck = (index: number, value: boolean) => {
    const newSchedules = JSON.parse(JSON.stringify(schedules))

    if (value === true) {
      delete newSchedules[index].lead_time_days
      delete newSchedules[index].lead_time_menu
    } else {
      delete newSchedules[index].expected_ship_date
    }

    updateQuote(newSchedules)
  }

  return (
    <Container>
      {scheduleFor === 'rfq' && (
        <Typography variant='subtitle1' gutterBottom>
          Dispatch Schedule
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {schedules &&
          schedules.map((schedule, i) => (
            <ScheduleField
              schedule={schedule}
              onChangeQuantity={onChangeQuantity}
              onChangeLeadTime={onChangeLeadTime}
              onDeleteSchedule={onDeleteSchedule}
              onChangeExplicitCheck={onChangeExplicitCheck}
              showExpectedDate={showExpectedScheduleDate}
              index={i}
              key={'schedule' + i}
              disabled={disabled}
              canSetExplicitDispatchScheduleDates={
                canSetExplicitDispatchScheduleDates
              }
            />
          ))}
      </Box>
    </Container>
  )
}

export default DispatchSchedules
