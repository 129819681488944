import {
  keepPreviousData,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'
import { ColorDTO, QuantitiesColorsDTO, RequisitionDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

type RequisitionListQueryParamsDTO = {
  pageIndex: number
  rowsCount: number
  filterValues?: {
    statuses: string[]
    users: string[]
    term: string
  }
}

type ResponseDTO = {
  quantities_colors: QuantitiesColorsDTO
  requisition: RequisitionDTO[]
  total_rows: number
}

const useRequisitionListQuery = (
  params: RequisitionListQueryParamsDTO
): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: [
      'requisition',
      'list',
      params.pageIndex,
      params.rowsCount,
      params.filterValues.statuses,
      params.filterValues.users,
      params.filterValues.term,
    ],
    queryFn: async () => {
      try {
        const reqParams = {
          status_id: params.filterValues.statuses.join(),
          assignee: params.filterValues.users.join(),
          search: params.filterValues.term,
          page: params.pageIndex,
          rows: params.rowsCount,
        }

        const res = await client.get('requisitions/list', { params: reqParams })

        return res.data.data
      } catch (err) {
        throw err.response.data
      }
    },
    placeholderData: keepPreviousData,
  })
}

export { useRequisitionListQuery }
