import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { HistoryDTO, PoDTO, StatusDTO, TimelineDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type ResponseDTO = {
  history: HistoryDTO[]
  purchase_order: PoDTO
  status_list: StatusDTO[]
  timeline: TimelineDTO[]
}

type PoHistoryParamsDTO = {
  uuid: string
  enabled?: boolean
}

const usePoHistoryQuery = ({
  uuid,
  enabled = true,
}: PoHistoryParamsDTO): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: ['po', 'history', uuid],
    queryFn: async () => {
      try {
        const res = await client.get(`po/history/${uuid}`)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    enabled,
    retry: false,
  })
}

export { usePoHistoryQuery }
