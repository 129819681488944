import {
  keepPreviousData,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'
import { ShipmentListItemDTO } from 'declarations'
import moment, { Moment } from 'moment'

import { client } from 'services/helpers/apiConfig'

type RequisitionListQueryParamsDTO = {
  pageIndex: number
  rowsCount: number
  filterValues?: {
    statuses: string[]
    startDate: Date
    endDate: Date
  }
}

type ResponseDTO = {
  shipments: ShipmentListItemDTO[]
  total_rows: number
}

const useShipmentListQuery = (
  params: RequisitionListQueryParamsDTO
): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: [
      'shipment',
      'list',
      params.pageIndex,
      params.rowsCount,
      params.filterValues?.statuses,
      params.filterValues?.startDate,
      params.filterValues?.endDate,
    ],
    queryFn: async () => {
      const reqParams = {
        page: params.pageIndex,
        status: params.filterValues.statuses.join(),
        after: moment(params.filterValues.startDate).format('YYYY-MM-DD'),
        before: moment(params.filterValues.endDate).format('YYYY-MM-DD'),
        rows: params.rowsCount,
      }

      const res = await client.get('shipment/list', { params: reqParams })

      return res.data.data
    },
    placeholderData: keepPreviousData,
  })
}

export { useShipmentListQuery }
