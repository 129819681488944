import React, { useState } from 'react'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material'

import SearchBox from './searchBox'
import { PartNumberRuleClassDTO, SupplierDTO } from 'declarations'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type FilterBoxProps = {
  suppliers: SupplierDTO[]
  partNumberClasses: PartNumberRuleClassDTO[]
  onChangeFilter: (
    supplierId: string[],
    partNumberClass: string[],
    term?: string
  ) => void
}

const FilterBox = ({
  suppliers,
  partNumberClasses,
  onChangeFilter,
}: FilterBoxProps) => {
  const [supplier, setSupplier] = useState([])
  const [partNumberClass, setPartNumberClass] = useState([])

  const onChangeSupplier = (e) => {
    setSupplier(e.target.value)
    onChangeFilter(e.target.value, partNumberClass)
  }

  const onChangePartNumberClass = (e) => {
    setPartNumberClass(e.target.value)
    onChangeFilter(supplier, e.target.value)
  }

  const onChangeTerm = (term) => {
    onChangeFilter(supplier, partNumberClass, term)
  }

  return (
    <Box display='flex' alignItems='center'>
      <Box mr={3}>
        <SearchBox
          supplierIds={supplier}
          partNumberClassIds={partNumberClass}
          onChangeTerm={onChangeTerm}
        />
      </Box>

      <Typography style={{ marginRight: '1rem' }}>Filter By:</Typography>

      <FormControl variant='outlined' sx={{ margin: 1, minWidth: 200 }}>
        <InputLabel
          htmlFor='mutiple-supplier-label-outlined'
          id='mutiple-supplier-label'
        >
          Supplier
        </InputLabel>
        <Select
          labelId='mutiple-supplier-label'
          id='mutiple-supplier'
          multiple
          value={supplier}
          onChange={onChangeSupplier}
          input={
            <OutlinedInput
              id='mutiple-supplier-label-outlined'
              label='Supplier'
            />
          }
          MenuProps={MenuProps}
        >
          {suppliers.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant='outlined' sx={{ margin: 1, minWidth: 200 }}>
        <InputLabel
          htmlFor='mutiple-part-number-class-label-outlined'
          id='mutiple-part-number-class-label'
        >
          Part Number Class
        </InputLabel>
        <Select
          labelId='mutiple-part-number-class-label'
          id='mutiple-part-number-class'
          multiple
          value={partNumberClass}
          onChange={onChangePartNumberClass}
          input={
            <OutlinedInput
              id='mutiple-part-number-class-label-outlined'
              label='Part Number Class'
            />
          }
          MenuProps={MenuProps}
        >
          {partNumberClasses.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default FilterBox
