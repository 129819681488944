import { ApiMetaDataDTO, UserTypeDTO } from 'declarations'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'

type ResponseDTO = {
  code: number
  data: UserTypeDTO[]
  api_metadata: ApiMetaDataDTO
}

export const useUserTypesQuery = (): UseQueryResult<UserTypeDTO[], unknown> => {
  return useQuery({
    queryKey: ['user-types'],
    queryFn: async () => {
      const { data } = await client.get<ResponseDTO>('userType/list')

      return data.data
    },
  })
}
