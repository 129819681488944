import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  DataGrid,
  GridColDef,
  GridPagination,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid'
import { Box, IconButton } from '@mui/material'

import { useMnvListQuery } from 'services/hooks/manufacturerNameVariants/useMnvListQuery'
import { useMnvServicesQuery } from 'services/hooks/manufacturerNameVariants/useMnvServicesQuery'
import { useMnvDeleteMutation } from 'services/hooks/manufacturerNameVariants/useMnvDeleteMutation'
import { NameVariantDTO } from 'declarations'
import { useManufacturersListQuery } from 'services/hooks/others/useManufacturersListQuery'
import FilterBox from './table/filterBox'

import DataGridCustomPagination from 'components/dataGridCustomPagination'
import SelectAssociate from './table/selectAssociate'
import TableActions from './table/tableActions'
import { useMnvUpdateMutation } from 'services/hooks/manufacturerNameVariants/useMnvUpdateMutation'

const ManufacturerNameVariants = () => {
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const [defaultBulkSupplier, setDefaultBulkSupplier] = useState('')
  const { mutate: updateMnvMutation } = useMnvUpdateMutation()
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25,
  })
  const [filters, setFilters] = useState({
    showOnlyUnassignedName: false,
    service: '',
    term: '',
  })
  const {
    data: listData,
    refetch,
    isFetching: isFetchingMnvList,
  } = useMnvListQuery({
    pageIndex: paginationModel.page,
    rowsCount: paginationModel.pageSize,
    filterValues: {
      showOnlyUnassignedName: filters.showOnlyUnassignedName,
      service: filters.service,
    },
    searchTerm: filters.term,
  })
  const { data: services } = useMnvServicesQuery()
  const { data: manufacturers } = useManufacturersListQuery()
  const { mutate: deleteMutation } = useMnvDeleteMutation()
  const rowCountRef = React.useRef(listData?.total_rows || 0)

  const rowCount = React.useMemo(() => {
    if (listData?.total_rows !== undefined) {
      rowCountRef.current = listData.total_rows
    }
    return rowCountRef.current
  }, [listData?.total_rows])

  const onChangeFilter = (filterObj) => {
    setFilters(filterObj)
    setPaginationModel({
      ...paginationModel,
      page: 0,
    })
  }

  const onDeleteRow = (row: NameVariantDTO) => {
    deleteMutation(
      { uuid: row.uuid },
      {
        onSuccess() {
          refetch()
          toast.success(
            `${row.name_variant}/${row.service_name} successfully deleted`
          )
        },
      }
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'name_variant',
      headerName: 'Name Variant',
      flex: 1,
    },
    {
      field: 'service_name',
      headerName: 'Service',
      flex: 1,
    },
    {
      field: 'table_for',
      headerName: 'Manufacturer',
      renderCell: (params: GridRenderCellParams) =>
        manufacturers && (
          <SelectAssociate
            list={manufacturers}
            value={params.row.manufacturer_id}
            uuid={params.row.uuid}
            tableFor='manufacturer'
          />
        ),
      display: 'flex',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'delete',
      headerName: '',
      renderCell: (params: GridRenderCellParams) => (
        <IconButton aria-label='delete'>
          <DeleteIcon onClick={() => onDeleteRow(params.row)} />
        </IconButton>
      ),
    },
  ]

  const onUpdateSelected = async (selectedValue) => {
    setDefaultBulkSupplier(selectedValue)

    updateMnvMutation(
      {
        uuids: rowSelectionModel as string[],
        updateBody: {
          manufacturer_id: selectedValue,
        },
      },
      {
        onSuccess() {
          refetch()
        },
      }
    )
  }

  useEffect(() => {
    setDefaultBulkSupplier('')
  }, [rowSelectionModel])

  return (
    <>
      {services && (
        <Box display='flex' alignItems='center' justifyContent='flex-end'>
          <FilterBox
            services={services}
            onChangeFilter={onChangeFilter}
            tableFor='manufacturer'
          />
        </Box>
      )}
      <Box sx={{ height: 500 }}>
        <DataGrid
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          rows={listData?.manufacturer_name_variants || []}
          columns={columns} // Define your columns
          rowCount={rowCount}
          paginationMode='server'
          paginationModel={paginationModel}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          onPaginationModelChange={setPaginationModel}
          loading={isFetchingMnvList}
          slots={{
            pagination: (props) => (
              <GridPagination
                ActionsComponent={DataGridCustomPagination}
                {...props}
              />
            ),
          }}
          getRowId={(row) => row.uuid}
        />
      </Box>
      {manufacturers && (
        <TableActions
          defaultValue={defaultBulkSupplier}
          bulkActionDisabled={rowSelectionModel.length === 0}
          list={manufacturers}
          onUpdateSelected={onUpdateSelected}
          tableFor='supplier'
        />
      )}
    </>
  )
}

export default ManufacturerNameVariants
