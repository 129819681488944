import React, { useState, useCallback } from 'react'
import { TextField, Autocomplete, CircularProgress } from '@mui/material'
import _debounce from 'lodash/debounce'

import { useSearchPartNumberRulesQuery } from 'services/hooks/partNumberRules/useSearchPartNumberRulesQuery'

type AutocompleteSearchProps = {
  supplierIds: string[]
  partNumberClassIds: string[]
  onChangeTerm: (value: string) => void
}

const DEBOUNCE_TIME_MS = 1000

const AutocompleteSearch = ({
  supplierIds,
  partNumberClassIds,
  onChangeTerm,
}: AutocompleteSearchProps) => {
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const strSupplierIds = supplierIds.toString()
  const strPartNumberClassIds = partNumberClassIds.toString()
  const [term, setTerm] = useState('')
  const { data: searchPartNumberRulesResult, isFetching } =
    useSearchPartNumberRulesQuery({
      term: term,
      supplierIds: strSupplierIds,
      partNumberClassIds: strPartNumberClassIds,
    })

  const debouncedUpdateData = useCallback(
    _debounce((value: string) => {
      setTerm(value)
    }, DEBOUNCE_TIME_MS),
    []
  )

  const onChangeSearchField = async (e) => {
    setSearchValue(e.target.value)
    debouncedUpdateData(e.target.value)
  }

  const onChangeValue = (event, value) => {
    onChangeTerm(value)
  }

  return (
    <Autocomplete
      id='asynchronous-demo'
      style={{ width: 200 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      options={searchPartNumberRulesResult?.search.terms || []}
      loading={isFetching}
      onChange={onChangeValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Search'
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          value={searchValue}
          onChange={onChangeSearchField}
        />
      )}
    />
  )
}

export default AutocompleteSearch
