import React, { useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridPagination,
  GridRenderCellParams,
} from '@mui/x-data-grid'
import { Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import { DateRangePicker } from 'react-date-range'

import moment from 'moment'

import { useShipmentListQuery } from 'services/hooks/shipment/useShipmentListQuery'
import { Box, Link, Tooltip, Button, TextField, Popover } from '@mui/material'
import ShipmentStatusBar from 'components/shipmentStatusBar'
import { useShipmentStatusListQuery } from 'services/hooks/shipment/useShipmentStatusListQuery'
import { FilterSelect } from 'components/listTableFilterBox'
import DataGridCustomPagination from 'components/dataGridCustomPagination'

const RequisitionIdColumn = ({ lines }) => {
  const requisitionIds = lines.map((line) =>
    line.requisition ? line.requisition.order_id : null
  )

  return (
    <div>
      <Link component={RouterLink} to={`/requisitions/${requisitionIds[0]}`}>
        {requisitionIds[0]}
      </Link>{' '}
      {requisitionIds.length > 1 && (
        <Tooltip title={requisitionIds.join(', ')}>
          <Button color='primary'>{requisitionIds.length - 1} more...</Button>
        </Tooltip>
      )}
    </div>
  )
}

const columns: GridColDef[] = [
  {
    field: 'tracking_number',
    headerName: 'Tracking Number',
    renderCell: (params: GridRenderCellParams) => (
      <Link component={RouterLink} to={`/shipment/${params.value}`}>
        {params.value}
      </Link>
    ),
    minWidth: 250,
  },
  {
    field: 'carrier_service',
    headerName: 'Carrier/Service',
    valueGetter: (value, row) => `${row['carrier']} ${row['service_name']}`,
    minWidth: 150,
  },
  {
    field: 'supplier',
    headerName: 'Supplier',
    valueGetter: (value, row) => row['lines'][0].supplier_name,
  },
  {
    field: 'requisition_id',
    headerName: 'Requisition ID',
    valueGetter: (value, row) => row['lines'][0].requisition?.order_id,
    renderCell: (params) => <RequisitionIdColumn lines={params.row['lines']} />,
    width: 200,
  },
  {
    field: 'ship_date.timestamp',
    headerName: 'Shipped',
    renderCell: (params) => {
      return !_isEmpty(params.row.ship_date) ? (
        <Tooltip title={params.row.ship_date.formatted_date}>
          <span>{params.row.ship_date.human_date.relative.long}</span>
        </Tooltip>
      ) : (
        ''
      )
    },
    minWidth: 150,
    valueGetter: (value, row) => row.ship_date.formatted_date,
  },
  {
    field: 'expected_delivery_date.timestamp',
    headerName: 'Delivery',
    renderCell: (params) => {
      return !_isEmpty(params.row.expected_delivery_date) ? (
        <Tooltip title={params.row.expected_delivery_date.formatted_date}>
          <span>
            {params.row.expected_delivery_date.human_date.relative.long}
          </span>
        </Tooltip>
      ) : (
        ''
      )
    },
    minWidth: 150,
    valueGetter: (value, row) => row.expected_delivery_date.formatted_date,
  },
  {
    field: 'last_scan_date.timestamp',
    headerName: 'Last Scan Date',
    renderCell: (params) => {
      return !_isEmpty(params.row.last_scan_date) ? (
        <Tooltip title={params.row.last_scan_date.formatted_date}>
          <span>{params.row.last_scan_date.human_date.relative.long}</span>
        </Tooltip>
      ) : (
        ''
      )
    },
    minWidth: 150,
    valueGetter: (value, row) => row.last_scan_date.formatted_date,
  },
  {
    field: 'status.term',
    headerName: 'Status',
    valueGetter: (value, row) => `${row.status.term}`,
    renderCell: (params) => <ShipmentStatusBar status={params.row.status} />,
    minWidth: 300,
  },
]

const ShipmentList = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25,
  })
  const [filterValues, setFilterValues] = useState({
    statuses: [],
    dateRange: {
      startDate: moment().subtract(3, 'years').toDate(),
      endDate: moment().toDate(),
      key: 'selection',
    },
  })

  const {
    data: shipmentList,
    isFetching,
    refetch,
  } = useShipmentListQuery({
    pageIndex: paginationModel.page,
    rowsCount: paginationModel.pageSize,
    filterValues: {
      statuses: filterValues.statuses,
      startDate: filterValues.dateRange.startDate,
      endDate: filterValues.dateRange.endDate,
    },
  })
  const { data: shipmentStatusList } = useShipmentStatusListQuery()
  const rowCountRef = React.useRef(shipmentList?.total_rows || 0)

  const rowCount = React.useMemo(() => {
    if (shipmentList?.total_rows !== undefined) {
      rowCountRef.current = shipmentList.total_rows
    }
    return rowCountRef.current
  }, [shipmentList?.total_rows])

  const onChangeFilter = (filterType, values) => {
    setFilterValues({
      ...filterValues,
      [filterType]: values,
    })
    setPaginationModel({
      ...paginationModel,
      page: 0,
    })
  }

  const onClickDateRangeField = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseDateRangePopover = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const onChangeRange = (ranges) => {
    setFilterValues({
      ...filterValues,
      dateRange: ranges.selection,
    })
  }

  return (
    <Box>
      <Box
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          justifyContent: 'flex-end',
        }}
      >
        <Typography>Filter By:</Typography>
        {shipmentStatusList && (
          <FilterSelect
            label='Status'
            filterType='statuses'
            filterList={shipmentStatusList}
            selectValues={filterValues.statuses}
            onChange={onChangeFilter}
          />
        )}
        <Box>
          <TextField
            id='date-range'
            label='Date Range'
            variant='outlined'
            value={`${moment(filterValues.dateRange.startDate).format(
              'YYYY/MM/DD'
            )} - ${moment(filterValues.dateRange.endDate).format(
              'YYYY/MM/DD'
            )}`}
            onClick={onClickDateRangeField}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onCloseDateRangePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <DateRangePicker
              ranges={[filterValues.dateRange]}
              onChange={onChangeRange}
            />
          </Popover>
        </Box>
      </Box>
      <Box height={600}>
        <DataGrid
          rows={shipmentList?.shipments || []}
          columns={columns} // Define your columns
          rowCount={rowCount}
          paginationMode='server'
          paginationModel={paginationModel}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          onPaginationModelChange={setPaginationModel}
          loading={isFetching}
          getRowId={(row) => row.shipment_id}
          slots={{
            pagination: (props) => (
              <GridPagination
                ActionsComponent={DataGridCustomPagination}
                {...props}
              />
            ),
          }}
        />
      </Box>
    </Box>
  )
}

export default ShipmentList
