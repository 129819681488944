import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  TextField,
  Box,
  FormControl,
  InputLabel,
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import { useParams } from 'react-router'

import leadTimeValues from '../../../utils/leadTimes'

import ChangeStatusModalContent from './components/changeStatusModalContent'
import LineTableRow from './lineTableRow'
import { indigo, yellow } from '@mui/material/colors'
import { useManufacturersListQuery } from 'services/hooks/others/useManufacturersListQuery'
import { usePoLineCreateMutation } from 'services/hooks/purchaseOrders/usePoLineCreateMutation'
import { usePoLineDeleteMutation } from 'services/hooks/purchaseOrders/usePoLineDeleteMutation'
import { useQueryClient } from '@tanstack/react-query'

const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-input': {
    color: '#fff !important',
  },
  '& .MuiInputLabel-root': {
    color: '#fff !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fff !important',
  },
}))

const CustomSelect = styled(Select)(() => ({
  '& .MuiFormLabel-root': {
    color: '#fff !important',
  },
  '& .MuiOutlinedInput-input': {
    color: '#fff !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fff !important',
  },
}))

const LineTable = ({ isAddNewLine, closeAddNewLine, poData }) => {
  const queryClient = useQueryClient()
  const { id: poId }: any = useParams()
  const { data: manufacturers } = useManufacturersListQuery()
  const [rows, setRows] = useState([])
  const [inputValues, setInputValues] = useState<any>({
    quantity: 0,
    item: '',
    unit_cost: 0,
    lead_time: '',
    manufacturer_id: '',
  })
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false)
  const [changeStatusFor, setChangeStatusFor] = useState<string>()
  const [selectedRowToDelete, setSelectedRowToDelete] = useState({
    row: null,
    index: null,
  })
  const { mutate: poLineCreateMutation } = usePoLineCreateMutation()
  const { mutate: poLineDeleteMutation } = usePoLineDeleteMutation()

  useEffect(() => {
    if (poData) {
      setRows(poData.lines.lines)
    }
  }, [poData])

  const onInputUpdate = (
    event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const { value } = event.target
    setInputValues({
      ...inputValues,
      [name]: value,
    })
  }

  const onSaveNewLine = async () => {
    poLineCreateMutation(
      {
        ...inputValues,
        po_id: poId,
      },
      {
        onSuccess(data, variables, context) {
          toast.success('New line item is added.')
          queryClient.invalidateQueries({ queryKey: ['po', 'read'] })

          setInputValues({
            quantity: 0,
            item: '',
            unit_cost: 0,
          })
          closeAddNewLine()
        },
      }
    )
  }

  const onDeleteLine = async (row, i) => {
    poLineDeleteMutation(
      { uuid: row.uuid },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({ queryKey: ['po', 'read'] })
          setSelectedRowToDelete({ row: null, index: null })
        },
      }
    )
  }

  const onCheckDeleteLinePermission = (row, i) => {
    if (!poData.permissions.can_delete_lines) {
      setOpenChangeStatusModal(true)
      setChangeStatusFor('delete this line')
      setSelectedRowToDelete({ row, index: i })
    } else {
      onDeleteLine(row, i)
    }
  }

  const onCloseChangeStatusModal = () => {
    setOpenChangeStatusModal(false)
    setSelectedRowToDelete({ row: null, index: null })
  }

  return (
    <Box
      sx={{
        '& .mui-icon-button': {
          padding: 0,
        },

        '& .lead-time-selector': {
          minWidth: '150px',
        },

        '& .new-line': {
          backgroundColor: indigo[500],

          '& th': {
            position: 'relative',
          },

          '& .input-field': {
            width: '100px',
          },
        },

        '& .btn-save': {
          color: yellow[700],
        },
      }}
    >
      <TableContainer component={Paper}>
        <Table size='small' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Quantity</TableCell>
              <TableCell>Part Number</TableCell>
              <TableCell>Supplier Part Number</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Requisition</TableCell>
              <TableCell sx={{ width: 150 }}>Unit Price</TableCell>
              <TableCell>Extended Price</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row, i) => (
                <LineTableRow
                  poData={poData}
                  key={'line-table-row' + i}
                  row={row}
                  index={i}
                  onDeleteLine={onCheckDeleteLinePermission}
                />
              ))}
            {isAddNewLine && (
              <TableRow className='new-line'>
                <TableCell component='th' scope='row'>
                  <TextField
                    id='number'
                    label='Qunatity'
                    variant='outlined'
                    type='number'
                    value={inputValues.quantity}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onInputUpdate(event, 'quantity')
                    }
                  />
                </TableCell>
                <TableCell>
                  <CustomTextField
                    id='item'
                    label='Part Number'
                    variant='outlined'
                    value={inputValues.item}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onInputUpdate(event, 'item')
                    }
                  />
                </TableCell>
                <TableCell>
                  <FormControl
                    variant='outlined'
                    fullWidth
                    className='lead-time-selector'
                  >
                    <InputLabel
                      id='lead-time-select-outlined-label'
                      sx={{ color: '#fff !important' }}
                    >
                      Lead Time
                    </InputLabel>
                    <CustomSelect
                      labelId='lead-time-select-outlined-label'
                      id='lead-time-select-outlined'
                      value={inputValues.lead_time}
                      onChange={(event: SelectChangeEvent) =>
                        onInputUpdate(event, 'lead_time')
                      }
                      label='Lead Time'
                    >
                      {leadTimeValues.map((leadtime, i) => (
                        <MenuItem
                          value={leadtime.value}
                          key={'lead-time-val' + i}
                        >
                          {leadtime.label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl
                    variant='outlined'
                    fullWidth
                    className='manufacturer-selector'
                  >
                    <InputLabel
                      id='manufacturer-select-outlined-label'
                      sx={{ color: '#fff !important' }}
                    >
                      Manufacturer
                    </InputLabel>
                    <CustomSelect
                      labelId='manufacturer-select-outlined-label'
                      id='manufacturer-select-outlined'
                      value={inputValues.manufacturer_id}
                      onChange={(event: SelectChangeEvent) =>
                        onInputUpdate(event, 'manufacturer_id')
                      }
                      label='Manufacturer'
                    >
                      {manufacturers?.map((manufacturer, i) => (
                        <MenuItem
                          value={manufacturer.id}
                          key={'manufacturer' + i}
                        >
                          {manufacturer.name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <CustomTextField
                    id='unit_cost'
                    label='Unit Price'
                    variant='outlined'
                    value={inputValues.unit_cost}
                    type='number'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onInputUpdate(event, 'unit_cost')
                    }
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <IconButton className='btn-save' onClick={onSaveNewLine}>
                    <SaveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog onClose={onCloseChangeStatusModal} open={openChangeStatusModal}>
        <ChangeStatusModalContent
          changeStatusFor={changeStatusFor}
          onDeleteLine={() =>
            onDeleteLine(selectedRowToDelete.row, selectedRowToDelete.index)
          }
          onClose={onCloseChangeStatusModal}
        />
      </Dialog>
    </Box>
  )
}

export default LineTable
