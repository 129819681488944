import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

import PoList from './list'

const Po = (props) => {
  const routeURL = props.match.url

  return (
    <Switch>
      <Route exact path={`${routeURL}`}>
        <Redirect to={`${routeURL}/list`} />
      </Route>
      <Route
        exact
        path={[
          `${routeURL}/list`,
          `${routeURL}/:id`,
          `${routeURL}/:id/:tabName`,
          `${routeURL}/:id/:tabName/thread/:threadUUID`,
          `${routeURL}/:id/:tabName/message/:messageUUID`,
        ]}
        component={PoList}
      />
    </Switch>
  )
}

export default Po
