import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useUserReadQuery } from 'services/hooks/user/useUserReadQuery'
import { ReadUserTypeDTO } from 'services/hooks/user/useUserTypeReadQuery'

type UserTypeProfileProps = {
  userTypeData: ReadUserTypeDTO
}

interface IFormInput {
  user_type: string
  admin: boolean
}

const UserTypeProfile = ({ userTypeData }: UserTypeProfileProps) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      user_type: userTypeData.user_type,
      admin: userTypeData.admin,
    },
  })
  const { data: userData } = useUserReadQuery()

  const onSubmit: SubmitHandler<IFormInput> = (formData) => {
    console.log(formData)
  }

  return (
    <Card>
      <CardHeader title='User Type Profile' />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} alignItems='center'>
            <Grid item xs={6}>
              <Controller
                name='user_type'
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    label='User Type Name'
                    fullWidth
                    error={Boolean(errors.user_type)}
                    helperText={errors.user_type?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name='admin'
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox {...field} checked={field.value} disabled />
                    }
                    label='Administrator'
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Button type='submit' variant='contained' color='primary'>
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export default UserTypeProfile
