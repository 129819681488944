import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const ShipmentReadContainer = styled(Box)`
  .shipment-information {
    .status {
      text-align: right;

      p {
        display: inline-flex;
        align-items: center;
        color: white;
        padding: 5px 10px;

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .tab-content {
    .card {
      height: 100%;

      .card-content {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        h5.title {
          text-decoration: underline;
        }

        .summary {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .lineItem {
    .ReactTable {
      .rt-tbody {
        text-align: center;
      }
    }
  }
`

export default ShipmentReadContainer
