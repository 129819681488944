import { ApiMetaDataDTO, ErrorDTO, UserAuthDTO } from 'declarations'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { client, queryClient } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  userIds: number[]
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useBulkUserAddMfaMutation = () =>
  useMutation<unknown, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        const res = await client.put('/user/update/addMFA', {
          user_id: values.userIds,
        })

        // if (res.data.code === 201 || res.data.code === 202) {
        //   toast.success('User is now active')
        // } else {
        //   toast.warn(res.data.message)
        // }

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
