import React from 'react'
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import NumberFormat from 'react-number-format'
import _isEmpty from 'lodash/isEmpty'

import AssignDropdown from '../../../components/assignDropdown'
import QuantityChart from '../../../components/tables/quantityChart'

import RequisitionTabs from './components/requisitionTabs'
import { useRequisitionQuery } from 'services/hooks/requisition/useRequisitionQuery'
import { useRequisitionAssignUsersQuery } from 'services/hooks/requisition/useRequisitionAssignUsersQuery'
import { useRequisitionPriceHistoryQuery } from 'services/hooks/requisition/useRequisitionPriceHistoryQuery'
import { useParams } from 'react-router'

const RequisitionReadModal = ({ onClose }) => {
  const { id: requisitionId }: any = useParams()
  const {
    data: requisitionData,
    isError: isErrorRequisitionData,
    isFetching,
  } = useRequisitionQuery(requisitionId)
  const { data: requisitionAssignUsersList } = useRequisitionAssignUsersQuery()
  const { data: requisitionPriceHistory } = useRequisitionPriceHistoryQuery(
    requisitionId,
    !isErrorRequisitionData && !isFetching
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Box display='flex' alignItems='center' gap={2}>
          <Typography
            variant='h5'
            sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
          >
            Requisition ID: {requisitionData?.requisition.order_id}{' '}
          </Typography>
          {requisitionData && (
            <AssignDropdown
              userList={requisitionAssignUsersList}
              selected={requisitionData.requisition}
              assign={requisitionData.permissions.assign}
              componentType='menu'
              updateFor='requisition'
            />
          )}
        </Box>

        <IconButton aria-label='Close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {requisitionData && (
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Card sx={{ height: '100%' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                {requisitionData.requisition.type === 'q4q' && (
                  <Box
                    sx={{
                      background: 'rgb(255, 217, 66)',
                      margin: '0 auto',
                      py: 1,
                      px: 2,
                      borderRadius: '8px',
                      color: '#000',
                      mb: 2,
                    }}
                  >
                    <Typography>Quote for Quote</Typography>
                  </Box>
                )}
                <Typography variant='h6'>Part Number</Typography>
                <div className='summary'>
                  {requisitionData.requisition.part_numbers.map((number, i) => (
                    <p
                      key={'requisition' + i}
                      className={`${i === 0 && 'first'}`}
                    >
                      {number}
                    </p>
                  ))}
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <QuantityChart
                  requisition={requisitionData.requisition}
                  quantitiesColors={requisitionData.quantities_colors}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ height: '100%' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                {requisitionData.requisition.type !== 'q4q' && (
                  <>
                    <Box sx={{ mb: 4 }}>
                      <Typography variant='h6'>Must Deliver By</Typography>
                      <Box className='status' width='50%'>
                        <Typography>
                          {_isEmpty(
                            requisitionData.requisition.must_arrive_by_date
                          ) ? (
                            <span>&mdash;</span>
                          ) : (
                            typeof requisitionData.requisition
                              .must_arrive_by_date === 'object' &&
                            requisitionData.requisition.must_arrive_by_date
                              .formatted_date
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant='h6'>Pricing</Typography>
                      <Box display='flex'>
                        <Box className='status' width='50%'>
                          <Typography variant='h5' gutterBottom>
                            <NumberFormat
                              value={requisitionData.requisition.high_target}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                              decimalScale={3}
                            />
                          </Typography>
                          <Typography>High Target</Typography>
                        </Box>
                        {requisitionPriceHistory &&
                          requisitionPriceHistory.historical_average && (
                            <Box className='status' width='50%'>
                              <Typography variant='h5' gutterBottom>
                                <NumberFormat
                                  value={
                                    requisitionPriceHistory.historical_average
                                  }
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                  decimalScale={3}
                                />
                              </Typography>
                              <Typography>Historical Average</Typography>
                            </Box>
                          )}
                      </Box>
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <RequisitionTabs requisition={requisitionData} />
          </Grid>
        </Grid>
      )}

      {isErrorRequisitionData && (
        <Typography
          variant='h5'
          sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
        >
          No Data Found
        </Typography>
      )}
    </>
  )
}

export default RequisitionReadModal
