import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { RfqDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

export type PoCorrespondenceResponseDTO = {
  rfq: RfqDTO[]
  threads: any
  total_rows: number
}

const usePoCorrespondenceQuery = (
  uuid: string | number,
  enabled = true
): UseQueryResult<PoCorrespondenceResponseDTO> => {
  return useQuery({
    queryKey: ['po', 'correspondence', uuid],
    queryFn: async () => {
      const res = await client.get(`/po/correspondence/${uuid}`)

      return res.data.data
    },
    enabled,
    refetchInterval: 300000,
  })
}

export { usePoCorrespondenceQuery }
