import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

interface IFormInput {
  rfq_contact_method: string
  default_shipping_method: string
}

const SettingsForm = () => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      rfq_contact_method: '',
      default_shipping_method: '',
    },
  })

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {}

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              name='rfq_contact_method'
              control={control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  error={Boolean(errors.rfq_contact_method)}
                >
                  <InputLabel id='contact-method-select-label'>
                    RFQ Contact Method
                  </InputLabel>
                  <Select
                    {...field}
                    labelId='contact-method-select-label'
                    label='RFQ Contact Method'
                  >
                    <MenuItem value=''>None</MenuItem>
                    <MenuItem value='email'>Email</MenuItem>
                    <MenuItem value='phone'>Phone</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors.rfq_contact_method?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='default_shipping_method'
              control={control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  error={Boolean(errors.default_shipping_method)}
                >
                  <InputLabel id='shipping-method-select-label'>
                    Default Shipping Method
                  </InputLabel>
                  <Select
                    {...field}
                    labelId='shipping-method-select-label'
                    label='Default Shipping Method'
                  >
                    <MenuItem value=''>None</MenuItem>
                    <MenuItem value='email'>Email</MenuItem>
                    <MenuItem value='phone'>Phone</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors.default_shipping_method?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' color='primary' type='submit'>
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default SettingsForm
