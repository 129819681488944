import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  Card,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import _filter from 'lodash/filter'
import { toast } from 'react-toastify'
import NumberFormat from 'react-number-format'
import numeral from 'numeral'
import { Box, Grid } from '@mui/material'

import DispatchSchedules from '../../../../../components/dispatchSchedules'

import NewTableContainer from './index.style'
import EditableInput from './editableInput'
import EditableSelect from './editableSelect'
import NewQuoteLine from './newQuoteLine'
import AddToPoButton from './addToPoButton'
import FinalizeConfirmDialog from './finalizeConfirmDialog'
import { useManufacturersListQuery } from 'services/hooks/others/useManufacturersListQuery'
import { useRfqQuoteDeleteMutation } from 'services/hooks/rfq/useRfqQuoteDeleteMutation'
import { useQueryClient } from '@tanstack/react-query'
import { useRfqQuoteFinalizeMutation } from 'services/hooks/rfq/useRfqQuoteFinalizeMutation'
import { useRfqQuoteUpdateMutation } from 'services/hooks/rfq/useRfqQuoteUpdateMutation'
import { useRfqQuoteRatingUpdateMutation } from 'services/hooks/rfq/useRfqQuoteRatingUpdateMutation'
import { useRfqQuotePauseMutation } from 'services/hooks/rfq/useRfqQuotePauseMutation'

const QuotedItemsTable = ({
  rows,
  rfqUUID,
  rfqId,
  permissions,
  supplierId,
}) => {
  const { data: manufacturers } = useManufacturersListQuery()
  const [selectAll, setSelectAll] = useState(false)
  const [tableRows, setTableRows] = useState(null)
  const [showFinalizeModal, setShowFinalizeModal] = useState(false)
  const canEditQuote = permissions.can_edit_quote
  const canUpdateRating = permissions.can_update_rating
  const canEditLines = permissions.can_edit_lines
  const canPause = permissions.can_pause
  const { mutate: rfqQuoteDeleteMutation } = useRfqQuoteDeleteMutation()
  const { mutate: rfqQuoteFinalizeMutation } = useRfqQuoteFinalizeMutation()
  const { mutate: rfqQuoteUpdateMutation } = useRfqQuoteUpdateMutation()
  const { mutate: rfqQuoteRatingUpdateMutation } =
    useRfqQuoteRatingUpdateMutation()
  const { mutate: rfqQuotePauseMutation } = useRfqQuotePauseMutation()
  const queryClient = useQueryClient()

  useEffect(() => {
    const quotedItems = rows
    quotedItems.map((item) => {
      const newItem = item
      newItem.selected = false
      return newItem
    })
    setTableRows(quotedItems)
  }, [rows])

  const onRefreshTable = () => {
    queryClient.invalidateQueries({ queryKey: ['rfq', 'quote', 'read'] })
  }

  const onSelectAllClicked = () => {
    const arr = tableRows

    arr.map((item) => {
      const newItem = item
      newItem.selected = !selectAll
      return newItem
    })

    setTableRows(arr)
    setSelectAll(!selectAll)
  }

  const onClickSmile = (row, index) => {
    rfqQuoteRatingUpdateMutation(
      {
        uuid: row.rfq_quote_line_uuid,
        rating: tableRows[index].rating === 1 ? 0 : 1,
      },
      {
        onSuccess(data, variables, context) {
          onRefreshTable()
        },
      }
    )
  }

  const onClickShit = (row, index) => {
    rfqQuoteRatingUpdateMutation(
      {
        uuid: row.rfq_quote_line_uuid,
        rating: tableRows[index].rating !== -1 ? -1 : 0,
      },
      {
        onSuccess(data, variables, context) {
          onRefreshTable()
        },
      }
    )
  }

  const onClickPause = async (row, index) => {
    rfqQuotePauseMutation(
      {
        uuid: row.rfq_quote_line_uuid,
        toggle: tableRows[index].pause ? 'off' : 'on',
      },
      {
        onSuccess(data, variables, context) {
          onRefreshTable()
        },
      }
    )
  }

  const onDeleteItem = async (row) => {
    rfqQuoteDeleteMutation(
      { quoteLineUUID: row.rfq_quote_line_uuid },
      {
        onSuccess(data, variables, context) {
          onRefreshTable()
          toast.success('Quote item is deleted.')
        },
      }
    )
  }

  const onDownArrowClicked = (row) => {
    onDeleteItem(row)
  }

  const onQuoteAdded = (quoteBody) => {
    setTableRows([
      ...tableRows,
      {
        rfq_quote_line_uuid: quoteBody.uuid,
        isCustomItem: true,
        ...quoteBody,
      },
    ])
  }

  const onChangeSelectChecker = (index) => {
    const arr = tableRows
    arr[index].selected = !arr[index].selected

    setTableRows([...arr])
  }

  const onChangeCustomChecker = async (uuid, index) => {
    rfqQuoteUpdateMutation(
      {
        uuid,
        body: {
          supplier_part_number_custom:
            !tableRows[index].supplier_part_number_custom,
        },
      },
      {
        onSuccess(data, variables, context) {
          onRefreshTable()
        },
      }
    )
  }

  const getSelectedRows = () => {
    const selectedRows = _filter(tableRows, function (o) {
      return o.selected
    })
    return selectedRows
  }

  const onFinalizeQuote = async () => {
    rfqQuoteFinalizeMutation(
      { uuid: rfqUUID },
      {
        onSuccess(data, variables, context) {
          setShowFinalizeModal(true)
          queryClient.invalidateQueries({ queryKey: ['rfq', 'read'] })
        },
      }
    )
  }

  const onCloseFinalizeModal = () => {
    setShowFinalizeModal(false)
  }

  return (
    <NewTableContainer>
      {tableRows && tableRows.length > 0 && !canEditQuote && (
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAll}
              onChange={onSelectAllClicked}
              value='selectAll'
              color='primary'
            />
          }
          label='Select all'
        />
      )}
      <Box
        sx={{ mb: 2.5, display: 'flex', flexDirection: 'column', gap: 2 }}
        className='quoted-items'
      >
        {tableRows &&
          tableRows.map((row, i) => (
            <Card
              variant='outlined'
              sx={{ p: 2.5, position: 'relative' }}
              className='quoted-item'
              key={'quoted-card-item' + i}
            >
              <Box>
                {!canEditQuote && (
                  <Checkbox
                    checked={row.selected}
                    value={`quote_item_${i}_checkbox`}
                    onChange={() => onChangeSelectChecker(i)}
                    color='primary'
                    inputProps={{ 'aria-label': `quote_item_${i}_checkbox` }}
                  />
                )}
                <Grid container spacing={2} alignItems='center' mb={2}>
                  <Grid item xs={6} sm={4} md={3} className='field'>
                    {canEditQuote ? (
                      <EditableInput
                        quoteLineUUID={row.rfq_quote_line_uuid}
                        inputValue={row.quantity}
                        inputType='quantity'
                        label='Quantity'
                      />
                    ) : (
                      <Typography>
                        Quantity <br /> {row.quantity}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} className='field'>
                    <Typography>
                      Requisition #<br />
                      {!row.isCustomItem && (
                        <Link
                          to={`/requisitions/${row.rfq_line.requisition_detail.order_id}`}
                        >
                          {row.rfq_line.requisition_detail.order_id}
                        </Link>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} className='field'>
                    {canEditQuote ? (
                      <EditableInput
                        quoteLineUUID={row.rfq_quote_line_uuid}
                        inputValue={row.item}
                        inputType='item'
                        label='Part No.'
                      />
                    ) : (
                      <Typography>
                        Part No.
                        <br />
                        {row.item}
                      </Typography>
                    )}
                  </Grid>
                  {canEditQuote && (
                    <Grid item xs={6} sm={4} md={3} className='field'>
                      <Typography style={{ margin: 0 }}>Custom</Typography>
                      <Checkbox
                        checked={row.supplier_part_number_custom}
                        onChange={() =>
                          onChangeCustomChecker(row.rfq_quote_line_uuid, i)
                        }
                        value='secondary'
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} sm={4} md={3} className='field'>
                    {canEditQuote && row.supplier_part_number_custom ? (
                      <EditableInput
                        quoteLineUUID={row.rfq_quote_line_uuid}
                        inputValue={row.supplier_part_number}
                        inputType='supplier_part_number'
                        label='Supplier Part No'
                      />
                    ) : (
                      <Typography>
                        Supplier Part No
                        <br />
                        {row.supplier_part_number}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={6} sm={4} md={3} className='field'>
                    {manufacturers && (
                      <EditableSelect
                        quoteLineUUID={row.rfq_quote_line_uuid}
                        selectList={manufacturers}
                        selectValue={row.manufacturer_id}
                        selectType='manufacturer_id'
                        disabled={!canEditQuote}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} className='field'>
                    {canEditQuote ? (
                      <EditableInput
                        quoteLineUUID={row.rfq_quote_line_uuid}
                        inputValue={numeral(row.price).format('0.00')}
                        inputType='price'
                        label='Price'
                      />
                    ) : (
                      <Typography>
                        Price
                        <br />
                        <NumberFormat
                          value={row.price.toFixed(4)}
                          displayType='text'
                          thousandSeparator
                          prefix='$'
                        />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <DispatchSchedules line={row} scheduleFor='rfq' />
              </Box>

              {row.po_lines && row.po_lines.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    paddingRight: '100px',
                    marginTop: '10px',
                  }}
                  className='associated-pos'
                >
                  {row.po_lines.map((poLine, i) => (
                    <Link to={`/po/${poLine.po_id}`} key={'po-line' + i}>
                      <span key={'quoted-po-line' + i}>
                        {poLine.quantity} in PO {poLine.po_id}
                      </span>
                    </Link>
                  ))}
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  position: 'absolute',
                  right: 20,
                  bottom: 20,
                }}
              >
                {canEditQuote && !row.isCustomItem && (
                  <IconButton onClick={() => onDownArrowClicked(row)}>
                    <ArrowDownwardIcon />
                  </IconButton>
                )}
                {canEditQuote && row.isCustomItem && (
                  <IconButton onClick={() => onDeleteItem(row)}>
                    <DeleteIcon />
                  </IconButton>
                )}
                {canUpdateRating && (
                  <span
                    onClick={() => onClickShit(row, i)}
                    role='button'
                    tabIndex={0}
                  >
                    {row.rating === -1 ? (
                      <span role='img' aria-label='shit'>
                        💩
                      </span>
                    ) : (
                      <span role='img' aria-label='shit' className='icon_grey'>
                        💩
                      </span>
                    )}
                  </span>
                )}
                {canUpdateRating && (
                  <span
                    onClick={() => onClickSmile(row, i)}
                    role='button'
                    tabIndex={0}
                  >
                    {row.rating === 1 ? (
                      <span role='img' aria-label='happy'>
                        😀
                      </span>
                    ) : (
                      <span role='img' aria-label='happy' className='icon_grey'>
                        😀
                      </span>
                    )}
                  </span>
                )}

                {canPause && (
                  <span
                    onClick={() => onClickPause(row, i)}
                    role='button'
                    tabIndex={0}
                  >
                    {row.pause ? (
                      <span role='img' aria-label='pause'>
                        ✋
                      </span>
                    ) : (
                      <span role='img' aria-label='pause' className='icon_grey'>
                        ✋
                      </span>
                    )}
                  </span>
                )}
              </Box>
            </Card>
          ))}
        {manufacturers && canEditQuote && (
          <NewQuoteLine manufacturerList={manufacturers} rfqUUID={rfqUUID} />
        )}
      </Box>
      {!canEditQuote && !canEditLines && (
        <AddToPoButton
          selectedRows={getSelectedRows()}
          supplierId={supplierId}
          // rfqUUID={rfqUUID}
        />
      )}
      {canEditQuote && (
        <Box sx={{ textAlign: 'right' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={onFinalizeQuote}
            disabled={tableRows && tableRows.length === 0}
          >
            Finalize Quote
          </Button>
        </Box>
      )}
      <FinalizeConfirmDialog
        open={showFinalizeModal}
        onClose={onCloseFinalizeModal}
        rfqId={rfqId}
      />
    </NewTableContainer>
  )
}

export default QuotedItemsTable
