import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type UpdateRfqLineDTO = {
  uuid: string
  reqBody: {
    quantity?: number
    item?: string
    supplier_part_number?: string
    supplier_part_number_custom?: boolean
    manufacturer_id?: number
  }
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqLineUpdateMutation = () =>
  useMutation<unknown, ErrorDTO, UpdateRfqLineDTO>({
    mutationFn: async (values: UpdateRfqLineDTO) => {
      try {
        const res = await client.put<ApiResponseDTO>(
          `/rfq/line/update/${values.uuid}`,
          values.reqBody
        )

        // toast.success('Supplier profile is updated')

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
