import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { client } from 'services/helpers/apiConfig'

type QueryParamsDTO = {
  uuid: string
  receive_by_date: string
  carrierServiceId: string
}

const usePoSuplierShipByDateQuery = (
  params: QueryParamsDTO,
  enabled = true
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [
      'supplier',
      'ship_by_date',
      params.uuid,
      params.receive_by_date,
      params.carrierServiceId,
    ],
    queryFn: async () => {
      try {
        const reqParams = {
          uuid: params.uuid,
          receive_by_date: params.receive_by_date,
          carrierServiceId: params.carrierServiceId,
        }

        const res = await client.get('supplier/ship_by_date', {
          params: reqParams,
        })

        return res.data.data
      } catch (err) {
        throw err.response.data
      }
    },
    enabled,
  })
}

export { usePoSuplierShipByDateQuery }
