import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ReplyIcon from '@mui/icons-material/Reply'
import NotesIcon from '@mui/icons-material/Notes'
import { toast } from 'react-toastify'

import MessageContainer from './message.style'
import MessageBlock from './messageBlock'
import ReplyMessage from './replyMessage'
import FileViewerModal from '../../fileViewerModal'
import { useThreadDraftCreateMutation } from 'services/hooks/thread/useThreadDraftCreateMutation'
import { useFileUrlQuery } from 'services/hooks/file/useFileUrlQuery'

const ReadMessage = ({
  selectedLine,
  correspondence,
  onClose,
  onSavedQuote,
}) => {
  const [replyType, setReplyType] = useState(null)
  const [replyMessageUUID, setReplyMessageUUID] = useState(null)
  const [fileViewModalOpen, setFileViewModalOpen] = useState(false)
  const [correspondenceState, setCorrespondenceState] = useState(correspondence)
  const { mutate: createThreadDraftMutation } = useThreadDraftCreateMutation()
  const [attachmentObj, setAttachmentObj] = useState<any>()
  const attachmentUUID = attachmentObj ? attachmentObj.uuid : null
  const { data: fileUrlData } = useFileUrlQuery(
    attachmentUUID,
    attachmentUUID !== null
  )

  useEffect(() => {
    setCorrespondenceState(correspondence)
  }, [correspondence])

  const onClickAttachment = async (attachment) => {
    setAttachmentObj(attachment)
  }

  useEffect(() => {
    if (fileUrlData) {
      const fileUrl = fileUrlData.url
      setAttachmentObj({
        ...attachmentObj,
        fileUrl,
      })
      if (
        attachmentObj.mime_type === 'application/pdf' ||
        attachmentObj.mime_type === 'image/jpeg' ||
        attachmentObj.mime_type === 'image/png'
      ) {
        setFileViewModalOpen(true)
      } else {
        window.open(fileUrl)
      }
    }
  }, [fileUrlData])

  const closeReply = () => {
    setReplyType(null)
    setReplyMessageUUID(null)
  }

  const onSaveAsQuote = () => {
    onSavedQuote(correspondenceState)
  }

  const createThreadDraft = (replyType) => {
    const { messages } = correspondence

    createThreadDraftMutation(
      {
        subject: correspondence.thread,
        body: '',
        author_id: messages[0].author_id,
        'to-email': messages[0].to_email,
        'reply-to': messages[0].uuid,
        is_internal:
          replyType === 'internal_note' ? true : messages[0].is_internal,
      },
      {
        onSuccess(data, variables, context) {
          toast.success('Reply message draft is created.')
          setReplyType(replyType)
          setReplyMessageUUID(data.uuid)
        },
      }
    )
  }

  const onCloseFileViewModal = () => {
    setAttachmentObj(null)
    setFileViewModalOpen(false)
  }

  return (
    <MessageContainer>
      <Box
        component={Paper}
        elevation={3}
        sx={{
          p: 1.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: 0,
        }}
      >
        <Typography>{correspondence.thread.subject}</Typography>
        <IconButton aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        component={Paper}
        className='message-modal-content'
        sx={{ borderRadius: 0, px: 1.5, maxHeight: '70vh', overflow: 'auto' }}
      >
        <div className='messages'>
          {correspondenceState.messages &&
            correspondenceState.messages.map((message, i) => (
              <Box key={'correspondence-state' + i}>
                <MessageBlock
                  key={message.id}
                  message={message}
                  messageIndex={i}
                  selectedLine={selectedLine}
                  onClickAttachment={onClickAttachment}
                />
                <Divider />
              </Box>
            ))}
        </div>

        {replyMessageUUID && replyType ? (
          <ReplyMessage
            correspondence={correspondence}
            selectedLine={selectedLine}
            replyType={replyType}
            messageUUID={replyMessageUUID}
            closeReply={closeReply}
          />
        ) : (
          <Box sx={{ py: 1, display: 'flex', gap: 1 }}>
            {onSavedQuote && (
              <Button variant='outlined' onClick={onSaveAsQuote}>
                <OpenInNewIcon />
                &nbsp;Convert to Quote
              </Button>
            )}

            <Button
              variant='outlined'
              onClick={() => createThreadDraft('reply')}
            >
              <ReplyIcon />
              &nbsp;Reply
            </Button>
            <Button
              variant='outlined'
              onClick={() => createThreadDraft('internal_note')}
            >
              <NotesIcon />
              &nbsp;Internal Note
            </Button>
          </Box>
        )}
      </Box>
      <FileViewerModal
        open={fileViewModalOpen}
        attachment={attachmentObj}
        onClose={onCloseFileViewModal}
      />
    </MessageContainer>
  )
}

export default ReadMessage
