import React, { useState } from 'react'
import { Box, Card, CardContent } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { grey } from '@mui/material/colors'

import { File } from 'declarations'

type FilePreviewProps = {
  file: File
}

const FilePreview = ({ file }: FilePreviewProps) => {
  return (
    <Card sx={{ height: '100%' }}>
      <Box
        sx={{
          height: 300,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
          // Refactor below later
          // background: theme.palette.type !== 'dark' ? grey[400] : 'transparent',
        }}
      >
        <img
          src={file.thumbnail.url} // use normal <img> attributes as props
          width={200}
          // effect='blur'
        />
      </Box>

      <CardContent>{file.filename}</CardContent>
    </Card>
  )
}

export default FilePreview
