import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type Params = {
  rfq_id: number
  requisition_ids: (string | number)[]
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqLineCreateBatchMutation = () =>
  useMutation<any, ErrorDTO, Params>({
    mutationFn: async (params: Params) => {
      try {
        const res = await client.post('/rfq/line/create/batch', params)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
