import React, { useState } from 'react'
import { Button, Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import ShipmentsStatus from '../../../components/shipmentsStatus'
import LoadingSpinner from '../../../components/common/loadingSpinner'

import AddShipmentModal from './components/addShipmentModal'
import ShipmentsContainer from './shipments.style'
import { usePoReadShipmentQuery } from 'services/hooks/purchaseOrders/usePoReadShipmentQuery'
import { useShipmentTrackingQuery } from 'services/hooks/shipment/useShipmentTrackingQuery'
import { usePoStatusUpdateMutation } from 'services/hooks/purchaseOrders/usePoStatusUpdateMutation'

const ShipmentsNew = ({ uuid }) => {
  const [openAddShipmentModal, setOpenAddShipmentModal] = useState(false)
  const { data: poShipmentData, refetch: refetchShipments } =
    usePoReadShipmentQuery(uuid)
  const { data: shipmentDetailsData, isFetching: isFetchingShipmentDetails } =
    useShipmentTrackingQuery(
      {
        po_uuid: uuid,
        shipments: poShipmentData?.shipments,
      },
      {
        enabled: !!poShipmentData,
      }
    )
  const { mutate: poStatusUpdateMutation } = usePoStatusUpdateMutation()

  const onDisplayMessageButtonClicked = async (statusId) => {
    poStatusUpdateMutation(
      {
        uuid,
        reqBody: {
          status_id: statusId,
        },
      },
      {
        onSuccess(data, variables, context) {
          refetchShipments()
        },
      }
    )
  }

  const openModal = () => {
    setOpenAddShipmentModal(true)
  }

  const closeModal = () => {
    setOpenAddShipmentModal(false)
  }

  const onAddedShipment = () => {
    closeModal()
    refetchShipments()
  }

  return !isFetchingShipmentDetails ? (
    <ShipmentsContainer>
      {poShipmentData && (
        <>
          {poShipmentData.purchase_order.permissions.can_view_shipment &&
            poShipmentData && (
              <div className='shipment-tables'>
                <ShipmentsStatus shipments={shipmentDetailsData} />
                {poShipmentData.purchase_order.permissions
                  .can_create_shipment && (
                  <Fab
                    size='medium'
                    color='secondary'
                    aria-label='add'
                    className='btn-add-shipment'
                    onClick={openModal}
                  >
                    <AddIcon />
                  </Fab>
                )}
              </div>
            )}

          {poShipmentData.purchase_order.permissions.can_create_shipment &&
            poShipmentData.shipments.length === 0 && (
              <div className='empty-state'>
                <p>There are no shipments to view.</p>
                <Button variant='contained' color='primary' onClick={openModal}>
                  Create a shipment
                </Button>
              </div>
            )}
          {!poShipmentData.purchase_order.permissions.can_view_shipment && (
            <div className='empty-state'>
              <p>{poShipmentData.display_message.message}</p>
              {poShipmentData.display_message.button.display && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    onDisplayMessageButtonClicked(
                      poShipmentData.display_message.button.status_id
                    )
                  }
                >
                  {poShipmentData.display_message.button.language}
                </Button>
              )}
            </div>
          )}

          <AddShipmentModal
            remainingLines={poShipmentData?.remaining_lines}
            handleClose={closeModal}
            open={openAddShipmentModal}
            onAddedShipment={onAddedShipment}
          />
        </>
      )}
    </ShipmentsContainer>
  ) : (
    <LoadingSpinner />
  )
}

export default ShipmentsNew
