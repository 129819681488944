import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { RfqDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

type RfqListQueryParamsDTO = {
  pageIndex?: number
  rowsCount?: number
  filterValues?: {
    statuses?: string[]
    suppliers?: string[]
    users?: string[]
    term?: string
  }
}

type ResponseDTO = {
  rfq: RfqDTO[]
  total_rows: number
}

const useRfqListQuery = (
  params: RfqListQueryParamsDTO,
  enabled = true
): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: [
      'rfq',
      'list',
      params.pageIndex,
      params.rowsCount,
      params.filterValues.statuses,
      params.filterValues.suppliers,
      params.filterValues.users,
      params.filterValues.term,
    ],
    queryFn: async () => {
      try {
        const reqParams = {
          status_id: params.filterValues?.statuses?.join(),
          supplier_id: params.filterValues?.suppliers?.join(),
          assignee: params.filterValues?.users?.join(),
          page: params.pageIndex,
          rows: params.rowsCount,
          search: params.filterValues.term,
        }

        const res = await client.get('rfq/list', { params: reqParams })

        return res.data.data
      } catch (err) {
        console.log(err)
        throw err.response.data
      }
    },
    enabled,
  })
}

export { useRfqListQuery }
