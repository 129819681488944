import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type CreateUserSupplierDTO = {
  name: string
  street_address_1: string
  street_address_2?: string
  city: string
  web_address: string
  state: string
  zip: string
  warehouse_zip: string
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useCreateSupplierProfileMutation = () =>
  useMutation<unknown, ErrorDTO, CreateUserSupplierDTO>({
    mutationFn: async (values: CreateUserSupplierDTO) => {
      try {
        const res = await client.post<ApiResponseDTO>(
          '/supplier/create',
          values
        )

        toast.success('New supplier profile is created')

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
