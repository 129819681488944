import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { TextField, Button, CircularProgress, Link, Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Link as RouterLink } from 'react-router-dom'

import AuthWrapper from '../authWrapper'
import { useLoginMutation } from 'services/hooks/auth/useLoginMutation'

interface IFormInput {
  userName: string
  password: string
}

const Login = () => {
  const history = useHistory()
  const { mutate: loginMutate, status, error } = useLoginMutation()
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userName: '',
      password: '',
    },
  })

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    loginMutate(formData, {
      onSuccess(data, variables, context) {
        console.log(data)

        if (data.require_mfa_code) {
          history.push('/googleAuthenticatorCode', {
            userName: getValues('userName'),
            password: getValues('password'),
          })
          return
        }

        if (data.mfa_codes) {
          history.push('/googleAuthenticatorQRCode', {
            authData: data,
          })
          return
        }

        if (data.force_password_change) {
          history.push('/changePassword')

          return
        }

        if (data.user.active) {
          history.push('/home')
          toast.success('Loggedin successfully!')
          return
        }
      },
      onError(error, variables, context) {
        toast.error(error.message)
      },
    })
  }

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='userName'
          control={control}
          rules={{ required: 'Required' }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='standard'
              label='Username'
              fullWidth
              error={Boolean(errors.userName)}
              helperText={errors.userName?.message}
            />
          )}
        />
        <Controller
          name='password'
          control={control}
          rules={{ required: 'Required' }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='standard'
              label='Password'
              fullWidth
              type='password'
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
            />
          )}
        />

        <div className='card-actions'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={status === 'pending'}
          >
            {status === 'pending' ? (
              <CircularProgress size={20} thickness={5} />
            ) : (
              <span>Login</span>
            )}
          </Button>
          <Box sx={{ mt: 2 }}>
            <Link
              component={RouterLink}
              to={{
                pathname: '/forgotPassword',
                state: { userName: getValues('userName') },
              }}
            >
              Forgot Password?
            </Link>
          </Box>
        </div>
      </form>
    </AuthWrapper>
  )
}

export default Login
