import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  TablePagination,
  Paper,
  Box,
  Link,
} from '@mui/material'
import _find from 'lodash/find'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Link as RouterLink } from 'react-router-dom'

import EnhancedTableHead from '../../../../components/common/enhancedTableHead'
import LoadingSpinner from '../../../../components/common/loadingSpinner'

import FilterBox from './filterBox'
import TableActions from './tableActions'
import { useSprPartNumberClassesQuery } from 'services/hooks/supplierPreferenceRules/useSprPartNumberClassesQuery'
import { useSprSequencesQuery } from 'services/hooks/supplierPreferenceRules/useSprSequencesQuery'
import { useSprListQuery } from 'services/hooks/supplierPreferenceRules/useSprListQuery'
import { useSprUpdateSequencesMutation } from 'services/hooks/supplierPreferenceRules/useSprUpdateSequencesMutation'
import { useSprDeleteMutation } from 'services/hooks/supplierPreferenceRules/useSprDeleteMutation'

const headCells = [
  {
    id: 'name',
    label: 'Name',
  },
  { id: 'part_number_class', label: 'Part Number Class' },
]

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? '#757ce8' : '',
  ...draggableStyle,
})

const SupplierPreferenceRules = () => {
  const [preferenceRules, setPreferenceRules] = useState(null)
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const [sequences, setSequences] = useState(null)
  const [isDraggable, setIsDraggable] = useState(true)
  const [filters, setFilters] = useState({
    partNumberClass: '',
    term: '',
  })
  const { data: sprPartNumberClasses } = useSprPartNumberClassesQuery()
  const { data: sprSequences } = useSprSequencesQuery()
  const {
    data: sprList,
    isFetching,
    refetch,
  } = useSprListQuery({
    pageIndex: page,
    rowsCount: rowsPerPage,
    filterValues: {
      partNumberClass: filters.partNumberClass,
    },
    searchTerm: filters.term,
  })
  const { mutate: updateSequencesMutate } = useSprUpdateSequencesMutation()
  const { mutate: deleteSprMutate } = useSprDeleteMutation()

  useEffect(() => {
    if (sprSequences) {
      setSequences(sprSequences)
    }
  }, [sprSequences])

  useEffect(() => {
    if (sprList) {
      setPreferenceRules(sprList.supplier_preference_rules)
    }
  }, [sprList])

  useEffect(() => {
    if (filters.partNumberClass == '' && filters.term == '') {
      setIsDraggable(true)
    }
  }, [filters])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = preferenceRules.map((n) => n.uuid)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const renderPartNumberClass = (partNumberClassId) => {
    const partNumberClass = _find(sprPartNumberClasses, {
      id: partNumberClassId,
    })
    return partNumberClass ? partNumberClass.description : ''
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination || !isDraggable) {
      return
    }

    const items = reorder(
      preferenceRules,
      result.source.index,
      result.destination.index
    )

    const orderArray = items.map((item: any) => item.id)
    setPreferenceRules(items)

    sequences.splice(page * rowsPerPage, 0, ...orderArray)
    sequences.splice((page + 1) * rowsPerPage, rowsPerPage)

    setSequences(sequences)

    updateSequencesMutate({ sequences })
  }

  const onChangeFilter = (partNumberClass = null, term = null) => {
    setIsDraggable(false)
    setFilters({
      partNumberClass: partNumberClass.toString(),
      term,
    })
  }

  const onDeleteSelected = async () => {
    deleteSprMutate(
      { uuids: selected },
      {
        onSuccess(data, variables, context) {
          setSelected([])
          refetch()
        },
      }
    )
  }

  const emptyRows = sprList?.supplier_preference_rules.length
    ? rowsPerPage -
      Math.min(rowsPerPage, sprList?.supplier_preference_rules.length)
    : 0

  return (
    <Box sx={{ marginBottom: '100px' }}>
      <Paper sx={{ marginBottom: 2, paddingTop: 2 }}>
        {sprPartNumberClasses && (
          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            <FilterBox
              partNumberClasses={sprPartNumberClasses}
              onChangeFilter={onChangeFilter}
            />
          </Box>
        )}

        {isFetching ? (
          <Box py={5}>
            <LoadingSpinner />
          </Box>
        ) : (
          <Box>
            <TableContainer>
              <Table
                aria-labelledby='tableTitle'
                aria-label='enhanced table'
                sx={{ minWidth: '750' }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={sprList.supplier_preference_rules.length}
                  headCells={headCells}
                />
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                      <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {preferenceRules &&
                          preferenceRules
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              const isItemSelected = isSelected(row.uuid)
                              const labelId = `enhanced-table-checkbox-${index}`

                              return (
                                <Draggable
                                  key={row.uuid}
                                  draggableId={'p-' + row.uuid}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <TableRow
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                      hover
                                      //
                                      role='checkbox'
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      selected={isItemSelected}
                                    >
                                      <TableCell padding='checkbox'>
                                        <Checkbox
                                          checked={isItemSelected}
                                          inputProps={{
                                            'aria-labelledby': labelId,
                                          }}
                                          onChange={(event) =>
                                            handleClick(event, row.uuid)
                                          }
                                        />
                                      </TableCell>
                                      <TableCell scope='row'>
                                        <Link
                                          component={RouterLink}
                                          to={`/admin/supplier_preference_rules/update/${row.uuid}`}
                                        >
                                          {row.name}
                                        </Link>
                                      </TableCell>
                                      <TableCell>
                                        {renderPartNumberClass(
                                          row.part_number_class_id
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Draggable>
                              )
                            })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, 250]}
              component='div'
              count={sprList.total_rows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
      <TableActions
        onDelete={onDeleteSelected}
        bulkActionDisabled={selected.length === 0}
      />
    </Box>
  )
}

export default SupplierPreferenceRules
