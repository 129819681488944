import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { StatusDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

const useRequisitionStatusListQuery = (): UseQueryResult<StatusDTO[]> => {
  return useQuery({
    queryKey: ['requisition', 'status', 'list'],
    queryFn: async () => {
      try {
        const res = await client.get('requisitions/status/list')

        return res.data.data
      } catch (err) {
        throw err.response.data
      }
    },
  })
}

export { useRequisitionStatusListQuery }
