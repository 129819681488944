import React, { useState } from 'react'
import {
  TextField,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { toast } from 'react-toastify'

import PartNumberTable from './partNumberTable'
import { useShipmentCreateMutation } from 'services/hooks/shipment/useShipmentCreateMutation'

const AddShipmentModal = ({
  remainingLines,
  open,
  handleClose,
  onAddedShipment,
}) => {
  const [linesData, setLinesData] = useState([])
  const [trackingNumber, setTrackingNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { mutate: shipmentCreateMutation } = useShipmentCreateMutation()

  const onSelectLine = (selectedLines) => {
    setLinesData(selectedLines)
  }

  const onChangeTrackingNumber = (e) => {
    setTrackingNumber(e.target.value)
  }

  const onAddShipment = async () => {
    setIsLoading(true)
    const reqLinesData = linesData.map((line) => ({
      quantity: line.quantity,
      purchase_order_line_uuids: line.purchase_order_line_uuids,
    }))

    shipmentCreateMutation(
      {
        tracking_number: trackingNumber,
        lines: reqLinesData,
      },
      {
        onSuccess(data, variables, context) {
          toast.success('New shipment is created')
          onAddedShipment()
        },
      }
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id='form-dialog-title'>Add a shipment</DialogTitle>
      <DialogContent>
        {remainingLines && (
          // <PartNumberTable data={remainingLines} onSelectLine={onSelectLine} />
          <PartNumberTable lines={remainingLines} onSelectLine={onSelectLine} />
        )}
        <Box display='flex' alignItems='center'>
          <Box flexGrow={1} pr={1}>
            <TextField
              id='tracking-number'
              label='Tracking Number'
              value={trackingNumber}
              onChange={onChangeTrackingNumber}
              className='field-trackingNo'
              fullWidth
              size='small'
              variant='outlined'
            />
          </Box>

          <div className='form-action'>
            <Button
              color='primary'
              variant='contained'
              onClick={onAddShipment}
              disabled={
                linesData.length === 0 || trackingNumber === '' || isLoading
              }
            >
              Submit
            </Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AddShipmentModal
