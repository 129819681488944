import React, { useState, useCallback } from 'react'
import { useRfqLineUpdateMutation } from 'services/hooks/rfq/useRfqLineUpdateMutation'
import _debounce from 'lodash/debounce'
import { TextField } from '@mui/material'

const DEBOUNCE_TIME_MS = 500

const EditableInput = ({ value, line, property, type = 'text' }) => {
  const [inputValue, setInputValue] = useState(value)
  const { mutate: rfqLineUpdateMutation } = useRfqLineUpdateMutation()

  const debouncedUpdateData = useCallback(
    _debounce((value: string) => {
      rfqLineUpdateMutation(
        {
          uuid: line.uuid,
          reqBody: {
            [property]: value,
          },
        },
        {
          onSuccess(data, variables, context) {},
          onError(error, variables, context) {},
        }
      )
    }, DEBOUNCE_TIME_MS),
    []
  )

  const onUpdateInput = async (event) => {
    setInputValue(event.target.value)
    debouncedUpdateData(event.target.value)
  }

  return (
    <TextField
      hiddenLabel
      variant='filled'
      size='small'
      value={inputValue}
      onChange={onUpdateInput}
      type={type}
    />
  )
}

export default EditableInput
