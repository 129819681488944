export function clearToken() {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('loggedIn')
  localStorage.removeItem('username')
}

export function getToken(): {
  loggedIn: string
  accessToken: string
  username: string
} {
  try {
    const accessToken = localStorage.getItem('accessToken') || ''
    const loggedIn = localStorage.getItem('loggedIn') || ''
    const username = localStorage.getItem('username') || ''

    return { loggedIn, accessToken, username }
  } catch (err) {
    clearToken()
    return {
      loggedIn: '',
      accessToken: '',
      username: '',
    }
  }
}
