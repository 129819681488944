import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { PriceHistoryDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

type ResponseDTO = {
  historical_average: number
  price_history: Record<string, PriceHistoryDTO[]>
}

const useRequisitionShipmentsQuery = (uuid: string): UseQueryResult<any> => {
  return useQuery({
    queryKey: ['requisition', 'shipments', uuid],
    queryFn: async () => {
      const res = await client.get(`/requisitions/shipments/${uuid}`)

      return res.data.data
    },
  })
}

export { useRequisitionShipmentsQuery }
