import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const ShipmentsContainer = styled(Box)`
  .shipment-tables {
    padding-bottom: 100px;
    position: relative;

    .btn-add-shipment {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    margin-left: auto;

    .selector,
    .field-trackingNo {
      width: 100%;
    }

    .form-action {
      margin-top: 30px;
      text-align: right;
    }
  }

  .empty-state {
    text-align: center;
  }
`

export default ShipmentsContainer
