import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import { toast } from 'react-toastify'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { useManufacturersListQuery } from 'services/hooks/others/useManufacturersListQuery'
import { useRfqLineCreateMutation } from 'services/hooks/rfq/useRfqLineCreateMutation'

interface IFormInput {
  quantity: number
  part_number: string
  supplier_part_number: string
  manufacturer: string
}

const CreateRfqLineModal = ({ open, handleClose, rfqId }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      quantity: 0,
      manufacturer: '',
      part_number: '',
      supplier_part_number: '',
    },
  })
  const { data: manufacturers } = useManufacturersListQuery()
  const { mutate: rfqLineCreateMutation } = useRfqLineCreateMutation()

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    rfqLineCreateMutation(
      {
        quantity: formData.quantity,
        item: formData.part_number,
        supplier_part_number: formData.supplier_part_number,
        manufacturer_id: formData.manufacturer,
        rfq_id: rfqId,
      },
      {
        onSuccess() {
          toast.success('New line item is added.')
          reset()
          handleClose()
        },
      }
    )
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle>New Line Item</DialogTitle>
      <DialogContent>
        <Box sx={{ py: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Controller
                name='quantity'
                control={control}
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^\d+$/,
                    message: 'Quantity must be integer',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    label='Quantity'
                    fullWidth
                    error={Boolean(errors.quantity)}
                    helperText={errors.quantity?.message}
                    type='number'
                  />
                )}
              />
              <Controller
                name='manufacturer'
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.manufacturer)}>
                    <InputLabel id='demo-simple-select-label'>
                      User Type
                    </InputLabel>
                    <Select
                      {...field}
                      labelId='demo-simple-select-label'
                      label='User Type'
                    >
                      <MenuItem value={0}>None</MenuItem>
                      {manufacturers?.map((manufacturer) => (
                        <MenuItem value={manufacturer.id} key={manufacturer.id}>
                          {manufacturer.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.manufacturer?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              <Controller
                name='part_number'
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    label='Part Number'
                    fullWidth
                    error={Boolean(errors.part_number)}
                    helperText={errors.part_number?.message}
                  />
                )}
              />
              <Controller
                name='supplier_part_number'
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    label='Supplier Part Number'
                    fullWidth
                    error={Boolean(errors.supplier_part_number)}
                    helperText={errors.supplier_part_number?.message}
                  />
                )}
              />
              <Button type='submit' color='primary' variant='contained'>
                Create
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CreateRfqLineModal
