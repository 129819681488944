import React, { useState, useEffect } from 'react'
import { Menu, MenuItem, Button, Box, Rating, Typography } from '@mui/material'
import moment from 'moment'

import InventoryTable, {
  InventoryTableLineDTO,
} from '../../../../components/inventoryTable'
import SendRfqModal from '../rfq/sendRfqModal'
import { useRequisitionQuery } from 'services/hooks/requisition/useRequisitionQuery'
import { useRfqListDraftQuery } from 'services/hooks/rfq/useRfqListDraftQuery'
import { useSupplierShipByDateQuery } from 'services/hooks/supplier/useSupplierShipByDateQuery'
import { useRfqCreateMutation } from 'services/hooks/rfq/useRfqCreateMutation'
import { useRfqLinesCreateMutation } from 'services/hooks/rfq/useRfqLinesCreateMutation'

type InventoryProps = {
  inventory: any
  requisitionId: string
}

const Inventory = ({ inventory, requisitionId }: InventoryProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [linesData, setLinesData] = useState<InventoryTableLineDTO[]>([])
  const [openRfqModal, setOpenRfqModal] = useState(false)
  const [createdRfqLineIds, setCreatedRfqLineIds] = useState<number[]>()
  const [createdRfqId, setCreatedRfqId] = useState<number | null>(null)
  const { data: requisitionData } = useRequisitionQuery(requisitionId)
  const { data: rfqDrafts, refetch: refetchRfqDrafts } = useRfqListDraftQuery(
    inventory.supplier.id
  )
  const { data: shipByDate } = useSupplierShipByDateQuery({
    uuid: inventory.supplier.id,
    receiveByDate:
      typeof requisitionData?.requisition.must_arrive_by_date === 'string'
        ? moment(requisitionData.requisition.must_arrive_by_date).format(
            'YYYY-MM-DD'
          )
        : null,
  })
  const { mutate: rfqCreateMutation } = useRfqCreateMutation()
  const { mutate: rfqLinesCreateMutation } = useRfqLinesCreateMutation()

  useEffect(() => {
    if (requisitionData && requisitionData.permissions.create_rfq) {
      refetchRfqDrafts()
    }
  }, [requisitionData, inventory])

  const createRfqPermission =
    requisitionData && requisitionData.permissions.create_rfq
  let total = 0
  inventory.lines.map((line: any) => {
    total += line.quantity_available
  })

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onSelectLine = (linesData: InventoryTableLineDTO[]) => {
    setLinesData(linesData)
  }

  const onAddRfq = () => {
    createRfq()
  }

  const onSelectRfq = (rfq: any) => {
    setCreatedRfqId(rfq.id)
    createRfqLine(rfq.id)
  }

  const onCloseAddRfqModal = () => {
    setOpenRfqModal(false)
    setCreatedRfqLineIds([])
    setCreatedRfqId(null)
  }

  const createRfqLine = async (rfqId: any) => {
    const lines = linesData.map((x) => ({
      quantity: x.quantity_required,
      part_number: x.part_number,
      manufacturer_id: x.manufacturer?.id,
    }))
    rfqLinesCreateMutation(
      {
        rfqId,
        requisitionUUID: requisitionData.requisition.uuid,
        lines: lines,
      },
      {
        onSuccess(data, variables, context) {
          setCreatedRfqLineIds(data)
          setOpenRfqModal(true)
        },
      }
    )
  }

  const createRfq = async () => {
    rfqCreateMutation(
      {
        supplier_id: inventory.supplier.id,
      },
      {
        onSuccess(data, variables, context) {
          const rfqId = data.rfq_id

          setCreatedRfqId(rfqId)
          createRfqLine(rfqId)
        },
      }
    )
  }

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant='h5' sx={{ mr: 2, fontWeight: 600 }}>
            {inventory.supplier.name}
          </Typography>
          {inventory.supplier.rating && (
            <Rating
              name='simple-controlled'
              value={inventory.supplier.rating}
              readOnly
            />
          )}
          {inventory.supplier.banner && (
            <Box
              sx={{
                backgroundColor: inventory.supplier.banner.color,
                color: inventory.supplier.banner['text-color'],
                padding: '5px 10px',
                borderRadius: '5px',
                marginLeft: '10px',
              }}
            >
              <Typography>{inventory.supplier.banner.text}</Typography>
            </Box>
          )}
        </Box>
        {shipByDate && (
          <Box>
            Ship By:{' '}
            {shipByDate ? (
              `${shipByDate.ship_date.human_date.precise.long.day} ${shipByDate.ship_date.human_date.precise.short.date}`
            ) : (
              <span>&mdash;</span>
            )}
          </Box>
        )}
      </Box>

      <InventoryTable data={inventory.lines} onSelectLine={onSelectLine} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 1,
        }}
      >
        <p>Total: {total}</p>
        {createRfqPermission && (
          <div>
            {rfqDrafts?.rfq.length > 0 ? (
              <div>
                <Button
                  aria-controls='simple-menu'
                  aria-haspopup='true'
                  onClick={handleClick}
                  variant='contained'
                  className='btn-rfq'
                  color='primary'
                  disabled={linesData.length === 0}
                >
                  Add to RFQ &gt;
                </Button>
                <Menu
                  id='simple-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={onAddRfq}>New Rfq</MenuItem>
                  {rfqDrafts.rfq.map((rfq: any, i: number) => (
                    <MenuItem
                      onClick={() => onSelectRfq(rfq)}
                      key={'rfq-draft' + i}
                    >
                      RFQ {rfq.id}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ) : (
              <Button
                variant='contained'
                className='btn-rfq'
                onClick={onAddRfq}
                color='primary'
                disabled={linesData.length === 0}
              >
                Add to RFQ &gt;
              </Button>
            )}
          </div>
        )}
      </Box>
      {createdRfqId && createdRfqLineIds && (
        <SendRfqModal
          open={openRfqModal}
          close={onCloseAddRfqModal}
          rfqId={createdRfqId}
          rfqLineIds={createdRfqLineIds}
        />
      )}
    </Box>
  )
}

export default Inventory
