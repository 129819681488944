import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { AssignUserDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

const usePoAssignUsersQuery = (): UseQueryResult<AssignUserDTO[]> => {
  return useQuery({
    queryKey: ['po', 'assign', 'list'],
    queryFn: async () => {
      const res = await client.get('user/assign/po')

      return res.data.data
    },
  })
}

export { usePoAssignUsersQuery }
