const leadTimeValues = [
  { value: '0_day', label: '0 Day' },
  { value: '1_day', label: '1 Day' },
  { value: '2_day', label: '2 Days' },
  { value: '3_day', label: '3 Days' },
  { value: '4_day', label: '4 Days' },
  { value: '5_day', label: '5 Days' },
  { value: '6_day', label: '6 Days' },
  { value: '1_week', label: '1 Week' },
  { value: '2_week', label: '2 Weeks' },
  { value: '3_week', label: '3 Weeks' },
  { value: '4_week', label: '4 Weeks' },
  { value: '5_week', label: '5 Weeks' },
  { value: '6_week', label: '6 Weeks' },
  { value: '7_week', label: '7 Weeks' },
  { value: '8_week', label: '8 Weeks' },
  { value: '9_week', label: '9 Weeks' },
  { value: '10_week', label: '10 Weeks' },
  { value: '11_week', label: '11 Weeks' },
  { value: '12_week', label: '12 Weeks' },
  { value: '13_week', label: '13 Weeks' },
  { value: '14_week', label: '14 Weeks' },
  { value: '15_week', label: '15 Weeks' },
  { value: '16_week', label: '16 Weeks' },
  { value: '17_week', label: '17 Weeks' },
  { value: '18_week', label: '18 Weeks' },
  { value: '19_week', label: '19 Weeks' },
  { value: '20_week', label: '20 Weeks' },
  { value: '21_week', label: '21 Weeks' },
  { value: '22_week', label: '22 Weeks' },
  { value: '23_week', label: '23 Weeks' },
  { value: '24_week', label: '24 Weeks' },
  { value: '25_week', label: '25 Weeks' },
  { value: '26_week', label: '26 Weeks' },
  { value: '27_week', label: '27 Weeks' },
  { value: '28_week', label: '28 Weeks' },
  { value: '29_week', label: '29 Weeks' },
  { value: '30_week', label: '30 Weeks' },
  { value: '31_week', label: '31 Weeks' },
  { value: '32_week', label: '32 Weeks' },
  { value: '33_week', label: '33 Weeks' },
  { value: '34_week', label: '34 Weeks' },
  { value: '35_week', label: '35 Weeks' },
  { value: '36_week', label: '36 Weeks' },
  { value: '37_week', label: '37 Weeks' },
  { value: '38_week', label: '38 Weeks' },
  { value: '39_week', label: '39 Weeks' },
  { value: '40_week', label: '40 Weeks' },
  { value: '41_week', label: '41 Weeks' },
  { value: '42_week', label: '42 Weeks' },
  { value: '43_week', label: '43 Weeks' },
  { value: '44_week', label: '44 Weeks' },
  { value: '45_week', label: '45 Weeks' },
  { value: '46_week', label: '46 Weeks' },
  { value: '47_week', label: '47 Weeks' },
  { value: '48_week', label: '48 Weeks' },
  { value: '49_week', label: '49 Weeks' },
  { value: '50_week', label: '50 Weeks' },
  { value: '51_week', label: '51 Weeks' },
  { value: '52_week', label: '52 Weeks' },
]

export default leadTimeValues
