import { ApiMetaDataDTO, SupplierDTO } from 'declarations'
import { Query, useQuery, UseQueryResult } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

type ResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

type QueryParamsDTO = {
  uuid: string
  receiveByDate: string
  carrierServiceId?: string
}

export const useSupplierShipByDateQuery = (
  params: QueryParamsDTO
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [
      'supplier',
      'ship-by-date',
      params.uuid,
      params.receiveByDate,
      params.carrierServiceId,
    ],
    queryFn: async () => {
      try {
        const reqParams = {
          uuid: params.uuid,
          receive_by_date: params.receiveByDate,
          carrierServiceId: params.carrierServiceId,
        }

        const res = await client.get<ResponseDTO>('/supplier/ship_by_date', {
          params: reqParams,
        })

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    enabled: !!params.receiveByDate,
  })
}
