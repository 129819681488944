import React from 'react'

import { Popover, Typography, Box } from '@mui/material'

const SupplierPopover = ({ open, anchorEl, handlePopoverClose, supplier }) => {
  return (
    <Popover
      id='mouse-over-popover'
      sx={{ pointerEvents: 'none', marginTop: '20px' }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Box sx={{ p: 2 }}>
        <Typography variant='h5' gutterBottom>
          {supplier.name}
        </Typography>
        <Typography variant='h6' gutterBottom>
          {supplier.first_name} {supplier.last_name}
        </Typography>
        <Typography variant='h6' gutterBottom>
          {supplier.city}, {supplier.state}
        </Typography>
        <Typography gutterBottom>{supplier.email}</Typography>
        <Typography>{supplier.phone}</Typography>
      </Box>
    </Popover>
  )
}

export default SupplierPopover
