import React, { useState, useEffect } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
} from '@mui/material'

const TableActions = ({
  defaultValue,
  list,
  bulkActionDisabled,
  onUpdateSelected,
  tableFor,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  useEffect(() => {
    setSelectedValue(defaultValue)
  }, [defaultValue])

  const handleChange = async (event) => {
    setSelectedValue(event.target.value)
    onUpdateSelected(event.target.value)
  }

  return (
    <Card
      sx={{
        position: 'fixed',
        bottom: 30,
        right: 24,
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <FormControl
            variant='outlined'
            sx={{
              margin: 1,
              minWidth: 200,
            }}
          >
            <InputLabel id='select-label'>
              {tableFor === 'supplier' ? 'Supplier' : 'Manufacturer'}
            </InputLabel>
            <Select
              labelId='select-label'
              id='select-outlined'
              label={tableFor === 'supplier' ? 'Supplier' : 'Manufacturer'}
              value={selectedValue}
              onChange={handleChange}
              disabled={bulkActionDisabled}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {list.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  )
}

export default TableActions
