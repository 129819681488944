import React, { useState, useContext, useEffect } from 'react'
import { Button, Typography, Box, Link, Tooltip, Card } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import AttachFileIcon from '@mui/icons-material/AttachFile'

import { generateQuotesHTMLContent } from '../../../utils/common'

import { CorrespondenceTableContext } from '../index'
import MessageBlockContainer from './messageBlock.style'
import DraftMessage from './draftMessage'
import { useThreadMarkReadMutation } from 'services/hooks/thread/useThreadMarkReadMutation'
import { useQueryClient } from '@tanstack/react-query'

const MessageBlock = ({
  message,
  messageIndex,
  selectedLine,
  onClickAttachment,
}) => {
  const queryClient = useQueryClient()
  const correspondenceFor = window.location.pathname.split('/')[1]
  const correspondenceContext = useContext(CorrespondenceTableContext)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [messageObj, setMessageObj] = useState(message)
  const [quotesContent, setQuotesContent] = useState(null)
  const [showQuotes, setShowQuotes] = useState(false)
  const [draftMessage, setDraftMessage] = useState<any>()
  const { mutate: threadMarkReadMutation } = useThreadMarkReadMutation()

  useEffect(() => {
    setMessageObj(message)
  }, [message])

  const isFirstMessage =
    messageIndex === correspondenceContext.correspondence.messages.length - 1

  useEffect(() => {
    setMessageObj(message)
  }, [message])

  const onCloseDraft = () => {
    setDraftMessage(null)
  }

  const onCorrespondenceUpdated = () => {
    if (correspondenceFor === 'rfq') {
      queryClient.invalidateQueries({
        queryKey: ['rfq', 'correspondence'],
      })
    } else {
      queryClient.invalidateQueries({
        queryKey: ['po', 'correspondence'],
      })
    }
  }

  const onClickMessageHeader = async () => {
    if (messageObj.status.status === 'Unread') {
      threadMarkReadMutation(
        { uuid: message.uuid },
        {
          onSuccess(data, variables, context) {
            const updatedMessageObj = messageObj
            updatedMessageObj.status = {
              status: 'Read',
              color: '#000000',
              bold: false,
            }

            setMessageObj({ ...updatedMessageObj })
            onCorrespondenceUpdated()
          },
        }
      )
    }

    if (messageObj.status.status === 'Draft' && isCollapsed) {
      setDraftMessage(messageObj)
    } else if (messageObj.status.status === 'Draft' && !isCollapsed) {
      onCloseDraft()
    }

    setIsCollapsed(!isCollapsed)
  }

  const onShowTrimmedContent = () => {
    if (!quotesContent) {
      const { messages } = correspondenceContext.correspondence
      const quoteMessages = [...messages]
      quoteMessages.splice(0, messageIndex + 1)
      const quotesHtmlContent = generateQuotesHTMLContent(null, quoteMessages)
      setQuotesContent(quotesHtmlContent)
    }
    setShowQuotes(!showQuotes)
  }

  return (
    <MessageBlockContainer
      className={`message ${
        messageObj.status.status === 'Unread' && 'unread-status'
      }`}
    >
      <div
        className='message-header'
        onClick={onClickMessageHeader}
        role='button'
        tabIndex={0}
      >
        <p className='name'>
          {messageObj.display_name}{' '}
          <span className='message-to'>
            {messageObj.is_internal
              ? 'Internal Note'
              : `Message to ${selectedLine.supplier}`}
          </span>{' '}
          <span
            style={{
              color: messageObj.status.color,
              fontWeight: messageObj.status.bold ? 'bold' : 'normal',
            }}
          >
            {messageObj.status.status}
          </span>
        </p>
        <p className='date'>{messageObj.date_added.formatted_date}</p>
      </div>
      {!isCollapsed && messageObj.html_body && (
        <Card variant='outlined'>
          <Box className='message-body' px={1}>
            <p
              dangerouslySetInnerHTML={{
                __html: messageObj.html_body,
              }}
            />
            {!messageObj.is_quote_attached &&
              messageObj.status.status !== 'Draft' &&
              !isFirstMessage && (
                <Tooltip title='Show trimmed content'>
                  <Button
                    variant='contained'
                    onClick={onShowTrimmedContent}
                    className='btn-show-quote'
                  >
                    <MoreHorizIcon />
                  </Button>
                </Tooltip>
              )}
          </Box>
          {messageObj.attachments.length > 0 && (
            <Box className='attachments' p={1}>
              <Typography variant='body1' gutterBottom>
                <strong>Attachments</strong>
              </Typography>
              {messageObj.attachments.map((attachment) => (
                <Box key={'message' + attachment.uuid}>
                  <Link onClick={() => onClickAttachment(attachment)}>
                    <Box display='flex'>
                      {attachment.mime_type === 'application/pdf' ? (
                        <PictureAsPdfIcon />
                      ) : (
                        <AttachFileIcon />
                      )}
                      &nbsp;
                      {attachment.filename}
                    </Box>
                  </Link>
                </Box>
              ))}
            </Box>
          )}
          {showQuotes && (
            <Box className='quotes' p={1}>
              <div
                dangerouslySetInnerHTML={{
                  __html: quotesContent,
                }}
              ></div>
            </Box>
          )}
        </Card>
      )}
      {draftMessage && (
        <DraftMessage
          message={draftMessage}
          selectedMessageIndex={messageIndex}
          selectedLine={selectedLine}
          closeDraft={onCloseDraft}
        />
      )}
    </MessageBlockContainer>
  )
}

export default MessageBlock
