import { Box, Grid, Typography } from '@mui/material'

import RecommendationLine from 'components/recommendationLine'

const TabRecommendations = ({ requisition }) => {
  return (
    <Box>
      <Typography variant='h5' gutterBottom>
        Recommendations
      </Typography>
      <Grid container>
        <Grid item xs={8}>
          {!requisition.recommendations ? (
            <>
              <Typography>No recommendation found</Typography>
            </>
          ) : (
            requisition.recommendations?.map((recommendation, i) => (
              <RecommendationLine
                requisition={requisition}
                recommendation={recommendation}
                key={'recommendation-line' + i}
              />
            ))
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default TabRecommendations
