import { ApiMetaDataDTO, ErrorDTO, UserAuthDTO } from 'declarations'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { client, queryClient } from 'services/helpers/apiConfig'

type AuthDataDTO = {
  userName: string
  oldPassword: string
  newPassword: string
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useChangePasswordMutation = () =>
  useMutation<unknown, ErrorDTO, AuthDataDTO>({
    mutationFn: async (values: AuthDataDTO) => {
      console.log(values)
      try {
        const apiString = `/user/update/${values.userName}`

        await client.put<ApiResponseDTO>(apiString, {
          old_password: values.oldPassword,
          new_password: values.newPassword,
        })

        return true
      } catch (err) {
        console.log(err)
        throw err
      }
    },
  })
