import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Box,
  Fab,
  Card,
  CardContent,
  Link,
  Paper,
  Tooltip,
  Button,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import Dialog from '../../../components/common/dialog'

import RfqReadModal from './readModal'
import CreateRfqModal from './createRfqModal'
import TableBulkActions from './components/tableBulkActions'
import { useRfqListQuery } from 'services/hooks/rfq/useRfqListQuery'
import { useRfqStatusListQuery } from 'services/hooks/rfq/useRfqStatusListQuery'
import { useRfqAssignUsersQuery } from 'services/hooks/rfq/useRfqAssignUsersQuery'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'
import {
  DataGrid,
  GridColDef,
  GridPagination,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid'
import DataGridCustomPagination from 'components/dataGridCustomPagination'
import AssignDropdown from 'components/assignDropdown'
import StatusLabel from 'components/tables/statusLabel'
import Filters from './filters'

const RfqList = () => {
  const { id: rfqId }: any = useParams()
  const history = useHistory()

  const [filterValues, setFilterValues] = useState({
    statuses: [],
    suppliers: [],
    users: [],
    term: '',
  })
  const [rfqModalOpen, setRfqModalOpen] = useState(false)
  const [isCreateRfq, setIsCreateRfq] = useState(false)
  const { data: statusList } = useRfqStatusListQuery()
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25,
  })
  const { data: rfqListData, isFetching: isFetchingRfqList } = useRfqListQuery({
    pageIndex: paginationModel.page,
    rowsCount: paginationModel.pageSize,
    filterValues,
  })
  const { data: suppliersList } = useSuppliersQuery()
  const { data: rfqAssignUsersList } = useRfqAssignUsersQuery()
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([])

  const rowCountRef = React.useRef(rfqListData?.total_rows || 0)

  const rowCount = React.useMemo(() => {
    if (rfqListData?.total_rows !== undefined) {
      rowCountRef.current = rfqListData.total_rows
    }
    return rowCountRef.current
  }, [rfqListData?.total_rows])

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'RFQ',
      renderCell: (params: GridRenderCellParams) => (
        <Link
          component='button'
          variant='body2'
          onClick={() => openRfqModal(params.row)}
        >
          {params.row.id}
        </Link>
      ),
      minWidth: 150,
    },
    {
      field: 'date_added.timestamp',
      headerName: 'Date',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.row.date_added.formatted_date}>
          <span>{params.row.date_added.human_date.relative.long}</span>
        </Tooltip>
      ),
      minWidth: 200,
      valueGetter: (value, row) => row.date_added.formatted_date,
    },
    {
      field: 'part_numbers',
      headerName: 'Part Number',
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          {params.row.part_numbers[0]}{' '}
          {params.row.part_numbers.length > 1 && (
            <Tooltip title={params.row.part_numbers.join(', ')}>
              <Button color='primary'>
                {params.row.part_numbers.length - 1} more...
              </Button>
            </Tooltip>
          )}
        </Box>
      ),
      minWidth: 200,
      valueGetter: (value, row) => row.part_numbers[0],
    },
    {
      field: 'requisition_ids',
      headerName: 'Requisition ID',
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          {params.row.requisition_ids[0]}{' '}
          {params.row.requisition_ids.length > 1 && (
            <Tooltip title={params.row.requisition_ids.join(', ')}>
              <Button color='primary'>
                {params.row.requisition_ids.length - 1} more...
              </Button>
            </Tooltip>
          )}
        </Box>
      ),
      minWidth: 200,
      valueGetter: (value, row) => row.requisition_ids[0],
    },
    {
      field: 'supplier',
      headerName: 'Supplier',
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params: GridRenderCellParams) =>
        statusList && (
          <StatusLabel statusList={statusList} statusId={params.row.status_id}>
            {params.row.status}
          </StatusLabel>
        ),
      display: 'flex',
      minWidth: 150,
    },
    {
      field: 'assignee',
      headerName: 'AssignedTo',
      renderCell: (params: GridRenderCellParams) =>
        rfqAssignUsersList && (
          <AssignDropdown
            userList={rfqAssignUsersList}
            selected={params.row}
            assign={params.row.permissions.assign}
            updateFor='rfq'
          />
        ),
      minWidth: 200,
      display: 'flex',
      valueGetter: (value, row) => row.assignee?.name,
    },
  ]

  const openRfqModal = (rfq) => {
    history.push(`/rfq/${rfq.id}/lines`)
  }

  const handleClose = () => {
    history.push('/rfq/list')
  }

  const onCreateRfqModalOpen = () => {
    setIsCreateRfq(true)
  }

  const onChangeFilter = (target, values) => {
    setFilterValues({
      ...filterValues,
      [target]: values,
    })
  }

  useEffect(() => {
    if (rfqId) {
      setRfqModalOpen(true)
    } else {
      setRfqModalOpen(false)
    }
  }, [rfqId])

  return (
    <Paper sx={{ p: 2 }} elevation={3}>
      <Filters onChangeFilter={onChangeFilter} filterValues={filterValues} />

      <Box mb={3} height={600}>
        <DataGrid
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          rows={rfqListData?.rfq || []}
          columns={columns} // Define your columns
          rowCount={rowCount}
          paginationMode='server'
          paginationModel={paginationModel}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          onPaginationModelChange={setPaginationModel}
          loading={isFetchingRfqList}
          slots={{
            pagination: (props) => (
              <GridPagination
                ActionsComponent={DataGridCustomPagination}
                {...props}
              />
            ),
          }}
        />
      </Box>
      {statusList && rfqAssignUsersList && suppliersList && rfqListData && (
        <Box>
          <Card>
            <CardContent
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <TableBulkActions checkedRfqs={rowSelectionModel} />
              <Fab
                color='secondary'
                aria-label='add'
                onClick={onCreateRfqModalOpen}
              >
                <AddIcon />
              </Fab>
              <CreateRfqModal
                open={isCreateRfq}
                handleClose={() => {
                  setIsCreateRfq(false)
                }}
              />
            </CardContent>
          </Card>

          <Dialog open={rfqModalOpen} onClose={handleClose}>
            {rfqId && (
              <RfqReadModal
                userList={rfqAssignUsersList}
                onClose={handleClose}
              />
            )}
          </Dialog>
        </Box>
      )}
    </Paper>
  )
}
export default RfqList
