import React, { useState } from 'react'
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material'

import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import { usePoUpdateMutation } from 'services/hooks/purchaseOrders/usePoUpdateMutation'
import { usePoAssignUsersQuery } from 'services/hooks/purchaseOrders/usePoAssignUsersQuery'
import { usePoBatchStatusUpdateMutation } from 'services/hooks/purchaseOrders/usePoBatchStatusUpdateMutation'
import { usePoStatusListQuery } from 'services/hooks/purchaseOrders/usePoStatusListQuery'
import { usePoConfirmBatchMutation } from 'services/hooks/purchaseOrders/usePoConfirmBatchMutation'

type TableBulkActionsProps = {
  checkedPos: any
}

const TableBulkActions = ({ checkedPos }: TableBulkActionsProps) => {
  const { data: statusList } = usePoStatusListQuery()
  const { data: poAssignUsersList } = usePoAssignUsersQuery()
  const [bulkAction, setBulkAction] = useState('')
  const [assignUser, setAssignUser] = useState('')
  const [status, setStatus] = useState('')
  const [openPoSendModal, setOpenPoSendModal] = useState(false)
  const queryClient = useQueryClient()
  const { mutate: updatePoMutation } = usePoUpdateMutation()
  const { mutate: updatePoBatchStatusUpdateMutation } =
    usePoBatchStatusUpdateMutation()
  const { mutate: poConfirmBatchMutation } = usePoConfirmBatchMutation()

  const resetSelectors = () => {
    setBulkAction('')
    setAssignUser('')
    setStatus('')
  }

  const unAssignUser = async () => {
    updatePoMutation(
      {
        reqBody: {
          assignee: 0,
          id: checkedPos,
        },
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: ['po', 'list'],
          })
          resetSelectors()
          toast.success('Unassigned user successfully')
        },
      }
    )
  }

  const onSendPo = async () => {
    poConfirmBatchMutation(
      { poIds: checkedPos },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: ['po', 'list'],
          })
          setOpenPoSendModal(false)
        },
      }
    )
  }

  const onBulkActionChange = (e: any) => {
    setBulkAction(e.target.value)
    if (e.target.value === 'unassign') {
      unAssignUser()
    } else if (e.target.value === 'sendPo') {
      setOpenPoSendModal(true)
    }
  }

  const onAssignUser = async (e: any) => {
    const { value } = e.target
    setAssignUser(e.target.value)

    updatePoMutation(
      {
        reqBody: {
          assignee: value,
          id: checkedPos,
        },
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: ['po', 'list'],
          })
          resetSelectors()
          toast.success('Assigned to user successfully')
        },
      }
    )
  }

  const onChangeStatus = async (e: any) => {
    const { value } = e.target
    setStatus(value)

    updatePoBatchStatusUpdateMutation(
      {
        status_id: value,
        po_uuids: checkedPos.toString(),
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: ['po', 'list'],
          })
          resetSelectors()
          toast.success('Changed status successfully')
        },
      }
    )
  }

  const handleClose = () => {
    setOpenPoSendModal(false)
  }

  return (
    <Box display='flex'>
      <Box sx={{ minWidth: 200, mr: 1 }}>
        <FormControl fullWidth>
          <InputLabel id='select-bulkactions-label'>Bulk Actions</InputLabel>
          <Select
            labelId='select-bulkactions-label'
            id='select-bulkactions-outlined'
            value={bulkAction}
            onChange={onBulkActionChange}
            label='Bulk Actions'
            disabled={checkedPos.length === 0}
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='assign'>Assign to user</MenuItem>
            <MenuItem value='unassign'>Unassign</MenuItem>
            <MenuItem value='sendPo'>Send Pos</MenuItem>
            <MenuItem value='status'>Set Status</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {bulkAction === 'assign' && (
        <Box sx={{ minWidth: 200, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel id='select-userlist-label'>User List</InputLabel>
            <Select
              labelId='select-userlist-label'
              id='select-userlist-outlined'
              value={assignUser}
              onChange={onAssignUser}
              label='User List'
              disabled={checkedPos.length === 0}
            >
              {poAssignUsersList?.map((user: any) => (
                <MenuItem value={user.id} key={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {bulkAction === 'status' && (
        <Box sx={{ minWidth: 200, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel id='select-statuslist-label'>Status List</InputLabel>
            <Select
              labelId='select-statuslist-label'
              id='select-statuslist-outlined'
              value={status}
              onChange={onChangeStatus}
              label='Status List'
              disabled={checkedPos.length === 0}
            >
              <MenuItem value=''>None</MenuItem>
              {statusList?.map((status: any) => (
                <MenuItem value={status.id} key={status.id}>
                  {status.status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <Dialog onClose={handleClose} open={openPoSendModal}>
        <DialogTitle>
          You are about to send Pos {checkedPos.toString()} to the suppliers.
          Are you sure you want to do this?
        </DialogTitle>
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' color='primary' onClick={onSendPo}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TableBulkActions
