import React, { createContext } from 'react'
import { Box, Paper, Toolbar } from '@mui/material'
import { useLocation } from 'react-router-dom'

import AppDrawer from 'components/appDrawer'

export const PageWrapperContext = createContext({})

const PageWrapper = ({ isLoggedIn, userInfo, children }) => {
  const location = useLocation()

  return isLoggedIn && !location.pathname.includes('/changePassword') ? (
    <PageWrapperContext.Provider value={{ userData: userInfo?.data }}>
      <Box sx={{ display: 'flex' }}>
        <AppDrawer />
        <Box
          component={Paper}
          sx={{
            flexGrow: 1,
            px: 3,
            overflowX: 'hidden',
            borderRadius: 0,
            minHeight: '100vh',
          }}
        >
          <Toolbar />
          <Box sx={{ paddingY: 3 }}>{children}</Box>
        </Box>
      </Box>
    </PageWrapperContext.Provider>
  ) : (
    <Box>{children}</Box>
  )
}

export default PageWrapper
