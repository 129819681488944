import React, { useState } from 'react'
import { TextField, Button, Box, Typography } from '@mui/material'

import { ModalStyle } from '.'
import { useDeleteGoogleAuthCodeMutation } from 'services/hooks/user/useDeleteGoogleAuthCodeMutation'

const DisableMFAModalContent = ({ onClose, userId }) => {
  const [password, setPassword] = useState('')
  const { mutate: deleteGoogleAuthCodeMutate } =
    useDeleteGoogleAuthCodeMutation()

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const onCancel = () => {
    onClose()
  }

  const onConfirm = (event) => {
    event.preventDefault()

    deleteGoogleAuthCodeMutate(
      { password: password, user_id: userId },
      {
        onSuccess(data, variables, context) {
          onClose()
        },
      }
    )
  }

  return (
    <Box sx={ModalStyle}>
      <form onSubmit={onConfirm}>
        <Box>
          <Typography sx={{ marginBottom: 2 }}>
            Please Confirm Your Password
          </Typography>
          <TextField
            label='Password'
            type='password'
            value={password}
            onChange={onChangePassword}
            fullWidth
          />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Button variant='contained' color='secondary' onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            disabled={password === ''}
            sx={{ marginLeft: 1 }}
          >
            Confirm
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default DisableMFAModalContent
