export { default as ChangePasswordForm } from './changePasswordForm'
// export { default as AuthenticatedBrowsers } from './authenticatedBrowsers'
export { default as MetaData } from './metaData'
export { default as ProfileForm } from './profileForm'

export const ModalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
