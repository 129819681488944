import React, { useEffect } from 'react'
import { InputLabel, FormControl, MenuItem, Select } from '@mui/material'

import { useSnvUpdateMutation } from 'services/hooks/supplierNameVariants/useSnvUpdateMutation'
import { useMnvUpdateMutation } from 'services/hooks/manufacturerNameVariants/useMnvUpdateMutation'

const SelectAssociate = ({ list, value, uuid, tableFor }) => {
  const [selectedValue, setSelectedValue] = React.useState(value)
  const { mutate: snvUpdateMutation } = useSnvUpdateMutation()
  const { mutate: mnvUpdateMutation } = useMnvUpdateMutation()

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  const handleChange = async (event) => {
    const tempValue = selectedValue
    setSelectedValue(event.target.value)

    if (tableFor === 'supplier') {
      snvUpdateMutation(
        {
          uuids: [uuid],
          updateBody: {
            supplier_id: event.target.value,
          },
        },
        {
          onError() {
            setSelectedValue(tempValue)
          },
        }
      )
    } else {
      mnvUpdateMutation(
        {
          uuids: [uuid],
          updateBody: {
            manufacturer_id: event.target.value,
          },
        },
        {
          onError() {
            setSelectedValue(tempValue)
          },
        }
      )
    }
  }

  return (
    <FormControl
      size='small'
      variant='outlined'
      sx={{ minWidth: 120 }}
      fullWidth
    >
      <InputLabel id='select'>
        {tableFor === 'supplier' ? 'Supplier' : 'Manufacturer'}
      </InputLabel>
      <Select
        labelId='select'
        value={selectedValue}
        onChange={handleChange}
        label={tableFor === 'supplier' ? 'Supplier' : 'Manufacturer'}
      >
        <MenuItem value='0'>
          <em>None</em>
        </MenuItem>
        {list.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectAssociate
