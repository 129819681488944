import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type UpdateUserSupplierDTO = {
  uuid: string
  reqBody: {
    name?: string
    street_address_1?: string
    street_address_2?: string
    city?: string
    web_address?: string
    state?: string
    zip?: string
    warehouse_zip?: string
    linked_user_id?: string
  }
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useUpdateSupplierProfileMutation = () =>
  useMutation<unknown, ErrorDTO, UpdateUserSupplierDTO>({
    mutationFn: async (values: UpdateUserSupplierDTO) => {
      try {
        const res = await client.put<ApiResponseDTO>(
          `/supplier/update/${values.uuid}`,
          values.reqBody
        )

        toast.success('Supplier profile is updated')

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
