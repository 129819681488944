import { Box, Grid, Link } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Link as RouterLink } from 'react-router-dom'
import { useRequisitionRfqPoQuery } from 'services/hooks/requisition/useRequisitionRfqPoQuery'
import SupplierTableCell from './supplierTableCell'
import { useRequisitionQuery } from 'services/hooks/requisition/useRequisitionQuery'
import AddRfqForm from '../rfq/addRfqForm'

type TabRfqProps = {
  uuid: string
}

const TabRfq = ({ uuid }: TabRfqProps) => {
  const { data: requisitionRfqPoData, refetch: refetchRequisitionRfpPo } =
    useRequisitionRfqPoQuery(uuid)
  const { data: requisitionData } = useRequisitionQuery(uuid)

  const columns: GridColDef[] = [
    {
      field: 'reference',
      headerName: 'Reference',
      renderCell: (params: GridRenderCellParams) => (
        <Link
          component={RouterLink}
          to={`/${params.row.type.toLowerCase()}/${params.row.id}`}
        >
          <span style={{ textTransform: 'uppercase' }}>
            {params.row.type}&nbsp;
            {params.row.id}
          </span>
        </Link>
      ),
    },
    {
      field: 'supplier',
      headerName: 'Supplier',
      renderCell: (params: GridRenderCellParams) => (
        <SupplierTableCell supplier={params.row.supplier} />
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      valueGetter: (value: any, row) => value.formatted_date,
      minWidth: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: (value: any, row) => value.term,
    },
  ]

  const onRfqSent = () => {
    refetchRequisitionRfpPo()
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={requisitionData?.permissions.create_rfq ? 9 : 12}>
          {requisitionRfqPoData && (
            <DataGrid
              rows={requisitionRfqPoData.lines}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          )}
        </Grid>
        {requisitionData?.permissions.create_rfq && (
          <Grid item xs={3}>
            <AddRfqForm requisition={requisitionData} onRfqSent={onRfqSent} />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default TabRfq
