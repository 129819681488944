import {
  useQuery,
  keepPreviousData,
  UseQueryResult,
} from '@tanstack/react-query'
import { SupplierPreferenceRuleDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

type RequisitionListQueryParamsDTO = {
  pageIndex: number
  rowsCount: number
  filterValues?: {
    partNumberClass: string
  }
  searchTerm?: string
}

type ResponseDTO = {
  supplier_preference_rules: SupplierPreferenceRuleDTO[]
  total_rows: number
}

const useSprListQuery = (
  params: RequisitionListQueryParamsDTO
): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: [
      'spr',
      params.pageIndex,
      params.rowsCount,
      params.filterValues?.partNumberClass,
      params.searchTerm,
    ],
    queryFn: async () => {
      const reqParams = {
        part_number_class: params.filterValues?.partNumberClass,
        page: params.pageIndex,
        rows: params.rowsCount,
        search_term: params.searchTerm,
      }

      const res = await client.get(`/supplier_preference_rules/list`, {
        params: reqParams,
      })

      return res.data.data
    },
    placeholderData: keepPreviousData,
    staleTime: 5000,
  })
}

export { useSprListQuery }
