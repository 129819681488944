import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ConfirmRfqParams = {
  rfq_id: number
  message_body: string
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqConfirmMutation = () =>
  useMutation<any, ErrorDTO, ConfirmRfqParams>({
    mutationFn: async (values: ConfirmRfqParams) => {
      try {
        const res = await client.put<ApiResponseDTO>('/rfq/confirm', values)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
