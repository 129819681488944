import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { PoDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

type PoListQueryParamsDTO = {
  pageIndex: number
  rowsCount: number
  filterValues?: {
    statusIds?: string[]
    supplierIds?: string[]
    users?: string[]
    term?: string
    status?: string
  }
}

type ResponseDTO = {
  purchase_order: PoDTO[]
  total_rows: number
}

const usePoListQuery = (
  params: PoListQueryParamsDTO
): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: [
      'po',
      'list',
      params.pageIndex,
      params.rowsCount,
      params.filterValues?.statusIds,
      params.filterValues?.supplierIds,
      params.filterValues?.users,
      params.filterValues?.term,
      params.filterValues?.status,
    ],
    queryFn: async () => {
      try {
        const reqParams = {
          status: params.filterValues?.status,
          status_id: params.filterValues?.statusIds?.join(),
          supplier_id: params.filterValues?.supplierIds?.join(),
          assignee: params.filterValues?.users?.join(),
          page: params.pageIndex,
          rows: params.rowsCount,
          search: params.filterValues.term,
        }

        const res = await client.get('po/list', { params: reqParams })

        return res.data.data
      } catch (err) {
        throw err.response.data
      }
    },
  })
}

export { usePoListQuery }
