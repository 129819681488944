import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Link,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useBulkUserAddMfaMutation } from 'services/hooks/user/useBulkUserAddMfaMutation'
import { useBulkUserForceLogoutMutation } from 'services/hooks/user/useBulkUserForceLogoutMutation'
import { useBulkUserMakeActiveMutation } from 'services/hooks/user/useBulkUserMakeActiveMutation'
import { useBulkUserMakeInActiveMutation } from 'services/hooks/user/useBulkUserMakeInactiveMutation'

const TableActions = ({ checkedUserIds, addUser }) => {
  const [bulkAction, setBulkAction] = useState('')
  const {
    mutate: bulkUserMakeActiveMutate,
    status: bulkUserMakeActiveMutateStatus,
  } = useBulkUserMakeActiveMutation()
  const {
    mutate: bulkUserMakeInactiveMutate,
    status: bulkUserMakeInactiveMutateStatus,
  } = useBulkUserMakeInActiveMutation()
  const {
    mutate: bulkUserForceLogoutMutate,
    status: bulkUserForceLogoutMutateStatus,
  } = useBulkUserForceLogoutMutation()
  const { mutate: bulkUserAddMfaMutate, status: bulkUserAddMfaMutateStatus } =
    useBulkUserAddMfaMutation()
  const queryClient = useQueryClient()

  const isPendingBulkAction =
    bulkUserMakeActiveMutateStatus === 'pending' ||
    bulkUserMakeInactiveMutateStatus === 'pending' ||
    bulkUserForceLogoutMutateStatus === 'pending' ||
    bulkUserAddMfaMutateStatus === 'pending'

  const handleChangeBulkAction = (
    event: SelectChangeEvent<typeof bulkAction>
  ) => {
    setBulkAction(event.target.value)
  }

  const onApplyBulkAction = () => {
    switch (bulkAction) {
      case 'makeActive':
        bulkUserMakeActiveMutate(
          { userIds: checkedUserIds },
          {
            onSuccess(data, variables, context) {
              queryClient.invalidateQueries({ queryKey: ['user', 'list'] })
              toast.success('Updated Successfully!')
            },
          }
        )
        break
      case 'makeInactive':
        bulkUserMakeInactiveMutate(
          { userIds: checkedUserIds },
          {
            onSuccess(data, variables, context) {
              queryClient.invalidateQueries({ queryKey: ['user', 'list'] })
              toast.success('Updated Successfully!')
            },
          }
        )
        break
      case 'forceLogout':
        bulkUserForceLogoutMutate(
          { userIds: checkedUserIds },
          {
            onSuccess(data, variables, context) {
              queryClient.invalidateQueries({ queryKey: ['user', 'list'] })
              toast.success('Updated Successfully!')
            },
          }
        )
        break
      case 'requireMFA':
        bulkUserAddMfaMutate(
          { userIds: checkedUserIds },
          {
            onSuccess(data, variables, context) {
              queryClient.invalidateQueries({ queryKey: ['user', 'list'] })
              toast.success('Updated Successfully!')
            },
          }
        )
        break
      default:
        break
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <FormControl variant='outlined'>
          <InputLabel>Bulk Actions</InputLabel>
          <Select
            native
            value={bulkAction}
            onChange={handleChangeBulkAction}
            label='Bulk Actions'
          >
            <option value='' />
            <option value='makeActive'>Make Active</option>
            <option value='makeInactive'>Make Inactive</option>
            <option value='forceLogout'>Force Logout</option>
            <option value='requireMFA'>Require MFA</option>
          </Select>
        </FormControl>
        <Button
          variant='contained'
          color='primary'
          disabled={
            checkedUserIds.length === 0 ||
            bulkAction === '' ||
            isPendingBulkAction
          }
          onClick={onApplyBulkAction}
        >
          {isPendingBulkAction ? (
            <CircularProgress size={20} thickness={5} />
          ) : (
            'Apply'
          )}
        </Button>
      </Box>
      {addUser && (
        <Link component={RouterLink} to='/admin/user/create'>
          <Button variant='contained'>+ Add User</Button>
        </Link>
      )}
    </Box>
  )
}

export default TableActions
