import {
  Box,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import { useEffect, useState } from 'react'
import _isEqual from 'lodash/isEqual'

import { useManufacturersListQuery } from 'services/hooks/others/useManufacturersListQuery'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'
import { useLazySprProcessQuery } from 'services/hooks/supplierPreferenceRules/useSprProcessQuery'

type RecommendationValue = {
  partNumber: string | null
  manufacturer: string | null
  supplier: string | null
}

type RecommedationsProps = {
  partNumbers: string[]
  partNumberClass: string
  onUpdateRecommendations: (recommendations: RecommendationValue[]) => void
  onUpdateDraftRecommendation: (recommendation: any) => void
}

const Recommendations = ({
  partNumbers,
  partNumberClass,
  onUpdateRecommendations,
  onUpdateDraftRecommendation,
}: RecommedationsProps) => {
  const { data: manufacturers } = useManufacturersListQuery()
  const { data: suppliers } = useSuppliersQuery()

  const [recommendations, setRecommendations] = useState<RecommendationValue[]>(
    []
  )
  const [isRecommendationValueError, setIsRecommendationValueError] =
    useState<boolean>(false)
  const [recommendationValues, setRecommendationValues] =
    useState<RecommendationValue>({
      partNumber: '',
      manufacturer: '',
      supplier: '',
    })

  const [fetchSprProcess] = useLazySprProcessQuery()

  const onDeleteRecommendation = (recommendation: RecommendationValue) => {
    setRecommendations(
      recommendations.filter((x) => !_isEqual(x, recommendation))
    )
  }

  const onChangeRecommendationSelect = (
    property: string,
    event: SelectChangeEvent
  ) => {
    setIsRecommendationValueError(false)
    setRecommendationValues({
      ...recommendationValues,
      [property]: event.target.value,
    })
  }

  const onSaveRecommendation = () => {
    if (
      recommendations.findIndex((x) => _isEqual(x, recommendationValues)) === -1
    ) {
      onUpdateDraftRecommendation(null)
      setRecommendations([...recommendations, recommendationValues])
      setRecommendationValues({
        partNumber: '',
        manufacturer: '',
        supplier: '',
      })
    } else {
      setIsRecommendationValueError(true)
    }
  }

  const getSupplierPreferenceRules = async (partNumber: string) => {
    try {
      const res = await fetchSprProcess(partNumber, partNumberClass)
      const newRecommendations = res.recommendations.map((recommendation) => {
        const manufacturerName = manufacturers.find(
          (x) => x.id === recommendation.manufacturer_id
        )?.name
        const supplierName = suppliers.find(
          (x) => x.id === recommendation.supplier_id
        )?.name

        return {
          partNumber: recommendation.part_number,
          manufacturer: manufacturerName,
          supplier: supplierName,
        }
      })

      let filteredRecommendations = []

      newRecommendations.map((recommendation) => {
        if (
          recommendations.findIndex(
            (x) => JSON.stringify(x) === JSON.stringify(recommendation)
          ) === -1
        ) {
          filteredRecommendations = [...filteredRecommendations, recommendation]
        }
      })

      return filteredRecommendations
    } catch (err) {
      console.log(err)
    }
  }

  const getRulesforAllPartNumbers = async (partNumbers) => {
    let arr = []
    // Use a for loop to iterate over the partNumbers array
    for (let i = 0; i < partNumbers.length; i++) {
      // Use await to wait for each promise to resolve before moving to the next one
      const newRecommendations = await getSupplierPreferenceRules(
        partNumbers[i]
      )
      arr = [...arr, ...newRecommendations]
    }

    setRecommendations([...recommendations, ...arr])
  }

  useEffect(() => {
    if (manufacturers && suppliers && partNumbers.length > 0) {
      getRulesforAllPartNumbers(partNumbers)
    }
  }, [partNumbers, manufacturers, suppliers])

  useEffect(() => {
    const reformedRecommendations: any = recommendations.map(
      (recommendation) => {
        const manufacturer = manufacturers.find(
          (x) => x.name === recommendation.manufacturer
        )
        const supplier = suppliers.find(
          (x) => x.name === recommendation.supplier
        )

        return {
          partNumber: recommendation.partNumber,
          manufacturer: manufacturer,
          supplier: supplier,
        }
      }
    )
    onUpdateRecommendations(reformedRecommendations)
  }, [recommendations])

  useEffect(() => {
    if (
      recommendationValues.partNumber !== '' &&
      recommendationValues.manufacturer !== '' &&
      recommendationValues.supplier !== ''
    ) {
      const manufacturer = manufacturers?.find(
        (x) => x.name === recommendationValues.manufacturer
      )
      const supplier = suppliers?.find(
        (x) => x.name === recommendationValues.supplier
      )

      const reformedRecommendation = {
        partNumber: recommendationValues.partNumber,
        manufacturer: manufacturer,
        supplier: supplier,
      }

      onUpdateDraftRecommendation(reformedRecommendation)
    }
  }, [recommendationValues, manufacturers, suppliers])

  return (
    <Box mt={2}>
      <Box mb={2}>
        <Typography variant='h5' gutterBottom>
          Recommendations (optional)
        </Typography>
        {recommendations.map((recommendation, index) => (
          <Box
            key={index}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              gap: 1,
              maxWidth: 400,
            }}
          >
            <Typography>
              Buy{' '}
              {!recommendation.partNumber
                ? 'any part number'
                : recommendation.partNumber}{' '}
              {!recommendation.manufacturer
                ? ''
                : `in ${recommendation.manufacturer}`}{' '}
              from{' '}
              {!recommendation.supplier
                ? 'any supplier'
                : recommendation.supplier}
            </Typography>
            <IconButton
              aria-label='delete'
              size='small'
              onClick={() => onDeleteRecommendation(recommendation)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>

      <Box>
        <Box display='flex' alignItems='center' sx={{ gap: 1 }}>
          <FormControl fullWidth variant='filled'>
            <InputLabel id='type-select-label'>All Part Numbers</InputLabel>
            <Select
              labelId='type-select-label'
              name='part_numbers'
              value={recommendationValues.partNumber}
              onChange={(e) => onChangeRecommendationSelect('partNumber', e)}
            >
              {partNumbers.map((partNumber) => (
                <MenuItem value={partNumber}>{partNumber}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant='filled'>
            <InputLabel id='type-select-label'>Any Manufacturer</InputLabel>
            <Select
              labelId='type-select-label'
              name='manufacturer'
              value={recommendationValues.manufacturer}
              onChange={(e) => onChangeRecommendationSelect('manufacturer', e)}
            >
              {manufacturers?.map((manufacturer) => (
                <MenuItem value={manufacturer.name} key={manufacturer.id}>
                  {manufacturer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant='filled'>
            <InputLabel id='type-select-label'>Any Supplier</InputLabel>
            <Select
              labelId='type-select-label'
              name='supplier'
              value={recommendationValues.supplier}
              onChange={(e) => onChangeRecommendationSelect('supplier', e)}
            >
              {suppliers?.map((supplier) => (
                <MenuItem value={supplier.name} key={supplier.id}>
                  {supplier.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <IconButton
              disabled={
                recommendationValues.manufacturer === '' &&
                recommendationValues.partNumber === '' &&
                recommendationValues.supplier === ''
              }
              onClick={onSaveRecommendation}
            >
              <SaveIcon />
            </IconButton>
          </Box>
        </Box>
        {isRecommendationValueError && (
          <Typography
            color='error'
            variant='caption'
            style={{ marginLeft: 14, marginTop: 3, display: 'block' }}
          >
            This Recommendation already exists
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default Recommendations
