import { TextField, Tooltip } from '@mui/material'
import { useState } from 'react'
import { InventoryTableLineDTO } from '.'

type EditableInputProps = {
  line: InventoryTableLineDTO
  onChange: (line: InventoryTableLineDTO) => void
}

const EditableInput = ({ line, onChange }: EditableInputProps) => {
  const [error, setError] = useState(false)
  const [qty, setQty] = useState(`${line.quantity_required}`)

  const onTooltipClose = () => {
    setError(false)
  }

  const onChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (parseInt(value, 10) > line.maxQuantity || parseInt(value, 10) <= 0) {
      setError(true)
    } else {
      setError(false)
      setQty(value)

      onChange({ ...line, quantity_required: parseInt(value, 10) })
    }
  }

  return (
    <Tooltip
      open={error}
      title='Quantity should be less than remaining quantity and bigger than 0'
      onClose={onTooltipClose}
    >
      <TextField
        hiddenLabel
        value={qty}
        variant='filled'
        size='small'
        onChange={onChangeQuantity}
        error={error}
      />
    </Tooltip>
  )
}

export default EditableInput
