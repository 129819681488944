import React, { useEffect } from 'react'
import { Button, Box, Menu, MenuItem, CircularProgress } from '@mui/material'

import { useRfqStatusUpdateMutation } from 'services/hooks/rfq/useRfqStatusUpdateMutation'
import { usePoStatusUpdateMutation } from 'services/hooks/purchaseOrders/usePoStatusUpdateMutation'

export const StatusDropdown = ({
  statuses,
  statusId,
  uuid,
  onStatusUpdated,
  loadingStatuses,
  statusFor,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedStatus, setSelectedStatus] = React.useState(
    statuses[statusId - 1]
  )
  const { mutate: rfqStatusUpdateMutation } = useRfqStatusUpdateMutation()
  const { mutate: poStatusUpdateMutation } = usePoStatusUpdateMutation()

  useEffect(() => {
    setSelectedStatus(statuses[statusId - 1])
  }, [statusId])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onSelectStatus = async (status) => {
    setAnchorEl(null)
    if (statusFor === 'rfq') {
      rfqStatusUpdateMutation(
        {
          uuid,
          reqBody: {
            status_id: status.id,
          },
        },
        {
          onSuccess() {
            setSelectedStatus(status)
            onStatusUpdated()
          },
        }
      )
    } else {
      poStatusUpdateMutation(
        {
          uuid,
          reqBody: {
            status_id: status.id,
          },
        },
        {
          onSuccess() {
            setSelectedStatus(status)
            onStatusUpdated()
          },
        }
      )
    }
  }

  return (
    <Box sx={{ margin: '0 10px' }}>
      {loadingStatuses ? (
        <Button variant='contained' disabled>
          <CircularProgress size={25} />
        </Button>
      ) : (
        <Box>
          <Button
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            {selectedStatus.status}
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {statuses.map((status, i) => (
              <MenuItem
                onClick={() => onSelectStatus(status)}
                key={'status-menu-item' + i}
                className={`${status.disabled && 'disabled'}`}
              >
                {status.status}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </Box>
  )
}

export default StatusDropdown
