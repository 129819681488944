import { styled } from '@mui/material/styles'
import { orange } from '@mui/material/colors'

const ReplyMessageContainer = styled('div')`
  padding: 16px;

  .wrapper {
    border: 1px solid #dadce0;
    border-radius: 8px;
    padding: 10px;

    .reply-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      span.text-draft {
        color: ${orange[500]};
      }

      .reply-to {
        span {
          display: flex;
          align-items: center;
        }
      }

      .reply-option {
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }
      }
    }

    .reply-content {
      textarea {
        width: 100%;
        border: none;
        outline: none;
        height: 200px;
        color: #222;
        font-size: 0.85em;
      }
    }

    .reply-buttons {
      text-align: right;
    }
  }
`

export default ReplyMessageContainer
