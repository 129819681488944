import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

const useRfqMessageTemplateQuery = (rfqId: number, enabled = true) => {
  return useQuery({
    queryKey: ['rfq', 'message_template', rfqId],
    queryFn: async () => {
      try {
        const res = await client.get(`/rfq/message_template/${rfqId}`)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    enabled,
    retry: false,
  })
}

export { useRfqMessageTemplateQuery }
