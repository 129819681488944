import {
  ApiMetaDataDTO,
  ErrorDTO,
  FullPermissionsDTO,
  RfqDTO,
} from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type RfqCreateParams = {
  supplier_id: number
}

type CreatedRfqDTO = Omit<RfqDTO, 'date_last_updated' | 'permissions'> & {
  permissions: FullPermissionsDTO
  rfq_id: number
  action_due: string
}

type ApiResponseDTO = {
  code: number
  data: CreatedRfqDTO
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqCreateMutation = () =>
  useMutation<CreatedRfqDTO, ErrorDTO, RfqCreateParams>({
    mutationFn: async (values: RfqCreateParams) => {
      try {
        const res = await client.post<ApiResponseDTO>('/rfq/create', values)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
