import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { RfqDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

type ResponseDTO = {
  rfq: RfqDTO[]
  total_rows: number
}

const useRfqListDraftQuery = (
  supplierId: string
): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: ['rfq', 'list', 'draft', supplierId],
    queryFn: async () => {
      const res = await client.get(`/rfq/list/draft?supplier_id=${supplierId}`)

      return res.data.data
    },
    enabled: false,
  })
}

export { useRfqListDraftQuery }
