import { useQueryClient } from '@tanstack/react-query'
import { ApiMetaDataDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useLazyFileReadGetURLQuery = () => {
  const queryClient = useQueryClient()
  const fetchData = (uuid: string) => {
    return queryClient.fetchQuery({
      queryKey: ['file', 'read', uuid, 'geturl'],
      queryFn: async () => {
        try {
          const res = await client.get<ApiResponseDTO>(
            `/file/read/${uuid}/geturl`
          )

          return res.data.data
        } catch (err) {
          toast.error(err.response.data.message)
          throw err.response.data
        }
      },
    })
  }
  return [fetchData]
}

export const useLazyFileReadQuery = () => {
  const queryClient = useQueryClient()
  const fetchData = (uuid: string) => {
    return queryClient.fetchQuery({
      queryKey: ['file', 'read', uuid],
      queryFn: async () => {
        try {
          const res = await client.get<ApiResponseDTO>(`/file/read/${uuid}`)

          return res.data.data
        } catch (err) {
          toast.error(err.response.data.message)
          throw err.response.data
        }
      },
    })
  }
  return [fetchData]
}
