import React, { useState } from 'react'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material'

import SearchBox from './searchBox'

const FilterBox = ({ services, onChangeFilter, tableFor }) => {
  const [service, setService] = useState('')
  const [term, setTerm] = useState()
  const [showOnlyUnassignedName, setShowOnlyUnassignedName] = useState(false)

  const onChangeTerm = (value) => {
    setTerm(value)
    onChangeFilter({
      showOnlyUnassignedName: showOnlyUnassignedName,
      service,
      term: value,
    })
  }

  const onChangeService = (event) => {
    setService(event.target.value)
    onChangeFilter({
      showOnlyUnassignedName: showOnlyUnassignedName,
      service: event.target.value,
      term: term,
    })
  }

  const handleChange = (event) => {
    setShowOnlyUnassignedName(event.target.checked)
    onChangeFilter({
      showOnlyUnassignedName: event.target.checked,
      service,
      term,
    })
  }

  return (
    <Box display='flex' alignItems='center'>
      <FormControlLabel
        control={
          <Checkbox
            name='checkedB'
            color='primary'
            onChange={handleChange}
            checked={showOnlyUnassignedName}
          />
        }
        label='Show only unassigned name variants'
      />
      <Box mr={3}>
        <SearchBox onChangeTerm={onChangeTerm} tableFor={tableFor} />
      </Box>
      <Typography style={{ marginRight: '1rem' }}>Filter By:</Typography>
      <FormControl
        variant='outlined'
        sx={{
          margin: 1,
          minWidth: 200,
        }}
      >
        <InputLabel id='service-select-label'>Service</InputLabel>
        <Select
          labelId='service-select-label'
          value={service}
          onChange={onChangeService}
          label='Service'
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {services.map((service, i) => (
            <MenuItem value={service} key={'service-shipment' + i}>
              {service}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default FilterBox
