import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type QueryParamsDTO = {
  po_uuid?: string
  shipments: {
    carrier: string
    tracking_number: string
  }[]
}

const useShipmentTrackingQuery = (
  params: QueryParamsDTO,
  options: any
): UseQueryResult<any> => {
  return useQuery({
    queryKey: ['shipment', 'tracking', params.po_uuid, params.shipments],
    queryFn: async () => {
      try {
        let shipmentDetails = []
        for (const shipment of params.shipments) {
          const res = await client.get(
            `shipment/${shipment.carrier}/${shipment.tracking_number}/${params.po_uuid}`
          )
          const shipmentDetail = res.data.data
          shipmentDetails = [...shipmentDetails, shipmentDetail]
        }

        return shipmentDetails
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    ...options,
  })
}

export { useShipmentTrackingQuery }
