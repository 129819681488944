import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type MutationParamsDTO = {
  status_id: string
  rfq_uuids: string
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqBatchUpdateStatusMutation = () =>
  useMutation<any, ErrorDTO, MutationParamsDTO>({
    mutationFn: async (values: MutationParamsDTO) => {
      try {
        const res = await client.put<ApiResponseDTO>(
          '/rfq/status/update/batch',
          values
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
