import { FileList } from 'declarations'
import {
  useQuery,
  keepPreviousData,
  UseQueryResult,
} from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'

type TFiles = {
  file: FileList
  total_rows: number
}

type FilesListQueryParamsDTO = {
  page: number
  rows: number
  unassociated: boolean
  source: string
  deleted: boolean
}

const useFilesListQuery = (
  params: FilesListQueryParamsDTO
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [
      'files',
      'list',
      params.page,
      params.rows,
      params.unassociated,
      params.source,
      params.deleted,
    ],
    queryFn: async () => {
      try {
        const res = await client.get('file/list', {
          params: {
            page: params.page,
            rows: params.rows,
            unassociated: params.unassociated,
            deleted: params.deleted,
            source: params.source,
          },
        })

        return res.data.data
      } catch (err) {
        throw err.response.data
      }
    },
    placeholderData: keepPreviousData,
  })
}

export default useFilesListQuery
