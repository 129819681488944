import React from 'react'
import TabFilesContent from '../../../../components/filesTab'

type TabFilesProps = {
  uuid: string
}

const TabFiles = (props: TabFilesProps) => {
  return <TabFilesContent uuid={props.uuid} usageFor='rfq' />
}

export default TabFiles
