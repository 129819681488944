import React from 'react'
import { Link, Box } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import TableActions from './tableActions'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'
import {
  DataGrid,
  GridColDef,
  GridPagination,
  GridRenderCellParams,
} from '@mui/x-data-grid'
import DataGridCustomPagination from 'components/dataGridCustomPagination'

const SupplierList = () => {
  const { data: suppliersListData } = useSuppliersQuery()
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25,
  })

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams) => (
        <Link
          component={RouterLink}
          to={`/admin/supplier/update/${params.row.uuid}`}
        >
          {params.row.name}
        </Link>
      ),
      flex: 1,
    },
    {
      field: 'city',
      headerName: 'City/State',
      renderCell: (params: GridRenderCellParams) => (
        <span>
          {params.row.city} {params.row.state}
        </span>
      ),
      flex: 1,
    },
  ]

  return (
    <Box sx={{ marginBottom: 10, height: 500 }}>
      <DataGrid
        rows={suppliersListData || []}
        columns={columns}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          pagination: (props) => (
            <GridPagination
              ActionsComponent={DataGridCustomPagination}
              {...props}
            />
          ),
        }}
      />

      <TableActions />
    </Box>
  )
}

export default SupplierList
