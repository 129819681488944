//@ts-nocheck

import React from 'react'
import { Grid, Card, CardHeader, CardContent } from '@mui/material'

import SupplierProfileForm from '../update/supplierProfileFormNew'

const SupplierCreate = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card>
          <CardHeader title='Supplier Profile' />
          <CardContent className='card-content'>
            <SupplierProfileForm />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default SupplierCreate
