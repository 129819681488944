import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type RfqQuoteUpdateParamsDTO = {
  uuid: string
  body: {
    supplier_part_number_custom?: boolean
    dispatch_schedule?: {
      quantity: number
      lead_time: string
    }[]
    supplier_part_number?: string
    manufacturer_id?: number
    quantity?: number
    price?: number
  }
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqQuoteUpdateMutation = () =>
  useMutation<any, ErrorDTO, RfqQuoteUpdateParamsDTO>({
    mutationFn: async (params: RfqQuoteUpdateParamsDTO) => {
      try {
        const res = await client.put(
          `rfq/quote/update/${params.uuid}`,
          params.body
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
