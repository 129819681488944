import React, { useState } from 'react'
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import { useRfqBatchConfirmMutation } from 'services/hooks/rfq/useRfqBatchConfirmMutation'
import { useRfqUpdateMutation } from 'services/hooks/rfq/useRfqUpdateMutation'
import { useRfqAssignUsersQuery } from 'services/hooks/rfq/useRfqAssignUsersQuery'
import { useRfqStatusListQuery } from 'services/hooks/rfq/useRfqStatusListQuery'
import { useRfqBatchUpdateStatusMutation } from 'services/hooks/rfq/useRfqBatchUpdateStatusMutation'

const TableBulkActions = ({ checkedRfqs }) => {
  const [bulkAction, setBulkAction] = useState('')
  const [assignUser, setAssignUser] = useState('')
  const [status, setStatus] = useState('')
  const [openRfqSendModal, setOpenRfqSendModal] = useState(false)
  const { mutate: rfqBatchConfirmMutation } = useRfqBatchConfirmMutation()
  const { mutate: rfqUpdateMutation } = useRfqUpdateMutation()
  const { mutate: rfqBatchUpdateStatusMutation } =
    useRfqBatchUpdateStatusMutation()
  const { data: rfqAssignUsersList } = useRfqAssignUsersQuery()
  const { data: rfqStatusList } = useRfqStatusListQuery()
  const queryClient = useQueryClient()

  const resetSelectors = () => {
    setBulkAction('')
    setAssignUser('')
    setStatus('')
  }

  const unAssignUser = async () => {
    rfqUpdateMutation(
      {
        reqBody: {
          assignee: 0,
          id: checkedRfqs,
        },
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: ['rfq', 'list'],
          })
          resetSelectors()
          toast.success(
            'Unassigned user successfully is there any guys returning there?'
          )
        },
      }
    )
  }

  const onSendRfq = async () => {
    rfqBatchConfirmMutation(
      {
        rfq_ids: checkedRfqs.toString(),
      },
      {
        onSuccess(data, variables, context) {
          setOpenRfqSendModal(false)
        },
      }
    )
  }

  const onBulkActionChange = (e) => {
    setBulkAction(e.target.value)
    if (e.target.value === 'unassign') {
      unAssignUser()
    } else if (e.target.value === 'sendRfq') {
      setOpenRfqSendModal(true)
    }
  }

  const onAssignUser = async (e) => {
    const { value } = e.target
    setAssignUser(e.target.value)
    rfqUpdateMutation(
      {
        reqBody: {
          assignee: value,
          id: checkedRfqs,
        },
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: ['rfq', 'list'],
          })
          resetSelectors()
          toast.success('Assigned to user successfully')
        },
      }
    )
  }

  const onChangeStatus = async (e) => {
    const { value } = e.target
    setStatus(value)

    rfqBatchUpdateStatusMutation(
      {
        status_id: value,
        rfq_uuids: checkedRfqs.toString(),
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: ['rfq', 'list'],
          })
          resetSelectors()
          toast.success('Changed status successfully')
        },
      }
    )
  }

  const handleClose = () => {
    setOpenRfqSendModal(false)
  }

  return (
    <Box display='flex'>
      <Box sx={{ minWidth: 200, mr: 1 }}>
        <FormControl fullWidth>
          <InputLabel id='select-bulkactions-label'>Bulk Actions</InputLabel>
          <Select
            labelId='select-bulkactions-label'
            id='select-bulkactions-outlined'
            value={bulkAction}
            onChange={onBulkActionChange}
            label='Bulk Actions'
            disabled={checkedRfqs.length === 0}
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='assign'>Assign to user</MenuItem>
            <MenuItem value='unassign'>Unassign</MenuItem>
            <MenuItem value='sendRfq'>Send RFQs</MenuItem>
            <MenuItem value='status'>Set Status</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {bulkAction === 'assign' && (
        <Box sx={{ minWidth: 200, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel id='select-userlist-label'>User List</InputLabel>
            <Select
              labelId='select-userlist-label'
              id='select-userlist-outlined'
              value={assignUser}
              onChange={onAssignUser}
              label='User List'
              disabled={checkedRfqs.length === 0}
            >
              {rfqAssignUsersList.map((user) => (
                <MenuItem value={user.id} key={'rfq-assign' + user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {bulkAction === 'status' && (
        <Box sx={{ minWidth: 200, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel id='select-statuslist-label'>Status List</InputLabel>
            <Select
              labelId='select-statuslist-label'
              id='select-statuslist-outlined'
              value={status}
              onChange={onChangeStatus}
              label='Status List'
              disabled={checkedRfqs.length === 0}
            >
              <MenuItem value=''>None</MenuItem>
              {rfqStatusList.map((status) => (
                <MenuItem value={status.id} key={'bulk-action' + status.id}>
                  {status.status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <Dialog onClose={handleClose} open={openRfqSendModal}>
        <DialogTitle>
          You are about to send RFQs {checkedRfqs.toString()} to the suppliers.
          Are you sure you want to do this?
        </DialogTitle>
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' color='primary' onClick={onSendRfq}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TableBulkActions
