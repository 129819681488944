import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'

export type CreateUserDataDTO = {
  first_name: string
  last_name: string
  email: string
  phone: string
  user_type_id: number
  password?: string
  force_password_change?: boolean
  email_password?: boolean
  require_mfa?: boolean
  active?: boolean
}

type ResponseDTO = {
  id: number
  random_password: string
}

type ApiResponseDTO = {
  code: number
  data: ResponseDTO
  api_metadata: ApiMetaDataDTO
}

export const useCreateUserMutation = () =>
  useMutation<ResponseDTO, ErrorDTO, CreateUserDataDTO>({
    mutationFn: async (values: CreateUserDataDTO) => {
      try {
        const res = await client.post<ApiResponseDTO>('user/create', values)

        return res.data.data
      } catch (err) {
        throw err.response.data
      }
    },
  })
