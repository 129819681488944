import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { PoDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

const usePoReadQuery = (
  uuid: string,
  enabled = true
): UseQueryResult<PoDTO> => {
  return useQuery({
    queryKey: ['po', 'read', uuid],
    queryFn: async () => {
      try {
        const res = await client.get(`po/read/${uuid}`)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    enabled,
    retry: false,
  })
}

export { usePoReadQuery }
