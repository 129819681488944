import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  uuid: string
  partNumber: string
}

type ResponseDTO = {
  message: string[]
  part_number: string
  part_number_class: string
  supplier_name: string
  supplier_part_number: string
}

type ApiResponseDTO = {
  code: number
  data: ResponseDTO
  api_metadata: ApiMetaDataDTO
}

export const usePartNumberRuleProcessMutation = () =>
  useMutation<ResponseDTO, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        const res = await client.get<ApiResponseDTO>(
          `/part_number_rules/process/${values.uuid}?part_number=${values.partNumber}`
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
