import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { AssignUserDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

const useRequisitionAssignUsersQuery = (): UseQueryResult<AssignUserDTO[]> => {
  return useQuery({
    queryKey: ['requisition', 'assign-users'],
    queryFn: async () => {
      try {
        const res = await client.get('user/assign/requisition')

        return res.data.data
      } catch (err) {
        toast.error(err.response.data)
        throw err.response.data
      }
    },
  })
}

export { useRequisitionAssignUsersQuery }
