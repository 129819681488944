import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type UpdateUserDataDTO = {
  userName: string
  reqBody: {
    first_name?: string
    last_name?: string
    email?: string
    phone?: number
    user_type_id?: number
    old_password?: string
    new_password?: string
  }
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useUpdateUserMutation = () =>
  useMutation<unknown, ErrorDTO, UpdateUserDataDTO>({
    mutationFn: async (values: UpdateUserDataDTO) => {
      try {
        const res = await client.put<ApiResponseDTO>(
          `/user/update/${values.userName}`,
          values.reqBody
        )

        if (res.data.code === 201) {
          toast.success('User updated successfully!')
        } else {
          toast.warn(res.data.message)
        }

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
