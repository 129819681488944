import { styled } from '@mui/material/styles'
import Button, { ButtonProps } from '@mui/material/Button'
import { orange, cyan } from '@mui/material/colors'

export const OrangeButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(orange[500]),
  backgroundColor: orange[500],
  '&:hover': {
    backgroundColor: orange[700],
  },
}))

export const CyanButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(cyan[500]),
  backgroundColor: cyan[500],
  '&:hover': {
    backgroundColor: cyan[700],
  },
}))
