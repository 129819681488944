import React, { useState, useEffect, useContext } from 'react'
import { Tabs, Tab, AppBar, Paper, Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import TabContainer, { a11yProps } from '../../../components/tabContainer'

import { Overview, Tracking, Contents } from './components'
import ShipmentReadContainer from './index.style'
import { useShipmentReadQuery } from 'services/hooks/shipment/useShipmentReadQuery'

const ShipmentRead = ({ match, history }) => {
  const [tabValue, setTabValue] = useState(0)
  const { trackingNumber, tabName }: any = useParams()
  const { data: shipmentData } = useShipmentReadQuery(trackingNumber)

  useEffect(() => {
    if (tabName) {
      switch (tabName) {
        case 'overview':
          setTabValue(0)
          break
        case 'tracking':
          setTabValue(1)
          break
        case 'contents':
          setTabValue(2)
          break
        default:
          break
      }
    }
  }, [])

  const handleTabChange = (event, newValue) => {
    const { trackingNumber } = match.params

    switch (newValue) {
      case 0:
        history.push(`/shipment/${trackingNumber}/overview`)
        break
      case 1:
        history.push(`/shipment/${trackingNumber}/tracking`)
        break
      case 2:
        history.push(`/shipment/${trackingNumber}/contents`)
        break
      default:
        break
    }
    setTabValue(newValue)
  }

  return (
    <Box component={Paper}>
      <ShipmentReadContainer>
        {shipmentData && (
          <div className='shipment-information'>
            {/* {shipmentData.status.length > 0 && (
              <div className='status'>
                <p style={{ backgroundColor: `#${shipmentData.status.color}` }}>
                  <Icon>{shipmentData.status.icon}</Icon>
                  {shipmentData.status.standardized_term}
                </p>
              </div>
            )} */}

            <AppBar position='static' color='default'>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label='Shipment Tabs'
              >
                <Tab label='Overview' {...a11yProps(0)} />
                <Tab label='Tracking' {...a11yProps(1)} />
                <Tab label='Contents' {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            {tabValue === 0 && (
              <TabContainer>
                <Overview
                  data={shipmentData}
                  trackingNumber={match.params.trackingNumber}
                />
              </TabContainer>
            )}
            {tabValue === 1 && (
              <TabContainer>
                <Tracking data={shipmentData} />
              </TabContainer>
            )}
            {tabValue === 2 && (
              <TabContainer>
                <Contents data={shipmentData} />
              </TabContainer>
            )}
          </div>
        )}
      </ShipmentReadContainer>
    </Box>
  )
}

export default ShipmentRead
