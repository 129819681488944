import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useParams } from 'react-router'

import MfaModalContent from './mfaModalContent'
import DisableMFAModalContent from './disableMfaModalContent'
import { useAddMFAMutation } from 'services/hooks/user/useAddMFAMutation'
import { useRemoveMFAMutation } from 'services/hooks/user/useRemoveMFAMutation'
import { useBulkUserMakeActiveMutation } from 'services/hooks/user/useBulkUserMakeActiveMutation'
import { useBulkUserMakeInActiveMutation } from 'services/hooks/user/useBulkUserMakeInactiveMutation'
import { useDeleteGoogleAuthCodeMutation } from 'services/hooks/user/useDeleteGoogleAuthCodeMutation'

const MFAStatus = ({ userData }) => {
  const params: any = useParams()
  const { userName } = params
  const [requireMFA, setRequireMFA] = useState(userData.require_mfa)
  const [active, setActive] = useState(userData.active)
  const [mfaModalOpen, setMfaModalOpen] = useState(false)
  const [mfaUpdateMode, setMfaUpdateMode] = useState('')
  const [mfaEnabled, setMfaEnabled] = useState(userData.mfa)
  const [disableMFAModalOpen, setDisableMFAModalOpen] = useState(false)
  const { mutate: addMFAMutate } = useAddMFAMutation()
  const { mutate: removeMFAMutate } = useRemoveMFAMutation()
  const { mutate: bulkUserMakeActiveMutate } = useBulkUserMakeActiveMutation()
  const { mutate: bulkUserMakeInactiveMutate } =
    useBulkUserMakeInActiveMutation()
  const { mutate: deleteGoogleAuthCodeMutation } =
    useDeleteGoogleAuthCodeMutation()

  const onMFADisable = () => {
    setDisableMFAModalOpen(true)
  }

  console.log(userData)

  const onUpdateMFA = (updateMode: 'remove' | 'enable') => {
    // if (userName !== 'self') {
    //   if (updateMode === 'remove') {
    //     deleteGoogleAuthCodeMutation(
    //       { user_id: userData.id },
    //       { onSuccess(data, variables, context) {} }
    //     )
    //   }
    // } else {
    setMfaModalOpen(true)
    setMfaUpdateMode(updateMode)
    // }
  }

  const onCheckRequireMFA = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequireMFA(event.target.checked)

    if (event.target.checked) {
      addMFAMutate(
        { user_id: userData.id },
        {
          onError(error, variables, context) {
            setRequireMFA(false)
          },
        }
      )
    } else {
      removeMFAMutate(
        { user_id: userData.id },
        {
          onError(error, variables, context) {
            setRequireMFA(true)
          },
        }
      )
    }
  }

  const onCheckActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked)

    if (event.target.checked) {
      bulkUserMakeActiveMutate(
        { userIds: [userData.id] },
        {
          onError(error, variables, context) {
            setActive(false)
          },
        }
      )
    } else {
      bulkUserMakeInactiveMutate(
        { userIds: [userData.id] },
        {
          onError(error, variables, context) {
            setActive(true)
          },
        }
      )
    }
  }

  return (
    <Box>
      <Typography>
        Multrifactor Authentication is{' '}
        {!userData.require_mfa && !mfaEnabled ? 'not' : ''} enabled
        {mfaEnabled && (
          <Button
            variant='contained'
            color='primary'
            sx={{ marginLeft: '20px' }}
            onClick={onMFADisable}
          >
            Disable
          </Button>
        )}
      </Typography>
      {userName !== 'self' && (
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={requireMFA || userData.disable_mfa_checkbox}
                disabled={userData.disable_mfa_checkbox}
                onChange={onCheckRequireMFA}
              />
            }
            label='Require MFA'
          />
          <FormControlLabel
            control={<Checkbox checked={active} onChange={onCheckActive} />}
            label='Active'
          />
        </Box>
      )}

      {userName === 'self' && !mfaEnabled && (
        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={() => onUpdateMFA('enable')}
          >
            Enable MFA
          </Button>
        </Box>
      )}
      {userName === 'self' && mfaEnabled && !requireMFA && (
        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={() => onUpdateMFA('remove')}
          >
            Remove MFA
          </Button>
        </Box>
      )}
      <Modal
        aria-labelledby='Mfa modal'
        aria-describedby='mfa-modal'
        open={mfaModalOpen}
        onClose={() => {
          setMfaModalOpen(false)
        }}
      >
        <MfaModalContent
          userData={userData}
          mfaUpdateMode={mfaUpdateMode}
          onClose={() => {
            setMfaModalOpen(false)
          }}
        />
      </Modal>
      <Modal
        open={disableMFAModalOpen}
        onClose={() => {
          setDisableMFAModalOpen(false)
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <DisableMFAModalContent
          onClose={() => {
            setDisableMFAModalOpen(false)
          }}
          userId={userData.id}
        />
      </Modal>
    </Box>
  )
}

export default MFAStatus
