import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ApiMetaDataDTO, PartNumberRuleDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type ApiResponseDTO = {
  code: number
  data: PartNumberRuleDTO
  api_metadata: ApiMetaDataDTO
}

const useReadPartNumberRuleQuery = (
  uuid: string
): UseQueryResult<PartNumberRuleDTO> => {
  return useQuery({
    queryKey: ['part_number_rules', uuid],
    queryFn: async () => {
      try {
        const res = await client.get<ApiResponseDTO>(
          `part_number_rules/read/${uuid}`
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
}

export { useReadPartNumberRuleQuery }
