import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const Container = styled(Box)`
  .field-group {
    margin-right: 20px;
    width: 200px;
  }
`

export default Container
