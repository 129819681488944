import { ErrorDTO, SupplierPreferenceRuleDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  name?: string
}

export const useSprCreateMutation = () =>
  useMutation<SupplierPreferenceRuleDTO, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        const res = await client.post(
          '/supplier_preference_rules/create',
          values
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
