import { Box, Link } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useUserTypeListQuery } from 'services/hooks/user/useUserTypeListQuery'

const UserTypeList = () => {
  const { data: userTypeListData } = useUserTypeListQuery()
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })

  const columns: GridColDef[] = [
    {
      field: 'user_type',
      headerName: 'User Type',
      flex: 1,
    },
    {
      field: 'link',
      headerName: '',
      renderCell: (params: GridRenderCellParams) => {
        const route = params.row.user_type.replace(' ', '+')

        return (
          <Link component={RouterLink} to={`/admin/user_type/update/${route}`}>
            Edit
          </Link>
        )
      },
    },
  ]

  console.log(userTypeListData)

  return (
    <Box sx={{ height: 500 }}>
      <DataGrid
        rows={userTypeListData || []}
        columns={columns}
        paginationModel={paginationModel}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        onPaginationModelChange={setPaginationModel}
      />
    </Box>
  )
}

export default UserTypeList
