import { ApiMetaDataDTO, UserDTO } from 'declarations'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'

type ResponseDTO = {
  code: number
  data: UserDTO
  api_metadata: ApiMetaDataDTO
}

export const useUserQuery = (): UseQueryResult<ResponseDTO, unknown> => {
  return useQuery({
    queryKey: ['get-user-info'],
    queryFn: async () => {
      const username = localStorage.getItem('username') || ''

      if (username !== '') {
        const { data } = await client.get<ResponseDTO>(`/user/read/${username}`)

        if (data.code === 200) {
          return data
        } else {
          throw { message: 'User not found' }
        }
      } else {
        throw { message: 'User not found' }
      }
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}
