import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  sequences: number[]
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useSprUpdateSequencesMutation = () =>
  useMutation<unknown, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        await client.put('/supplier_preference_rules/sequence/update', {
          sequence: values.sequences,
        })

        return 'Sequence is updated'
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
