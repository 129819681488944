import React, { useCallback, useEffect, useState } from 'react'
import {
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  Grid,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { toast } from 'react-toastify'

import leadTimeValues from '../../utils/leadTimes'

type ScheduleFieldProps = {
  schedule: any
  index: number
  onChangeQuantity: (quantity: number, key: number) => void
  onChangeLeadTime: (key: number, leadTime: string) => void
  onDeleteSchedule: (key: number) => void
  onChangeExplicitCheck: (index: number, value: boolean) => void
  showExpectedDate: boolean
  disabled: boolean
  canSetExplicitDispatchScheduleDates: boolean
}

const ScheduleField = ({
  schedule,
  index,
  onChangeQuantity,
  onChangeLeadTime,
  onDeleteSchedule,
  onChangeExplicitCheck,
  showExpectedDate = true,
  disabled = false,
  canSetExplicitDispatchScheduleDates,
}: ScheduleFieldProps) => {
  const [scheduleValues, setScheduleValues] = useState({ ...schedule })
  const expectedShipDate = scheduleValues.expected_ship_date
    ? new Date(scheduleValues.expected_ship_date.timestamp)
    : null
  const calculationMethod = scheduleValues.calculation_method
  const [explicitChecked, setExplicitChecked] = useState(
    calculationMethod === 'explicit'
  )

  const onChangeCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setExplicitChecked(event.target.checked)
      onChangeExplicitCheck(index, event.target.checked)
    },
    [expectedShipDate]
  )

  useEffect(() => {
    setScheduleValues({ ...schedule })
  }, [schedule])

  const onChangeInput = (e) => {
    setScheduleValues({
      ...scheduleValues,
      quantity: parseInt(e.target.value, 10),
    })
    if (parseInt(e.target.value, 10) === 0) {
      if (index === 0) {
        toast.error(
          'The first line of the dispatch schedule must be greater than zero.'
        )
      } else {
        onDeleteSchedule(index)

        return
      }
    }

    onChangeQuantity(parseInt(e.target.value, 10), index)
  }

  const onChangeSelect = (e) => {
    setScheduleValues({ ...scheduleValues, lead_time_menu: e.target.value })

    onChangeLeadTime(index, e.target.value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={4} md={3}>
        <TextField
          type='number'
          required
          id='outlined-required'
          label='Quantity'
          value={scheduleValues.quantity}
          onChange={onChangeInput}
          variant='outlined'
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <FormControl variant='outlined' fullWidth>
          <InputLabel id='demo-simple-select-outlined-label'>
            Lead Time
          </InputLabel>
          <Select
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            value={scheduleValues.lead_time_menu}
            onChange={onChangeSelect}
            label='Lead Time'
            disabled={disabled || explicitChecked}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {leadTimeValues.map((item, i) => (
              <MenuItem value={item.value} key={'lead-time-value' + i}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {!canSetExplicitDispatchScheduleDates &&
        showExpectedDate &&
        schedule.expected_ship_date &&
        schedule.expected_ship_date.human_date && (
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography>
              {schedule.expected_ship_date.human_date.precise.long.dayte}
            </Typography>
          </Grid>
        )}

      {expectedShipDate && canSetExplicitDispatchScheduleDates && (
        <Grid item xs={6} sm={4} md={3}>
          <DatePicker
            label='Expected Ship Date'
            value={expectedShipDate}
            disabled={!explicitChecked}
          />
        </Grid>
      )}

      {canSetExplicitDispatchScheduleDates && (
        <Grid item xs={6} sm={4} md={3}>
          <Checkbox
            checked={explicitChecked}
            onChange={onChangeCheck}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
      )}
      {index !== 0 && (
        <Grid item xs={6} sm={4} md={3}>
          <IconButton
            aria-label='delete'
            onClick={() => onDeleteSchedule(index)}
            disabled={disabled}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export default ScheduleField
