import { ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  uuid: string
  updateBody: {
    logic_objects?: {
      display_text: string
      manufacturer_ids: number[]
      rank: number
      supplier_ids: number[]
    }[]
    name?: string
    string?: string
    string_evaluation_type_id?: number
    part_number_class_id?: number
    supplier_id?: number
    notes?: string
  }
}

export const useSprUpdateMutation = () =>
  useMutation<unknown, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        await client.put(
          `/supplier_preference_rules/update/${values.uuid}`,
          values.updateBody
        )

        return 'Supplier Preference Rule is updated'
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
