import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { client } from 'services/helpers/apiConfig'

export type InventoryLineDTO = {
  fetched: any
  lead_time: any
  manufacturer: {
    abbreviation: string
    id: number
    name: string
    uuid: string
  }
  part_number: string
  quantity_available: any
  quantity_required: number
  rating: number
  source: any
}

type ResponseDTO = {
  lines: InventoryLineDTO[]
  supplier: {
    banner: {
      color: string
      star: number
      text: string
      ['text-color']: string
    }
    id: number
    name: string
    rating: any
  }
}[]

const useRequisitionInventoryQuery = (
  requisitionId: string
): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: ['requisition', 'inventory', requisitionId],
    queryFn: async () => {
      const res = await client.get(`/requisitions/inventory/${requisitionId}`)

      return res.data.data
    },
  })
}

export { useRequisitionInventoryQuery }
