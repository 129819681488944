import React, { useEffect, useState } from 'react'
import { Select, Button, Menu, MenuItem, FormControl } from '@mui/material'
import _find from 'lodash/find'

const AssignSelector = ({
  assignedTo,
  userList,
  componentType,
  updateAssignee,
}) => {
  const [assignedValue, setAssignedValue] = useState(assignedTo.value)
  const [anchorEl, setAnchorEl] = useState(null)
  const [assignedUser, setAssignedUser] = useState(null)

  useEffect(() => {
    setAssignedValue(assignedTo.value)
  }, [assignedTo])

  useEffect(() => {
    if (componentType === 'menu') {
      const assignedUser = _find(userList, {
        id: parseInt(assignedTo.value, 10),
      })
      setAssignedUser(assignedUser)
    }
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeAssignee = (value) => {
    setAssignedValue(value)
    updateAssignee(value)
    if (componentType === 'menu') {
      const assignedUser = _find(userList, { id: value })
      handleClose()
      setAssignedUser(assignedUser)
    }
  }

  const handleChange = (event) => {
    changeAssignee(event.target.value)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectMenuItem = (id) => {
    changeAssignee(id)
  }

  return (
    <div>
      {componentType === 'selector' && (
        <FormControl size='small' fullWidth>
          <Select
            value={assignedValue}
            onChange={handleChange}
            inputProps={{
              name: 'assign',
              id: 'assign',
            }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {userList.map((user, i) => (
              <MenuItem value={user.id} key={'selector' + i}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {componentType === 'menu' && (
        <div>
          <Button
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClick}
            variant='contained'
          >
            {assignedUser ? assignedUser.name : 'Unassigned'}
          </Button>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {userList.map((user, i) => (
              <MenuItem
                value={user.id}
                key={'user' + i}
                onClick={() => handleSelectMenuItem(user.id)}
              >
                {user.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </div>
  )
}

export default AssignSelector
