import React, { useState } from 'react'
import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material'
import { toast } from 'react-toastify'

import { useRfqConfirmMutation } from 'services/hooks/rfq/useRfqConfirmMutation'
import { usePoConfirmMutation } from 'services/hooks/purchaseOrders/usePoConfirmMutation'

const SendMessageTemplateModal = ({
  message,
  id,
  onCloseDialog,
  modalFor = 'rfq',
}) => {
  const [messageTemplate, setMessageTemplate] = useState(message)
  const { mutate: rfqConfirmMutation } = useRfqConfirmMutation()
  const { mutate: poConfirmMutation } = usePoConfirmMutation()

  const onSendMessage = async () => {
    if (modalFor === 'rfq') {
      rfqConfirmMutation(
        {
          rfq_id: id,
          message_body: messageTemplate,
        },
        {
          onSuccess(data) {
            toast.success(`RFQ ${data.rfq_id} Sent to ${data.supplier}`)
            onCloseDialog()
          },
        }
      )
    } else {
      poConfirmMutation(
        {
          po_id: id,
          message_body: messageTemplate,
        },
        {
          onSuccess(data) {
            toast.success(`Po ${data.po_id} Sent to ${data.supplier}`)
            onCloseDialog()
          },
        }
      )
    }
  }

  const onCancel = async () => {
    onCloseDialog()
  }

  const onMessageUpdate = (e) => {
    setMessageTemplate(e.target.value)
  }

  return (
    <Box sx={{ minWidth: 500 }}>
      <DialogContent>
        <TextField
          id='standard-multiline-static'
          label='Message'
          multiline
          rows={10}
          value={messageTemplate}
          onChange={onMessageUpdate}
          variant='filled'
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onSendMessage}>Send</Button>
      </DialogActions>
    </Box>
  )
}

export default SendMessageTemplateModal
