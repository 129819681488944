import React, { Component } from 'react'

import PurchaseOrderHistory from '../../../../components/purchaseOrderHistory'
import { Box } from '@mui/material'
import { useRfqHistoryQuery } from 'services/hooks/rfq/useRfqHistoryQuery'

type TabHistoryProps = {
  uuid: string
}

const TabHistory = ({ uuid }: TabHistoryProps) => {
  const { data: rfqHistory } = useRfqHistoryQuery(uuid)

  return (
    <Box>
      {rfqHistory && <PurchaseOrderHistory histories={rfqHistory.history} />}
    </Box>
  )
}

export default TabHistory
