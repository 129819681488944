import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const NewTableContainer = styled(Box)`
  .icon_grey {
    filter: grayscale(100%);
  }

  .quoted-items {
    .quoted-item {
      .associated-pos {
        a {
          margin-right: 10px;
          margin-bottom: 5px;
          background: lightgrey;
          padding: 5px 15px;
          border-radius: 30px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
`

export default NewTableContainer
