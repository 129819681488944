import 'date-fns'
import React from 'react'
import { Grid } from '@mui/material'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

const ConfirmedDateTime = ({ date, disabled, onUpdateDate }) => {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(
    new Date(date.timestamp)
  )

  const handleDateChange = (date) => {
    setSelectedDate(date)
    onUpdateDate(date)
  }

  return (
    <LocalizationProvider utils={DateFnsUtils}>
      <Grid container justify='space-around' spacing={2}>
        <Grid item>
          <DatePicker
            margin='normal'
            id='date-picker-dialog'
            label='Date Confirmed'
            value={selectedDate}
            onChange={handleDateChange}
            disabled={disabled}
            disableFuture
          />
        </Grid>
        <Grid item>
          <TimePicker
            margin='normal'
            id='time-picker'
            label='Time Confirmed'
            value={selectedDate}
            onChange={handleDateChange}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

export default ConfirmedDateTime
