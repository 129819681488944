import { ApiMetaDataDTO, DateDTO } from 'declarations'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

type ResponseDTO = {
  code: number
  data: UserListQueryResponseDTO
  api_metadata: ApiMetaDataDTO
}

type UserListQueryResponseDTO = {
  addUser: boolean
  users: {
    active: boolean
    can_edit: boolean
    email: string
    id: number
    last_login: DateDTO
    name: string
    phone: string
    self: boolean
    user_type: string
    username: string
  }[]
}

export const useUserListSuppliersQuery = (): UseQueryResult<
  UserListQueryResponseDTO,
  unknown
> => {
  return useQuery({
    queryKey: ['user-suppliers'],
    queryFn: async () => {
      try {
        const res = await client.get<ResponseDTO>('/user/list/supplier')
        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
}
