import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  TablePagination,
  Paper,
  Box,
  Link,
} from '@mui/material'
import _find from 'lodash/find'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Link as RouterLink } from 'react-router-dom'

import EnhancedTableHead from '../../../../components/common/enhancedTableHead'
import LoadingSpinner from '../../../../components/common/loadingSpinner'

import FilterBox from './filterBox'
import TableActions from './tableActions'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'
import { usePartNumberRulesClassListQuery } from 'services/hooks/partNumberRules/usePartNumberRulesClassListQuery'
import { useDeletePartNumberRulesMutation } from 'services/hooks/partNumberRules/useDeletePartNumberRulesMutation'
import { useUpdatePartNumberRulesSequenceMutation } from 'services/hooks/partNumberRules/useUpdatePartNumberRulesSequenceMutation'
import { usePartNumberRulesSequencesQuery } from 'services/hooks/partNumberRules/usePartNumberRulesSequencesQuery'
import { usePartNumberRulesListQuery } from 'services/hooks/partNumberRules/usePartNumberRulesListQuery'

const headCells = [
  {
    id: 'name',
    label: 'Name',
  },
  { id: 'supplier', label: 'Supplier' },
  { id: 'part_number_class', label: 'Part Number Class' },
]

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? '#757ce8' : '',
  ...draggableStyle,
})

const SupplierPartNumberRules = () => {
  const [partNumberRules, setPartNumberRules] = useState(null)
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const [filters, setFilters] = useState({
    supplierId: '',
    partNumberClass: '',
    term: '',
  })
  const [sequences, setSequences] = useState(null)
  const [isDraggable, setIsDraggable] = useState(true)

  const { data: suppliers } = useSuppliersQuery()
  const { data: partNumberClasses } = usePartNumberRulesClassListQuery()
  const { data: partNumberRulesSequences } = usePartNumberRulesSequencesQuery()
  const { mutate: deletePartNumberRulesMutation } =
    useDeletePartNumberRulesMutation()
  const { mutate: updatePartNumberRulesSequencesMutate } =
    useUpdatePartNumberRulesSequenceMutation()
  const {
    data: partNumberRulesList,
    isFetching,
    refetch,
  } = usePartNumberRulesListQuery({
    pageIndex: page,
    rowsCount: rowsPerPage,
    filterValues: {
      supplierId: filters.supplierId,
      partNumberClass: filters.partNumberClass,
    },
    searchTerm: filters.term,
  })

  useEffect(() => {
    if (partNumberRulesList) {
      setPartNumberRules(partNumberRulesList.part_number_rules)
    }
  }, [partNumberRulesList])

  useEffect(() => {
    if (partNumberRulesSequences) {
      setSequences(partNumberRulesSequences)
    }
  }, [partNumberRulesSequences])

  useEffect(() => {
    if (
      filters.supplierId == '' &&
      filters.partNumberClass == '' &&
      filters.term == ''
    ) {
      setIsDraggable(true)
    }
  }, [filters])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = partNumberRules.map((n) => n.uuid)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const renderSupplier = (supplierId) => {
    const supplier = _find(suppliers, { id: supplierId })

    return supplier ? supplier.name : ''
  }

  const renderPartNumberClass = (partNumberClassId) => {
    const partNumberClass = _find(partNumberClasses, { id: partNumberClassId })
    return partNumberClass ? partNumberClass.description : ''
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination || !isDraggable) {
      return
    }

    const items = reorder(
      partNumberRules,
      result.source.index,
      result.destination.index
    )

    const orderArray = items.map((item: any) => item.id)
    setPartNumberRules(items)

    sequences.splice(page * rowsPerPage, 0, ...orderArray)
    sequences.splice((page + 1) * rowsPerPage, rowsPerPage)

    setSequences(sequences)

    updatePartNumberRulesSequencesMutate(
      { sequences },
      { onSuccess(data, variables, context) {} }
    )
  }

  const onChangeFilter = (
    supplierId = null,
    partNumberClass = null,
    term = null
  ) => {
    setIsDraggable(false)
    setFilters({
      partNumberClass: partNumberClass.toString(),
      supplierId: supplierId.toString(),
      term,
    })
  }

  const onDeleteSelected = async () => {
    deletePartNumberRulesMutation(
      { uuids: selected },
      {
        onSuccess(data, variables, context) {
          setSelected([])
          refetch()
        },
      }
    )
  }

  const emptyRows = partNumberRulesList?.part_number_rules.length
    ? rowsPerPage -
      Math.min(rowsPerPage, partNumberRulesList?.part_number_rules.length)
    : 0

  return (
    <Box sx={{ marginBottom: '100px' }}>
      <Paper>
        {partNumberClasses && suppliers && (
          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            <FilterBox
              suppliers={suppliers}
              partNumberClasses={partNumberClasses}
              onChangeFilter={onChangeFilter}
            />
          </Box>
        )}

        {isFetching ? (
          <Box py={5}>
            <LoadingSpinner />
          </Box>
        ) : (
          <Box>
            <TableContainer>
              <Table aria-labelledby='tableTitle' aria-label='enhanced table'>
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={partNumberRulesList.part_number_rules.length}
                  headCells={headCells}
                />
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                      <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        // style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {partNumberRules &&
                          partNumberRules
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              const isItemSelected = isSelected(row.uuid)
                              const labelId = `enhanced-table-checkbox-${index}`

                              return (
                                <Draggable
                                  key={row.uuid}
                                  draggableId={'p-' + row.uuid}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <TableRow
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                      hover
                                      //
                                      role='checkbox'
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      selected={isItemSelected}
                                    >
                                      <TableCell padding='checkbox'>
                                        <Checkbox
                                          checked={isItemSelected}
                                          inputProps={{
                                            'aria-labelledby': labelId,
                                          }}
                                          onChange={(event) =>
                                            handleClick(event, row.uuid)
                                          }
                                        />
                                      </TableCell>
                                      <TableCell scope='row'>
                                        <Link
                                          component={RouterLink}
                                          to={`/admin/supplier_part_number_rules/update/${row.uuid}`}
                                        >
                                          {row.name}
                                        </Link>
                                      </TableCell>
                                      <TableCell>
                                        {renderSupplier(row.supplier_id)}
                                      </TableCell>
                                      <TableCell>
                                        {renderPartNumberClass(
                                          row.part_number_class_id
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Draggable>
                              )
                            })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, 250]}
              component='div'
              count={partNumberRulesList.total_rows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
      <TableActions
        onDelete={onDeleteSelected}
        bulkActionDisabled={selected.length === 0}
      />
    </Box>
  )
}

export default SupplierPartNumberRules
