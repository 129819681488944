import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material'
import _find from 'lodash/find'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import { useRequisitionAssignUsersQuery } from 'services/hooks/requisition/useRequisitionAssignUsersQuery'
import { useRequisitionStatusListQuery } from 'services/hooks/requisition/useRequisitionStatusListQuery'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'
import { useRfqCreateMutation } from 'services/hooks/rfq/useRfqCreateMutation'
import { useRfqLineCreateBatchMutation } from 'services/hooks/rfq/useRfqLineCreateBatchMutation'
import { useRfqListQuery } from 'services/hooks/rfq/useRfqListQuery'
import { useRequisitionUpdateMutation } from 'services/hooks/requisition/useRequisitionUpdateMutation'
import { useRequisitionStatusBatchUpdateMutation } from 'services/hooks/requisition/useRequisitionStatusBatchUpdateMutation'
import { useRequisitionDeleteMutation } from 'services/hooks/requisition/useRequisitionDeleteMutation'

const TableBulkActions = ({ checkedRequisitions, checkedOrderIds }) => {
  const queryClient = useQueryClient()
  const { data: requisitionAssignUsersList } = useRequisitionAssignUsersQuery()
  const { data: statusList } = useRequisitionStatusListQuery()
  const { data: suppliers } = useSuppliersQuery()
  const history = useHistory()
  const [bulkAction, setBulkAction] = useState('')
  const [assignUser, setAssignUser] = useState('')
  const [status, setStatus] = useState('')
  const [supplier, setSupplier] = useState<number>(0)
  const [openRfqAddedModal, setOpenRfqAddedModal] = useState(false)
  const [openDeleteRequisitionsModal, setOpenDeleteRequisitionsModal] =
    useState(false)
  const [rfqAddedMessage, setRfqAddedMessage] = useState('')
  const [rfqValue, setRfqValue] = useState('')
  const [createdRfqId, setCreatedRfqId] = useState()
  const { mutate: rfqCreateMutation } = useRfqCreateMutation()
  const { mutate: rfqLineCreateBatchMutation } = useRfqLineCreateBatchMutation()
  const { mutate: requisitionUpdateMutation } = useRequisitionUpdateMutation()
  const { mutate: requisitionStatusBatchUpdateMutation } =
    useRequisitionStatusBatchUpdateMutation()
  const {
    mutate: requisitionDeleteMutation,
    isPending: isPendingRequisitionDelete,
  } = useRequisitionDeleteMutation()
  const { data: rfqList } = useRfqListQuery(
    {
      filterValues: { suppliers: [`${supplier}`] },
    },
    supplier !== 0
  )

  const resetSelectors = () => {
    setBulkAction('')
    setAssignUser('')
    setStatus('')
    setSupplier(0)
  }

  const onBulkActionChange = (e) => {
    setBulkAction(e.target.value)
    if (e.target.value === 'unassign') {
      unAssignUser()
    } else if (e.target.value === 'delete') {
      setOpenDeleteRequisitionsModal(true)
    }
  }

  const unAssignUser = async () => {
    requisitionUpdateMutation(
      {
        reqBody: {
          assignee: 0,
          id: checkedRequisitions,
        },
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: ['requisition', 'list'],
          })
          resetSelectors()
          toast.success('Unassigned user successfully')
        },
      }
    )
  }

  const onAssignUser = async (e) => {
    const { value } = e.target
    requisitionUpdateMutation(
      {
        reqBody: {
          assignee: value,
          id: checkedRequisitions,
        },
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({
            queryKey: ['requisition', 'list'],
          })
          resetSelectors()
          toast.success('Assigned to user successfully')
        },
      }
    )

    setAssignUser(e.target.value)
  }

  const onChangeStatus = async (e) => {
    const { value } = e.target
    setStatus(value)

    requisitionStatusBatchUpdateMutation(
      {
        status_id: value,
        requisition_uuids: checkedRequisitions.toString(),
      },
      {
        onSuccess(data, variables, context) {
          const status = _find(statusList, { id: value })
          queryClient.invalidateQueries({
            queryKey: ['requisition', 'list'],
          })
          resetSelectors()
          toast.success('Changed status successfully')
        },
      }
    )
  }

  const onChangeSupplier = async (e) => {
    const { value } = e.target
    setSupplier(value)
    setRfqValue('')
  }

  const onAddRfq = async (e) => {
    const { value } = e.target

    if (value === 'new_rfq') {
      rfqCreateMutation(
        { supplier_id: supplier },
        {
          onSuccess(data, variables, context) {
            rfqLineCreateBatchMutation({
              rfq_id: data.rfq_id,
              requisition_ids: checkedRequisitions,
            })
            setRfqAddedMessage(
              `You created a RFQ and added the selected requisition lines`
            )
            setOpenRfqAddedModal(true)
            setRfqValue(value)
            resetSelectors()
          },
        }
      )
    } else {
      rfqLineCreateBatchMutation(
        { rfq_id: value, requisition_ids: checkedRequisitions },
        {
          onSuccess(data, variables, context) {
            setRfqAddedMessage(
              `You added the selected requisition lines to RFQ ${value}`
            )
            setOpenRfqAddedModal(true)
            setRfqValue(value)
            resetSelectors()
          },
        }
      )
    }
  }

  const handleClose = () => {
    setOpenRfqAddedModal(false)
    setRfqAddedMessage('')
    setCreatedRfqId(null)
  }

  const onDeleteRequisitions = async () => {
    requisitionDeleteMutation(
      { orderIds: checkedOrderIds },
      {
        onSuccess(data, variables, context) {
          setOpenDeleteRequisitionsModal(false)
          resetSelectors()
          queryClient.invalidateQueries({
            queryKey: ['requisition', 'list'],
          })
        },
      }
    )
  }

  return (
    <Box display='flex'>
      <Box sx={{ minWidth: 200, mr: 1 }}>
        <FormControl fullWidth>
          <InputLabel id='select-bulkactions-label'>Bulk Actions</InputLabel>
          <Select
            labelId='select-bulkactions-label'
            id='select-bulkactions-outlined'
            value={bulkAction}
            onChange={onBulkActionChange}
            label='Bulk Actions'
            disabled={checkedRequisitions.length === 0}
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='assign'>Assign to user</MenuItem>
            <MenuItem value='unassign'>Unassign</MenuItem>
            <MenuItem value='addRfq'>Add to RFQ</MenuItem>
            <MenuItem value='status'>Set Status</MenuItem>
            <MenuItem value='delete'>Delete Requisitions</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {bulkAction === 'assign' && (
        <Box sx={{ minWidth: 200, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel id='select-userlist-label'>User List</InputLabel>
            <Select
              labelId='select-userlist-label'
              id='select-userlist-outlined'
              value={assignUser}
              onChange={onAssignUser}
              label='User List'
              disabled={checkedRequisitions.length === 0}
            >
              {requisitionAssignUsersList.map((user) => (
                <MenuItem value={user.id} key={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {bulkAction === 'status' && (
        <Box sx={{ minWidth: 200, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel id='select-statuslist-label'>Status List</InputLabel>
            <Select
              labelId='select-statuslist-label'
              id='select-statuslist-outlined'
              value={status}
              onChange={onChangeStatus}
              label='Status List'
              disabled={checkedRequisitions.length === 0}
            >
              <MenuItem value=''>None</MenuItem>
              {statusList.map((status) => (
                <MenuItem value={status.id} key={status.id}>
                  {status.status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {bulkAction === 'addRfq' && (
        <Box sx={{ minWidth: 200, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel id='select-supplierlist-label'>
              Supplier List
            </InputLabel>
            <Select
              labelId='select-supplierlist-label'
              id='select-supplierlist-outlined'
              value={supplier}
              onChange={onChangeSupplier}
              label='Supplier List'
              disabled={checkedRequisitions.length === 0}
            >
              {suppliers &&
                suppliers.map((supplier) => (
                  <MenuItem value={supplier.id} key={supplier.id}>
                    {supplier.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {supplier !== 0 && rfqList && (
        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel id='select-supplierlist-label'>Rfq List</InputLabel>
            <Select
              labelId='select-rfqlist-label'
              id='select-rfqlist-outlined'
              value={rfqValue}
              onChange={onAddRfq}
              label='Rfq List'
              disabled={checkedRequisitions.length === 0}
            >
              <MenuItem value='new_rfq'>New Rfq</MenuItem>
              {rfqList.rfq.map((rfq) => (
                <MenuItem value={rfq.id} key={rfq.id}>
                  {rfq.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <Dialog onClose={handleClose} open={openRfqAddedModal}>
        <DialogTitle>{rfqAddedMessage}</DialogTitle>
        <DialogActions>
          {createdRfqId && (
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                history.push(`/rfq/${createdRfqId}`)
              }}
            >
              Go to RFQ {createdRfqId}
            </Button>
          )}

          <Button variant='contained' color='secondary' onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => {
          setOpenDeleteRequisitionsModal(false)
        }}
        open={openDeleteRequisitionsModal}
      >
        <DialogTitle>
          Are you sure you want to delete {checkedRequisitions.length}{' '}
          requisition lines?
        </DialogTitle>
        <DialogActions>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setOpenDeleteRequisitionsModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={onDeleteRequisitions}
            disabled={isPendingRequisitionDelete}
          >
            {isPendingRequisitionDelete ? (
              <CircularProgress size={25} />
            ) : (
              'Delete'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TableBulkActions
