import React from 'react'
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles'
import { QueryClientProvider } from '@tanstack/react-query'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { queryClient } from 'services/helpers/apiConfig'

import './App.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'draft-js/dist/Draft.css'

// Components
import PublicRoutes from './routes'

// Redux
import { ToastContainer } from 'react-toastify'

const AppLayout = () => {
  return (
    <>
      <ToastContainer />
      <PublicRoutes />
    </>
  )
}

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <CssVarsProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppLayout />
        </LocalizationProvider>
      </CssVarsProvider>
    </QueryClientProvider>
  )
}

export default App
