//@ts-nocheck
import React, { useEffect, useState } from 'react'
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { Link } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import DateFnsUtils from '@date-io/date-fns'

import {
  useLazyShipmentListUpcomingQuery,
  useShipmentListUpcomingQuery,
} from 'services/hooks/shipment/useShipmentListUpcomingQuery'
import { useShipmentConfirmDeliveryMutation } from 'services/hooks/shipment/useShipmentConfirmDeliveryMutation'

const DueToday = () => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [open, setOpen] = useState(false)
  const [lines, setLines] = useState([])
  const [selectedTrackingNumber, setSelectedTrackingNumber] = useState()
  const [value, setValue] = React.useState(dayjs())
  const [fetchShipmentListUpcomings] = useLazyShipmentListUpcomingQuery()
  const { mutate: shipmentConfirmDeliveryMutation } =
    useShipmentConfirmDeliveryMutation()

  const handleClickOpen = (trackingNumber) => {
    setSelectedTrackingNumber(trackingNumber)
    setOpen(true)
  }

  const handleClose = () => {
    setSelectedTrackingNumber()
    setOpen(false)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const fetchUpcomingShipmentList = async () => {
    try {
      const res = await fetchShipmentListUpcomings(selectedDate)
      setLines(res.lines)
    } catch (e) {
      console.log(e)
    }
  }

  const onConfirmReceipt = async () => {
    shipmentConfirmDeliveryMutation({ trackingNumber: selectedTrackingNumber })
  }

  useEffect(() => {
    fetchUpcomingShipmentList()
  }, [selectedDate])

  return (
    <Paper sx={{ p: 2 }}>
      <Box display='flex' justifyContent='flex-end'>
        <LocalizationProvider utils={DateFnsUtils}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker
              label='Date'
              value={selectedDate}
              onChange={handleDateChange}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>

      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Tracking Number</TableCell>
              <TableCell>Carrier/Service</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lines.length > 0 &&
              lines.map((row) => (
                <TableRow key={'due-today' + row.name}>
                  <TableCell component='th' scope='row'>
                    <Link to={`/shipment/${row.tracking_number}`}>
                      {row.tracking_number}
                    </Link>
                  </TableCell>
                  <TableCell>{row.carrier_service_name}</TableCell>
                  <TableCell>{row.supplier_name}</TableCell>
                  <TableCell>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleClickOpen(row.tracking_number)}
                    >
                      Confirm Shipment Received
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Confirm?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to confirm receipt of the UPS Ground package
            with tracking number {selectedTrackingNumber}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={onConfirmReceipt} color='primary' autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default DueToday
