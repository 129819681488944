import { Box, Card, CardContent, Typography } from '@mui/material'
import { useRequisitionInventoryQuery } from 'services/hooks/requisition/useRequisitionInventoryQuery'
import Inventory from './inventory'

type TabInventoryProps = {
  uuid: string
}

const TabInventory = ({ uuid }: TabInventoryProps) => {
  const { data: inventories } = useRequisitionInventoryQuery(uuid)

  return (
    <Box>
      {inventories && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {inventories.length > 0 ? (
            inventories.map((inventory, i) => (
              <Card variant='outlined'>
                <CardContent>
                  <Inventory
                    inventory={inventory}
                    key={'inventory' + i}
                    requisitionId={uuid}
                  />
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No inventory found</Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

export default TabInventory
