import React from 'react'
import {
  Box,
  Collapse,
  Grid,
  Icon,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const Row = ({ row }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.total_quantity}
        </TableCell>
        <TableCell>{row.part_number}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ m: 3 }}>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Requisition Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.lines.map((line, index) => (
                    <TableRow key={index}>
                      <TableCell component='th' scope='row'>
                        {line.quantity}
                      </TableCell>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={`/requisitions/${line.requisition}`}
                        >
                          {line.requisition}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
const ShipmentsStatus = ({ shipments }) => {
  return (
    <Box>
      {shipments.length > 0 ? (
        <Grid container spacing={3}>
          {shipments.map((shipment, i) => (
            <Grid item xs={6} key={'shipment' + i}>
              <Box
                className='shipment-info'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Box
                  className='info-header'
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={`/shipment/read/${shipment.tracking_number}`}
                  >
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                      {shipment.tracking_number}
                    </Typography>
                  </Link>
                  <span>{shipment.service.description}</span>
                </Box>
                <Box
                  sx={{
                    backgroundColor: `#${shipment.activity[0].color}`,
                    color: '#fff',
                    padding: '5px 10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Icon>{shipment.activity[0].icon}</Icon>&nbsp;
                  {shipment.activity[0].status}
                </Box>
              </Box>

              <TableContainer component={Paper}>
                <Table size='small' aria-label='collapsible table'>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Quantity</TableCell>
                      <TableCell>Item</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shipment.lines.map((row, index) => (
                      <Row key={index} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant='h6'>No shipments found </Typography>
      )}
    </Box>
  )
}

export default ShipmentsStatus
