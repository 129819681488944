import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { UserDTO } from 'declarations'
import { useState } from 'react'
import { useUpdateUserMutation } from 'services/hooks/user/useUpdateUserMutation'
import { useUserTypesQuery } from 'services/hooks/userType/useUserTypesQuery'
import { useParams } from 'react-router'

import { ModalStyle } from '.'
import MFAStatus from './mfaStatus'

type MetaDataProps = {
  userData: UserDTO
}

const MetaData = ({ userData }: MetaDataProps) => {
  const { data: userTypes } = useUserTypesQuery()
  const [userType, setUserType] = useState(`${userData.user_type_id}`)
  const params: any = useParams()
  const { userName } = params

  const [userTypeChangeModalOpen, setUserTypeChangeModalOpen] = useState(false)

  const { mutate: updateUserMutate } = useUpdateUserMutation()

  const onUserTypeSelect = (event: SelectChangeEvent) => {
    const tempUserType = userType
    setUserType(event.target.value)

    if (userData.scope_customizations) {
      setUserTypeChangeModalOpen(true)
    } else {
      updateUserMutate(
        {
          userName: userData.username,
          reqBody: {
            user_type_id: parseInt(event.target.value, 10),
          },
        },
        {
          onError(error, variables, context) {
            setUserType(tempUserType)
          },
        }
      )
    }
  }

  const onContinueChangeUserType = () => {
    updateUserMutate(
      {
        userName: userData.username,
        reqBody: {
          user_type_id: parseInt(userType, 10),
        },
      },
      {
        onSuccess(data, variables, context) {
          setUserTypeChangeModalOpen(false)
        },
        onError(error, variables, context) {},
      }
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography>Last Login: {userData.last_login}</Typography>
      <Typography>User Created: {userData.date_added}</Typography>
      {userName !== 'self' && (
        <FormControl fullWidth>
          <InputLabel id='user-type-select-label'>User Type</InputLabel>
          <Select
            value={userType}
            onChange={onUserTypeSelect}
            labelId='user-type-select-label'
            label='User Type'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {userTypes?.map((type) => (
              <MenuItem value={type.id} key={type.id}>
                {type.user_type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Modal
        aria-labelledby='user-type-change-modal'
        aria-describedby='user-type-change'
        open={userTypeChangeModalOpen}
        onClose={() => setUserTypeChangeModalOpen(false)}
      >
        <Box sx={ModalStyle} component={Paper}>
          <Typography>
            This user has customized Scope settings, which will be permanently
            deleted when changing User Type. <br /> <br /> Do you want to
            continue?
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => setUserTypeChangeModalOpen(false)}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={onContinueChangeUserType}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>
      <MFAStatus userData={userData} />
    </Box>
  )
}

export default MetaData
