import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { DateDTO, SupplierDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

export type ResponseDTO = {
  lines: {
    date: DateDTO
    id: number
    status: {
      id: number
      term: string
    }
    supplier: SupplierDTO
    type: string
    uuid: string
  }[]
}

const useRequisitionRfqPoQuery = (
  uuid: string
): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: ['requisition', 'rfqpo', uuid],
    queryFn: async () => {
      const res = await client.get(`requisitions/rfqpo/${uuid}`)

      return res.data.data
    },
  })
}

export { useRequisitionRfqPoQuery }
