import React from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'

const LoadingSpinner = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '50px 0',
      }}
    >
      <CircularProgress />
      <Typography sx={{ ml: '10px' }}>Loading...</Typography>
    </Box>
  )
}

export default LoadingSpinner
