import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  uuid: string
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useSnvDeleteMutation = () =>
  useMutation<unknown, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        await client.delete(`/supplier_name_variant/delete/${values.uuid}`)

        return `${values.uuid} deleted successfully!`
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
