import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  uuids: string[]
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useSprDeleteMutation = () =>
  useMutation<unknown, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        for (const uuid of values.uuids) {
          await client.delete(`/supplier_preference_rules/delete/${uuid}`)
        }

        toast.success('All UUIDs have been deleted successfully.')
        return 'All UUIDs have been processed successfully.'
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
