import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useRfqMessageTemplateQuery } from 'services/hooks/rfq/useRfqMessageTemplateQuery'
import { useRfqConfirmMutation } from 'services/hooks/rfq/useRfqConfirmMutation'
import { useRfqLineCancelMutation } from 'services/hooks/rfq/useRfqLineCancelMutation'

type SendRfqModalTypes = {
  rfqId: number
  rfqLineIds: number[]
  open: boolean
  close: () => void
  onRfqSent?: () => void
  onSaveRfq?: () => void
}

const SendRfqModal = ({
  onRfqSent,
  rfqId,
  rfqLineIds,
  onSaveRfq,
  open,
  close,
}: SendRfqModalTypes) => {
  const { data: messageTemplate, isFetched } = useRfqMessageTemplateQuery(rfqId)
  const [modalMessageTemplate, setModalMessageTemplate] = useState('')
  const history = useHistory()
  const { mutate: rfqConfirmMutation } = useRfqConfirmMutation()
  const { mutate: rfqCancelRfqLineMutation } = useRfqLineCancelMutation()

  useEffect(() => {
    if (messageTemplate && isFetched) {
      setModalMessageTemplate(messageTemplate)
    }
  }, [messageTemplate, isFetched])

  const viewRfq = () => {
    history.push(`/rfq/${rfqId}`)
  }

  const sendRfq = async () => {
    rfqConfirmMutation(
      { rfq_id: rfqId, message_body: modalMessageTemplate },
      {
        onSuccess(data, variables, context) {
          toast.success(`RFQ ${data.rfq_id} Sent to ${data.supplier}`)
          if (onRfqSent) {
            onRfqSent()
          }

          close()
        },
      }
    )
  }

  const handleCancelRfq = async (id) => {
    rfqCancelRfqLineMutation(
      { id },
      {
        onSuccess(data, variables, context) {
          console.log(`RFQ with UUID ${id} canceled successfully`)
        },
      }
    )
  }

  const cancelRfq = async () => {
    close()
    rfqLineIds.forEach((id) => {
      handleCancelRfq(id)
    })
  }

  const onMessageUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModalMessageTemplate(event.target.value)
  }

  const saveRfq = () => {
    close()
    toast.success(`RFQ ${rfqId} Created`)
    if (onSaveRfq) {
      onSaveRfq()
    }
  }

  return (
    <Dialog onClose={close} open={open}>
      <DialogContent>
        <TextField
          id='outlined-multiline-static'
          label='Multiline'
          multiline
          rows={10}
          value={modalMessageTemplate}
          onChange={onMessageUpdate}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelRfq}>Cancel</Button>
        <Button onClick={viewRfq}>View RFQ</Button>
        <Button onClick={saveRfq}>Save RFQ as Draft</Button>
        <Button onClick={sendRfq}>Send</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SendRfqModal
