import React from 'react'
import _find from 'lodash/find'
import { Box } from '@mui/material'

const StatusLabel = ({ statusList, statusId, children }) => {
  const status = _find(statusList, { id: statusId })

  return (
    <Box
      sx={{
        backgroundColor: status.color,
        borderRadius: '4px',
        padding: '5px',
        textAlign: 'center',
        width: '100%',
      }}
    >
      {children}
    </Box>
  )
}

export default StatusLabel
