import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react'
import { Editor, EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import _filter from 'lodash/filter'
import {
  NativeSelect,
  Tooltip,
  FormControl,
  Button,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { toast } from 'react-toastify'
import _debounce from 'lodash/debounce'
import { useQueryClient } from '@tanstack/react-query'

import { generateQuotesHTMLContent } from '../../../utils/common'

import ReplyMessageContainer from './replyMessage.style'
import { CorrespondenceTableContext } from '../index'
import { useThreadDraftDeleteMutation } from 'services/hooks/thread/useThreadDraftDeleteMutation'
import { useThreadDraftSendMutation } from 'services/hooks/thread/useThreadDraftSendMutation'
import { useThreadDraftUpdateMutation } from 'services/hooks/thread/useThreadDraftUpdateMutation'

const DEBOUNCE_TIME_MS = 1000

const DraftMessage = ({
  message,
  selectedMessageIndex,
  selectedLine,
  closeDraft,
}) => {
  const queryClient = useQueryClient()
  const correspondenceFor = window.location.pathname.split('/')[1]
  const { mutate: threadDraftDeleteMutation } = useThreadDraftDeleteMutation()
  const { mutate: threadDraftSendMutation } = useThreadDraftSendMutation()
  const { mutate: threadDraftUpdateMutation } = useThreadDraftUpdateMutation()

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [isShowQuoteMessages, setIsShowQuoteMessages] = useState(false)
  const [replyTypeValue, setReplyTypeValue] = useState(
    message.is_internal ? 'internal' : 'supplier'
  )

  const correspondenceContext = useContext(CorrespondenceTableContext)
  const textInput = useRef(null)

  const isFirstMessage =
    selectedMessageIndex ===
    correspondenceContext.correspondence.messages.length - 1

  useEffect(() => {
    const contentState = stateFromHTML(message.html_body)

    setEditorState(EditorState.createWithContent(contentState))
  }, [])

  const onUpdateThreadDraft = async (
    showQuoteMessage,
    replyType,
    updatedEditorState
  ) => {
    const html = stateToHTML(updatedEditorState.getCurrentContent())
    threadDraftUpdateMutation(
      {
        uuid: message.uuid,
        reqBody: {
          is_internal: replyType === 'internal',
          body: html,
          is_quote_attached: showQuoteMessage,
        },
      },
      {
        onSuccess(data, variables, context) {
          onCorrespondenceUpdated()
        },
      }
    )
  }

  const onChangeReplyingTypeOption = (e) => {
    setReplyTypeValue(e.target.value)
    onUpdateThreadDraft(isShowQuoteMessages, e.target.value, editorState)
  }

  const onMessageContentUpdated = (newEditorState) => {
    if (
      editorState.getCurrentContent() !== newEditorState.getCurrentContent()
    ) {
      setEditorState(newEditorState)
    }
  }

  const debouncedUpdateMessage = useCallback(
    _debounce((showQuoteMessage, replyType, updatedEditorState) => {
      onUpdateThreadDraft(showQuoteMessage, replyType, updatedEditorState)
    }, DEBOUNCE_TIME_MS),
    []
  )

  useEffect(() => {
    debouncedUpdateMessage(isShowQuoteMessages, replyTypeValue, editorState)
  }, [editorState])

  const onCorrespondenceUpdated = () => {
    if (correspondenceFor === 'rfq') {
      queryClient.invalidateQueries({
        queryKey: ['rfq', 'correspondence'],
      })
    } else {
      queryClient.invalidateQueries({
        queryKey: ['po', 'correspondence'],
      })
    }
  }

  const onSendReply = async () => {
    threadDraftSendMutation(
      {
        uuid: message.uuid,
      },
      {
        onSuccess(data, variables, context) {
          closeDraft()
          toast.success('Message is sent.')
          onCorrespondenceUpdated()
        },
      }
    )
  }

  const onDeleteDraft = async () => {
    threadDraftDeleteMutation(
      {
        uuid: message.uuid,
      },
      {
        onSuccess(data, variables, context) {
          toast.success('Draft message is deleted')
          closeDraft()
          onCorrespondenceUpdated()
        },
      }
    )
  }

  const onShowTrimmedContent = () => {
    setIsShowQuoteMessages(true)
    const existingContent = stateToHTML(editorState.getCurrentContent())
    const msgs = [...correspondenceContext.correspondence.messages]
    msgs.splice(0, selectedMessageIndex + 1)
    const quoteMessages = _filter(msgs, function (o) {
      return !o.is_draft
    })

    const quotesHtmlContent = generateQuotesHTMLContent(
      existingContent,
      quoteMessages
    )
    const contentState = stateFromHTML(quotesHtmlContent)

    setEditorState(EditorState.createWithContent(contentState))
  }

  return (
    <ReplyMessageContainer>
      <div className='wrapper'>
        <div className='reply-header'>
          <div className='reply-to'>
            <div className='reply-option'>
              <FormControl>
                <NativeSelect
                  value={replyTypeValue}
                  name='type'
                  onChange={onChangeReplyingTypeOption}
                  inputProps={{ 'aria-label': 'message-type' }}
                >
                  <option value='internal'>Internal Note</option>
                  <option value='supplier'>
                    Message to Supplier ({selectedLine.supplier})
                  </option>
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <span className='text-draft'>Draft</span>
        </div>
        <div className='reply-content'>
          <Editor
            editorState={editorState}
            onChange={onMessageContentUpdated}
            ref={textInput}
          />
          {!message.is_quote_attached &&
            !isFirstMessage &&
            !isShowQuoteMessages && (
              <Tooltip title='Show trimmed content'>
                <Button
                  variant='contained'
                  onClick={onShowTrimmedContent}
                  className='btn-show-quote'
                >
                  <MoreHorizIcon />
                </Button>
              </Tooltip>
            )}
        </div>
        <div className='reply-buttons'>
          <IconButton aria-label='delete' onClick={onDeleteDraft}>
            <DeleteIcon />
          </IconButton>
          <Button variant='contained' color='primary' onClick={onSendReply}>
            Send
          </Button>
        </div>
      </div>
    </ReplyMessageContainer>
  )
}

export default DraftMessage
