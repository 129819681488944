import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  NativeSelect,
  IconButton,
  FormControl,
  Button,
  Box,
  Paper,
  Input,
  Typography,
  Divider,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Editor, EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { toast } from 'react-toastify'
import _debounce from 'lodash/debounce'

import MessageContainer from './message.style'
import { useRfqCorrespondenceUpdateMutation } from 'services/hooks/rfq/useRfqCorrespondenceUpdateMutation'
import { usePoCorrespondenceUpdateMutation } from 'services/hooks/purchaseOrders/usePoCorrespondenceUpdateMutation'
import { useQueryClient } from '@tanstack/react-query'
import { useThreadDraftDeleteMutation } from 'services/hooks/thread/useThreadDraftDeleteMutation'
import { useThreadDraftSendMutation } from 'services/hooks/thread/useThreadDraftSendMutation'
import { useUserQuery } from 'services/hooks/auth/useUserQuery'

const DEBOUNCE_TIME_MS = 1000

const NewMessage = ({
  selectedLine,
  createdCorrespondence,
  correspondenceFor,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  )
  const [newMessage, setNewMessage] = useState({
    type: 'internal',
    subject: '',
    body: '',
  })

  const textInput = useRef(null)
  const subjectInput = useRef(null)
  const {
    mutate: rfqCorrespondenceUpdateMutation,
    isPending: isUpdatingRfqCorrespondence,
  } = useRfqCorrespondenceUpdateMutation()
  const {
    mutate: poCorrespondenceUpdateMutation,
    isPending: isUpdatingPoCorrespondence,
  } = usePoCorrespondenceUpdateMutation()
  const { mutate: threadDraftDeleteMutation } = useThreadDraftDeleteMutation()
  const { mutate: threadDraftSendMutation } = useThreadDraftSendMutation()
  const { data: userData } = useUserQuery()

  useEffect(() => {
    // textInput.current.focus()
    subjectInput.current.focus()

    const html =
      userData.data.email_signature_html !== ''
        ? `<br/><br/>${userData.data.email_signature_html}`
        : ''

    const contentState = stateFromHTML(html)
    setEditorState(EditorState.createWithContent(contentState))
    setNewMessage({
      ...newMessage,
      body: html,
    })
  }, [])

  const onCorrespondenceUpdated = () => {
    if (correspondenceFor === 'rfq') {
      queryClient.invalidateQueries({
        queryKey: ['rfq', 'correspondence'],
      })
    } else {
      queryClient.invalidateQueries({
        queryKey: ['po', 'correspondence'],
      })
    }
  }

  const saveDraftMessage = async (msgBody) => {
    if (correspondenceFor === 'rfq') {
      rfqCorrespondenceUpdateMutation(
        {
          messageUuid: createdCorrespondence.message.uuid,
          reqBody: msgBody,
        },
        {
          onSuccess(data, variables, context) {
            onCorrespondenceUpdated()
          },
        }
      )
    } else {
      poCorrespondenceUpdateMutation(
        {
          messageUuid: createdCorrespondence.message.uuid,
          reqBody: newMessage,
        },
        {
          onSuccess(data, variables, context) {
            onCorrespondenceUpdated()
          },
        }
      )
    }
  }

  const debouncedUpdateMessage = useCallback(
    _debounce((msg) => {
      saveDraftMessage(msg)
    }, DEBOUNCE_TIME_MS),
    []
  )

  const onChangeMessageContent = (fieldName, event) => {
    const { value } = event.target

    setNewMessage({
      ...newMessage,
      [fieldName]: value,
    })
  }

  const onChangeText = (newEditorState) => {
    if (
      editorState.getCurrentContent() !== newEditorState.getCurrentContent()
    ) {
      setEditorState(newEditorState)
      const html = stateToHTML(newEditorState.getCurrentContent())
      setNewMessage({
        ...newMessage,
        body: html,
      })
    }
  }

  useEffect(() => {
    debouncedUpdateMessage(newMessage)
  }, [newMessage])

  const sendMessage = async () => {
    threadDraftSendMutation(
      {
        uuid: createdCorrespondence.message.uuid,
      },
      {
        onSuccess(data, variables, context) {
          onClose()
          onCorrespondenceUpdated()
        },
      }
    )
  }

  const onCloseMessage = async () => {
    if (newMessage.subject === '' && newMessage.body === '') {
      threadDraftDeleteMutation(
        {
          uuid: createdCorrespondence.message.uuid,
        },
        {
          onSuccess(data, variables, context) {
            onClose()
            onCorrespondenceUpdated()
          },
        }
      )
    } else {
      onClose()
    }
  }

  const onDeleteDraft = async () => {
    threadDraftDeleteMutation(
      {
        uuid: createdCorrespondence.message.uuid,
      },
      {
        onSuccess(data, variables, context) {
          toast.success('Draft message is deleted')
          onClose()
          onCorrespondenceUpdated()
        },
      }
    )
  }

  return (
    <MessageContainer>
      <Box
        component={Paper}
        elevation={3}
        sx={{
          p: 1.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: 0,
        }}
      >
        <Typography>New Message</Typography>
        <IconButton aria-label='close' onClick={onCloseMessage}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        component={Paper}
        className='message-modal-content'
        sx={{ borderRadius: 0, px: 1.5 }}
      >
        <form>
          <Box
            className='form-content'
            sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <span>Type</span>
              <FormControl>
                <NativeSelect
                  value={newMessage.type}
                  name='type'
                  onChange={(e) => onChangeMessageContent('type', e)}
                  inputProps={{ 'aria-label': 'message-type' }}
                >
                  <option value='internal'>Internal Note</option>
                  <option value='supplier'>
                    Message to Supplier ({selectedLine.supplier})
                  </option>
                </NativeSelect>
              </FormControl>
            </Box>
            <Divider />
            <Box>
              <Input
                placeholder='Subject'
                inputProps={{ 'aria-label': 'subject' }}
                onChange={(e) => onChangeMessageContent('subject', e)}
                ref={subjectInput}
                fullWidth
              />
            </Box>
            <Divider />
            <Box>
              <Editor
                editorState={editorState}
                onChange={onChangeText}
                ref={textInput}
              />
            </Box>
          </Box>
          <Box
            sx={{
              py: 1.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          >
            <IconButton
              aria-label='delete'
              onClick={onDeleteDraft}
              disabled={
                isUpdatingRfqCorrespondence || isUpdatingPoCorrespondence
              }
            >
              <DeleteIcon />
            </IconButton>
            <Button
              variant='contained'
              color='primary'
              onClick={sendMessage}
              disabled={
                isUpdatingRfqCorrespondence || isUpdatingPoCorrespondence
              }
            >
              Send
            </Button>
          </Box>
        </form>
      </Box>
    </MessageContainer>
  )
}

export default NewMessage
