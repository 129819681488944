import { useState } from 'react'
import { toast } from 'react-toastify'
import { Box, Button, Typography, Menu, MenuItem } from '@mui/material'
import { NestedMenuItem } from 'mui-nested-menu'

import SendRfqModal from 'pages/requisitions/list/rfq/sendRfqModal'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'
import { useRfqListQuery } from 'services/hooks/rfq/useRfqListQuery'
import { useRfqCreateMutation } from 'services/hooks/rfq/useRfqCreateMutation'
import { useRfqLinesCreateMutation } from 'services/hooks/rfq/useRfqLinesCreateMutation'

type RecommendationLineProps = {
  requisition: any
  recommendation: {
    display_text: string
    manufacturer_id: number
    supplier_id: number
    part_number: string
  }
}

const RecommendationLine = ({
  requisition,
  recommendation,
}: RecommendationLineProps) => {
  const [openRfqModal, setOpenRfqModal] = useState(false)
  const [createdRfqId, setCreatedRfqId] = useState(null)
  const [createdRfqLineIds, setCreatedRfqLineIds] = useState([])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { data: suppliers } = useSuppliersQuery()
  const { mutate: rfqCreateMutation } = useRfqCreateMutation()
  const { mutate: rfqLinesCreateMutation } = useRfqLinesCreateMutation()
  const { data: rfqList } = useRfqListQuery({
    filterValues: {
      suppliers: [`${recommendation.supplier_id}`],
    },
  })

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const createRfqLine = (rfqId) => {
    rfqLinesCreateMutation(
      {
        lines: [
          {
            quantity: 1,
            part_number: recommendation.part_number,
            manufacturer_id: recommendation.manufacturer_id,
          },
        ],
        rfqId: rfqId,
        requisitionUUID: requisition.uuid,
      },
      {
        onSuccess(data) {
          setCreatedRfqId(rfqId)
          setCreatedRfqLineIds(data)
          setOpenRfqModal(true)
          toast.success(`Requisition Line is added to the RFQ ${rfqId}`)
        },
      }
    )
  }

  const onClickItem = async (rfq = null) => {
    setAnchorEl(null)
    if (rfq) {
      createRfqLine(rfq.id)
    } else {
      rfqCreateMutation(
        { supplier_id: recommendation.supplier_id },
        {
          onSuccess(data, variables, context) {
            toast.success(`New RFQ ${data.rfq_id} is created.`)
            createRfqLine(data.rfq_id)
          },
        }
      )
    }
  }

  const onClickSupplier = async (supplier) => {
    setAnchorEl(null)
    rfqCreateMutation(
      { supplier_id: supplier.id },
      {
        onSuccess(data, variables, context) {
          toast.success(`New RFQ ${data.rfq_id} is created.`)
          createRfqLine(data.rfq_id)
        },
      }
    )
  }

  const onCloseAddRfqModal = () => {
    setOpenRfqModal(false)
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      sx={{ mb: 1 }}
    >
      <Typography>{recommendation.display_text}</Typography>
      <Box>
        <Button variant='contained' onClick={handleClick}>
          Add to RFQ
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={() => onClickItem()}>New RFQ</MenuItem>
          {rfqList?.rfq?.map((rfq) => (
            <MenuItem onClick={() => onClickItem(rfq)} key={rfq.id}>
              RFQ {rfq.id} ({rfq.supplier})
            </MenuItem>
          ))}
          {recommendation.supplier_id === 0 && suppliers && (
            <NestedMenuItem label='Suppliers' parentMenuOpen={open}>
              {suppliers.map((supplier) => (
                <MenuItem
                  onClick={() => onClickSupplier(supplier)}
                  key={supplier.id}
                >
                  {supplier.name}
                </MenuItem>
              ))}
            </NestedMenuItem>
          )}
        </Menu>
      </Box>
      {createdRfqId && createdRfqLineIds && (
        <SendRfqModal
          open={openRfqModal}
          close={onCloseAddRfqModal}
          rfqId={createdRfqId}
          rfqLineIds={createdRfqLineIds}
        />
      )}
    </Box>
  )
}

export default RecommendationLine
