import { Box, Button } from '@mui/material'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import AssignSelector from './selector'
import { AssignUserDTO, PermissionsDTO } from 'declarations'
import { useRfqUpdateMutation } from 'services/hooks/rfq/useRfqUpdateMutation'
import { usePoUpdateMutation } from 'services/hooks/purchaseOrders/usePoUpdateMutation'
import { useRequisitionUpdateMutation } from 'services/hooks/requisition/useRequisitionUpdateMutation'

type AssignDropdownProps = {
  selected: any
  assign: any
  userList: AssignUserDTO[]
  componentType?: string
  updateFor: string
}

const AssignDropdown = ({
  selected,
  assign,
  userList,
  componentType = 'selector',
  updateFor,
}: AssignDropdownProps) => {
  const { assignee, uuid } = selected
  const { mutate: rfqUpdateMutation } = useRfqUpdateMutation()
  const { mutate: poUpdateMutation } = usePoUpdateMutation()
  const { mutate: requisitionUpdateMutation } = useRequisitionUpdateMutation()
  const queryClient = useQueryClient()
  const [assignedTo, setAssignedTo] = useState<{
    type: string
    value: string
  }>()

  useEffect(() => {
    let assignedTo = {
      type: '',
      value: '',
    }

    if (!assign) {
      if (assignee) {
        assignedTo = {
          type: 'read-only',
          value: 'Assigned',
        }
      } else {
        assignedTo = {
          type: 'read-only',
          value: 'Unassigned',
        }
      }
    } else if (typeof assign === 'string' && assign === 'self') {
      assignedTo = {
        type: 'self',
        value: '',
      }
    } else if (assign === true) {
      if (assignee !== null && typeof assignee === 'object') {
        assignedTo = {
          type: 'multiple',
          value: `${assignee.user_id}`,
        }
      } else {
        assignedTo = {
          type: 'multiple',
          value: '',
        }
      }

      setAssignedTo(assignedTo)
    }
  }, [assignee, assign])

  const onUpdateAssignee = (assigneeValue) => {
    switch (updateFor) {
      case 'requisition':
        requisitionUpdateMutation(
          {
            uuid,
            reqBody: {
              assignee: assigneeValue,
            },
          },
          {
            onSuccess(data, variables, context) {
              queryClient.invalidateQueries({
                queryKey: ['requisition', 'history'],
              })

              toast.success('Assigned Successfully')
            },
          }
        )
        break
      case 'rfq':
        rfqUpdateMutation(
          {
            uuid,
            reqBody: {
              assignee: assigneeValue,
            },
          },
          {
            onSuccess(data, variables, context) {
              toast.success('Assigned Successfully')
            },
          }
        )
        break
      case 'po':
        poUpdateMutation(
          {
            uuid,
            reqBody: {
              assignee: assigneeValue,
            },
          },
          {
            onSuccess(data, variables, context) {
              toast.success('Assigned Successfully')
            },
          }
        )
        break
      default:
        break
    }
  }

  const onGrab = () => {
    onUpdateAssignee('self')
  }

  return (
    <Box sx={{ width: '100%' }}>
      {assignedTo && (
        <span>
          {assignedTo.type === 'read-only' && assignedTo.value}
          {assignedTo.type === 'self' && (
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={onGrab}
            >
              Grab
            </Button>
          )}
          {assignedTo.type === 'multiple' && userList.length > 0 && (
            <AssignSelector
              assignedTo={assignedTo}
              userList={userList}
              updateAssignee={onUpdateAssignee}
              componentType={componentType}
            />
          )}
        </span>
      )}
    </Box>
  )
}

export default AssignDropdown
