import React, { useState, useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ShipmentList from './list'
import ShipmentRead from './read'
import DueToday from './dueToday'
import Receiving from './receiving'
import { Box, Paper } from '@mui/material'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Shipment = ({ history, match }) => {
  const routeURL = match.url
  const [value, setValue] = useState(0)
  const location = useLocation()
  const [isClickTab, setIsClickTab] = useState(false)

  useEffect(() => {
    if (!isClickTab) {
      const { pathname } = location
      const pageRoute = pathname.split('/')[2]

      switch (pageRoute) {
        case 'list':
          setValue(0)
          break
        case 'due-today':
          setValue(1)
          break
        case 'receiving':
          setValue(2)
          break
        default:
          setValue(0)
          break
      }
    } else {
      setIsClickTab(false)
    }
  }, [location])

  const handleChange = (event, newValue) => {
    setIsClickTab(true)
    setValue(newValue)

    switch (newValue) {
      case 0:
        history.push(`${routeURL}/list`)
        break
      case 1:
        history.push(`${routeURL}/due-today`)
        break
      case 2:
        history.push(`${routeURL}/receiving`)
        break
      default:
        break
    }
  }

  return (
    <Paper sx={{ p: 3 }} elevation={3}>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='All Shipments' {...a11yProps(0)} />
          <Tab label='Due Today' {...a11yProps(1)} />
          <Tab label='Receiving' {...a11yProps(2)} />
        </Tabs>
      </Box>

      <Switch>
        <Route exact path={`${routeURL}/list`} component={ShipmentList} />
        <Route exact path={`${routeURL}/due-today`} component={DueToday} />
        <Route exact path={`${routeURL}/receiving`} component={Receiving} />
        <Route
          exact
          path={[
            `${routeURL}/:trackingNumber`,
            `${routeURL}/:trackingNumber/:tabName`,
          ]}
          component={ShipmentRead}
        />
      </Switch>
    </Paper>
  )
}

export default Shipment
