import { CircularProgress } from '@mui/material'
import AuthWrapper from 'pages/auth/authWrapper'

const LoadingScreen = () => {
  return (
    <AuthWrapper>
      <CircularProgress />
    </AuthWrapper>
  )
}

export default LoadingScreen
