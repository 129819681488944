import { ApiMetaDataDTO, ErrorDTO, UserAuthDTO } from 'declarations'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { client, queryClient } from 'services/helpers/apiConfig'
import { isAxiosError, AxiosError } from 'axios'

type AuthDataDTO = {
  userName: string
  password: string
  googleAuthenticatorCode?: string
}

type ApiResponseDTO = {
  code: number
  data: {
    token: string
    token_ttl: number
    user: UserAuthDTO
    force_password_change?: boolean
    require_mfa_code?: boolean
    mfa_codes?: any
    googleAuthenticatorQRCode?: any
  }
  api_metadata: ApiMetaDataDTO
}

type MutationResponseDTO = ApiResponseDTO['data']

type LoginReqBodyDTO = {
  username: string
  password: string
  mfa_code?: string
}

export const useLoginMutation = () =>
  useMutation<MutationResponseDTO, ErrorDTO, AuthDataDTO>({
    mutationFn: async (values: AuthDataDTO) => {
      try {
        const apiString = `/user/login`

        let reqBody: LoginReqBodyDTO = {
          username: values.userName,
          password: encodeURIComponent(values.password),
        }

        if (values.googleAuthenticatorCode) {
          reqBody = {
            ...reqBody,
            mfa_code: values.googleAuthenticatorCode,
          }
        }

        const { data } = await client.get<ApiResponseDTO>(apiString, {
          params: reqBody,
        })

        localStorage.setItem('username', values.userName)
        localStorage.setItem('accessToken', data.data.token)

        client.defaults.headers.common = {
          Authorization: `Bearer ${data.data.token}`,
        }

        if (!data.data.force_password_change && data.data.token) {
          await queryClient.invalidateQueries({ queryKey: ['get-user-info'] })
        }

        return data.data
      } catch (err) {
        if (isAxiosError(err)) {
          throw err.response.data as ErrorDTO
        } else {
          throw err as ErrorDTO
        }
      }
    },
  })
