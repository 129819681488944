import React, { useState } from 'react'
import { Paper, Button, Link, Box, Dialog } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import ReceiveModal from './receiveModal'
import { useShipmentRecevingListQuery } from 'services/hooks/shipment/useShipmentRecevingListQuery'
import { ShipmentDTO } from 'declarations'

const Receiving = () => {
  const [openReceiveModal, setOpenReceiveModal] = useState(false)
  const [selectedShipment, setSelectedShipment] = useState<ShipmentDTO | null>()
  const { data: shipmentReceivingListData } = useShipmentRecevingListQuery()

  const onReceiveButtonClick = (shipment) => {
    setSelectedShipment(shipment)
    setOpenReceiveModal(true)
  }

  const handleClose = () => {
    setSelectedShipment(null)
    setOpenReceiveModal(false)
  }

  const columns: GridColDef[] = [
    {
      field: 'tracking_number',
      headerName: 'Tracking Number',
      renderCell: (params: GridRenderCellParams) => (
        <Link
          component={RouterLink}
          to={`/shipment/${params.row.tracking_number}`}
        >
          {params.row.tracking_number}
        </Link>
      ),
      valueGetter: (value, row) => row.tracking_number,
      flex: 1,
    },
    {
      field: 'supplier',
      headerName: 'Supplier',
      valueGetter: (value, row) => row.supplier.name,
      flex: 1,
    },
    {
      field: 'delivery_confirmed_date',
      headerName: 'On dock',
      flex: 1,
    },
    {
      field: 'receive',
      headerName: '',
      renderCell: (params: GridRenderCellParams) => (
        <Button
          color='primary'
          variant='contained'
          onClick={() => onReceiveButtonClick(params.row)}
        >
          Receive {'>'}
        </Button>
      ),
      display: 'flex',
      flex: 1,
    },
  ]

  const onCloseModal = () => {
    setSelectedShipment(null)
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ height: 500 }}>
        <DataGrid
          rows={shipmentReceivingListData?.shipments || []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25]}
          getRowId={(row) => row.id}
        />
      </Box>

      <Dialog
        fullWidth
        maxWidth='md'
        open={!!selectedShipment}
        onClose={onCloseModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {selectedShipment && (
          <ReceiveModal shipment={selectedShipment} handleClose={handleClose} />
        )}
      </Dialog>
    </Paper>
  )
}

export default Receiving
