import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type UpdateRfqCrrespondenceParamsDTO = {
  messageUuid: string
  reqBody: {
    type?: string
    body?: string
    subject?: string
  }
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqCorrespondenceUpdateMutation = () =>
  useMutation<unknown, ErrorDTO, UpdateRfqCrrespondenceParamsDTO>({
    mutationFn: async (values: UpdateRfqCrrespondenceParamsDTO) => {
      try {
        const res = await client.put<ApiResponseDTO>(
          `/rfq/correspondence/update/${values.messageUuid}`,
          values.reqBody
        )

        // toast.success('Supplier profile is updated')

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
