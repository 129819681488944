import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { RfqDTO, StatusDTO, TimelineDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

export type RfqCorrespondenceResponseDTO = {
  rfq: RfqDTO[]
  status_list: StatusDTO[]
  threads: any
  timeline: TimelineDTO[]
}

const useRfqCorrespondenceQuery = (
  uuid: string | number,
  enabled = true
): UseQueryResult<RfqCorrespondenceResponseDTO> => {
  return useQuery({
    queryKey: ['rfq', 'correspondence', uuid],
    queryFn: async () => {
      const res = await client.get(`/rfq/correspondence/${uuid}`)

      return res.data.data
    },
    enabled,
    refetchInterval: 300000,
  })
}

export { useRfqCorrespondenceQuery }
