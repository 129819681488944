import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  user_id: number
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useRemoveMFAMutation = () =>
  useMutation<unknown, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        const res = await client.put('/user/update/removeMFA', {
          user_id: values.user_id,
        })

        if (res.data.code === 201) {
          toast.success('MFA Disabled')
        } else {
          toast.warn(res.data.message)
        }

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
