import {
  Button,
  CircularProgress,
  Link,
  Tooltip,
  TextField,
} from '@mui/material'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import AuthWrapper from '../authWrapper'
import { useChangePasswordMutation } from 'services/hooks/auth/useChangePasswordMutation'

interface IFormInput {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const longText = `
Password must have at least one upper case letter, one lower case letter, one number and one special symbol.
`

const ChangePassword = () => {
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })
  const { mutate: changePasswordMutate, status } = useChangePasswordMutation()
  const newPassword = watch('newPassword')
  const userName = localStorage.getItem('username')
  const history = useHistory()

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    const reqBody = {
      userName,
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    }
    changePasswordMutate(reqBody, {
      onSuccess(data, variables, context) {
        history.push('/login')
        toast.success('Password changed successfully! Login Now!')
      },
      onError(error, variables, context) {
        toast.error(error.message)
      },
    })
  }

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='oldPassword'
          control={control}
          rules={{
            required: 'Required',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='standard'
              label='Old Password'
              fullWidth
              type='password'
              error={Boolean(errors.oldPassword)}
              helperText={errors.oldPassword?.message}
            />
          )}
        />
        <Controller
          name='newPassword'
          control={control}
          rules={{
            required: 'Required',
            pattern: {
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              message:
                'The password does not meet the minimum password requirements',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='standard'
              label='New Password'
              fullWidth
              type='password'
              error={Boolean(errors.newPassword)}
              helperText={errors.newPassword?.message}
            />
          )}
        />
        <Controller
          name='confirmPassword'
          control={control}
          rules={{
            required: 'Required',
            validate: (value) =>
              value === newPassword || 'Confirm password does not match',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='standard'
              label='Confirm Password'
              fullWidth
              type='password'
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword?.message}
            />
          )}
        />
        <div className='card-actions'>
          <Tooltip title={longText}>
            <Link
              component='button'
              variant='body2'
              className='tooltip-password'
            >
              Password Requirements
            </Link>
          </Tooltip>

          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={status === 'pending'}
            className='btn-submit'
          >
            {status === 'pending' ? (
              <CircularProgress size={20} thickness={5} />
            ) : (
              <span>Submit</span>
            )}
          </Button>
        </div>
      </form>
    </AuthWrapper>
  )
}

export default ChangePassword
