import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { TextField, Button, CircularProgress, Link, Box } from '@mui/material'
import { useLocation, useHistory } from 'react-router-dom'
import AuthWrapper from '../authWrapper'
import { useForgotPasswordMutation } from 'services/hooks/auth/useForgotPasswordMutation'
import { toast } from 'react-toastify'

interface IFormInput {
  userName: string
}

type LocationState = {
  pathname: string
  state: {
    userName?: string
  }
}

const ForgotPassword = () => {
  const history = useHistory()
  const location: LocationState = useLocation()
  const { mutate: forgotPasswordMutate, status } = useForgotPasswordMutation()
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userName: location.state?.userName || '',
    },
  })

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    // const res = await forgotPasswordMutation(data)
    forgotPasswordMutate(formData, {
      onSuccess(data, variables, context) {
        history.push('/forgotPasswordConfirm')
      },
      onError(error, variables, context) {
        toast.error(error.message)
      },
    })
  }

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='userName'
          control={control}
          rules={{ required: 'Required' }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='standard'
              label='Username'
              fullWidth
              error={Boolean(errors.userName)}
              helperText={errors.userName?.message}
            />
          )}
        />
        <div className='card-actions'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={status === 'pending'}
            className='btn-submit'
          >
            {status === 'pending' ? (
              <CircularProgress size={20} thickness={5} />
            ) : (
              <span>Submit</span>
            )}
          </Button>
        </div>
      </form>
    </AuthWrapper>
  )
}

export default ForgotPassword
