import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles'

import states from '../../../../utils/states_titlecase'
import { useCreateSupplierProfileMutation } from 'services/hooks/supplier/useCreateSupplierProfileMutation'
import { useUpdateSupplierProfileMutation } from 'services/hooks/supplier/useUpdateSupplierProfileMutation'
import { useSupplierLogoUploadMutation } from 'services/hooks/supplier/useSupplierLogoUploadMutation'

interface IFormInput {
  name: string
  street_address_1: string
  street_address_2: string
  city: string
  web_address: string
  state: string
  zip: string
  warehouse_zip: string
}

type SupplierProfileFormProps = {
  uuid?: string
  initialValues: IFormInput
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const SupplierProfileForm = ({
  uuid,
  initialValues,
}: SupplierProfileFormProps) => {
  const { mutate: createSupplierMutate } = useCreateSupplierProfileMutation()
  const { mutate: updateSupplierMutate } = useUpdateSupplierProfileMutation()
  const { mutate: uploadSupplierLogoMutate } = useSupplierLogoUploadMutation()

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: initialValues || {
      name: '',
      street_address_1: '',
      street_address_2: '',
      city: '',
      web_address: '',
      state: '',
      zip: '',
      warehouse_zip: '',
    },
  })

  const onFileUpload = async (e) => {
    if (uuid) {
      uploadSupplierLogoMutate({ uuid, file: e.target.files[0] })
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    if (uuid) {
      updateSupplierMutate(
        { uuid, reqBody: formData },
        {
          onSuccess(data, variables, context) {},
          onError(error, variables, context) {},
        }
      )
    } else {
      createSupplierMutate(formData, {
        onSuccess(data, variables, context) {
          reset()
        },
        onError(error, variables, context) {},
      })
    }
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  label='Supplier Name'
                  fullWidth
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='street_address_1'
              control={control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  label='Street Address 1'
                  fullWidth
                  error={Boolean(errors.street_address_1)}
                  helperText={errors.street_address_1?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='street_address_2'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  label='Street Address 2'
                  fullWidth
                  error={Boolean(errors.street_address_2)}
                  helperText={errors.street_address_2?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='city'
              control={control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  label='City'
                  fullWidth
                  error={Boolean(errors.city)}
                  helperText={errors.city?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='web_address'
              control={control}
              rules={{
                required: 'Required',
                pattern: {
                  value:
                    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                  message: 'Web address is not valid',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  label='Web Address'
                  fullWidth
                  error={Boolean(errors.web_address)}
                  helperText={errors.web_address?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name='state'
              control={control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <FormControl
                  variant='outlined'
                  fullWidth
                  error={Boolean(errors.state)}
                >
                  <InputLabel id='state-select-label'>State</InputLabel>
                  <Select
                    {...field}
                    labelId='state-select-label'
                    label='States'
                  >
                    <MenuItem value={0}>None</MenuItem>
                    {states?.map((state, i) => (
                      <MenuItem value={state.abbreviation} key={'state-' + i}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.state?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name='zip'
              control={control}
              rules={{
                required: 'Required',
                pattern: {
                  value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                  message: 'Zipcode is not valid',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  label='Zipcode'
                  fullWidth
                  error={Boolean(errors.zip)}
                  helperText={errors.zip?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name='warehouse_zip'
              control={control}
              rules={{
                required: 'Required',
                pattern: {
                  value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                  message: 'Zipcode is not valid',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  label='Warehouse Zip Code'
                  fullWidth
                  error={Boolean(errors.warehouse_zip)}
                  helperText={errors.warehouse_zip?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              component='label'
              role={undefined}
              variant='contained'
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput type='file' onChange={onFileUpload} />
            </Button>
            <Typography>Requirements: Longest size must be 200MB.</Typography>
          </Grid>
          <Grid item xs={12} className='grid-item-justify-right'>
            <Box textAlign='right'>
              <Button variant='contained' color='primary' type='submit'>
                {uuid ? 'Update' : 'Create supplier'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default SupplierProfileForm
