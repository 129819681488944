import React from 'react'
import { Box, Avatar } from '@mui/material'

const RequisitionQuickGlance = ({ row, handleGlancePopoverOpen }) => {
  const handlePopoverOpen = (event, name) => {
    handleGlancePopoverOpen(event, {
      ...row[name],
      type: name,
      title:
        name === 'sp_icon'
          ? 'Supplier Preference Rules'
          : 'Supplier Part Number Rules',
    })
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Box mr={1}>
        <Avatar
          style={{
            backgroundColor: row.pn_icon.icon_color,
            cursor: 'pointer',
          }}
          onClick={(e) => handlePopoverOpen(e, 'pn_icon')}
        >
          PN
        </Avatar>
      </Box>
      <Box>
        <Avatar
          style={{
            backgroundColor: row.sp_icon.icon_color,
            cursor: 'pointer',
          }}
          onClick={(e) => handlePopoverOpen(e, 'sp_icon')}
        >
          SP
        </Avatar>
      </Box>
    </Box>
  )
}

export default RequisitionQuickGlance
