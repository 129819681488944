import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'

type ColumnDTO = {
  bold: boolean
  text: string
}

type ExpeditingDTO = {
  background_color: string
  can_close: boolean
  column1: ColumnDTO
  column2: ColumnDTO
  column3: ColumnDTO
  icon: {
    color: string
    icon: string
  }
  uuid: string
}

type ApiMetaDataDTO = {
  api_version: string
  git_branch_name: string
  git_commit_date: string
  git_commit_id: string
}

type ResponseDTO = {
  code: number
  data: {
    expediting: ExpeditingDTO[]
  }
  api_metadata: ApiMetaDataDTO
}

export const useShipmentExpeditingQuery = (): UseQueryResult<
  ExpeditingDTO[],
  unknown
> => {
  return useQuery({
    queryKey: ['shipmentExpediting-all'],
    queryFn: async () => {
      const { data } = await client.get<ResponseDTO>(
        '/shipment/expediting/list'
      )

      if (data.code === 200) {
        return data.data.expediting
      } else {
        throw { message: 'Shipment Expeditings not found' }
      }
    },
  })
}
