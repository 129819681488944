import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'

export type ParamsDTO = {
  name?: string
}

export const useSupplierLogoUploadMutation = () =>
  useMutation({
    mutationFn: async (body: { uuid: string; file: any }) => {
      const formData = new FormData()
      formData.append('file', body.file)
      const config = {
        headers: {
          'content-type': 'application/octet-stream',
        },
      }

      await client.post(`/suppliers/logo/${body.uuid}/upload`, formData, config)
    },

    onSuccess: () => {
      console.log('file uploading success')
    },

    onError: (error) => {
      console.log('uploading error', error)
    },
  })
