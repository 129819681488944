import React, { useState, useEffect } from 'react'
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material'
import _find from 'lodash/find'
import { toast } from 'react-toastify'

import { usePoStatusListQuery } from 'services/hooks/purchaseOrders/usePoStatusListQuery'
import { useHistory, useParams } from 'react-router'
import { usePoReadQuery } from 'services/hooks/purchaseOrders/usePoReadQuery'
import { usePoStatusUpdateMutation } from 'services/hooks/purchaseOrders/usePoStatusUpdateMutation'
import { usePoDeleteMutation } from 'services/hooks/purchaseOrders/usePoDeleteMutation'
import { useQueryClient } from '@tanstack/react-query'

const ChangeStatusModalContent = ({
  changeStatusFor,
  onClose,
  onDeleteLine,
}) => {
  const { id: poId }: any = useParams()
  const { data: poData } = usePoReadQuery(poId)
  const { data: statusList } = usePoStatusListQuery()
  const [statusToUpdate, setStatusToUpdate] = useState<any>()
  const history = useHistory()
  const {
    mutate: poStatusUpdateMutation,
    isPending: isPendingPoStatusUpdateMutation,
  } = usePoStatusUpdateMutation()
  const { mutate: poDeleteMutation, isPending: isPendingPodeleteMutation } =
    usePoDeleteMutation()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (statusList) {
      const currentStatusIndex = statusList.findIndex(
        (el) => el.id === poData.status_id
      )

      const arr1 = statusList.slice(0, currentStatusIndex)
      const arr2 = statusList.slice(
        currentStatusIndex + 1,
        statusList.length + 1
      )
      const statusListForSearch = [...arr1.reverse(), ...arr2.reverse()]

      if (changeStatusFor === 'delete this purchase order') {
        setStatusToUpdate(
          _find(statusListForSearch, function (x) {
            return x.permissions.can_delete === true
          })
        )
      } else if (changeStatusFor === 'edit this purchase order') {
        setStatusToUpdate(
          _find(statusListForSearch, function (x) {
            return x.permissions.can_edit === true
          })
        )
      } else if (changeStatusFor === 'delete this line') {
        setStatusToUpdate(
          _find(statusListForSearch, function (x) {
            return x.permissions.can_delete_lines === true
          })
        )
      }
    }
  }, [statusList])

  const onChangeStatus = async () => {
    poStatusUpdateMutation(
      {
        uuid: poData.uuid,
        reqBody: {
          status_id: statusToUpdate.id,
        },
      },
      {
        onSuccess(data, variables, context) {
          if (changeStatusFor === 'delete this purchase order') {
            poDeleteMutation(
              { uuid: poData.uuid },
              {
                onSuccess(data, variables, context) {
                  history.push(`/po/list`)
                  toast.success(`Po ${poData.id} is deleted.`)
                },
              }
            )
          } else if (changeStatusFor === 'edit this purchase order') {
            toast.success(`Po ${poData.id} is updated.`)
            queryClient.invalidateQueries({ queryKey: ['po', 'read'] })
          } else if (changeStatusFor === 'delete this line') {
            onDeleteLine()
          }
          onClose()
        },
      }
    )
  }

  return (
    <>
      <DialogContent>
        <Typography>
          To {changeStatusFor}, you need to change the status
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={onChangeStatus}
          disabled={
            isPendingPoStatusUpdateMutation || isPendingPodeleteMutation
          }
        >
          {isPendingPoStatusUpdateMutation || isPendingPodeleteMutation ? (
            <CircularProgress size={25} />
          ) : (
            `Change status to "${statusToUpdate && statusToUpdate.status}"`
          )}
        </Button>
      </DialogActions>
    </>
  )
}

export default ChangeStatusModalContent
