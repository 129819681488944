import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

const usePoReadShipmentQuery = (
  uuid: string,
  enabled = true
): UseQueryResult<any> => {
  return useQuery({
    queryKey: ['po', 'read', 'shipment', uuid],
    queryFn: async () => {
      try {
        const res = await client.get(`po/read/${uuid}/shipment`)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    enabled,
  })
}

export { usePoReadShipmentQuery }
