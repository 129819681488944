import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import {
  PermissionsDTO,
  QuantitiesColorsDTO,
  RequisitionDTO,
} from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

export type RequisitionReadResponseDTO = {
  quantities_colors: QuantitiesColorsDTO
  requisition: RequisitionDTO
  permissions: PermissionsDTO
}

const useRequisitionQuery = (
  requisitionId: string
): UseQueryResult<RequisitionReadResponseDTO> => {
  return useQuery({
    queryKey: ['requisition', requisitionId],
    queryFn: async () => {
      try {
        const res = await client.get(`requisitions/read/${requisitionId}`)
        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    retry: false,
  })
}

export { useRequisitionQuery }
