import {
  ApiMetaDataDTO,
  ErrorDTO,
  RfqDTO,
  RfqLineDTO,
  StatusDTO,
  TimelineDTO,
} from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type PoLineBatchCreateParam = {
  rfq_quote_lines: string[]
  po: number
}

type PoDTO = {
  id: number
  uuid: string
}

type PoLineDTO = {
  po_id: number
  po_line_id: number
  po_uuid: string
  quantity: number
}

type DispatchScheduleDTO = {
  lead_time_days: number
  lead_time_menu: string
  quantity: number
}

type RfqItemDTO = {
  date_last_updated: string
  dispatch_schedule: DispatchScheduleDTO[]
  item: string
  manufacturer_id: number
  metadata: any[]
  pause: boolean
  po_lines: PoLineDTO[]
  price: number
  quantity: number
  rating: number
  rfq_line: RfqLineDTO
  rfq_quote_line_uuid: string
  supplier_part_number: string
  supplier_part_number_custom: boolean
}

type ResponseDataDTO = {
  non_bid_items: RfqLineDTO[]
  po: PoDTO
  quoted_items: {
    custom_items: RfqItemDTO[]
    rfq_items: RfqItemDTO[]
  }
  rfq: RfqDTO
  status_list: StatusDTO[]
  timeline: TimelineDTO[]
}

type ApiResponseDTO = {
  code: number
  data: ResponseDataDTO
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const usePoLineBatchCreateMutation = () =>
  useMutation<any, ErrorDTO, PoLineBatchCreateParam>({
    mutationFn: async (values: PoLineBatchCreateParam) => {
      try {
        const res = await client.post<ApiResponseDTO>(
          '/po/line/batch/create',
          values
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
