import React, { useState } from 'react'
import {
  FormControl,
  Dialog,
  InputLabel,
  IconButton,
  InputAdornment,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  OutlinedInput,
  Box,
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import _filter from 'lodash/filter'

import CreateUser from '../../user/create'
import { CyanButton } from 'components/common/button'
import { useUserListSuppliersQuery } from 'services/hooks/user/useUserListSuppliersQuery'
import { useUpdateSupplierProfileMutation } from 'services/hooks/supplier/useUpdateSupplierProfileMutation'

const WAIT_INTERVAL = 1000
let timer

const EmptyUserProfileForm = ({ uuid, onUserConnected }) => {
  const [searchValue, setSearchValue] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [isAdd, setIsAdd] = useState(false)
  const { data: userSuppliers } = useUserListSuppliersQuery()
  const { mutate: updateSupplierProfileMutate } =
    useUpdateSupplierProfileMutation()

  const openSuggestions = (currentTarget) => {
    setAnchorEl(currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onSearchChange = (e) => {
    const { value } = e.target
    const { currentTarget } = e
    clearTimeout(timer)
    setSearchValue(value)
    timer = setTimeout(async () => {
      const filterUsers = _filter(userSuppliers.users, function (o) {
        return o.name.toUpperCase().includes(value.toUpperCase())
      })
      openSuggestions(currentTarget)
      setSearchResults(filterUsers)
    }, WAIT_INTERVAL)
  }

  const onSelectUser = async (user) => {
    setSearchValue(user.name)
    handleClose()
    updateSupplierProfileMutate(
      {
        uuid,
        reqBody: {
          linked_user_id: user.id,
        },
      },
      {
        onSuccess(data, variables, context) {
          onUserConnected(user)
        },
      }
    )
  }

  const onUserCreated = (user) => {
    onSelectUser(user)
  }

  const onCloseAddUser = () => {
    setIsAdd(false)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel htmlFor='outlined-adornment-amount'>Search</InputLabel>
        <OutlinedInput
          id='outlined-adornment-amount'
          startAdornment={
            <InputAdornment position='start'>
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            </InputAdornment>
          }
          label='Search'
          value={searchValue}
          onChange={onSearchChange}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List
            component='nav'
            aria-label='supplier terms'
            style={{ width: 300 }}
          >
            {searchResults.length > 0 ? (
              searchResults.map((user, i) => (
                <ListItem
                  button
                  key={'search-result' + i}
                  onClick={() => onSelectUser(user)}
                >
                  <ListItemText primary={user.name} />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemIcon>
                  <ErrorIcon color='secondary' />
                </ListItemIcon>
                <ListItemText primary='No terms' />
              </ListItem>
            )}
          </List>
        </Popover>
      </FormControl>

      <CyanButton className='btn-cyan' onClick={() => setIsAdd(true)}>
        Add Contact
      </CyanButton>
      <Dialog
        aria-labelledby='simple-dialog-title'
        onClose={onCloseAddUser}
        open={isAdd}
        maxWidth='sm'
        fullWidth
      >
        <Box sx={{ padding: '40px', position: 'relative' }}>
          <IconButton
            aria-label='close'
            className='btn-close'
            onClick={onCloseAddUser}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
          <CreateUser isUpdateSupplier onUserCreated={onUserCreated} />
        </Box>
      </Dialog>
    </div>
  )
}

export default EmptyUserProfileForm
