import { ApiMetaDataDTO, SupplierDTO } from 'declarations'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

type ResponseDTO = {
  code: number
  data: SupplierDTO
  api_metadata: ApiMetaDataDTO
}

export const useReadSupplierQuery = (
  uuid: string
): UseQueryResult<SupplierDTO, unknown> => {
  return useQuery({
    queryKey: ['read-supplier', uuid],
    queryFn: async () => {
      try {
        const res = await client.get<ResponseDTO>(`/supplier/read/${uuid}`)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
}
