import { ApiMetaDataDTO, ErrorDTO, UserAuthDTO } from 'declarations'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { client, queryClient } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  user_id: number
  password?: string
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useDeleteGoogleAuthCodeMutation = () =>
  useMutation<unknown, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        let res
        if (!values.password) {
          res = await client.delete<ApiResponseDTO>(
            `/user/deleteGoogleAuthCode?&userId=${values.user_id}`
          )
        } else {
          res = await client.delete(
            `/user/deleteGoogleAuthCode?password=${values.password}&userId=${values.user_id}`
          )
        }

        toast.success('MFA is diabled')

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
