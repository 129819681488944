import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { toast } from 'react-toastify'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import _find from 'lodash/find'
import { useHistory, useLocation } from 'react-router'

import {
  CreateUserDataDTO,
  useCreateUserMutation,
} from 'services/hooks/user/useCreateUserMutation'
import { useUserTypesQuery } from 'services/hooks/userType/useUserTypesQuery'

type CreateFormProps = {
  isUpdateSupplier: boolean
  onUserCreated: (user: any) => void
}

interface IFormInput {
  firstName: string
  lastname: string
  email: string
  phone: string
  userType: number
  password: string
  forceChangePassword: boolean
  emailUserNewPassword: boolean
  requireMFA: boolean
  activeUser: boolean
}

const CreateForm = ({ isUpdateSupplier, onUserCreated }: CreateFormProps) => {
  const { data: userTypes } = useUserTypesQuery()
  const { mutate: createUserMutate } = useCreateUserMutation()
  const history = useHistory()
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastname: '',
      email: '',
      phone: '',
      userType: 0,
      password: '',
      forceChangePassword: true,
      emailUserNewPassword: true,
      requireMFA: false,
      activeUser: true,
    },
  })

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    let userData: CreateUserDataDTO = {
      first_name: formData.firstName,
      last_name: formData.lastname,
      email: formData.email,
      phone: formData.phone,
      user_type_id: formData.userType,
    }

    if (!isUpdateSupplier) {
      userData = {
        ...userData,
        password: formData.password,
        force_password_change: formData.forceChangePassword,
        email_password: formData.emailUserNewPassword,
        require_mfa: formData.requireMFA,
        active: formData.activeUser,
        user_type_id: formData.userType,
      }
    } else {
      userData = {
        ...userData,
        user_type_id: _find(userTypes, { user_type: 'supplier' }).id,
      }
    }

    createUserMutate(userData, {
      onSuccess(data) {
        toast.success(`New user is created successfully`)
        if (!isUpdateSupplier) {
          history.push('/admin/user/list')
        } else {
          onUserCreated({
            id: data.id,
            name: `${userData.first_name} ${userData.last_name}`,
            ...userData,
          })
        }
      },
      onError(error) {
        toast.error(error.message)
      },
    })
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={isUpdateSupplier ? 12 : 6}>
            <Card>
              <CardHeader title='User Profile' />
              <CardContent
                sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
              >
                <Controller
                  name='firstName'
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      label='First Name'
                      fullWidth
                      error={Boolean(errors.firstName)}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />
                <Controller
                  name='lastname'
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      label='Last Name'
                      fullWidth
                      error={Boolean(errors.lastname)}
                      helperText={errors.lastname?.message}
                    />
                  )}
                />
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: 'Required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Invalid email address',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      label='Email'
                      fullWidth
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                    />
                  )}
                />
                <Controller
                  name='phone'
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      label='Phone'
                      fullWidth
                      error={Boolean(errors.phone)}
                      helperText={errors.phone?.message}
                    />
                  )}
                />
                {!isUpdateSupplier && (
                  <Controller
                    name='userType'
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ field }) => (
                      <FormControl fullWidth error={Boolean(errors.userType)}>
                        <InputLabel id='demo-simple-select-label'>
                          User Type
                        </InputLabel>
                        <Select
                          {...field}
                          labelId='demo-simple-select-label'
                          label='User Type'
                        >
                          <MenuItem value={0}>None</MenuItem>
                          {userTypes?.map((type) => (
                            <MenuItem value={type.id} key={type.id}>
                              {type.user_type}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.userType?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          {!isUpdateSupplier && (
            <Grid item xs={6}>
              <Card className='card'>
                <CardHeader title='Password and Security' />
                <CardContent
                  sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                >
                  <Controller
                    name='password'
                    control={control}
                    rules={{
                      required: 'Required',
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                        message:
                          'The password does not meet the minimum password requirements',
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant='outlined'
                        label='Password'
                        type='password'
                        fullWidth
                        error={Boolean(errors.password)}
                        helperText={errors.password?.message}
                      />
                    )}
                  />
                  <Controller
                    name='forceChangePassword'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label='Force User to Change Password'
                      />
                    )}
                  />
                  <Controller
                    name='emailUserNewPassword'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label='Email user new password'
                      />
                    )}
                  />
                  <Divider />
                  <Controller
                    name='requireMFA'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label='Require MFA'
                      />
                    )}
                  />
                  <Controller
                    name='activeUser'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label='Active'
                      />
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
        <Box sx={{ marginTop: 2 }}>
          <Button variant='contained' color='primary' type='submit'>
            Create
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default CreateForm
