import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { client } from 'services/helpers/apiConfig'

const useRfqAssignUsersQuery = () => {
  return useQuery({
    queryKey: ['rfq-assign-users'],
    queryFn: async () => {
      const res = await client.get('user/assign/rfq')

      return res.data.data
    },
  })
}

export { useRfqAssignUsersQuery }
