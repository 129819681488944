//@ts-nocheck
import React, { useState, useEffect } from 'react'
import { Tabs, Tab, AppBar, Box } from '@mui/material'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import TabContainer, { a11yProps } from '../../../../components/tabContainer'
import CorrespondenceTable from '../../../../components/correspondenceTable'

import TabLineItems from './tabLineItems'
import TabHistory from './tabHistory'
import TabQuote from './tabQuote'
import TabFiles from './tabFiles'
import { RfqCorrespondenceResponseDTO } from 'services/hooks/rfq/useRfqCorrespondenceQuery'
import { PoCorrespondenceResponseDTO } from 'services/hooks/purchaseOrders/usePoCorrespondenceQuery'

const RfqTabs = ({ rfq }) => {
  const history = useHistory()
  const [tabValue, setTabValue] = useState(0)
  const { id: rfqId, tabName }: any = useParams()
  const [quotedCorrespondence, setQuotedCorrespondence] = useState()

  useEffect(() => {
    switch (tabName) {
      case 'lines':
        setTabValue(0)
        break
      case 'correspondence':
        setTabValue(1)
        break
      case 'quotes':
        setTabValue(2)
        break
      case 'activity':
        setTabValue(3)
        break
      case 'files':
        setTabValue(4)
        break
      default:
        break
    }
  }, [tabName])

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        history.push(`/rfq/${rfqId}/lines`)
        break
      case 1:
        history.push(`/rfq/${rfqId}/correspondence`)
        break
      case 2:
        history.push(`/rfq/${rfqId}/quotes`)
        break
      case 3:
        history.push(`/rfq/${rfqId}/activity`)
        break
      case 4:
        history.push(`/rfq/${rfqId}/files`)
        break
      default:
        break
    }
  }

  const onSavedQuote = (
    correspondence: RfqCorrespondenceResponseDTO | PoCorrespondenceResponseDTO
  ) => {
    toast.info('Saved as a quote.')
    history.push(`/rfq/${rfqId}/quotes`)
    setQuotedCorrespondence(correspondence)
  }

  return (
    <Box>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='Shipment Tabs'
        >
          <Tab label='Line Items' {...a11yProps(0)} />
          <Tab label='Correspondence' {...a11yProps(1)} />
          <Tab label='Quote' {...a11yProps(2)} />
          <Tab label='History' {...a11yProps(3)} />
          <Tab label='Files' {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      {tabValue === 0 && (
        <TabContainer>
          <TabLineItems />
        </TabContainer>
      )}
      {tabValue === 1 && (
        <TabContainer>
          <CorrespondenceTable onSavedQuote={onSavedQuote} />
        </TabContainer>
      )}
      {tabValue === 2 && (
        <TabContainer>
          <TabQuote quotedCorrespondence={quotedCorrespondence} />
        </TabContainer>
      )}
      {tabValue === 3 && (
        <TabContainer>
          <TabHistory uuid={rfq.uuid} />
        </TabContainer>
      )}
      {tabValue === 4 && (
        <TabContainer>
          <TabFiles uuid={rfq.uuid} />
        </TabContainer>
      )}
    </Box>
  )
}

export default RfqTabs
