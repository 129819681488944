import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

interface BarChartStackProps {
  percentage: number
  stack: {
    color: string
  }
}

const BarChartContainer = styled(Box)`
  display: flex;
`

export const BarChartStack = styled(Box)<BarChartStackProps>`
  width: ${(props) => `${props.percentage}%`};
  background-color: ${(props) => props.stack.color};
  color: white;
  text-align: right;
  padding: 0 3px;
  cursor: pointer;
`

export default BarChartContainer
