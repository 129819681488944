import { ApiMetaDataDTO, ErrorDTO, UserAuthDTO } from 'declarations'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { client, queryClient } from 'services/helpers/apiConfig'

type AuthDataDTO = {
  userName: string
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useForgotPasswordMutation = () =>
  useMutation<unknown, ErrorDTO, AuthDataDTO>({
    mutationFn: async (values: AuthDataDTO) => {
      try {
        const apiString = `/user/passwordReset?username=${values.userName}`

        await client.get<ApiResponseDTO>(apiString)

        return true
      } catch (err) {
        console.log(err)
        throw err
      }
    },
  })
