import { Button, CircularProgress, TextField } from '@mui/material'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useHistory, useLocation } from 'react-router-dom'

import AuthWrapper from '../authWrapper'
import { useLoginMutation } from 'services/hooks/auth/useLoginMutation'

interface IFormInput {
  googleAuthenticatorCode: string
}

type LocationState = {
  pathname: string
  state: {
    userName?: string
    password?: string
  }
}

const GoogleAuthenticator = () => {
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      googleAuthenticatorCode: '',
    },
  })
  const { mutate: loginMutate, status } = useLoginMutation()
  const location: LocationState = useLocation()
  const userName = localStorage.getItem('username')
  const history = useHistory()

  console.log(location.state)

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    loginMutate(
      {
        userName: location.state.userName,
        password: location.state.password,
        googleAuthenticatorCode: formData.googleAuthenticatorCode,
      },
      {
        onSuccess(data, variables, context) {
          console.log(data)

          history.push('/home')
          toast.success('Loggedin successfully!')
        },
      }
    )
  }

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='googleAuthenticatorCode'
          control={control}
          rules={{
            required: 'Enter the code from Google Authenticator',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='standard'
              label='Google Authenticator Code'
              fullWidth
              type='password'
              error={Boolean(errors.googleAuthenticatorCode)}
              helperText={errors.googleAuthenticatorCode?.message}
            />
          )}
        />

        <div className='card-actions'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={status === 'pending'}
            className='btn-submit'
          >
            {status === 'pending' ? (
              <CircularProgress size={20} thickness={5} />
            ) : (
              <span>Submit</span>
            )}
          </Button>
        </div>
      </form>
    </AuthWrapper>
  )
}

export default GoogleAuthenticator
