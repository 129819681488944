import { Box, Typography } from '@mui/material'
import LineTable from './lineTable'

const Contents = ({ data }) => {
  const lineItemNames = Object.getOwnPropertyNames(data.lines)
  let totalPieces = 0

  lineItemNames.map((name) => {
    let lineTotalQuantity = 0
    data.lines[name].map((line) => {
      lineTotalQuantity += line.shipped_quantity
    })

    totalPieces += lineTotalQuantity
  })

  return (
    <Box>
      {lineItemNames.length > 0 &&
        lineItemNames.map((name, index) => (
          <LineTable
            key={'line-item' + index}
            name={name}
            data={data.lines[name]}
          />
        ))}
      <Box sx={{ textAlign: 'right', marginTop: 6 }}>
        <Typography>Total Pieces: {totalPieces}</Typography>
      </Box>
    </Box>
  )
}

export default Contents
