import React, { useState } from 'react'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material'

import SearchBox from './searchBox'
import { PartNumberRuleClassDTO } from 'declarations'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type FilterBoxProps = {
  partNumberClasses: PartNumberRuleClassDTO[]
  onChangeFilter: (partNumberClass: string[], term?: string) => void
}

const FilterBox = ({ partNumberClasses, onChangeFilter }: FilterBoxProps) => {
  const [partNumberClass, setPartNumberClass] = useState([])
  const [term, setTerm] = useState(null)

  const onChangePartNumberClass = (e) => {
    setPartNumberClass(e.target.value)
    onChangeFilter(e.target.value, term)
  }

  const onChangeTerm = (value) => {
    setTerm(value)
    onChangeFilter(partNumberClass, value)
  }

  return (
    <Box display='flex' alignItems='center'>
      <Box mr={3}>
        <SearchBox
          partNumberClassIds={partNumberClass}
          onChangeTerm={onChangeTerm}
        />
      </Box>

      <Typography style={{ marginRight: '1rem' }}>Filter By:</Typography>

      <FormControl variant='outlined' sx={{ margin: 1, minWidth: 200 }}>
        <InputLabel
          htmlFor='mutiple-part-number-class-label-outlined'
          id='mutiple-part-number-class-label'
        >
          Part Number Class
        </InputLabel>
        <Select
          labelId='mutiple-part-number-class-label'
          id='mutiple-part-number-class'
          multiple
          value={partNumberClass}
          onChange={onChangePartNumberClass}
          input={
            <OutlinedInput
              id='mutiple-part-number-class-label-outlined'
              label='Part Number Class'
            />
          }
          MenuProps={MenuProps}
        >
          {partNumberClasses.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default FilterBox
