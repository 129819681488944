import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { HistoryDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

type ResponseDTO = {
  history: HistoryDTO[]
}

const useRequisitionHistoryQuery = (
  uuid: string
): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: ['requisition', 'history', uuid],
    queryFn: async () => {
      try {
        const res = await client.get(`requisitions/history/${uuid}`)

        return res.data.data
      } catch (err) {
        throw err.response.data
      }
    },
  })
}

export { useRequisitionHistoryQuery }
