import React, { useState, useEffect } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import {
  IconButton,
  Checkbox,
  Fab,
  Tooltip,
  Dialog,
  Box,
  Link,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { orange } from '@mui/material/colors'
import { toast } from 'react-toastify'

import SendMessageTemplateModal from '../../../../components/sendMessageTemplateModal'
import ManufacturerSelector from '../../../../components/manufacturerSelector'
import { useRfqReadQuery } from 'services/hooks/rfq/useRfqReadQuery'
import { useManufacturersListQuery } from 'services/hooks/others/useManufacturersListQuery'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useRfqLineUpdateMutation } from 'services/hooks/rfq/useRfqLineUpdateMutation'
import { RfqLineDTO } from 'declarations'
import EditableInput from './editableInput'
import CreateRfqLineModal from './createRfqLineModal'
import { useRfqLineDeleteMutation } from 'services/hooks/rfq/useRfqLineDeleteMutation'
import { useRfqDeleteMutation } from 'services/hooks/rfq/useRfqDeleteMutation'
import { useRfqMessageTemplateQuery } from 'services/hooks/rfq/useRfqMessageTemplateQuery'

const TabLineItems = () => {
  const history = useHistory()
  const { id: rfqId }: any = useParams()
  const { data: rfqData, refetch: refetchRfq } = useRfqReadQuery(rfqId)
  const queryClient = useQueryClient()
  const { mutate: rfqLineUpdateMutation } = useRfqLineUpdateMutation()
  const { mutate: rfqLineDeleteMutation } = useRfqLineDeleteMutation()
  const { mutate: rfqDeleteMutation } = useRfqDeleteMutation()
  const { data: manufacturers } = useManufacturersListQuery()
  const [rows, setRows] = useState<RfqLineDTO[]>([])
  const [rfqConfirmMessage, setRfqConfirmMessage] = useState(null)
  const [showCreateRfqLineModal, setShowCreateRfqLineModal] = useState(false)
  const { data: rfqMessageTemplateData, refetch: refetchRfqMessageTemplate } =
    useRfqMessageTemplateQuery(rfqId, false)
  const [showConfirmMessageModal, setShowConfirmMessageModal] = useState(false)

  useEffect(() => {
    if (rfqData) {
      setRows(rfqData.lines)
    }
  }, [rfqData])

  useEffect(() => {
    if (rfqMessageTemplateData) {
      setRfqConfirmMessage(rfqMessageTemplateData)
    }
  }, [rfqMessageTemplateData])

  const onDeleteLine = async (line) => {
    rfqLineDeleteMutation(
      { uuid: line.uuid },
      {
        onSuccess(data, variables, context) {
          toast.success('Line item is deleted.')
          refetchRfq()
        },
      }
    )
  }

  const onChangeSupplierPartNumberCustom = async (e, uuid) => {
    const rowIndex = rows.findIndex((x) => x.uuid === uuid)
    const arr = [...rows]
    arr[rowIndex].supplier_part_number_custom =
      !arr[rowIndex].supplier_part_number_custom

    rfqLineUpdateMutation(
      {
        uuid: uuid,
        reqBody: {
          supplier_part_number_custom:
            arr[rowIndex].supplier_part_number_custom,
        },
      },
      {
        onSuccess(data, variables, context) {
          setRows([...arr])
        },
        onError(error, variables, context) {
          setRows(rows)
        },
      }
    )
  }

  const onDeleteRfq = async () => {
    rfqDeleteMutation(
      { uuid: rfqData.uuid },
      {
        onSuccess(data, variables, context) {
          toast.success(`Rfq ${rfqData.id} is deleted.`)
          history.replace('/rfq/list')
          queryClient.invalidateQueries({
            queryKey: ['rfq', 'list'],
          })
        },
      }
    )
  }

  const onSubmitRfq = async () => {
    setShowConfirmMessageModal(true)
    refetchRfqMessageTemplate()
  }

  const onCloseRfqConfirmDialog = () => {
    setShowConfirmMessageModal(false)
  }

  const onAddNewLine = () => {
    setShowCreateRfqLineModal(true)
  }

  const columns: GridColDef[] = [
    {
      field: 'quantity',
      headerName: 'Quantity',
      renderCell: (params: GridRenderCellParams) =>
        rfqData?.permissions.can_edit_lines ? (
          <EditableInput
            value={params.row.quantity}
            line={params.row}
            property='quantity'
            type='number'
          />
        ) : (
          params.row.quantity
        ),
      display: 'flex',
    },
    {
      field: 'requisition_detail',
      headerName: 'Requisition Number',
      renderCell: (params: GridRenderCellParams) =>
        params.row.requisition_detail.order_id ? (
          <Link
            component={RouterLink}
            to={`/requisitions/${params.row.requisition_detail.order_id}`}
          >
            {params.row.requisition_detail.order_id}
          </Link>
        ) : (
          ''
        ),
      width: 200,
      display: 'flex',
    },
    {
      field: 'manufacturer',
      headerName: 'Manufacturer',
      renderCell: (params: GridRenderCellParams) =>
        manufacturers && (
          <Box sx={{ py: 1, width: '100%' }}>
            <ManufacturerSelector
              list={manufacturers}
              line={params.row}
              fieldType={
                rfqData?.permissions.can_edit_lines ? 'editable' : 'text'
              }
            />
          </Box>
        ),
      display: 'flex',
      width: 200,
    },
    {
      field: 'part_number',
      headerName: 'Part Number',
      renderCell: (params: GridRenderCellParams) =>
        params.row.requisition_detail ? (
          params.row.item
        ) : (
          <EditableInput
            value={params.row.item}
            line={params.row}
            property='item'
            type='text'
          />
        ),
      display: 'flex',
    },
    {
      field: 'supplier_part_number',
      headerName: 'Supplier Part Number',
      display: 'flex',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {params.row.supplier_part_number_custom ? (
            <EditableInput
              value={params.row.supplier_part_number}
              line={params.row}
              property='supplier_part_number'
              type='text'
            />
          ) : (
            <span>{params.row.supplier_part_number}</span>
          )}
          {rfqData?.permissions.can_edit_lines && (
            <Checkbox
              checked={params.row.supplier_part_number_custom}
              onChange={(e) =>
                onChangeSupplierPartNumberCustom(e, params.row.uuid)
              }
              value='secondary'
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          )}
        </Box>
      ),
    },
    {
      field: 'delete',
      headerName: '',
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          className='btn-delete'
          onClick={() => onDeleteLine(params.row)}
          disabled={!rfqData?.permissions.can_edit_lines}
        >
          <DeleteIcon />
        </IconButton>
      ),
      display: 'flex',
    },
  ]

  const onCloseCreateRfqLineModal = () => {
    setShowCreateRfqLineModal(false)
    refetchRfq()
  }

  return (
    <Box
      className='lines-table'
      sx={{ maxWidth: '1000px', margin: '0 auto 50px;' }}
    >
      <Box sx={{ height: 400 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
          getRowId={(row) => row.rfq_line_id}
          getRowHeight={() => 'auto'}
        />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          right: 20,
          bottom: 20,
          display: 'flex',
          gap: '10px',
        }}
      >
        <Tooltip title='Delete RFQ' placement='top'>
          <span>
            <Fab
              size='small'
              color='primary'
              aria-label='add'
              className='btn-refresh'
              onClick={onDeleteRfq}
              disabled={!rfqData?.permissions?.can_delete}
            >
              <DeleteIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title='Send' placement='top'>
          <span>
            <Fab
              size='small'
              aria-label='add'
              color='primary'
              className='btn-submit'
              onClick={onSubmitRfq}
              disabled={!rfqData?.permissions?.can_submit}
              sx={{
                color: '#fff',
                backgroundColor: orange[500],
                '&:hover': {
                  backgroundColor: orange[700],
                },
              }}
            >
              <ArrowForwardIosIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title='Add New Line Item' placement='top'>
          <span>
            <Fab
              size='small'
              color='secondary'
              aria-label='add'
              className='btn-add'
              onClick={onAddNewLine}
              disabled={!rfqData?.permissions?.can_edit_lines}
            >
              <AddIcon />
            </Fab>
          </span>
        </Tooltip>
      </Box>
      <Dialog
        onClose={onCloseRfqConfirmDialog}
        open={!!showConfirmMessageModal}
      >
        {rfqConfirmMessage && (
          <SendMessageTemplateModal
            message={rfqConfirmMessage}
            id={rfqData?.id}
            onCloseDialog={onCloseRfqConfirmDialog}
            modalFor='rfq'
          />
        )}
      </Dialog>
      {rfqData && (
        <CreateRfqLineModal
          open={showCreateRfqLineModal}
          handleClose={onCloseCreateRfqLineModal}
          rfqId={rfqData.id}
        />
      )}
    </Box>
  )
}

export default TabLineItems
