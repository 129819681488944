import React from 'react'
import { Grid, Card, CardHeader, CardContent, Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import SupplierProfileForm from './supplierProfileFormNew'
import UserProfileForm from './userProfileForm'
import SettingsForm from './settingsForm'
import PaymentForm from './paymentForm'
import { useReadSupplierQuery } from 'services/hooks/supplier/useReadSupplierQuery'

const SupplierUpdate = () => {
  const { uuid }: any = useParams()
  const { data: supplier } = useReadSupplierQuery(uuid)

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Card sx={{ height: '100%' }}>
            <CardHeader title='Supplier Profile' />
            <CardContent>
              {supplier && (
                <SupplierProfileForm
                  initialValues={supplier.profile}
                  uuid={uuid}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Card sx={{ height: '100%' }}>
                    <CardHeader title='Associated User Profile' />
                    <CardContent>
                      {supplier && (
                        <UserProfileForm
                          linkedUser={supplier.linked_user}
                          uuid={uuid}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card sx={{ height: '100%' }}>
                    <CardHeader title='Settings' />
                    <CardContent>
                      <SettingsForm />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Card sx={{ height: '100%' }}>
                    <CardHeader title='Payment' />
                    <CardContent>
                      {supplier && (
                        <PaymentForm initialValues={supplier.payment} />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card sx={{ height: '100%' }}>
                    <CardHeader title='Documents' />
                    <CardContent></CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SupplierUpdate
