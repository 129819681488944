import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const CorrespondenceContainer = styled(Box)`
  position: relative;

  .ReactTable {
    max-height: 500px;
  }
`

export default CorrespondenceContainer
