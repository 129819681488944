import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type Params = {
  status_id: number
  requisition_uuids: string
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRequisitionStatusBatchUpdateMutation = () =>
  useMutation<unknown, ErrorDTO, Params>({
    mutationFn: async (values: Params) => {
      try {
        const res = await client.put(
          '/requisitions/status/update/batch',
          values
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
