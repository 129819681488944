import { useMutation } from '@tanstack/react-query'
import { client, queryClient } from 'services/helpers/apiConfig'
import { clearToken } from 'services/helpers/utility'

export const useLogoutMutation = () =>
  useMutation<string, Error, { token?: string }>({
    mutationFn: async ({ token }) => {
      try {
        const data = await client.delete(
          token ? `/user/deleteToken/?key=${token}` : '/user/deleteToken/'
        )

        if (data.status === 202) {
          clearToken()
          await queryClient.invalidateQueries({ queryKey: ['get-user-info'] })
          return 'success'
        } else {
          throw { message: 'Logout failed' }
        }
      } catch (err) {
        throw err
      }
    },
  })
