import React from 'react'
import { Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

const LineTable = ({ name, data }) => {
  let lineTotal = 0
  data.map((item) => {
    lineTotal += parseInt(item.shipped_quantity, 10)
  })

  const columns: GridColDef[] = [
    {
      field: 'shipped_quantity',
      headerName: 'Quantity',
      flex: 1,
    },
    {
      field: 'requisition.order_id',
      headerName: 'Requisition Number',
      renderCell: (params: GridRenderCellParams) => (
        <Link
          component={RouterLink}
          to={`/requisitions/${params.row.requisition.order_id}`}
        >
          Requisition {params.row.requisition.order_id}
        </Link>
      ),
      flex: 1,
      valueGetter: (value, row) => row.requisition.order_id,
    },
    {
      field: 'po_id',
      headerName: 'Purchase Order',
      renderCell: (params: GridRenderCellParams) => (
        <Link component={RouterLink} to={`/po/${params.row.po_id}`}>
          PO {params.row.po_id}
        </Link>
      ),
      valueGetter: (value, row) => row.po_id,
      flex: 1,
    },
  ]

  return (
    <div className='lineItem'>
      <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold' }}>
        {data[0].supplier_part_number_custom
          ? `${data[0].supplier_part_number} (${name})`
          : name}
      </Typography>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        getRowId={(row) => row.shipment_line_id}
      />

      <p>Line Total: {lineTotal}</p>
    </div>
  )
}

export default LineTable
