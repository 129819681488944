import React from 'react'
import { Dialog, DialogTitle, DialogContent } from '@mui/material'

type SimpleDialogProps = {
  children?: React.ReactNode
  onClose: () => void
  open: boolean
  title?: string
}

const SimpleDialog = ({
  children,
  onClose,
  open,
  title,
}: SimpleDialogProps) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      maxWidth='lg'
      fullWidth
    >
      {title && <DialogTitle id='simple-dialog-title'>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

export default SimpleDialog
