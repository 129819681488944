import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ApiMetaDataDTO, PartNumberRuleDTO, ShipmentDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type ResponseDTO = {
  shipments: ShipmentDTO[]
  total_rows: number
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useShipmentRecevingListQuery = (): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: ['shipment', 'receiving', 'list'],
    queryFn: async () => {
      try {
        const res = await client.get<ApiResponseDTO>(`/shipment/receiving/list`)
        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
}
