import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { client, queryClient } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  carrier?: number
  tracking_number: string
  lines: {
    quantity: number
    purchase_order_line_uuids: string
  }[]
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useShipmentCreateMutation = () =>
  useMutation<unknown, ErrorDTO, ParamsDTO>({
    mutationFn: async (params: ParamsDTO) => {
      try {
        const res = await client.post('shipment/create', params)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
