import { ApiMetaDataDTO, SupplierDTO } from 'declarations'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

type ResponseDTO = {
  code: number
  data: SupplierDTO
  api_metadata: ApiMetaDataDTO
}

type ParamsDTO = {
  carrierId: string
}

export const useCarriersServicesListQuery = (
  params: ParamsDTO,
  enabled = true
): UseQueryResult<any, unknown> => {
  return useQuery({
    queryKey: ['carriers', 'services', 'list'],
    queryFn: async () => {
      try {
        const res = await client.get<ResponseDTO>(`/carriers/services/list`, {
          params: {
            carrier: params.carrierId,
          },
        })

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
}
