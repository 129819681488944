import { Box, Typography } from '@mui/material'
import ShipmentsStatus from 'components/shipmentsStatus'
import { useRequisitionShipmentsQuery } from 'services/hooks/requisition/useRequisitionShipmentsQuery'
import { useShipmentTrackingQuery } from 'services/hooks/shipment/useShipmentTrackingQuery'

type TabShipmentsProps = {
  uuid: string
}

const TabShipments = ({ uuid }: TabShipmentsProps) => {
  const { data: shipmentsData } = useRequisitionShipmentsQuery(uuid)
  const { data: shipmentDetailsData } = useShipmentTrackingQuery(
    {
      po_uuid: uuid,
      shipments: shipmentsData?.shipments,
    },
    {
      enabled: !!shipmentsData,
    }
  )

  return (
    <Box>
      <Typography variant='h5' gutterBottom>
        Shipments
      </Typography>
      {shipmentDetailsData?.length > 0 ? (
        <ShipmentsStatus shipments={shipmentDetailsData} />
      ) : (
        <Typography>No shipments found</Typography>
      )}
    </Box>
  )
}

export default TabShipments
