import React, { useState, useCallback } from 'react'
import {
  TextField,
  Autocomplete,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material'
import _debounce from 'lodash/debounce'

import { FilterSelect } from 'components/listTableFilterBox'
import { usePoSearchQuery } from 'services/hooks/purchaseOrders/usePoSearchQuery'
import { usePoAssignUsersQuery } from 'services/hooks/purchaseOrders/usePoAssignUsersQuery'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'
import { usePoStatusListQuery } from 'services/hooks/purchaseOrders/usePoStatusListQuery'

const DEBOUNCE_TIME_MS = 1000

type AutocompleteSearchProps = {
  onChangeFilter: (target: string, value: string) => void
  filterValues: {
    statusIds: string[]
    users: string[]
    term: string
    supplierIds: string[]
  }
}

const Filters = ({ onChangeFilter, filterValues }: AutocompleteSearchProps) => {
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [term, setTerm] = useState('')
  const { data: poAssignUsersList } = usePoAssignUsersQuery()
  const { data: statusList } = usePoStatusListQuery()
  const { data: suppliersList } = useSuppliersQuery()
  const { data: poSearchResult, isFetching } = usePoSearchQuery({
    term,
    assignee: filterValues.users.join(),
    status: filterValues.statusIds.join(),
    suppliers: filterValues.supplierIds.join(),
  })

  const debouncedUpdateData = useCallback(
    _debounce((value: string) => {
      setTerm(value)
    }, DEBOUNCE_TIME_MS),
    []
  )

  const onChangeSearchField = async (e) => {
    setSearchValue(e.target.value)
    debouncedUpdateData(e.target.value)
  }

  const onChangeValue = (event, value) => {
    onChangeFilter('term', value)
  }

  return (
    <Box
      sx={{
        p: 1.5,
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <Autocomplete
        id='asynchronous-demo'
        style={{ width: 200 }}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        options={poSearchResult?.search.terms || []}
        loading={isFetching}
        onChange={onChangeValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Search'
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetching ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            value={searchValue}
            onChange={onChangeSearchField}
          />
        )}
      />
      <Typography>Filter By:</Typography>
      {poAssignUsersList && (
        <FilterSelect
          label='Assignee'
          filterType='users'
          filterList={poAssignUsersList}
          selectValues={filterValues.users}
          onChange={onChangeFilter}
        />
      )}
      {suppliersList && (
        <FilterSelect
          label='Supplier'
          filterType='supplierIds'
          filterList={suppliersList}
          selectValues={filterValues.supplierIds}
          onChange={onChangeFilter}
        />
      )}
      {statusList && (
        <FilterSelect
          label='Status'
          filterType='statusIds'
          filterList={statusList}
          selectValues={filterValues.statusIds}
          onChange={onChangeFilter}
        />
      )}
    </Box>
  )
}

export default Filters
