import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ThreadDraftUpdateParamsDTO = {
  uuid: string
  reqBody: {
    subject?: string
    body?: string
    author_id?: number
    is_internal?: boolean
    is_draft?: boolean
    is_quote_attached?: boolean
  }
}

export const useThreadDraftUpdateMutation = () =>
  useMutation<any, ErrorDTO, ThreadDraftUpdateParamsDTO>({
    mutationFn: async (params) => {
      try {
        const res = await client.put(
          `/thread/draft/update/${params.uuid}`,
          params.reqBody
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
