import React, { useState } from 'react'
import Chart from 'react-apexcharts'

interface DonutChartProps {
  chartData: {
    labels: string[]
    colors: string[]
    series: number[]
  }
}

const DonutChart: React.FC<DonutChartProps> = ({ chartData }) => {
  const [options] = useState({
    chart: {
      width: '100%',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    labels: chartData.labels,
    colors: chartData.colors,
  })

  return (
    <div id='chart'>
      <Chart options={options} series={chartData.series} type='donut' />
    </div>
  )
}

export default DonutChart
