import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type UpdateRfqDTO = {
  uuid?: string
  reqBody: {
    assignee?: number | string
    status_id?: number
    id?: string[]
  }
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqUpdateMutation = () =>
  useMutation<unknown, ErrorDTO, UpdateRfqDTO>({
    mutationFn: async (values: UpdateRfqDTO) => {
      try {
        let res
        if (values.uuid) {
          res = await client.put<ApiResponseDTO>(
            `/rfq/update/${values.uuid}`,
            values.reqBody
          )
        } else {
          res = await client.put<ApiResponseDTO>('/rfq/update', values.reqBody)
        }

        // toast.success('Supplier profile is updated')

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
