import React, { useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import {
  Box,
  Grid,
  Tooltip,
  TextField,
  Checkbox,
  IconButton,
  Card,
  Snackbar,
  Alert,
  Typography,
} from '@mui/material'

import leadTimeValues from '../../../../../utils/leadTimes'

import EditableSelect from './editableSelect'
import { useRfqQuoteCreateMutation } from 'services/hooks/rfq/useRfqQuoteCreateMutation'
import { useQueryClient } from '@tanstack/react-query'

const NewQuoteLine = ({ manufacturerList, rfqUUID }) => {
  const [inputValues, setInputValues] = useState({
    quantity: 0,
    part_number: '',
    price: 0,
    manufacturer_id: '',
    lead_time: '',
    supplier_part_number: '',
    supplier_part_number_custom: false,
  })
  const [inputErrors, setInputErrors] = useState({
    quantityError: false,
    manufacturerError: false,
    partNumberError: false,
    priceError: false,
  })
  const [formInvalid, setFormInvalid] = useState(false)
  const { mutate: rfqQuoteCreateMutation } = useRfqQuoteCreateMutation()
  const queryClient = useQueryClient()

  const onSaveQuotedItem = async () => {
    if (
      inputValues.quantity > 0 &&
      inputValues.part_number !== '' &&
      inputValues.price > 0 &&
      inputValues.manufacturer_id !== '' &&
      inputValues.lead_time !== ''
    ) {
      rfqQuoteCreateMutation(
        {
          rfq_uuid: rfqUUID,
          item: inputValues.part_number,
          price: inputValues.price,
          quantity: inputValues.quantity,
          manufacturer_id: parseInt(inputValues.manufacturer_id, 10),
          lead_time: inputValues.lead_time,
          supplier_part_number: inputValues.supplier_part_number,
          supplier_part_number_custom: inputValues.supplier_part_number_custom,
        },
        {
          onSuccess(data, variables, context) {
            toast.success('New quoted item is created')
            setInputValues({
              quantity: 0,
              part_number: '',
              supplier_part_number: '',
              supplier_part_number_custom: false,
              price: 0,
              manufacturer_id: '',
              lead_time: '',
            })
            queryClient.invalidateQueries({
              queryKey: ['rfq', 'quote', 'read'],
            })
          },
        }
      )
    } else {
      setFormInvalid(true)
    }
  }

  const onInputUpdate = (event) => {
    const { name, value } = event.target
    setFormInvalid(false)
    switch (name) {
      case 'quantity':
      case 'price':
        if (parseInt(value, 10) <= 0) {
          setInputErrors({ ...inputErrors, [`${name}Error`]: true })
        } else {
          setInputValues({ ...inputValues, [name]: value })
        }
        break
      default:
        setInputValues({ ...inputValues, [name]: value })
        break
    }
  }

  const onChangeSupplierCustom = () => {
    setInputValues({
      ...inputValues,
      supplier_part_number_custom: !inputValues.supplier_part_number_custom,
    })
  }

  const updateSelectValue = (selectName, value) => {
    setInputValues({ ...inputValues, [selectName]: value })
    setFormInvalid(false)
  }

  return (
    <Card
      className={`${formInvalid ? 'invalid' : ''} quoted-item new`}
      sx={{ p: 2.5, position: 'relative' }}
      variant='outlined'
    >
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={6} sm={4} md={3} className='field'>
          <Tooltip
            open={inputErrors.quantityError}
            onClose={() => {
              setInputErrors({ ...inputErrors, quantityError: false })
            }}
            title='Quantity must bigger than 0.'
          >
            <TextField
              required
              name='quantity'
              label='Quantity'
              value={inputValues.quantity}
              onChange={onInputUpdate}
              variant='outlined'
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6} sm={4} md={3} className='field'>
          <TextField
            required
            name='part_number'
            label='Part Number'
            value={inputValues.part_number}
            onChange={onInputUpdate}
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} className='field'>
          <TextField
            required
            name='supplier_part_number'
            label='Supplier Part Number'
            value={inputValues.supplier_part_number}
            onChange={onInputUpdate}
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} className='field'>
          <Typography>Custom</Typography>
          <Checkbox
            checked={inputValues.supplier_part_number_custom}
            onChange={onChangeSupplierCustom}
            value='secondary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} className='field'>
          <EditableSelect
            quoteLineUUID=''
            selectList={manufacturerList}
            selectValue={inputValues.manufacturer_id}
            selectType='manufacturer_id'
            isNewLine
            updateSelectValue={updateSelectValue}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} className='field'>
          <EditableSelect
            quoteLineUUID=''
            selectList={leadTimeValues}
            selectValue={inputValues.lead_time}
            selectType='lead_time'
            isNewLine
            updateSelectValue={updateSelectValue}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} className='field'>
          <Tooltip
            open={inputErrors.priceError}
            onClose={() => {
              setInputErrors({ ...inputErrors, priceError: false })
            }}
            title='Price must bigger than 0.'
          >
            <TextField
              required
              name='price'
              label='Price'
              value={inputValues.price}
              onChange={onInputUpdate}
              variant='outlined'
              fullWidth
            />
          </Tooltip>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={formInvalid}
          onClose={() => setFormInvalid(false)}
          autoHideDuration={6000}
        >
          <Alert
            onClose={() => setFormInvalid(false)}
            severity='error'
            variant='filled'
            sx={{ width: '100%' }}
          >
            New quote form is not valid
          </Alert>
        </Snackbar>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          position: 'absolute',
          right: 20,
          bottom: 20,
        }}
      >
        <IconButton onClick={onSaveQuotedItem}>
          <SaveIcon />
        </IconButton>
      </Box>
    </Card>
  )
}

export default NewQuoteLine
