import { ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  uuids: string[]
  updateBody: {
    manufacturer_id: number
  }
}

export const useMnvUpdateMutation = () =>
  useMutation<unknown, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        for (const uuid of values.uuids) {
          await client.put(
            `/manufacturer_name_variant/update/${uuid}`,
            values.updateBody
          )
        }

        toast.success('Manufacturer updated for selected name variants')
        return 'success'
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
