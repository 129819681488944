import { Box, Tooltip } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid'
import EditableInput from './editableInput'
import { useEffect, useState } from 'react'
import { InventoryLineDTO } from 'services/hooks/requisition/useRequisitionInventoryQuery'

export type InventoryTableLineDTO = InventoryLineDTO & {
  id: number
  maxQuantity: number
}

type InventoryTableProps = {
  data: InventoryLineDTO[]
  onSelectLine: (lines: InventoryTableLineDTO[]) => void
}

const InventoryTable = ({ data, onSelectLine }: InventoryTableProps) => {
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])
  const [rows, setRows] = useState<InventoryTableLineDTO[]>(
    data.map((x, index) => ({
      ...x,
      id: index,
      maxQuantity: x.quantity_required + (x.quantity_available || 0),
    }))
  )

  const columns: GridColDef[] = [
    {
      field: 'quantity',
      headerName: 'Quantity',
      renderCell: (params: GridRenderCellParams) =>
        params.row.quantity_available !== 0 ? (
          <EditableInput line={params.row} onChange={onChangeQuantity} />
        ) : (
          0
        ),
      display: 'flex',
    },
    {
      field: 'mfg',
      headerName: 'Mfg',
      renderCell: (params: GridRenderCellParams) =>
        params.row.manufacturer ? (
          <Tooltip title={params.row.manufacturer.name}>
            <span>{params.row.manufacturer.abbreviation}</span>
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      field: 'quantity_available',
      headerName: 'Quantity Available',
    },
    { field: 'part_number', headerName: 'Part Number' },
    { field: 'source', headerName: 'Service' },
    { field: 'lead_time', headerName: 'Lead Time' },
    {
      field: 'dataRetrieved',
      headerName: 'Data Retrieved',
      renderCell: (params: GridRenderCellParams) =>
        params.row.fetched ? params.row.fetched.human_date.relative.long : '',
    },
  ]

  const onChangeQuantity = (updatedLine) => {
    const updatedRows = [...rows]
    updatedRows[updatedLine.id] = { ...updatedLine }
    setRows(updatedRows)
  }

  useEffect(() => {
    const selectedRows = rowSelectionModel.map((id) => rows[id])
    onSelectLine(selectedRows)
  }, [rowSelectionModel])

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel)
        }}
        rowSelectionModel={rowSelectionModel}
      />
    </Box>
  )
}

export default InventoryTable
