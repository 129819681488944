import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ApiMetaDataDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type SearchSnvQueryParamsDTO = {
  term: string
}

type SearchResultDTO = {
  search: string[]
}
type ApiResponseDTO = {
  code: number
  data: SearchResultDTO
  api_metadata: ApiMetaDataDTO
}

const useSnvSearchQuery = (
  params: SearchSnvQueryParamsDTO
): UseQueryResult<SearchResultDTO> => {
  return useQuery({
    queryKey: ['supplier_name_variant', 'search', params.term],
    queryFn: async () => {
      try {
        const reqParams = {
          term: params.term,
        }

        const res = await client.get<ApiResponseDTO>(
          `/supplier_name_variant/search`,
          {
            params: reqParams,
          }
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    enabled: false,
  })
}

export { useSnvSearchQuery }
