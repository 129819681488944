import {
  useQuery,
  keepPreviousData,
  UseQueryResult,
} from '@tanstack/react-query'
import { NameVariantDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

type RequisitionListQueryParamsDTO = {
  pageIndex: number
  rowsCount: number
  filterValues?: {
    showOnlyUnassignedName?: boolean
    service?: string
  }
  searchTerm?: string
}

type ResponseDTO = {
  supplier_name_variants: NameVariantDTO[]
  total_rows: number
}

const useSnvListQuery = (
  params: RequisitionListQueryParamsDTO
): UseQueryResult<ResponseDTO> => {
  return useQuery({
    queryKey: [
      'supplier_name_variant',
      'list',
      params.pageIndex,
      params.rowsCount,
      params.filterValues?.showOnlyUnassignedName,
      params.filterValues?.service,
      params.searchTerm,
    ],
    queryFn: async () => {
      const reqParams = {
        page: params.pageIndex,
        rows: params.rowsCount,
        unassociated: params.filterValues?.showOnlyUnassignedName,
        service: params.filterValues.service,
        search_term: params.searchTerm,
      }

      const res = await client.get(`/supplier_name_variant/list`, {
        params: reqParams,
      })

      return res.data.data
    },
    placeholderData: keepPreviousData,
    staleTime: 5000,
  })
}

export { useSnvListQuery }
