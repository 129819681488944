import React, { useState, useEffect } from 'react'
import {
  Box,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Card,
  CardContent,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'

import { OrangeButton } from 'components/common/button'
import { useCreatePartNumberRuleMutation } from 'services/hooks/partNumberRules/useCreatePartNumberRuleMutation'

type TableActionsProps = {
  onDelete: () => void
  bulkActionDisabled: boolean
}

const TableActions = ({ onDelete, bulkActionDisabled }: TableActionsProps) => {
  const [action, setAction] = useState('')
  const [openCreatePartNumberModal, setOpenCreatePartNumberModal] =
    useState(false)
  const [partNumberName, setPartNumberName] = useState('')
  const history = useHistory()
  const { mutate: createPartNumberRuleMutation } =
    useCreatePartNumberRuleMutation()

  const onChangeBulkAction = (e) => {
    setAction(e.target.value)
    if (e.target.value === 'delete') {
      onDelete()
    }
  }

  useEffect(() => {
    if (bulkActionDisabled) {
      setAction('')
    }
  }, [bulkActionDisabled])

  const onCloseModal = () => {
    setPartNumberName('')
    setOpenCreatePartNumberModal(false)
  }

  const onChangeName = (e) => {
    setPartNumberName(e.target.value)
  }

  const onCreatePartNumberRule = async () => {
    createPartNumberRuleMutation(
      { name: partNumberName },
      {
        onSuccess(data, variables, context) {
          history.push(`/admin/supplier_part_number_rules/update/${data.uuid}`)

          onCloseModal()
        },
      }
    )
  }

  return (
    <Card
      sx={{
        position: 'fixed',
        bottom: 30,
        right: 24,
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <FormControl
            variant='outlined'
            sx={{
              margin: 1,
              minWidth: 200,
            }}
          >
            <InputLabel id='bulk-actions-select-label'>Bulk Actions</InputLabel>
            <Select
              labelId='bulk-actions-select-label'
              id='bulk-actions-select-outlined'
              value={action}
              onChange={onChangeBulkAction}
              label='Bulk Actions'
              disabled={bulkActionDisabled}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value='delete'>Delete</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Fab
            color='primary'
            aria-label='check'
            style={{ marginRight: '1rem' }}
          >
            <CheckIcon />
          </Fab>
          <Fab
            color='primary'
            aria-label='add'
            onClick={() => {
              setOpenCreatePartNumberModal(true)
            }}
          >
            <AddIcon />
          </Fab>
        </Box>
        <Dialog
          open={openCreatePartNumberModal}
          onClose={onCloseModal}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            Create a new supplier part number rule
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              label='Name'
              fullWidth
              value={partNumberName}
              onChange={onChangeName}
            />
          </DialogContent>
          <DialogActions>
            <OrangeButton
              onClick={onCloseModal}
              className='btn-orange'
              color='primary'
              variant='contained'
            >
              Cancel
            </OrangeButton>
            <Button
              color='primary'
              variant='contained'
              disabled={partNumberName === ''}
              onClick={onCreatePartNumberRule}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  )
}

export default TableActions
