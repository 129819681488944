import { styled } from '@mui/material/styles'
import { blue, indigo } from '@mui/material/colors'

const MessageBlockContainer = styled('div')`
  outline: none;

  &.unread-status {
    background-color: ${blue[100]};
  }

  .message-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    outline: none;

    p {
      &.name {
        font-weight: bold;

        span.message-to {
          font-weight: normal;
          margin: 0 10px;
          color: ${indigo[500]};
        }
      }
    }
  }

  .message-content {
    padding-left: 0.5rem;

    .attachments {
      border-top: 1px solid lightgrey;

      a {
        cursor: pointer;
      }
    }
  }
`

export default MessageBlockContainer
