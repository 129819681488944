import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { File } from 'components/filesTab/useTabFiles'
import { useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'

import { client } from 'services/helpers/apiConfig'

type FilePreviewModalContentProps = {
  previewFile: File
}

type FormValues = {
  documentType: string
}

const FilePreviewModalContent = ({
  previewFile,
}: FilePreviewModalContentProps) => {
  const formik = useFormik<FormValues>({
    initialValues: {
      documentType: '',
    },
    onSubmit: (values) => {},
  })

  console.log(previewFile)

  const { data, isFetching } = useQuery({
    queryKey: [`file-${previewFile.uuid}`],
    queryFn: () =>
      client.get(`/file/read/${previewFile.uuid}`).then((res) => res.data.data),
  })

  console.log(data)

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          Preview
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h5'>{previewFile.filename}</Typography>
          <FormControl
            fullWidth
            variant='outlined'
            error={
              formik.touched.documentType && Boolean(formik.errors.documentType)
            }
          >
            <InputLabel id='documentType-select-label'>
              Document Type
            </InputLabel>
            <Select
              label='Document Type'
              labelId='documentType-select-label'
              id='documentType'
              name='documentType'
              value={formik.values.documentType}
            >
              <MenuItem value={10}>Quote</MenuItem>
              <MenuItem value={20}>Invoice</MenuItem>
              <MenuItem value={30}>Pack Slip</MenuItem>
              <MenuItem value={30}>Other</MenuItem>
            </Select>
            {formik.touched.documentType && formik.errors.documentType && (
              <FormHelperText>{formik.errors.documentType}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FilePreviewModalContent
