import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type RfqQuoteAddParams = {
  uuid: string
  body: any
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqQuoteAddMutation = () =>
  useMutation<any, ErrorDTO, RfqQuoteAddParams>({
    mutationFn: async (params: RfqQuoteAddParams) => {
      try {
        const res = await client.post(
          `rfq/quote/add/${params.uuid}`,
          params.body
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
