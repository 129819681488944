import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { RfqDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

const useFileUrlQuery = (uuid: string, enabled = true): UseQueryResult<any> => {
  return useQuery({
    queryKey: ['file', 'read', 'geturl', uuid],
    queryFn: async () => {
      try {
        const res = await client.get(`file/read/${uuid}/geturl`)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    enabled,
  })
}

export { useFileUrlQuery }
