import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { useHistory } from 'react-router-dom'

import { OrangeButton } from 'components/common/button'
import { useRfqCreateMutation } from 'services/hooks/rfq/useRfqCreateMutation'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'

const CreateRfqModal = ({ open, handleClose }) => {
  const [supplierId, setSupplierId] = useState('')
  const history = useHistory()
  const { mutate: rfqCreateMutation } = useRfqCreateMutation()
  const { data: suppliersList } = useSuppliersQuery()

  const handleChange = (event) => {
    setSupplierId(event.target.value)
  }

  const onClose = () => {
    setSupplierId('')
    handleClose()
  }

  const handleCreate = async () => {
    rfqCreateMutation(
      {
        supplier_id: parseInt(supplierId, 10),
      },
      {
        onSuccess(data, variables, context) {
          setSupplierId('')
          handleClose()
          history.push(`/rfq/${data.rfq_id}/lines`)
        },
      }
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={open}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>Create a new RFQ</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel id='supplier-select-label'>Supplier</InputLabel>
          <Select
            labelId='supplier-select-label'
            id='supplier-select'
            value={supplierId}
            onChange={handleChange}
            label='Supplier'
          >
            {suppliersList.map((supplier) => (
              <MenuItem value={supplier.id} key={supplier.id}>
                {supplier.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <OrangeButton
          variant='contained'
          onClick={onClose}
          className='btn-orange'
        >
          Cancel
        </OrangeButton>
        <Button
          variant='contained'
          onClick={handleCreate}
          color='primary'
          disabled={supplierId === ''}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateRfqModal
