import { Box, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { PriceHistoryDTO } from 'declarations'
import NumberFormat from 'react-number-format'

import { useRequisitionPriceHistoryQuery } from 'services/hooks/requisition/useRequisitionPriceHistoryQuery'

type TabPricingProps = {
  uuid: string
}

const TabPricing = ({ uuid }: TabPricingProps) => {
  const { data: requisitionPriceHistory } =
    useRequisitionPriceHistoryQuery(uuid)
  const propertyNames =
    typeof requisitionPriceHistory?.price_history === 'object' &&
    requisitionPriceHistory?.price_history !== null &&
    !Array.isArray(requisitionPriceHistory?.price_history)
      ? Object.getOwnPropertyNames(requisitionPriceHistory?.price_history)
      : []

  const columns: GridColDef[] = [
    {
      field: 'date_bought',
      headerName: 'Date',
      valueGetter: (value: any, row) => value.formatted_date,
      flex: 1,
    },
    { field: 'order_qty', headerName: 'Quantity', flex: 1 },
    { field: 'company_name', headerName: 'Supplier', flex: 1 },
    {
      field: 'unit_cost',
      headerName: 'Unit Cost',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <NumberFormat
          value={params.value}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          decimalScale={3}
        />
      ),
    },
  ]

  const renderTable = (historyData: PriceHistoryDTO[]) => {
    const rows = historyData.map((x, index) => ({ ...x, id: index }))

    return (
      <Box sx={{ height: 250 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          getRowId={(row) => row.id}
        />
      </Box>
    )
  }

  return (
    <Box>
      <Typography variant='h5' gutterBottom fontWeight='bold'>
        Purchase History
      </Typography>
      {propertyNames.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {requisitionPriceHistory &&
            propertyNames.map((propertyName, i) => (
              <Box key={'purchase-history' + propertyName}>
                <Typography gutterBottom>{propertyName}</Typography>

                {renderTable(
                  requisitionPriceHistory.price_history[propertyName]
                )}
              </Box>
            ))}{' '}
        </Box>
      ) : (
        <Typography>No Purchase History Found</Typography>
      )}
    </Box>
  )
}

export default TabPricing
