//@ts-nocheck
import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, Typography, Box } from '@mui/material'

import TimelineSlider from '../../../../components/timelineSlider'

const Overview = ({ data, trackingNumber }) => {
  const [contentsSum, setContentsSum] = useState(0)

  useEffect(() => {
    const tracks = Object.values(data.lines)
    let sum = contentsSum

    tracks.map((track) => {
      track.map((line) => {
        sum += parseInt(line.shipped_quantity, 10)
        return line
      })

      setContentsSum(sum)
      return track
    })
  }, [])

  return (
    <Box sx={{ p: 2.5 }}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Box sx={{ p: 2.5, backgroundColor: '#888', textAlign: 'center' }}>
            <Typography variant='h6' gutterBottom>
              {trackingNumber}
            </Typography>
            <img
              src={data.carrier_logo_url}
              alt='logo'
              style={{ width: '100px' }}
            />
            <Typography>{data.service.description}</Typography>
            <a href={data.track_url} target='_blank'>
              Track via {data.carrier}
            </a>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <TimelineSlider timeline={data.timeline} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Card className='card'>
                <CardContent className='card-content'>
                  <h5 className='title'>Shipped Form</h5>
                  <div className='summary'>
                    <h3>{data.from_address.postal_code}</h3>
                    <p>
                      {data.from_address.city},{' '}
                      {data.from_address.state_province},{' '}
                      {data.from_address.country}
                    </p>
                    <img src={data.from_address.image_url} alt='shipped from' />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card className='card'>
                <CardContent className='card-content'>
                  <h5 className='title'>Shipped To</h5>
                  <div className='summary'>
                    <h3>{data.to_address.postal_code}</h3>
                    <p>
                      {data.to_address.city}, {data.to_address.state_province},{' '}
                      {data.to_address.country}
                    </p>
                    <img src={data.to_address.image_url} alt='shipped to' />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card className='card'>
                <CardContent className='card-content'>
                  <h5 className='title'>Weight</h5>
                  <div className='summary'>
                    <h1>{data.dimensions.weight}</h1>
                    <p>POUNDS</p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card className='card'>
                <CardContent className='card-content'>
                  <h5 className='title'>Contents</h5>
                  <div className='summary'>
                    <h1>{contentsSum}</h1>
                    <p>ITEMS</p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Overview
