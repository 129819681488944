import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Popover,
  Typography,
  Fab,
  Card,
  CardContent,
  Link,
  Paper,
  Tooltip,
  Button,
} from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridPagination,
  GridRowSelectionModel,
} from '@mui/x-data-grid'

import Dialog from '../../../components/common/dialog'

import TableBulkActions from './components/tableBulkActions'
import RequisitionReadModal from './readModal'
import CreateRequisitionModal from 'components/createRequisitionModal'
import { useRequisitionListQuery } from 'services/hooks/requisition/useRequisitionListQuery'
import { useRequisitionStatusListQuery } from 'services/hooks/requisition/useRequisitionStatusListQuery'
import { useRequisitionAssignUsersQuery } from 'services/hooks/requisition/useRequisitionAssignUsersQuery'
import RequisitionQuickGlance from 'components/tables/requisitionQuickGlance'
import BarChart from 'components/barChart'
import QuantityChart from 'components/tables/quantityChart'
import StatusLabel from 'components/tables/statusLabel'
import AssignDropdown from 'components/assignDropdown'
import DataGridCustomPagination from 'components/dataGridCustomPagination'
import Filters from './filters'

const RequisitionsList = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverId, setPopoverId] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const { id: requisitionId }: any = useParams()
  const [filterValues, setFilterValues] = useState({
    statuses: [],
    users: [],
    term: '',
  })
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const [selectedOrderIds, setSelectedOrderIds] = useState([])

  const [glancePopoverData, setGlancePopoverData] = useState(null)
  const [glancePopoverAnchorEl, setGlancePopoverAnchorEl] = useState(null)
  const [isCreateRequisition, setIsCreateRequisition] = useState<boolean>(false)
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25,
  })
  const { data: requisitionsListData, isFetching: isFetchingRequisitions } =
    useRequisitionListQuery({
      pageIndex: paginationModel.page,
      rowsCount: paginationModel.pageSize,
      filterValues,
    })
  const { data: requisitionAssignUsersList } = useRequisitionAssignUsersQuery()
  const { data: statusList } = useRequisitionStatusListQuery()

  const columns: GridColDef[] = [
    {
      field: 'order_id',
      headerName: 'Requisition ID',
      renderCell: (params: GridRenderCellParams) => (
        <Link
          component='button'
          variant='body2'
          onClick={() => openRequisitionModal(params.row)}
        >
          {params.row.order_id}
          {params.row.expedite && '🔥'}
        </Link>
      ),
      minWidth: 150,
    },
    {
      field: 'date.timestamp',
      headerName: 'Date',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.row.date.formatted_date}>
          {params.row.date.human_date.relative.long}
        </Tooltip>
      ),
      valueGetter: (value, row) => row.date.formatted_date,
    },
    {
      field: 'part_numbers',
      headerName: 'Part Number',
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          {params.row.part_numbers[0]}{' '}
          {params.row.part_numbers.length > 1 && (
            <Tooltip title={params.row.part_numbers.join(', ')}>
              <Button color='primary'>
                {params.row.part_numbers.length - 1} more...
              </Button>
            </Tooltip>
          )}
        </Box>
      ),
      width: 200,
      valueGetter: (value, row) => row.part_numbers[0],
    },
    {
      field: 'quick_glance',
      headerName: '',
      renderCell: (params: GridRenderCellParams) => (
        <RequisitionQuickGlance
          row={params.row}
          handleGlancePopoverOpen={handleGlancePopoverOpen}
        />
      ),
      width: 150,
      display: 'flex',
      valueGetter: (value, row) => row.pn_icon.icon_color,
    },
    {
      field: 'bar_chart',
      headerName: 'Qty Required',
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ width: '100%' }}>
          <Box
            aria-owns={open ? `mouse-over-popover-${params.row.id}` : undefined}
            aria-haspopup='true'
            onMouseEnter={(e) => handlePopoverOpen(e, params.row.id)}
            onMouseLeave={handlePopoverClose}
          >
            <BarChart chartData={params.row.bar_chart} />
          </Box>
          <Popover
            id={`mouse-over-popover-${params.row.id}`}
            sx={{
              pointerEvents: 'none',
              marginTop: '20px',
            }}
            open={popoverId === params.row.id}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box sx={{ width: 600, p: 2.5 }}>
              <QuantityChart
                requisition={params.row}
                quantitiesColors={requisitionsListData.quantities_colors}
              />
            </Box>
          </Popover>
        </Box>
      ),
      width: 300,
      display: 'flex',
      valueGetter: (value, row) => row.quantity.order_quantity,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params: GridRenderCellParams) =>
        statusList && (
          <StatusLabel statusList={statusList} statusId={params.row.status_id}>
            {params.row.status}
          </StatusLabel>
        ),
      display: 'flex',
      width: 150,
    },
    {
      field: 'assignee.name',
      headerName: 'AssignedTo',
      renderCell: (params: GridRenderCellParams) =>
        requisitionAssignUsersList && (
          <AssignDropdown
            userList={requisitionAssignUsersList}
            selected={params.row}
            assign={params.row.permissions.assign}
            updateFor='requisition'
          />
        ),
      minWidth: 200,
      display: 'flex',
      valueGetter: (value, row) => row.assignee?.name,
    },
  ]

  const rowCountRef = React.useRef(requisitionsListData?.total_rows || 0)

  const rowCount = React.useMemo(() => {
    if (requisitionsListData?.total_rows !== undefined) {
      rowCountRef.current = requisitionsListData.total_rows
    }
    return rowCountRef.current
  }, [requisitionsListData?.total_rows])

  useEffect(() => {
    if (location.pathname === '/requisitions/create') {
      setIsCreateRequisition(true)
    }
  }, [location])

  const onChangeFilter = (target, values) => {
    setFilterValues({
      ...filterValues,
      [target]: values,
    })
  }

  const handleCloseReadRequisitionModal = () => {
    history.push('/requisitions/list')
  }

  const handleCloseCreateRequisitionModal = (isRedirect = true) => {
    setIsCreateRequisition(false)
    if (isRedirect) {
      history.push('/requisitions/list')
    }
  }

  const openRequisitionModal = (requisition) => {
    history.push(`/requisitions/${requisition.order_id}/pricing`)
  }

  const handleGlancePopoverOpen = (event, popoverData) => {
    setGlancePopoverAnchorEl(event.currentTarget)
    setGlancePopoverData(popoverData)
  }

  const handleGlancePopoverClose = () => {
    setGlancePopoverAnchorEl(null)
    setGlancePopoverData(null)
  }

  const glanceOpen = Boolean(glancePopoverAnchorEl)

  const onOpenRequisitionCreateModal = () => {
    setIsCreateRequisition(true)
  }

  const handlePopoverOpen = (event, rowId) => {
    setPopoverId(rowId)
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setPopoverId(null)
  }

  useEffect(() => {
    const orderIds = rowSelectionModel.map((id) => {
      return requisitionsListData?.requisition.find((x) => x.id === id).order_id
    })

    setSelectedOrderIds(orderIds)
  }, [rowSelectionModel])

  return (
    <Box component={Paper} elevation={3} sx={{ p: 2 }}>
      <Filters onChangeFilter={onChangeFilter} filterValues={filterValues} />
      <Box mb={3} height={600}>
        <DataGrid
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          rows={requisitionsListData?.requisition || []}
          columns={columns} // Define your columns
          rowCount={rowCount}
          paginationMode='server'
          paginationModel={paginationModel}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          onPaginationModelChange={setPaginationModel}
          loading={isFetchingRequisitions}
          slots={{
            pagination: (props) => (
              <GridPagination
                ActionsComponent={DataGridCustomPagination}
                {...props}
              />
            ),
          }}
        />
      </Box>

      <Card>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TableBulkActions
            checkedRequisitions={rowSelectionModel}
            checkedOrderIds={selectedOrderIds}
          />
          <Fab
            color='secondary'
            aria-label='add'
            onClick={onOpenRequisitionCreateModal}
          >
            <AddIcon />
          </Fab>
          <CreateRequisitionModal
            isOpen={isCreateRequisition}
            handleClose={handleCloseCreateRequisitionModal}
          />
        </CardContent>
      </Card>
      <Popover
        id='glance-popovver'
        open={glanceOpen}
        anchorEl={glancePopoverAnchorEl}
        onClose={handleGlancePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {glancePopoverData && (
          <Box p={2}>
            <Typography variant='h5' gutterBottom>
              {glancePopoverData.title}
            </Typography>
            {glancePopoverData.type === 'pn_icon' &&
              glancePopoverData?.part_numbers &&
              glancePopoverData?.part_numbers.length > 0 &&
              glancePopoverData.part_numbers.map((pn, i) => (
                <Box key={'glance-pop-over' + i}>
                  <Typography variant='h6'>{pn.part_number}</Typography>
                  <ul style={{ paddingLeft: '20px' }}>
                    {pn.part_number_rules && pn.part_number_rules.length > 0 ? (
                      pn.part_number_rules.map((pnRule, j) => (
                        <li key={j}>
                          <Typography variant='h6'>{pnRule.name}</Typography>
                          {pnRule.steps &&
                            pnRule.steps.map((step, k) => (
                              <Typography key={k}>{step}</Typography>
                            ))}
                        </li>
                      ))
                    ) : (
                      <Typography>None</Typography>
                    )}
                  </ul>
                </Box>
              ))}
            {glancePopoverData.type === 'sp_icon' &&
              glancePopoverData.part_numbers &&
              glancePopoverData.part_numbers.length > 0 &&
              glancePopoverData.part_numbers.map((pn, i) => (
                <Box key={'glance-pop-over' + i}>
                  <Typography variant='h6'>{pn.part_number}</Typography>
                  <ul style={{ paddingLeft: '20px' }}>
                    {pn.supplier_preference_rules &&
                    pn.supplier_preference_rules.length > 0 ? (
                      pn.supplier_preference_rules.map((spRule, j) => (
                        <li key={j}>
                          <Typography variant='h6'>{spRule.name}</Typography>
                          {spRule.preferences &&
                            spRule.preferences.map((preference, k) => (
                              <Typography key={k}>{preference}</Typography>
                            ))}
                        </li>
                      ))
                    ) : (
                      <Typography>None</Typography>
                    )}
                  </ul>
                </Box>
              ))}
          </Box>
        )}
      </Popover>
      <Dialog open={!!requisitionId} onClose={handleCloseReadRequisitionModal}>
        {requisitionId && (
          <RequisitionReadModal onClose={handleCloseReadRequisitionModal} />
        )}
      </Dialog>
    </Box>
  )
}

export default RequisitionsList
