import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type CancelRfqParams = {
  id: number
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqLineCancelMutation = () =>
  useMutation<any, ErrorDTO, CancelRfqParams>({
    mutationFn: async (values: CancelRfqParams) => {
      try {
        const res = await client.delete<ApiResponseDTO>(
          `rfq/line/cancel/${values.id}`
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
