import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box } from '@mui/material'

import DonutChart from '../donutChart'
import { QuantityChartContainer } from './style'

const QuantityChart = ({ requisition, quantitiesColors }) => {
  const [chart, setChart] = useState({
    series: [],
    labels: [],
    colors: [],
  })

  useEffect(() => {
    let chartData = {
      series: [],
      labels: [],
      colors: [],
    }

    requisition.pie_chart.map((serie) => {
      const serieValue = serie.end_degree - serie.start_degree
      chartData = {
        series: [...chartData.series, serieValue],
        labels: [...chartData.labels, serie.label],
        colors: [...chartData.colors, serie.color],
      }

      return true
    })

    setChart(chartData)
  }, [])

  return (
    <QuantityChartContainer>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Typography
            variant='h6'
            sx={{ textDecoration: 'underline' }}
            gutterBottom
          >
            Quantity
          </Typography>
          <div className='summary chart'>
            {chart.series.length > 0 && <DonutChart chartData={chart} />}
            <h2>{requisition.quantity.order_quantity}</h2>
          </div>
        </Grid>
        <Grid item xs={7}>
          <Typography
            variant='h6'
            sx={{ textDecoration: 'underline' }}
            gutterBottom
          >
            RFQ
          </Typography>
          <div className='status'>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box
                  py={2}
                  borderRadius={5}
                  style={{
                    background: quantitiesColors.rfq.draft.background,
                    color: quantitiesColors.rfq.draft.text,
                  }}
                >
                  <Typography variant='h6'>
                    {requisition.quantity.rfq.draft}
                  </Typography>
                  <Typography variant='body1'>Draft</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  py={2}
                  borderRadius={5}
                  style={{
                    background: quantitiesColors.rfq.pending.background,
                    color: quantitiesColors.rfq.pending.text,
                  }}
                >
                  <Typography variant='h6'>
                    {requisition.quantity.rfq.pending}
                  </Typography>
                  <Typography variant='body1'>Pending</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  py={2}
                  borderRadius={5}
                  style={{
                    background: quantitiesColors.rfq.quoted.background,
                    color: quantitiesColors.rfq.quoted.text,
                  }}
                >
                  <Typography variant='h6'>
                    {requisition.quantity.rfq.quoted}
                  </Typography>
                  <Typography variant='body1'>Quoted</Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
          {requisition.type !== 'q4q' && (
            <>
              <Typography
                variant='h6'
                sx={{ textDecoration: 'underline' }}
                gutterBottom
              >
                PO
              </Typography>
              <div className='status'>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box
                      py={2}
                      borderRadius={5}
                      style={{
                        background: quantitiesColors.po.draft.background,
                        color: quantitiesColors.po.draft.text,
                      }}
                    >
                      <Typography variant='h6'>
                        {requisition.quantity.po.draft}
                      </Typography>
                      <Typography variant='body1'>Draft</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      py={2}
                      borderRadius={5}
                      style={{
                        background: quantitiesColors.po.ordered.background,
                        color: quantitiesColors.po.ordered.text,
                      }}
                    >
                      <Typography variant='h6'>
                        {requisition.quantity.po.ordered}
                      </Typography>
                      <Typography variant='body1'>Ordered</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      py={2}
                      borderRadius={5}
                      style={{
                        background: quantitiesColors.po.confirmed.background,
                        color: quantitiesColors.po.confirmed.text,
                      }}
                    >
                      <Typography variant='h6'>
                        {requisition.quantity.po.confirmed}
                      </Typography>
                      <Typography variant='body1'>Confirmed</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </QuantityChartContainer>
  )
}

export default QuantityChart
