import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  Box,
  Button,
  TableCell,
  TableRow,
  IconButton,
  Collapse,
} from '@mui/material'
import { toast } from 'react-toastify'
import { styled } from '@mui/material/styles'
import _isEmpty from 'lodash/isEmpty'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import NumberFormat from 'react-number-format'

import DispatchSchedules from '../../../components/dispatchSchedules'

import { usePoLineUpdateMutation } from 'services/hooks/purchaseOrders/usePoLineUpdateMutation'

const EditInput = styled('input')`
  width: 100px;
`

const LineTableRow = ({ poData, row, onDeleteLine, index }) => {
  const [open, setOpen] = useState(false)
  const [isEditLine, setIsEditLine] = useState(false)
  const [line, setLine] = useState(row)
  const [inputValues, setInputValues] = useState({
    quantity: row.quantity,
    supplier_part_number: row.supplier_part_number,
    manufacturer_name: row.manufacturer ? row.manufacturer.name : '',
    unit_cost: row.unit_cost,
  })
  const { mutate: poLineUpdateMutation } = usePoLineUpdateMutation()
  const queryClient = useQueryClient()

  const onToggleCollapse = () => {
    setOpen(!open)
  }

  const onEditLineClick = () => {
    setIsEditLine(true)
  }

  const onCancelEditLineClick = () => {
    setIsEditLine(false)
  }

  const onSaveLineClick = async () => {
    poLineUpdateMutation(
      {
        uuid: line.uuid,
        updateBody: inputValues,
      },
      {
        onSuccess(data, variables, context) {
          const resLine = data.line
          setLine(resLine)
          setIsEditLine(false)
          setInputValues({
            quantity: resLine.quantity,
            supplier_part_number: resLine.supplier_part_number,
            manufacturer_name: row.manufacturer
              ? resLine.manufacturer.name
              : '',
            unit_cost: resLine.unit_cost,
          })
          toast.success(`PO line ${line.uuid} is updated successfully`)
        },
      }
    )
  }

  const onInputUpdate = (event) => {
    const { name, value } = event.target
    setInputValues({
      ...inputValues,
      [name]: value,
    })
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell component='th' scope='row'>
          {isEditLine ? (
            <EditInput
              name='quantity'
              type='number'
              value={inputValues.quantity}
              onChange={onInputUpdate}
            />
          ) : (
            line.quantity
          )}
        </TableCell>
        <TableCell>{line.item}</TableCell>
        <TableCell>
          {isEditLine ? (
            <EditInput
              name='supplier_part_number'
              type='text'
              value={inputValues.supplier_part_number}
              onChange={onInputUpdate}
            />
          ) : (
            line.supplier_part_number
          )}
        </TableCell>
        <TableCell>
          {isEditLine ? (
            <EditInput
              name='manufacturer_name'
              type='text'
              value={inputValues.manufacturer_name}
              onChange={onInputUpdate}
            />
          ) : (
            <span>
              {!_isEmpty(line.manufacturer) && line.manufacturer.name}
            </span>
          )}
        </TableCell>
        <TableCell>{line.requisition_detail.order_id}</TableCell>
        <TableCell>
          {isEditLine ? (
            <EditInput
              name='unit_cost'
              type='text'
              value={inputValues.unit_cost}
              onChange={onInputUpdate}
            />
          ) : (
            <NumberFormat
              value={parseFloat(line.unit_cost).toFixed(2)}
              displayType='text'
              thousandSeparator
              prefix='$'
              fixedDecimalScale
            />
          )}
        </TableCell>
        <TableCell>
          <NumberFormat
            value={(line.quantity * parseFloat(line.unit_cost)).toFixed(2)}
            displayType='text'
            thousandSeparator
            prefix='$'
            fixedDecimalScale
          />
        </TableCell>
        <TableCell>
          {poData.permissions.can_edit_lines && !isEditLine && (
            <IconButton aria-label='edit' onClick={() => onEditLineClick()}>
              <EditIcon />
            </IconButton>
          )}

          {isEditLine && (
            <IconButton aria-label='save' onClick={() => onSaveLineClick()}>
              <CheckIcon />
            </IconButton>
          )}

          {isEditLine && (
            <IconButton
              aria-label='cancel'
              onClick={() => onCancelEditLineClick()}
            >
              <CloseIcon />
            </IconButton>
          )}

          {!isEditLine && (
            <IconButton
              aria-label='delete'
              onClick={() => onDeleteLine(row, index)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={8} component='th' scope='row'>
          <Button
            startIcon={open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            onClick={onToggleCollapse}
          >
            Dispatch schedule
          </Button>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <DispatchSchedules
                lineIndex={index}
                line={line}
                scheduleFor='po'
                showExpectedScheduleDate={!poData.permissions.can_edit}
                disabled={!poData.permissions.can_edit_dispatch_schedules}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default LineTableRow
