/** @jsxImportSource @emotion/react */

import { Box, Card, CardContent } from '@mui/material'

import logo from '../../assets/images/logo.svg'

import { AuthCardContainerCSS, AuthContainerCSS } from './index.style'

const AuthWrapper = ({ children }) => {
  return (
    <Box css={AuthContainerCSS}>
      <Card css={AuthCardContainerCSS}>
        <CardContent className='card-content'>
          <img src={logo} className='logo' alt='Turnerfox Purchasing' />
          {children}
        </CardContent>
      </Card>
    </Box>
  )
}

export default AuthWrapper
