import React, { useState, useEffect } from 'react'
import { Fab, Button, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import _findIndex from 'lodash/findIndex'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'

import CorrespondenceContainer from './index.style'
import NewMessage from './messages/newMessage'
import ReadMessage from './messages/readMessage'
import {
  RfqCorrespondenceResponseDTO,
  useRfqCorrespondenceQuery,
} from 'services/hooks/rfq/useRfqCorrespondenceQuery'
import {
  PoCorrespondenceResponseDTO,
  usePoCorrespondenceQuery,
} from 'services/hooks/purchaseOrders/usePoCorrespondenceQuery'
import { useRfqReadQuery } from 'services/hooks/rfq/useRfqReadQuery'
import { usePoReadQuery } from 'services/hooks/purchaseOrders/usePoReadQuery'
import { useRfqCorrespondenceCreateMutation } from 'services/hooks/rfq/useRfqCorrespondenceCreateMutation'
import { usePoCorrespondenceCreateMutation } from 'services/hooks/purchaseOrders/usePoCorrespondenceCreateMutation'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

type CorrespondenceTableContextDTO = {
  correspondence: any
}
export const CorrespondenceTableContext = React.createContext<
  CorrespondenceTableContextDTO | undefined
>({
  correspondence: {},
})

type CorrespondenceTableProps = {
  onSavedQuote?: any
  correspondenceFor: 'rfq' | 'po'
}

const CorrespondenceTable = ({
  onSavedQuote,
  correspondenceFor = 'rfq',
}: CorrespondenceTableProps) => {
  const history = useHistory()
  const { id: urlParamId, threadUUID }: any = useParams()
  const { data: rfqData } = useRfqReadQuery(
    urlParamId,
    correspondenceFor === 'rfq'
  )
  const { data: poData } = usePoReadQuery(
    urlParamId,
    correspondenceFor === 'po'
  )
  const {
    mutate: rfqCreateCorrespondenceMutation,
    isPending: isPendingRfqCreateCorrespondene,
  } = useRfqCorrespondenceCreateMutation()
  const {
    mutate: poCreateCorrespondenceMutation,
    isPending: isPendingPoCreateCorrespondene,
  } = usePoCorrespondenceCreateMutation()

  const [correspondence, setCorrespondence] = useState<
    RfqCorrespondenceResponseDTO | PoCorrespondenceResponseDTO
  >(null)
  const [newMessageModal, setNewMessageModal] = useState(false)
  const [createdCorrespondence, setCreatedCorrespondence] = useState(null)
  const [selectedCorrespondence, setSelectedCorrespondence] = useState(null)
  const {
    data: rfqCorrespondences,
    refetch: refetchRfqCorrespondences,
    isFetching: isFetchingRfqCorrespondences,
  } = useRfqCorrespondenceQuery(urlParamId, correspondenceFor === 'rfq')
  const {
    data: poCorrespondences,
    refetch: refetchPoCorrespondences,
    isFetching: isFetchingPoCorrespondences,
  } = usePoCorrespondenceQuery(urlParamId, correspondenceFor === 'po')

  useEffect(() => {
    if (correspondenceFor === 'rfq' && rfqCorrespondences) {
      setCorrespondence(rfqCorrespondences)
    } else if (correspondenceFor === 'po' && poCorrespondences) {
      setCorrespondence(poCorrespondences)
    }
  }, [rfqCorrespondences, poCorrespondences, threadUUID])

  useEffect(() => {
    if (correspondence && threadUUID) {
      const selectedThreadIndex = _findIndex(
        correspondence.threads,
        function (o: any) {
          return o.thread.uuid === threadUUID
        }
      )
      if (selectedThreadIndex > -1) {
        setSelectedCorrespondence(correspondence.threads[selectedThreadIndex])
      } else {
        setSelectedCorrespondence(null)
        history.push(
          `/${correspondenceFor}/${
            correspondenceFor === 'rfq' ? rfqData.id : poData.id
          }/correspondence`
        )
      }
    } else if (!threadUUID) {
      setSelectedCorrespondence(null)
    }
  }, [threadUUID, correspondence])

  const createNewMessage = async () => {
    if (correspondenceFor === 'rfq') {
      rfqCreateCorrespondenceMutation(
        { uuid: rfqData.uuid },
        {
          onSuccess(data, variables, context) {
            toast.success('Thread draft is created.')
            setNewMessageModal(true)
            setCreatedCorrespondence(data)
          },
        }
      )
    } else {
      poCreateCorrespondenceMutation(
        { uuid: poData.uuid },
        {
          onSuccess(data, variables, context) {
            toast.success('Thread draft is created.')
            setNewMessageModal(true)
            setCreatedCorrespondence(data)
          },
        }
      )
    }
  }

  const onCloseNewMessage = () => {
    setCreatedCorrespondence(null)
    setNewMessageModal(false)
  }

  const onShowMessage = (correspondence) => {
    history.push(
      `/${correspondenceFor}/${
        correspondenceFor === 'rfq' ? rfqData.id : poData.id
      }/correspondence/thread/${correspondence.thread.uuid}`
    )
  }

  const onCloseReadMessage = () => {
    history.push(
      `/${correspondenceFor}/${
        correspondenceFor === 'rfq' ? rfqData.id : poData.id
      }/correspondence`
    )
  }

  const onRefresh = () => {
    if (correspondenceFor === 'rfq') {
      refetchRfqCorrespondences()
    } else {
      refetchPoCorrespondences()
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'thread.participants',
      headerName: 'Participants',
      renderCell: (params: GridRenderCellParams) => (
        <Button color='primary' onClick={() => onShowMessage(params.row)}>
          {params.row.thread.participants.map((participant, i) => (
            <span key={'correspondence-item' + i}>
              {participant}
              {i < params.row.thread.participants.length - 1 && ','}
            </span>
          ))}
        </Button>
      ),
      minWidth: 300,
    },
    {
      field: 'thread.subject',
      headerName: 'Subject',
      minWidth: 400,
      valueGetter: (value, row) => `${row.thread.subject}`,
    },
    {
      field: 'thread.status',
      headerName: 'Status',
      valueGetter: (value, row) => `${row.thread.subject}`,
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            color: params.row.thread.status.color,
            fontWeight: params.row.thread.status.bold ? 'bold' : 'normal',
          }}
        >
          {params.row.thread.status.status}
        </span>
      ),
      minWidth: 200,
    },
    {
      field: 'thread.date_last_updated.formatted_date',
      headerName: 'Date',
      valueGetter: (value, row) =>
        `${row.thread.date_last_updated.formatted_date}`,
      minWidth: 300,
    },
  ]

  return (
    <CorrespondenceTableContext.Provider
      value={{
        correspondence: selectedCorrespondence,
      }}
    >
      <CorrespondenceContainer>
        {correspondence && (
          <DataGrid
            rows={correspondence.threads}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            pageSizeOptions={[25]}
            getRowId={(row) => row.thread?.id}
          />
        )}

        <Box
          className='correspondence-actions'
          sx={{
            position: 'absolute',
            right: 20,
            bottom: 20,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            zIndex: 99,
          }}
        >
          <Fab
            size='small'
            color='primary'
            aria-label='add'
            className='btn-refresh'
            onClick={onRefresh}
            disabled={
              isFetchingRfqCorrespondences || isFetchingPoCorrespondences
            }
          >
            <RefreshIcon />
          </Fab>
          <Fab
            size='small'
            color='secondary'
            aria-label='add'
            className='btn-plus'
            onClick={createNewMessage}
            disabled={
              isPendingRfqCreateCorrespondene || isPendingPoCreateCorrespondene
            }
          >
            <AddIcon />
          </Fab>
        </Box>
        {newMessageModal && createdCorrespondence && (
          <NewMessage
            selectedLine={correspondenceFor === 'rfq' ? rfqData : poData}
            onClose={onCloseNewMessage}
            correspondenceFor={correspondenceFor}
            createdCorrespondence={createdCorrespondence}
          />
        )}

        {selectedCorrespondence && (
          <ReadMessage
            selectedLine={correspondenceFor === 'rfq' ? rfqData : poData}
            correspondence={selectedCorrespondence}
            onClose={onCloseReadMessage}
            onSavedQuote={onSavedQuote}
          />
        )}
      </CorrespondenceContainer>
    </CorrespondenceTableContext.Provider>
  )
}

export default CorrespondenceTable
