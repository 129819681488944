import React, { useState, useEffect } from 'react'
import {
  Button,
  Tooltip,
  NativeSelect,
  FormControl,
  IconButton,
} from '@mui/material'
import ReplyIcon from '@mui/icons-material/Reply'
import NotesIcon from '@mui/icons-material/Notes'
import DeleteIcon from '@mui/icons-material/Delete'
import { Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import _filter from 'lodash/filter'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { toast } from 'react-toastify'

import { generateQuotesHTMLContent } from '../../../utils/common'

import ReplyMessageContainer from './replyMessage.style'
import { useThreadDraftDeleteMutation } from 'services/hooks/thread/useThreadDraftDeleteMutation'
import { useThreadDraftSendMutation } from 'services/hooks/thread/useThreadDraftSendMutation'
import { useThreadDraftUpdateMutation } from 'services/hooks/thread/useThreadDraftUpdateMutation'
import { useQueryClient } from '@tanstack/react-query'
import { useUserQuery } from 'services/hooks/auth/useUserQuery'

const ReplyMessage = ({
  correspondence,
  selectedLine,
  replyType,
  messageUUID,
  closeReply,
}) => {
  const queryClient = useQueryClient()
  const correspondenceFor = window.location.pathname.split('/')[1]
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  )
  const { messages } = correspondence
  const quoteMessages = _filter(messages, function (o) {
    return !o.is_draft
  })
  const isInternal =
    replyType === 'internal_note' ? true : messages[0].is_internal
  const [replyValue, setReplyValue] = useState(
    isInternal ? 'internal' : 'supplier'
  )

  const [isShowQuoteMessages, setIsShowQuoteMessages] = useState(false)
  const { mutate: threadDraftDeleteMutation } = useThreadDraftDeleteMutation()
  const { mutate: threadDraftSendMutation } = useThreadDraftSendMutation()
  const { mutate: threadDraftUpdateMutation } = useThreadDraftUpdateMutation()
  const { data: userData } = useUserQuery()

  useEffect(() => {
    const html =
      userData.data.email_signature_html !== ''
        ? `<br/><br/>${userData.data.email_signature_html}`
        : ''

    const contentState = stateFromHTML(html)
    setEditorState(EditorState.createWithContent(contentState))
  }, [])

  const onShowTrimmedContent = () => {
    setIsShowQuoteMessages(true)
    const existingContent = stateToHTML(editorState.getCurrentContent())
    const quotesHtmlContent = generateQuotesHTMLContent(
      existingContent,
      quoteMessages
    )
    const contentState = stateFromHTML(quotesHtmlContent)

    setEditorState(EditorState.createWithContent(contentState))
  }

  const onCorrespondenceUpdated = () => {
    if (correspondenceFor === 'rfq') {
      queryClient.invalidateQueries({
        queryKey: ['rfq', 'correspondence'],
      })
    } else {
      queryClient.invalidateQueries({
        queryKey: ['po', 'correspondence'],
      })
    }
  }

  const onSendReply = async () => {
    const html = stateToHTML(editorState.getCurrentContent())
    threadDraftUpdateMutation(
      {
        uuid: messageUUID,
        reqBody: {
          is_internal: replyValue === 'internal',
          body: html,
          is_quote_attached: isShowQuoteMessages,
        },
      },
      {
        onSuccess(data, variables, context) {
          threadDraftSendMutation(
            { uuid: messageUUID },
            {
              onSuccess(data, variables, context) {
                closeReply()
                toast.success('Reply message is sent.')
                onCorrespondenceUpdated()
              },
            }
          )
        },
      }
    )
  }

  const onChangeText = (editorState) => {
    setEditorState(editorState)
  }

  const onChangeReplyingOption = (e) => {
    setReplyValue(e.target.value)
  }

  const onDeleteDraft = async () => {
    threadDraftDeleteMutation(
      {
        uuid: messageUUID,
      },
      {
        onSuccess(data, variables, context) {
          toast.success('Draft message is deleted')
          closeReply()
        },
      }
    )
  }

  return (
    <ReplyMessageContainer>
      <div className='wrapper'>
        <div className='reply-header'>
          <div className='reply-to'>
            <div className='reply-option'>
              {replyValue === 'internal' ? <NotesIcon /> : <ReplyIcon />}
              <FormControl>
                <NativeSelect
                  value={replyValue}
                  name='type'
                  onChange={onChangeReplyingOption}
                  inputProps={{ 'aria-label': 'message-type' }}
                >
                  <option value='internal'>Internal Note</option>
                  <option value='supplier'>
                    Message to Supplier ({selectedLine.supplier})
                  </option>
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <span className='text-draft'>Draft</span>
        </div>
        <div className='reply-content'>
          <Editor editorState={editorState} onChange={onChangeText} />
          {quoteMessages.length > 0 && !isShowQuoteMessages && (
            <Tooltip title='Show trimmed content'>
              <Button
                variant='contained'
                onClick={onShowTrimmedContent}
                className='btn-show-quote'
              >
                <MoreHorizIcon />
              </Button>
            </Tooltip>
          )}
        </div>
        <div className='reply-buttons'>
          <IconButton aria-label='delete' onClick={onDeleteDraft}>
            <DeleteIcon />
          </IconButton>
          <Button variant='contained' color='primary' onClick={onSendReply}>
            Send
          </Button>
        </div>
      </div>
    </ReplyMessageContainer>
  )
}

export default ReplyMessage
