import { ApiMetaDataDTO, SupplierDTO } from 'declarations'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

type ResponseDTO = {
  code: number
  data: SupplierDTO[]
  api_metadata: ApiMetaDataDTO
}

export const useSnvServicesQuery = (): UseQueryResult<
  SupplierDTO[],
  unknown
> => {
  return useQuery({
    queryKey: ['supplier_name_variant', 'services'],
    queryFn: async () => {
      try {
        const res = await client.get<ResponseDTO>(
          '/supplier_name_variant/services/list'
        )
        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
}
