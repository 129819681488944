// @ts-nocheck - may need to be at the start of file

import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'

import { clearToken } from './services/helpers/utility'
import LoginNew from './pages/auth/login'
import ForgotPassword from './pages/auth/forgotPassword'
import ForgotPasswordConfirm from './pages/auth/forgotPassword/confirm'
import GoogleAuthenticator from './pages/auth/googleAuthenticator'
import GoogleAuthenticatorQRCode from './pages/auth/googleAuthenticator/qrCode'
import ChangePassword from './pages/auth/changePassword'
import Admin from './pages/admin'
import PurchaseOrders from './pages/purchaseOrders'
import Home from './pages/home'
import Requisitions from './pages/requisitions'
import Shipment from './pages/shipment'
import NoMatchPage from './pages/noMatch'
import Rfq from './pages/rfq'
import PageWrapper from './pages/pageWrapper'
import Files from './pages/files'
import LoadingScreen from 'pages/auth/LoadingScreen'
import { useUserQuery } from 'services/hooks/auth/useUserQuery'

export const GlobalContext = React.createContext({})

// @ts-ignore:next-line
const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const AuthRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  const userName = localStorage.getItem('username')

  const authSubRoutes = [
    '/changePassword',
    '/googleAuthenticatorCode',
    '/googleAuthenticatorQRCode',
  ]

  const isRouteAccessible = authSubRoutes.includes(rest.path)
    ? !!userName && userName !== '' && userName !== 'undefined'
    : true

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn && isRouteAccessible ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const PublicRoutes = () => {
  const { data: userData, isFetching, error, isSuccess } = useUserQuery()

  const onApiError = useCallback((err: any) => {
    let errorMessage

    if (err && err.response) {
      if (err.response.data.code === 401) {
        clearToken()
        // dispatch({
        //   type: authActions.LOGOUT_SUCCESS,
        // })
      } else {
        errorMessage = err.response.data.message
        toast.error(errorMessage)
      }
    }
  }, [])

  const onApiResponse = useCallback((res: any) => {
    if (res.data.code === 200 || res.data.code === 201) {
      toast.success('Success')
    } else if (res.data.code >= 400 && res.data.code < 600) {
      toast.warning(res.data.message)
    }
  }, [])

  return (
    <GlobalContext.Provider value={{ onApiError, onApiResponse }}>
      {isFetching ? (
        <LoadingScreen />
      ) : (
        <Router>
          <PageWrapper isLoggedIn={isSuccess} userInfo={userData}>
            <Switch>
              <Route exact path='/'>
                <Redirect to='/home' />
              </Route>
              <AuthRoute
                exact
                path='/login'
                component={LoginNew}
                isLoggedIn={isSuccess}
              />
              <AuthRoute
                exact
                path='/forgotPassword'
                component={ForgotPassword}
                isLoggedIn={isSuccess}
              />
              <AuthRoute
                exact
                path='/forgotPasswordConfirm'
                component={ForgotPasswordConfirm}
                isLoggedIn={isSuccess}
              />
              <AuthRoute
                exact
                path='/changePassword'
                component={ChangePassword}
                isLoggedIn={isSuccess}
              />
              <AuthRoute
                exact
                path='/googleAuthenticatorCode'
                component={GoogleAuthenticator}
                isLoggedIn={isSuccess}
              />
              <AuthRoute
                exact
                path='/googleAuthenticatorQRCode'
                component={GoogleAuthenticatorQRCode}
                isLoggedIn={isSuccess}
              />

              <RestrictedRoute
                path='/admin'
                component={Admin}
                isLoggedIn={isSuccess}
              />
              <RestrictedRoute
                path='/home'
                component={Home}
                isLoggedIn={isSuccess}
              />
              <RestrictedRoute
                path='/requisitions'
                component={Requisitions}
                isLoggedIn={isSuccess}
              />
              <RestrictedRoute
                path='/po'
                component={PurchaseOrders}
                isLoggedIn={isSuccess}
              />
              <RestrictedRoute
                path='/shipment'
                component={Shipment}
                isLoggedIn={isSuccess}
              />
              <RestrictedRoute
                path='/files'
                component={Files}
                isLoggedIn={isSuccess}
              />
              <RestrictedRoute
                path='/rfq'
                component={Rfq}
                isLoggedIn={isSuccess}
              />
              <Route component={NoMatchPage} />
            </Switch>
          </PageWrapper>
        </Router>
      )}
    </GlobalContext.Provider>
  )
}

export default PublicRoutes
