import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  Paper,
  Grid,
} from '@mui/material'
import { DataGrid, GridPagination } from '@mui/x-data-grid'

import GridOnIcon from '@mui/icons-material/GridOn'
import ListIcon from '@mui/icons-material/List'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useHistory, useLocation } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import FilePreview from './filePreview'
import { File } from 'declarations'
import FilePreviewModalContent from './filePreviewModalContent'
import useFilesListQuery from 'services/hooks/file/useFilesListQuery'
import DataGridCustomPagination from 'components/dataGridCustomPagination'

const columns = [
  { field: 'filename', headerName: 'File name', width: 300 },
  {
    field: 'file_type',
    headerName: 'Type',
    width: 300,
    editable: true,
  },
  {
    field: 'mime_type',
    headerName: 'Format',
    width: 150,
    editable: true,
  },
  {
    field: 'assoc',
    headerName: 'Associate',
    width: 300,
    valueGetter: (value, row) => {
      let str = ''
      row.assoc.map((ass: any, index: number) => {
        if (index !== row.assoc.length - 1) {
          str += `${ass.assoc_id},`
        } else {
          str += ass.assoc_id
        }
      })

      return str
    },
  },
]

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Files = () => {
  const [value, setValue] = useState(0)
  const history = useHistory()
  const location = useLocation()
  const [isOnlyUnassociated, setIsOnlyUnassociated] = useState(false)
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  })
  const [filter, setFilter] = useState({
    source: '',
    deleted: false,
  })
  const [source, setSource] = useState('')
  const [deleted, setDeleted] = useState(false)
  const { data: filesListData, isFetching: isFetchingFiles } =
    useFilesListQuery({
      page: paginationModel.page,
      rows: paginationModel.pageSize,
      unassociated: isOnlyUnassociated,
      source: filter.source,
      deleted: filter.deleted,
    })
  const [viewType, setViewType] = useState('list')
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [previewFile, setPreviewFile] = useState<File>()

  const onClickCard = (file) => {
    setOpenPreviewModal(true)
    setPreviewFile(file)
  }

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOnlyUnassociated(event.target.checked)
  }

  useEffect(() => {
    const { pathname } = location
    const pageRoute = pathname.split('/')[2]

    setPaginationModel({
      ...paginationModel,
      page: 0,
    })

    switch (pageRoute) {
      case 'list':
        setValue(0)

        setFilter({
          source: '',
          deleted: false,
        })
        break
      case 'email':
        setValue(1)
        setFilter({
          source: 'email',
          deleted: false,
        })
        break
      case 'google-drive':
        setValue(2)
        setFilter({
          source: 'google drive',
          deleted: false,
        })
        break
      case 'deleted':
        setValue(3)
        setFilter({
          source: '',
          deleted: true,
        })
        break
      default:
        setValue(0)
        setFilter({
          source: '',
          deleted: false,
        })
        break
    }
  }, [location])

  const handleChange = (event: object, newValue: any) => {
    switch (newValue) {
      case 0:
        history.push(`/files/list`)
        break
      case 1:
        history.push(`/files/email`)
        break
      case 2:
        history.push(`/files/google-drive`)
        break
      case 3:
        history.push(`/files/deleted`)
        break
      default:
        break
    }
  }

  useEffect(() => {
    setPaginationModel({
      ...paginationModel,
      page: 0,
    })
  }, [source, deleted, isOnlyUnassociated])

  const onChangeView = () => {
    setViewType(viewType === 'list' ? 'grid' : 'list')
  }

  const rowCountRef = React.useRef(filesListData?.total_rows || 0)

  const rowCount = React.useMemo(() => {
    if (filesListData?.total_rows !== undefined) {
      rowCountRef.current = filesListData.total_rows
    }
    return rowCountRef.current
  }, [filesListData?.total_rows])

  return (
    <Paper sx={{ p: 3 }} elevation={3}>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='All Files' {...a11yProps(0)} />
          <Tab label='Email' {...a11yProps(1)} />
          <Tab label='Google Drive' {...a11yProps(2)} />
          <Tab label='Deleted' {...a11yProps(2)} />
        </Tabs>
      </Box>

      <Box pt={2} flex={1}>
        <Box display='flex' justifyContent='flex-end' style={{ gap: '1rem' }}>
          <IconButton onClick={onChangeView}>
            {viewType === 'list' ? <GridOnIcon /> : <ListIcon />}
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={isOnlyUnassociated}
                color='primary'
                onChange={handleChangeCheckbox}
                name='only unassociated'
              />
            }
            label='Only Unassociated'
          />
        </Box>

        {filesListData && (
          <Box>
            {viewType === 'list' ? (
              <Box height={600}>
                <DataGrid
                  paginationModel={paginationModel}
                  rows={filesListData.file}
                  columns={columns}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  disableRowSelectionOnClick
                  loading={isFetchingFiles}
                  rowCount={rowCount}
                  onPaginationModelChange={setPaginationModel}
                  paginationMode='server'
                  slots={{
                    pagination: (props) => (
                      <GridPagination
                        ActionsComponent={DataGridCustomPagination}
                        {...props}
                      />
                    ),
                  }}
                />
              </Box>
            ) : (
              <Box>
                <Grid container spacing={2}>
                  {filesListData.file.map((file) => (
                    <Grid
                      item
                      xs={3}
                      key={file.id}
                      onClick={() => onClickCard(file)}
                    >
                      <FilePreview file={file} />
                    </Grid>
                  ))}
                </Grid>
                <Box
                  mt={2}
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                >
                  <Box mr={1}>
                    <Typography>
                      {paginationModel.pageSize * paginationModel.page + 1}-
                      {paginationModel.pageSize * (paginationModel.page + 1)} of{' '}
                      {filesListData.total_rows}
                    </Typography>
                  </Box>

                  <ButtonGroup>
                    <Button
                      onClick={() =>
                        setPaginationModel({
                          ...paginationModel,
                          page: paginationModel.page - 1,
                        })
                      }
                      disabled={paginationModel.page === 0}
                    >
                      <ChevronLeftIcon />
                    </Button>
                    <Button
                      onClick={() =>
                        setPaginationModel({
                          ...paginationModel,
                          page: paginationModel.page + 1,
                        })
                      }
                      disabled={
                        paginationModel.page ===
                        Math.round(
                          filesListData.total_rows / paginationModel.pageSize
                        ) -
                          1
                      }
                    >
                      <ChevronRightIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Dialog
        fullWidth
        maxWidth='md'
        open={openPreviewModal}
        onClose={() => {
          setOpenPreviewModal(false)
        }}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogContent>
          <FilePreviewModalContent previewFile={previewFile} />
          {/* <DialogContentText>Dialog Preview</DialogContentText>
          <LazyLoadImage
            src={previewFile?.thumbnail.url} // use normal <img> attributes as props
            width={200}
            effect='blur'
          /> */}
        </DialogContent>
      </Dialog>
    </Paper>
  )
}

export default Files
