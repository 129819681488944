import React, { useState, useEffect } from 'react'
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@mui/material'

import EditableInputContainer from './editableInput.style'
import { useRfqQuoteUpdateMutation } from 'services/hooks/rfq/useRfqQuoteUpdateMutation'

let timer
const WAIT_INTERVAL = 1000

const EditableInput = ({ quoteLineUUID, inputValue, inputType, label }) => {
  const [value, setValue] = useState(inputValue)
  const [fieldError, setFieldError] = useState(null)
  const { mutate: rfqQuoteUpdateMutation } = useRfqQuoteUpdateMutation()

  useEffect(() => {
    timer = null
  }, [])

  const onUpdateInput = async (e) => {
    clearTimeout(timer)
    // const inputPrevValue = value;
    const inputUpdatedValue = e.target.value
    setValue(inputUpdatedValue)

    timer = setTimeout(async () => {
      if (inputUpdatedValue === '') {
        setFieldError('field must be not empty')
      } else {
        rfqQuoteUpdateMutation(
          {
            uuid: quoteLineUUID,
            body: {
              [inputType]: inputUpdatedValue,
            },
          },
          {
            onSuccess(data, variables, context) {
              setFieldError(null)
            },
            onError(error, variables, context) {
              setFieldError(error.message)
            },
          }
        )
      }
    }, WAIT_INTERVAL)
  }

  return (
    <EditableInputContainer>
      {inputType === 'price' ? (
        <FormControl variant='outlined' fullWidth>
          <InputLabel htmlFor='outlined-adornment-amount'>Price</InputLabel>
          <OutlinedInput
            id='outlined-adornment-amount'
            value={value}
            onChange={onUpdateInput}
            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
            label='Price'
          />
        </FormControl>
      ) : (
        <TextField
          required
          id='outlined-required'
          label={label}
          value={value}
          onChange={onUpdateInput}
          variant='outlined'
          fullWidth
        />
      )}

      {fieldError && <p className='field-error'>{fieldError}</p>}
    </EditableInputContainer>
  )
}

export default EditableInput
