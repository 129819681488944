import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Icon,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { useManufacturersListQuery } from 'services/hooks/others/useManufacturersListQuery'
import { useRfqListDraftQuery } from 'services/hooks/rfq/useRfqListDraftQuery'

import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'
import { RequisitionReadResponseDTO } from 'services/hooks/requisition/useRequisitionQuery'
import { useRfqCreateMutation } from 'services/hooks/rfq/useRfqCreateMutation'
import { useRfqLinesCreateMutation } from 'services/hooks/rfq/useRfqLinesCreateMutation'
import SendRfqModal from './sendRfqModal'

interface IFormInput {
  manufacturerId: string
  supplierId: string
  partNumber: string
  quantity: number
  rfqId: string
}

type AddRfqFormProps = {
  requisition: RequisitionReadResponseDTO
  onRfqSent: () => void
}

const AddRfqForm = ({ requisition, onRfqSent }: AddRfqFormProps) => {
  const [createdRfqId, setCreatedRfqId] = useState(null)
  const [createdRfqLineIds, setCreatedRfqLineIds] = useState([])
  const [openRfqModal, setOpenRfqModal] = useState(false)
  const partNumbers = requisition.requisition.part_numbers
  const { data: suppliers } = useSuppliersQuery()
  const { data: manufacturers } = useManufacturersListQuery()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      manufacturerId: '',
      supplierId: '',
      partNumber: partNumbers.length === 1 ? partNumbers[0] : '',
      quantity: requisition.requisition.quantity.outstanding,
      rfqId: '',
    },
  })
  const { mutate: rfqCreateMutation } = useRfqCreateMutation()
  const { mutate: rfqLinesCreateMutation } = useRfqLinesCreateMutation()

  const supplierId = watch('supplierId')
  const { data: rfqDrafts, refetch: refetchRfqDrafts } =
    useRfqListDraftQuery(supplierId)

  useEffect(() => {
    refetchRfqDrafts()
  }, [supplierId])

  useEffect(() => {
    if (rfqDrafts?.total_rows === 0) {
      setValue('rfqId', 'new_rfq')
    }
  }, [rfqDrafts])

  const createRfqLine = (formData, rfqId) => {
    rfqLinesCreateMutation(
      {
        lines: [
          {
            quantity: formData.quantity,
            part_number: formData.partNumber,
            manufacturer_id: parseInt(formData.manufacturerId, 10),
          },
        ],
        rfqId: rfqId,
        requisitionUUID: requisition.requisition.uuid,
      },
      {
        onSuccess(data, variables, context) {
          setOpenRfqModal(true)
          setCreatedRfqId(rfqId)
          setCreatedRfqLineIds(data)
        },
      }
    )
  }

  const onSubmit: SubmitHandler<IFormInput> = (formData) => {
    if (formData.rfqId === 'new_rfq') {
      rfqCreateMutation(
        { supplier_id: parseInt(formData.supplierId, 10) },
        {
          onSuccess(data, variables, context) {
            setCreatedRfqId(data.rfq_id)
            createRfqLine(formData, data.rfq_id)
          },
        }
      )
    } else {
      createRfqLine(formData, parseInt(formData.rfqId, 10))
    }
  }

  const onCloseAddRfqModal = () => {
    setOpenRfqModal(false)
    setCreatedRfqId(null)
    setCreatedRfqLineIds([])
  }

  const onSaveRfq = () => {
    onRfqSent()
  }

  return (
    <Box component={Paper} sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Controller
            name='partNumber'
            control={control}
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <FormControl error={Boolean(errors.partNumber)}>
                <FormLabel id='part-number-radios'>Part Numbers</FormLabel>
                <RadioGroup aria-labelledby='part-number-radios' {...field}>
                  {partNumbers.map((pn, index) => (
                    <FormControlLabel
                      value={pn}
                      control={<Radio />}
                      label={pn}
                      key={index}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          />
          <Controller
            name='manufacturerId'
            control={control}
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <FormControl fullWidth error={Boolean(errors.manufacturerId)}>
                <InputLabel id='supplier-select-label'>Manufacturer</InputLabel>
                <Select
                  {...field}
                  labelId='manufacturer-select-label'
                  label='Manufacturer'
                >
                  <MenuItem value={0}>None</MenuItem>
                  {manufacturers?.map((manufacturer, i) => (
                    <MenuItem value={manufacturer.id} key={'manufacturer' + i}>
                      {manufacturer.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors.manufacturerId?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name='supplierId'
            control={control}
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <FormControl fullWidth error={Boolean(errors.supplierId)}>
                <InputLabel id='supplier-select-label'>Supplier</InputLabel>
                <Select
                  {...field}
                  labelId='supplier-select-label'
                  label='Supplier'
                >
                  <MenuItem value=''>None</MenuItem>
                  {suppliers?.map((supplier, i) => (
                    <MenuItem value={supplier.id} key={'add-rfq-item' + i}>
                      {supplier.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.supplierId?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name='quantity'
            control={control}
            rules={{
              required: 'Required',
              pattern: {
                value: /^\d+$/,
                message: 'Quantity must be integer',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label='Quantity'
                fullWidth
                error={Boolean(errors.quantity)}
                helperText={errors.quantity?.message}
              />
            )}
          />
          {supplierId !== '' && rfqDrafts && (
            <Controller
              name='rfqId'
              control={control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <FormControl fullWidth error={Boolean(errors.rfqId)}>
                  <InputLabel id='rfq-select-label'>Rfq</InputLabel>
                  <Select {...field} labelId='rfq-select-label' label='Rfq'>
                    <MenuItem value=''>None</MenuItem>
                    <MenuItem value='new_rfq'>New RFQ</MenuItem>
                    {rfqDrafts?.rfq?.map((rfq, i) => (
                      <MenuItem value={rfq.id} key={'rfq-menu-item' + i}>
                        RFQ {rfq.id}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.rfqId?.message}</FormHelperText>
                </FormControl>
              )}
            />
          )}
          <Button
            variant='contained'
            color='primary'
            type='submit'
            disabled={!partNumbers}
          >
            Next{' '}
            <Icon>
              <ChevronRightIcon />
            </Icon>
          </Button>
        </Box>
      </form>
      {createdRfqId && createdRfqLineIds && (
        <SendRfqModal
          open={openRfqModal}
          close={onCloseAddRfqModal}
          onSaveRfq={onSaveRfq}
          rfqId={createdRfqId}
          rfqLineIds={createdRfqLineIds}
          onRfqSent={onRfqSent}
        />
      )}
    </Box>
  )
}

export default AddRfqForm
