import React from 'react'
import {
  Box,
  Paper,
  Typography,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Icon,
  List,
  ListItem,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useShipmentExpeditingQuery } from 'services/hooks/useShipmentExpeditingQuery'

const Home = () => {
  const { data: expeditings } = useShipmentExpeditingQuery()

  return (
    <Paper sx={{ py: 3 }} elevation={3}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Box p={5}>
            <Typography variant='h5' gutterBottom>
              Expediting
            </Typography>
            <Box boxShadow={1} borderRadius={2} style={{ overflow: 'hidden' }}>
              <List sx={{ padding: 0 }}>
                {expeditings &&
                  expeditings.map((item) => (
                    <ListItem
                      style={{ backgroundColor: `#${item.background_color}` }}
                      key={item.uuid}
                    >
                      <ListItemIcon>
                        <Icon
                          fontSize='large'
                          style={{ color: `#${item.icon.color}` }}
                        >
                          {item.icon.icon}
                        </Icon>
                      </ListItemIcon>
                      <Box
                        className='list-item-content'
                        display='flex'
                        flex='1'
                      >
                        <Grid container spacing={3} alignItems='center'>
                          <Grid item xs={3}>
                            <Typography>{item.column1.text}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>{item.column2.text}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{item.column3.text}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <ListItemSecondaryAction>
                        <IconButton edge='end' aria-label='delete'>
                          <CloseIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Home
