import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const TimelineSliderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  .time-marker {
    text-align: center;

    .pointer {
      height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      position: relative;
      z-index: 1;

      .dot {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: black;
        display: inline-block;
      }
    }
  }
`

export default TimelineSliderContainer
