import React from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Viewer, { Worker } from '@phuocng/react-pdf-viewer'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'
import { grey } from '@mui/material/colors'

const FileViewerModal = (props) => {
  const { onClose, open, attachment } = props

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      maxWidth='lg'
      fullWidth
    >
      {attachment && (
        <>
          <DialogTitle id='simple-dialog-title'>
            {attachment.filename}
            <IconButton
              aria-label='close'
              sx={{
                position: 'absolute',
                right: 1,
                top: 1,
                color: grey[500],
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ minHeight: 150 }}>
            {(attachment.mime_type === 'image/jpeg' ||
              attachment.mime_type === 'image/png') && (
              <img src={attachment.fileUrl} alt={attachment.filename} />
            )}
            {attachment.mime_type === 'application/pdf' && (
              <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js'>
                <Viewer fileUrl={attachment.fileUrl} />
              </Worker>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default FileViewerModal
