import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ApiMetaDataDTO, PartNumberRuleDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
}

export const useShipmentReadQuery = (
  trackingNumber: string
): UseQueryResult<any> => {
  return useQuery({
    queryKey: ['shipment', 'read', trackingNumber],
    queryFn: async () => {
      try {
        const res = await client.get<ApiResponseDTO>(
          `/shipment/read/${trackingNumber}`
        )
        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
}
