import React, { useState, useEffect, createContext } from 'react'
import {
  DialogContent,
  Tabs,
  Tab,
  AppBar,
  IconButton,
  Box,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useParams, useHistory } from 'react-router'
import _find from 'lodash/find'

import AssignDropdown from '../../../components/assignDropdown'
import TimelineSlider from '../../../components/timelineSlider'
import TabContainer, { a11yProps } from '../../../components/tabContainer'
import PurchaseOrderHistory from '../../../components/purchaseOrderHistory'
import CorrespondenceTable from '../../../components/correspondenceTable'
import StatusDropdown from '../../../components/modalStatusDropdown'
import LoadingSpinner from '../../../components/common/loadingSpinner'
import SupplierPopover from '../../../components/supplierPopover'

import LineItems from './lineItems'
import Shipments from './shipments'
import OrderModalContainer from './index.style'
import TabFiles from './tabFiles'
import { usePoReadQuery } from 'services/hooks/purchaseOrders/usePoReadQuery'
import { usePoHistoryQuery } from 'services/hooks/purchaseOrders/usePoHistoryQuery'
import { usePoAssignUsersQuery } from 'services/hooks/purchaseOrders/usePoAssignUsersQuery'
import { useSuppliersQuery } from 'services/hooks/supplier/useSuppliersQuery'

type ModalContextProps = {
  supplier: any
  orderData: any

  onStatusUpdated: () => void
}

const ModalContextDefault = {
  supplier: null,
  orderData: null,

  onStatusUpdated: () => undefined,
}

export const ModalContext =
  createContext<ModalContextProps>(ModalContextDefault)

type OrderModalContentProps = {
  onClose: any
}

const OrderModalContent = ({ onClose }: OrderModalContentProps) => {
  const history = useHistory()
  const { id: poId, tabName }: any = useParams()
  const {
    data: poData,
    isFetching: isFetchingPoData,
    refetch: refetchPoData,
    isError: isErrorPoData,
  } = usePoReadQuery(poId)

  const { data: poHistoryData } = usePoHistoryQuery({
    uuid: poId,
    enabled: !isFetchingPoData && !isErrorPoData,
  })
  const { data: poAssignUsersList } = usePoAssignUsersQuery()
  const { data: suppliersList } = useSuppliersQuery()

  const [orderData, setOrderData] = useState<any>(null)
  const [tabValue, setTabValue] = useState(0)

  const [anchorEl, setAnchorEl] = useState(null)

  const supplier =
    suppliersList &&
    poData &&
    _find(suppliersList, {
      id: poData.supplier_id,
    })

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  useEffect(() => {
    switch (tabName) {
      case 'lines':
        setTabValue(0)
        break
      case 'correspondence':
        setTabValue(1)
        break
      case 'activity':
        setTabValue(2)
        break
      case 'shipments':
        setTabValue(3)
        break
      case 'files':
        setTabValue(4)
        break
      default:
        break
    }
  }, [])

  const handleClose = () => {
    setOrderData(null)
    setTabValue(0)
    onClose()
  }

  const handleTabChange = (event: any, newValue: any) => {
    switch (newValue) {
      case 0:
        history.push(`/po/${poId}/lines`)
        break
      case 1:
        history.push(`/po/${poId}/correspondence`)
        break
      case 2:
        history.push(`/po/${poId}/activity`)
        break
      case 3:
        history.push(`/po/${poId}/shipments`)
        break
      case 4:
        history.push(`/po/${poId}/files`)
        break
      default:
        break
    }
    setTabValue(newValue)
  }

  const onStatusUpdated = () => {
    refetchPoData()
  }

  return (
    <ModalContext.Provider
      value={{
        supplier,
        orderData,
        onStatusUpdated,
      }}
    >
      <DialogContent>
        {poData && poHistoryData ? (
          <OrderModalContainer>
            <Box sx={{ marginBottom: '8px' }}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box display='flex' alignItems='center'>
                  <Box sx={{ marginRight: 2.5 }}>
                    <Typography variant='h4' sx={{ whiteSpace: 'nowrap' }}>
                      Purchase Order {poData.id}
                    </Typography>
                  </Box>

                  <AssignDropdown
                    userList={poAssignUsersList}
                    selected={poData}
                    assign={poData.permissions.can_assign}
                    componentType='menu'
                    updateFor='po'
                  />
                </Box>
                <Box display='flex' alignItems='center'>
                  {supplier && (
                    <>
                      <div
                        className='supplier-label'
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup='true'
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      >
                        {poData.supplier}
                      </div>
                      <SupplierPopover
                        open={open}
                        anchorEl={anchorEl}
                        handlePopoverClose={handlePopoverClose}
                        supplier={supplier}
                      />
                    </>
                  )}

                  <StatusDropdown
                    statuses={poData.status_list}
                    statusId={poData.status_id}
                    uuid={poData.uuid}
                    onStatusUpdated={onStatusUpdated}
                    loadingStatuses={isFetchingPoData}
                    statusFor='po'
                  />
                  <IconButton
                    className='btn-close'
                    aria-label='Close'
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <span className='status'></span>
            <TimelineSlider timeline={poData.timeline} />
            <div className='detail-tabs'>
              <AppBar position='static' color='default'>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label='Simple tabs example'
                >
                  <Tab label='Line Items' {...a11yProps(0)} />
                  <Tab label='Correspondence' {...a11yProps(1)} />
                  <Tab label='History' {...a11yProps(2)} />
                  <Tab label='Shipments' {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              {tabValue === 0 && (
                <TabContainer>
                  <LineItems />
                </TabContainer>
              )}
              {tabValue === 1 && (
                <TabContainer>
                  <CorrespondenceTable correspondenceFor='po' />
                </TabContainer>
              )}
              {tabValue === 2 && (
                <TabContainer>
                  <PurchaseOrderHistory histories={poHistoryData.history} />
                </TabContainer>
              )}
              {tabValue === 3 && (
                <TabContainer>
                  <Shipments uuid={poData.uuid} />
                </TabContainer>
              )}
              {/*{tabValue === 4 && (
                <TabContainer>
                  <TabFiles uuid={selectedPo.uuid} />
                </TabContainer>
              )} */}
            </div>
          </OrderModalContainer>
        ) : (
          <Box
            sx={{
              minHeight: 300,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isErrorPoData ? (
              <Typography variant='h5'>No Po Data Found!</Typography>
            ) : (
              <LoadingSpinner />
            )}
          </Box>
        )}
      </DialogContent>
    </ModalContext.Provider>
  )
}

export default OrderModalContent
