import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ApiMetaDataDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type SearchRequisitionQueryParamsDTO = {
  term: string
  assignee?: string
  status?: string
}

type SearchResultDTO = {
  search: {
    more_than_100: boolean
    record_count: number
    term_count: number
    terms: string[]
  }
}
type ApiResponseDTO = {
  code: number
  data: SearchResultDTO
  api_metadata: ApiMetaDataDTO
}

const useRequisitionSearchQuery = (
  params: SearchRequisitionQueryParamsDTO
): UseQueryResult<SearchResultDTO> => {
  return useQuery({
    queryKey: [
      'requisition',
      'search',
      params.term,
      params.assignee,
      params.status,
    ],
    queryFn: async () => {
      try {
        const reqParams = {
          term: params.term,
          assignee: params.assignee,
          status: params.status,
        }

        const res = await client.get<ApiResponseDTO>(`/requisitions/search`, {
          params: reqParams,
        })

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    enabled: params.term !== '',
  })
}

export { useRequisitionSearchQuery }
