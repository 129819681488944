import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type UpdateRequisitionsDTO = {
  uuid?: string
  reqBody: {
    assignee?: number | string
    id?: number[]
    expedite?: boolean
    uuid?: string[]
  }
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRequisitionUpdateMutation = () =>
  useMutation<unknown, ErrorDTO, UpdateRequisitionsDTO>({
    mutationFn: async (values: UpdateRequisitionsDTO) => {
      try {
        let res
        if (values.uuid) {
          res = await client.put<ApiResponseDTO>(
            `/requisitions/update/${values.uuid}`,
            values.reqBody
          )
        } else {
          res = await client.put<ApiResponseDTO>(
            '/requisitions/update',
            values.reqBody
          )
        }

        // toast.success('Supplier profile is updated')

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
