import React, { useState, useCallback } from 'react'
import { client } from '../../services/helpers/apiConfig'
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import WarningIcon from '@mui/icons-material/Warning'
import { useDropzone } from 'react-dropzone'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { yellow } from '@mui/material/colors'

import {
  usePoFileTypes,
  useMimeTypes,
  useRfqFileTypes,
} from '../../services/hooks/useFileTypes'
import useTabFiles, { File } from './useTabFiles'
import {
  useLazyFileReadGetURLQuery,
  useLazyFileReadQuery,
} from 'services/hooks/file/useFileRead'

type TabFilesProps = {
  uuid: string
  usageFor: string
}

const TabFiles = ({ uuid, usageFor = 'po' }: TabFilesProps) => {
  const queryClient = useQueryClient()
  const [open, setOpen] = useState<boolean>(false)
  const [fileType, setFileType] = useState('')
  const [fileToUpload, setFileToUpload] = useState<any>(null)
  const [openFileActionDialog, setOpenFileActionDialog] =
    useState<boolean>(false)
  const [fileActionType, setFileActionType] = useState<string | null>(null)
  const [fileToAction, setFileToAction] = useState<File | null>(null)
  const [uploadError, setUploadError] = useState<string | null>(null)
  const [fetchFileURL] = useLazyFileReadGetURLQuery()
  const [fetchFile] = useLazyFileReadQuery()

  const createFileMutation = useMutation({
    mutationFn: async (body: any) =>
      client.post(
        usageFor === 'po'
          ? `/po/${uuid}/file/create`
          : `/rfq/${uuid}/file/create`,
        body
      ),

    onSuccess: () => {
      setTimeout(() => {
        handleCloseUploadDialog()
      }, 500)

      queryClient.invalidateQueries({
        queryKey: [usageFor === 'po' ? 'poFileList' : 'rfqFileList', uuid],
      })
    },
    onError: (error: any, variables, context) => {
      setUploadError(error.response.data.message)
    },
  })

  const deleteFileMutation = useMutation({
    mutationFn: async ({ fileUuid }: { fileUuid: string }) =>
      client.delete(`/file/delete/${fileUuid}`),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [usageFor === 'po' ? 'poFileList' : 'rfqFileList', uuid],
      })
    },
  })

  const unlinkFileMutation = useMutation({
    mutationFn: async ({ fileUuid }: { fileUuid: string }) =>
      client.put(`/${usageFor}/${uuid}/file/${fileUuid}/unlink`),

    onSuccess: (data, { fileUuid }) => {
      if (fileActionType === 'delete') {
        deleteFileMutation.mutate({ fileUuid })
      } else {
        queryClient.invalidateQueries({
          queryKey: [usageFor === 'po' ? 'poFileList' : 'rfqFileList', uuid],
        })
      }
    },
  })

  const { data: fileList, isSuccess: isFileListFetchSuccess } = useTabFiles(
    uuid,
    usageFor
  )

  const getBase64 = (file: any): Promise<string> => {
    return new Promise((resolve) => {
      let baseURL: any = ''
      // Make new FileReader
      const reader = new FileReader()

      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result
        resolve(baseURL)
      }
    })
  }

  const onDrop = useCallback(async (acceptedFiles: any) => {
    const file = acceptedFiles[0]
    try {
      const result = await getBase64(acceptedFiles[0])
      const base64String = result.split(',')[1]

      file['base64'] = base64String
      setFileToUpload(file)
    } catch (err) {
      console.log(err)
    }
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    disabled:
      createFileMutation.status === 'pending' || createFileMutation.isError,
  })

  const handleChange = (event: SelectChangeEvent) => {
    setFileType(event.target.value as string)
  }

  const handleOpenUploadDialog = () => {
    createFileMutation.reset()
    setOpen(true)
  }

  const handleCloseUploadDialog = () => {
    setOpen(false)
    setFileToUpload(null)
  }

  const {
    isLoading,
    error,
    data: fileTypesData,
    isFetching,
  } = usageFor === 'po' ? usePoFileTypes() : useRfqFileTypes()
  const { data: mimeTypes } = useMimeTypes()

  const onUpload = async () => {
    if (fileToUpload && fileType !== '') {
      createFileMutation.mutate({
        file_type: fileType,
        file_data: fileToUpload.base64,
        file_name: fileToUpload.name,
      })
    }
  }

  const onClickFile = async (row: File) => {
    if (mimeTypes) {
      const mimeType = mimeTypes.mime_types.find(
        (x) => x.mime_type === row.mime_type
      )

      try {
        const res = await fetchFileURL(row.uuid)
        const resFileObj = await fetchFile(row.uuid)

        const fileObj = resFileObj.data.data
        const fileBlobUrl = `data:${fileObj.mime_type};base64,${fileObj.base64_data}`

        if (mimeType && mimeType.download) {
          fetch(fileBlobUrl)
            .then((res) => res.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = fileObj.filename
              a.click()
            })
        } else {
          window.open(res.data.data.url, '_blank')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const onUnlinkFileBtnClick = (row: File) => {
    setOpenFileActionDialog(true)
    setFileToAction(row)
    setFileActionType('unlink')
  }

  const onDeleteFileBtnClick = (row: File) => {
    setOpenFileActionDialog(true)
    setFileToAction(row)
    setFileActionType('delete')
  }

  const handleCloseFileActionDialog = () => {
    setFileToAction(null)
    setFileActionType(null)
    setOpenFileActionDialog(false)
  }

  const onConfirmFileAction = async () => {
    try {
      if (fileToAction) {
        unlinkFileMutation.mutate({ fileUuid: fileToAction.uuid })
      }
      handleCloseFileActionDialog()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Box>
      {isFileListFetchSuccess && fileList.file.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>File Type</TableCell>
                <TableCell>File Format</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileList.file.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    <Button color='primary' onClick={() => onClickFile(row)}>
                      {row.filename}
                    </Button>
                  </TableCell>
                  <TableCell>{row.date_added}</TableCell>
                  <TableCell>{row.file_type}</TableCell>
                  <TableCell>{row.mime_type}</TableCell>
                  <TableCell>
                    <Box display='flex'>
                      <IconButton
                        aria-label='unlink'
                        onClick={() => onUnlinkFileBtnClick(row)}
                      >
                        <LinkOffIcon fontSize='inherit' />
                      </IconButton>
                      <IconButton
                        aria-label='delete'
                        onClick={() => onDeleteFileBtnClick(row)}
                      >
                        <DeleteIcon fontSize='inherit' />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography>
            No files are associated with this{' '}
            {usageFor === 'po' ? 'Purchase Order' : 'Rfq'}
          </Typography>
        </Box>
      )}

      <Box sx={{ textAlign: 'right' }} mt={2}>
        <Fab color='primary' aria-label='add' onClick={handleOpenUploadDialog}>
          <AddIcon />
        </Fab>
      </Box>
      <Dialog
        open={open}
        onClose={handleCloseUploadDialog}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle id='form-dialog-title'>File Upload</DialogTitle>
        <DialogContent>
          <Box
            {...getRootProps()}
            sx={{
              height: 200,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              //refactor later
              // border: `1px solid ${theme.palette.text.primary}`,
            }}
          >
            <input {...getInputProps()} />
            {createFileMutation.status === 'pending' && (
              <Box textAlign='center'>
                <CircularProgress />
                <Typography>Uploading</Typography>
              </Box>
            )}
            {createFileMutation.isSuccess && (
              <Box textAlign='center'>
                <ThumbUpIcon />
                <Typography>Successfully Uploaded</Typography>
              </Box>
            )}
            {!createFileMutation.isError &&
              !(createFileMutation.status === 'pending') &&
              !createFileMutation.isSuccess && (
                <Box>
                  {isDragActive ? (
                    <Typography variant='h6'>Drop the file here ...</Typography>
                  ) : (
                    <Typography variant='h6'>
                      Drag 'n' drop a file here, or click to select a file
                    </Typography>
                  )}
                </Box>
              )}
            {createFileMutation.isError && uploadError && (
              <Box textAlign='center'>
                <WarningIcon
                  style={{ color: yellow[500], marginBottom: '0.5rem' }}
                />
                <Typography variant='h5'>{uploadError}</Typography>
              </Box>
            )}

            {fileToUpload && (
              <Box display='flex' alignItems='center'>
                <AttachFileIcon />
                <p>{fileToUpload.name}</p>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Box>
            {fileTypesData && (
              <FormControl
                variant='outlined'
                required
                className={`${fileToUpload && fileType === '' && 'active'}`}
                sx={{
                  margin: 1,
                  minWidth: 120,
                  //refactor later
                  // '&.active': {
                  //   boxShadow: `0px 0px 20px 5px ${theme.palette.primary.main}`,
                  // },
                }}
              >
                <InputLabel id='file-type-outlined-label'>File Type</InputLabel>
                <Select
                  labelId='file-type-outlined-label'
                  id='file-type-select-outlined'
                  value={fileType}
                  onChange={handleChange}
                  label='File Type'
                >
                  {fileTypesData.file_types.map((type, i) => (
                    <MenuItem
                      value={type.controlled_term}
                      key={'file-type' + i}
                    >
                      {type.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          <Box sx={{ gap: '1rem', display: 'flex' }}>
            <Button onClick={handleCloseUploadDialog} variant='contained'>
              Cancel
            </Button>
            {!createFileMutation.isError && (
              <Button
                color='primary'
                variant='contained'
                onClick={onUpload}
                disabled={
                  fileType === '' ||
                  fileToUpload === null ||
                  createFileMutation.status === 'pending'
                }
              >
                Upload {`>`}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openFileActionDialog}
        onClose={handleCloseFileActionDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to {fileActionType} this file from this{' '}
            {usageFor === 'po' ? 'PO' : 'RFQ'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFileActionDialog} color='primary'>
            Cancel
          </Button>
          <Button onClick={onConfirmFileAction} color='primary' autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TabFiles
