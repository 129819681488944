import React, { useEffect, useState } from 'react'
import {
  Box,
  FilledInput,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useLocation, useHistory } from 'react-router'

import Recommendations from './recommendations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'
import useQuery from 'utils/useQuery'
import { OrangeButton } from 'components/common/button'

type CreateRequisitionModalProps = {
  isOpen: boolean
  handleClose: (isRedirect?: boolean) => void
}

type FormValues = {
  requisitionId: string
  type: string
  quantity: number
  partNumberClass: string
}

type RecommendationValue = {
  partNumber: string | null
  manufacturer: any
  supplier: any
}

const validationSchema = yup.object({
  requisitionId: yup
    .string()
    .required('RequisitionId is required')
    .matches(
      /(^5[0-9]{7}[-]{0,1}[0-9]{0,3}$|^Q4Q[0-9]{8}[-]{0,1}[0-9]{0,3}$|^Q4Q20[0-9]{8}$|^Q4Q[0-9]{5,6}$)/
    ),
  type: yup.mixed().oneOf(['standard', 'q4q']).required('Type is required'),
  quantity: yup.string().required('Quantity is required'),
})

const CreateRequisitionModal = ({
  isOpen,
  handleClose,
}: CreateRequisitionModalProps) => {
  const query = useQuery()
  const location = useLocation()
  const history = useHistory()

  const [recommendations, setRecommendations] = useState<RecommendationValue[]>(
    []
  )
  const [draftRecommendation, setDraftRecommendation] =
    useState<RecommendationValue>()

  const createRequisitionMutation = useMutation({
    mutationFn: (formData: any) => {
      return client.post('/requisitions/create', formData)
    },
    onSuccess: (data, variables, context) => {
      const requisitionId = data.data.data.order_id
      toast.success(`${requisitionId} is created!`)
      handleClose(false)
      history.push(`/requisitions/${requisitionId}`)
    },
    onError: (error: any, variables, context) => {
      const errorMessage = error.response.data.message
      toast.error(errorMessage)
    },
  })

  const formik = useFormik<FormValues>({
    initialValues: {
      requisitionId: query.get('requisition_id') || '',
      type: query.get('type') || '',
      quantity: parseInt(query.get('quantity'), 10) || 0,
      partNumberClass: query.get('part_number_class'),
    },
    validationSchema,
    onSubmit: (values) => {
      let formData: any = {
        type: values.type,
        quantity: values.quantity,
        part_number_class: values.partNumberClass,
        requisition_id: values.requisitionId,
        part_numbers: partNumbers,
      }

      if (values.type === 'q4q') {
        const recommendationsArr = recommendations.map((x) => ({
          part_number: x.partNumber,
          manufacturer_id: x.manufacturer?.id,
          supplier_id: x.supplier?.id,
        }))

        if (draftRecommendation) {
          formData = {
            ...formData,
            recommendations: [
              ...recommendationsArr,
              {
                part_number: draftRecommendation?.partNumber,
                manufacturer_id: draftRecommendation?.manufacturer?.id,
                supplier_id: draftRecommendation?.supplier?.id,
              },
            ],
          }
        } else {
          formData = {
            ...formData,
            recommendations: recommendationsArr,
          }
        }
      }

      createRequisitionMutation.mutate(formData)
    },
  })
  const [partNumberValue, setPartNumberValue] = useState<string>('')
  const [partNumbers, setPartNumbers] = useState<string[]>([])
  const [isPartNumberValueError, setIsPartNumberValueError] =
    useState<boolean>(false)

  useEffect(() => {
    if (location.pathname === '/requisitions/create') {
      if (query.get('part_number')?.split(',').length > 0) {
        setPartNumbers(query.get('part_number').split(','))
      }
    }
  }, [location])

  const onChangePartNumber = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPartNumberValue(e.target.value)
    setIsPartNumberValueError(false)
  }

  const onSavePartNumbers = () => {
    if (partNumbers.findIndex((x) => x === partNumberValue) === -1) {
      setPartNumberValue('')
      setPartNumbers([...partNumbers, partNumberValue])
    } else {
      setIsPartNumberValueError(true)
    }
  }

  const onDeletePartNumber = (partNumber: string) => {
    setPartNumbers(partNumbers.filter((x) => x !== partNumber))
  }

  const onUpdateRecommendations = (recommendations) => {
    setRecommendations(recommendations)
  }

  const onUpdateDraftRecommendation = (recommendation) => {
    setDraftRecommendation(recommendation)
  }

  const onCloseModal = () => {
    setPartNumberValue('')
    setRecommendations([])
    setPartNumbers([])
    formik.resetForm({
      values: {
        requisitionId: '',
        type: '',
        quantity: 0,
        partNumberClass: '',
      },
    })
    handleClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>
        <Typography variant='h4'>Create a New Requisition</Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant='filled'
              id='requisitionId'
              name='requisitionId'
              label='Requisition ID'
              value={formik.values.requisitionId}
              onChange={formik.handleChange}
              error={
                formik.touched.requisitionId &&
                Boolean(formik.errors.requisitionId)
              }
              helperText={
                formik.touched.requisitionId && formik.errors.requisitionId
              }
            />
          </FormControl>
          <FormControl
            fullWidth
            error={formik.touched.type && Boolean(formik.errors.type)}
            variant='filled'
          >
            <InputLabel id='type-select-label'>Type</InputLabel>
            <Select
              labelId='type-select-label'
              id='type'
              name='type'
              value={formik.values.type}
              onChange={formik.handleChange}
            >
              <MenuItem value='standard'>Standard</MenuItem>
              <MenuItem value='q4q'>Quote for Quote</MenuItem>
            </Select>
            {formik.touched.type && formik.errors.type && (
              <FormHelperText>{formik.errors.type}</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant='filled'
              id='quantity'
              name='quantity'
              label='Quantity'
              type='number'
              value={formik.values.quantity}
              onChange={formik.handleChange}
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity && formik.errors.quantity}
            />
          </FormControl>
          <FormControl
            fullWidth
            error={formik.touched.type && Boolean(formik.errors.type)}
            variant='filled'
          >
            <InputLabel id='type-select-label'>Part Number Class</InputLabel>
            <Select
              labelId='type-select-label'
              id='partNumberClass'
              name='partNumberClass'
              value={formik.values.partNumberClass}
              onChange={formik.handleChange}
            >
              <MenuItem value='1'>Class 1</MenuItem>
              <MenuItem value='2'>Class 2</MenuItem>
              <MenuItem value='3'>Class 3</MenuItem>
            </Select>
            {formik.touched.partNumberClass &&
              formik.errors.partNumberClass && (
                <FormHelperText>{formik.errors.partNumberClass}</FormHelperText>
              )}
          </FormControl>
          <Divider />
          <Box>
            <Box mb={2}>
              <Typography variant='h5'>Part Numbers</Typography>
              {partNumbers.map((partNumber, index) => (
                <Box
                  key={index}
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{
                    gap: 1,
                    maxWidth: 400,
                  }}
                >
                  <Typography>{partNumber}</Typography>
                  <IconButton
                    aria-label='delete'
                    size='small'
                    onClick={() => onDeletePartNumber(partNumber)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <FormControl
              fullWidth
              variant='filled'
              error={isPartNumberValueError}
            >
              <InputLabel htmlFor='partnumber-input'>
                Part Number (and equivalents)
              </InputLabel>

              <FilledInput
                fullWidth
                id='partnumber-input'
                name='partnumber'
                value={partNumberValue}
                onChange={onChangePartNumber}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={onSavePartNumbers}
                    >
                      <SaveIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {isPartNumberValueError && (
                <FormHelperText>This Part Number already exists</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Divider />

          {formik.values.type === 'q4q' && (
            <Recommendations
              partNumbers={partNumbers}
              partNumberClass={formik.values.partNumberClass}
              onUpdateRecommendations={onUpdateRecommendations}
              onUpdateDraftRecommendation={onUpdateDraftRecommendation}
            />
          )}
        </DialogContent>
        <DialogActions>
          <OrangeButton
            variant='contained'
            onClick={onCloseModal}
            className='btn-orange'
          >
            Cancel
          </OrangeButton>
          <Button variant='contained' type='submit' color='primary'>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CreateRequisitionModal
