import React, { useEffect } from 'react'
import { Box, Tabs, Tab, Paper } from '@mui/material'
import { useHistory, useLocation } from 'react-router'

import AdminRoutes from './routes'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Admin = ({ match }) => {
  const [value, setValue] = React.useState(0)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const pageRoute = location.pathname.split('/')[2]

    switch (pageRoute) {
      case 'user':
        setValue(0)
        break
      case 'user_type':
        setValue(1)
        break
      case 'supplier':
        setValue(2)
        break
      case 'supplier_part_number_rules':
        setValue(3)
        break
      case 'supplier_preference_rules':
        setValue(4)
        break
      case 'supplier_name_variants':
        setValue(5)
        break
      case 'manufacturer_name_variants':
        setValue(6)
        break
      default:
        break
    }
  }, [location])

  const handleChange = (event, newValue) => {
    const routeURL = match.url

    if (newValue === 0) {
      history.push(`${routeURL}/user/list`)
    } else if (newValue === 1) {
      history.push(`${routeURL}/user_type/list`)
    } else if (newValue === 2) {
      history.push(`${routeURL}/supplier/list`)
    } else if (newValue === 3) {
      history.push(`${routeURL}/supplier_part_number_rules`)
    } else if (newValue === 4) {
      history.push(`${routeURL}/supplier_preference_rules`)
    } else if (newValue === 5) {
      history.push(`${routeURL}/supplier_name_variants`)
    } else if (newValue === 6) {
      history.push(`${routeURL}/manufacturer_name_variants`)
    }
  }

  return (
    <Paper sx={{ p: 3 }} elevation={3}>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Users' {...a11yProps(0)} />
          <Tab label='User Types' {...a11yProps(1)} />
          <Tab label='Suppliers' {...a11yProps(2)} />
          <Tab label='Supplier Part Number Rules' {...a11yProps(3)} />
          <Tab label='Supplier Preference Rules' {...a11yProps(4)} />
          <Tab label='Supplier Name Variants' {...a11yProps(5)} />
          <Tab label='Manufacturer Name Variants' {...a11yProps(6)} />
        </Tabs>
      </Box>

      <Box sx={{ pt: 3 }}>
        <AdminRoutes />
      </Box>
    </Paper>
  )
}

export default Admin
