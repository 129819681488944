import { ErrorDTO, PartNumberRuleDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ParamsDTO = {
  logic_function_objects?: {
    logic_function_id: number
    arguments: string[]
    display_text: string
  }[]
  name?: string
  string?: string
  string_evaluation_type_id?: number
  part_number_class_id?: number
  supplier_id?: number
  notes?: string
}

export const useCreatePartNumberRuleMutation = () =>
  useMutation<PartNumberRuleDTO, ErrorDTO, ParamsDTO>({
    mutationFn: async (values: ParamsDTO) => {
      try {
        const res = await client.post('/part_number_rules/create', values)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
