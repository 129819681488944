import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

import RequisitionsList from './list'

const Requisitions = () => {
  const routeURL = '/requisitions'

  return (
    <Switch>
      <Route exact path={`${routeURL}`}>
        <Redirect to={`${routeURL}/list`} />
      </Route>
      <Route exact path={[`${routeURL}/create`]} component={RequisitionsList} />
      <Route
        exact
        path={[
          `${routeURL}/list`,
          `${routeURL}/:id`,
          `${routeURL}/:id/:tabName`,
        ]}
        component={RequisitionsList}
      />
    </Switch>
  )
}

export default Requisitions
