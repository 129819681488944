import React from 'react'
import { useHistory } from 'react-router-dom'
import { Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

const TableActions = () => {
  const history = useHistory()

  return (
    <Fab
      color='primary'
      aria-label='add'
      onClick={() => {
        history.push('/admin/supplier/create')
      }}
      sx={{ position: 'fixed', bottom: 60, right: 20 }}
    >
      <AddIcon />
    </Fab>
  )
}

export default TableActions
