import React, { useContext } from 'react'
import {
  Button,
  Menu,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogActions,
  Box,
} from '@mui/material'

import { GlobalContext } from '../../../../../routes'

import { usePoLineBatchCreateMutation } from 'services/hooks/purchaseOrders/usePoLineBatchCreateMutation'
import { usePoListQuery } from 'services/hooks/purchaseOrders/usePoListQuery'
import { useHistory } from 'react-router'
import { queryClient } from 'services/helpers/apiConfig'

const PoConfirmDialog = (props) => {
  const { onClose, open, addedLine } = props
  const history = useHistory()

  const handleClose = () => {
    onClose()
    // need to invalidate queries for fetching quotes
    // tabQuoteContext.fetchQuoteItems()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='po-confirm-dialog-title'
      open={open}
    >
      <DialogTitle id='po-confirm-dialog-title'>
        The lines were successfully added to{' '}
        {addedLine ? `PO ${addedLine.po.id}` : `new PO`}
      </DialogTitle>
      <DialogActions>
        {addedLine && (
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              history.push(`/po/${addedLine.po.id}`)
            }}
          >
            GO TO PO {addedLine.po.id}
          </Button>
        )}
        <Button variant='contained' onClick={handleClose} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const AddToPoButton = ({ selectedRows, supplierId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [addedLine, setAddedLine] = React.useState(null)
  const context = useContext(GlobalContext)
  const { data: poList } = usePoListQuery({
    filterValues: {
      status: 'draft',
      supplierIds: [supplierId],
    },
  })
  const { mutate: poLineBatchCreateMutation } = usePoLineBatchCreateMutation()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onClickItem = async (po = null) => {
    let rfqQuoteLines = []
    selectedRows.map((line) => {
      rfqQuoteLines = [...rfqQuoteLines, line.rfq_quote_line_uuid]
      return rfqQuoteLines
    })

    poLineBatchCreateMutation(
      {
        rfq_quote_lines: rfqQuoteLines,
        po: po !== null ? po.id : null,
      },
      {
        onSuccess(data) {
          setModalOpen(true)
          setAddedLine(data)
          queryClient.invalidateQueries({ queryKey: ['rfq', 'quote', 'read'] })
        },
      }
    )
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <Box sx={{ textAlign: 'right' }}>
      <Button
        variant='contained'
        color='primary'
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        disabled={selectedRows.length === 0}
      >
        Add to po
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => onClickItem()}>New Po</MenuItem>
        {poList?.purchase_order?.map((po, i) => (
          <MenuItem onClick={() => onClickItem(po)} key={'purchase-order' + i}>
            Po {po.id}
          </MenuItem>
        ))}
      </Menu>

      <PoConfirmDialog
        open={modalOpen}
        onClose={handleCloseModal}
        addedLine={addedLine}
      />
    </Box>
  )
}

export default AddToPoButton
