import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import { useRfqQuoteAddMutation } from 'services/hooks/rfq/useRfqQuoteAddMutation'

const NonBidItemsTable = ({ rows }) => {
  const queryClient = useQueryClient()
  const [tableRows, setTableRows] = useState(rows)
  const { mutate: rfqQuoteAddMutation } = useRfqQuoteAddMutation()

  useEffect(() => {
    setTableRows(rows)
  }, [rows])

  const onUpArrowClicked = async (rfqLine) => {
    rfqQuoteAddMutation(
      {
        uuid: rfqLine.uuid,
        body: null,
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({ queryKey: ['rfq', 'quote', 'read'] })
        },
      }
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Quantity</TableCell>
            <TableCell>Requisition Number</TableCell>
            <TableCell>Part Number</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => (
            <TableRow key={row.item}>
              <TableCell component='th' scope='row'>
                {row.quantity}
              </TableCell>
              <TableCell>
                {row.requisition_detail ? (
                  <Link to={`/requisitions/${row.requisition_detail.order_id}`}>
                    {row.requisition_detail.order_id}
                  </Link>
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell>{row.item}</TableCell>
              <TableCell align='right'>
                <IconButton onClick={() => onUpArrowClicked(row)}>
                  <ArrowUpwardIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default NonBidItemsTable
