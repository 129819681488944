import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type RfqQuoteCreateParamsDTO = {
  rfq_uuid: string
  item: string
  price: number
  quantity: number
  manufacturer_id: number
  lead_time: string
  supplier_part_number: string
  supplier_part_number_custom: boolean
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqQuoteCreateMutation = () =>
  useMutation<any, ErrorDTO, RfqQuoteCreateParamsDTO>({
    mutationFn: async (body: RfqQuoteCreateParamsDTO) => {
      try {
        const res = await client.post(`rfq/quote/create`, body)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
