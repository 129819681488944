import React, { useState } from 'react'

import SupplierPopover from '../../../../components/supplierPopover'

const SupplierTableCell = ({ supplier }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const supplierObj = {
    name: supplier.profile.name,
    city: supplier.profile.city,
    state: supplier.profile.state,
    first_name: supplier.linked_user.first_name,
    last_name: supplier.linked_user.last_name,
    email: supplier.linked_user.email,
    phone: supplier.linked_user.phone,
  }

  return (
    <div>
      <span
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ cursor: 'pointer' }}
      >
        {supplier.profile.name}
      </span>
      <SupplierPopover
        open={open}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        supplier={supplierObj}
      />
    </div>
  )
}

export default SupplierTableCell
