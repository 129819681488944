import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type PoLineDeleteParams = {
  uuid: string
}

export const usePoLineDeleteMutation = () =>
  useMutation<any, ErrorDTO, PoLineDeleteParams>({
    mutationFn: async (params) => {
      try {
        const res = await client.delete(`/po/line/delete/${params.uuid}`)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
