//@ts-nocheck

import React, { useEffect, useState, useContext } from 'react'
import { Typography, Button, Dialog, IconButton, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { OrangeButton } from '../../../../components/common/button'

import EmptyUserProfileForm from './emptyUserProfileForm'
import EditUser from '../../user/edit'
import { useDeleteUserMutation } from 'services/hooks/user/useDeleteUserMutation'
import { useUnlinkUserSupplierMutation } from 'services/hooks/supplier/useUnlinkUserSupplierMutation'

const UserProfileForm = ({ linkedUser, uuid }) => {
  const { mutate: deleteUserMuate } = useDeleteUserMutation()
  const { mutate: unlinkUserSupplierMutate } = useUnlinkUserSupplierMutation()

  const [connectedUser, setConnectedUser] = useState(
    linkedUser
      ? {
          ...linkedUser,
          name: `${linkedUser.first_name} ${linkedUser.last_name}`,
        }
      : null
  )
  const [isEdit, setIsEdit] = useState(false)
  // const userData = useSelector((state) => state.admin.userData)

  // useEffect(() => {
  //   if (userData && isEdit) {
  //     setConnectedUser({
  //       ...userData,
  //       name: `${userData.first_name} ${userData.last_name}`,
  //     })
  //   }
  // }, [userData])

  const onDeleteContact = async (user) => {
    deleteUserMuate(
      { userId: user.id },
      {
        onSuccess(data, variables, context) {
          setConnectedUser(null)
        },
      }
    )
  }

  const onUnlinkContact = async () => {
    unlinkUserSupplierMutate(
      {
        uuid,
      },
      {
        onSuccess(data, variables, context) {
          setConnectedUser(null)
        },
      }
    )
  }

  const onEditContact = () => {
    setIsEdit(true)
  }

  const onUserConnected = (user) => {
    setConnectedUser(user)
  }

  const onCloseEditUser = () => {
    setIsEdit(false)
  }

  return (
    <Box>
      {connectedUser ? (
        <div>
          <Typography variant='h6' gutterBottom>
            {connectedUser.name}
          </Typography>
          <Typography variant='body1' gutterBottom>
            {connectedUser.email}
          </Typography>
          <Typography variant='body1' gutterBottom>
            {connectedUser.phone}
          </Typography>
          <Box sx={{ marginTop: 3, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => onDeleteContact(connectedUser)}
            >
              Delete Contact
            </Button>
            <OrangeButton onClick={onUnlinkContact}>
              Unlink Contact
            </OrangeButton>
            <Button
              variant='contained'
              color='primary'
              onClick={() => onEditContact(connectedUser)}
            >
              Edit Contact
            </Button>
          </Box>
          <Dialog
            aria-labelledby='simple-dialog-title'
            onClose={onCloseEditUser}
            open={isEdit}
            maxWidth='lg'
            fullWidth
          >
            <Box sx={{ padding: '40px', position: 'relative' }}>
              <IconButton
                aria-label='close'
                className='btn-close'
                onClick={onCloseEditUser}
                sx={{ position: 'absolute', right: 0, top: 0 }}
              >
                <CloseIcon />
              </IconButton>
              <EditUser userName={connectedUser.username} />
            </Box>
          </Dialog>
        </div>
      ) : (
        <EmptyUserProfileForm uuid={uuid} onUserConnected={onUserConnected} />
      )}
    </Box>
  )
}

export default UserProfileForm
