import { ApiMetaDataDTO, UserDTO } from 'declarations'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type ReadUserTypeDTO = {
  admin: boolean
  scopes: any
  user_type: string
  user_type_id: number
}

export const useUserTypeReadQuery = (
  userType: string
): UseQueryResult<ReadUserTypeDTO, unknown> => {
  return useQuery({
    queryKey: ['user_type', 'read', userType],
    queryFn: async () => {
      try {
        const res = await client.get(`/userType/read/${userType}`)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
}
