import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { RfqDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

const useRfqReadQuery = (
  uuid: string,
  enabled = true
): UseQueryResult<RfqDTO> => {
  return useQuery({
    queryKey: ['rfq', 'read', uuid],
    queryFn: async () => {
      try {
        const res = await client.get(`/rfq/read/${uuid}`)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    enabled,
  })
}

export { useRfqReadQuery }
