import React, { useState, useEffect } from 'react'
import { Typography, Grid, Button, Alert } from '@mui/material'

import LoadingSpinner from '../../../../components/common/loadingSpinner'

import QuotedItemsTable from './quotedItemsTable'
import NonBidItemsTable from './nonBidItemsTable'
import TabQuoteContainer from './tabQuote.style'
import { useRfqReadQuery } from 'services/hooks/rfq/useRfqReadQuery'
import { useParams } from 'react-router'
import { useRfqQuoteReadQuery } from 'services/hooks/rfq/useRfqQuoteReadQuery'
import { useRfqStatusUpdateMutation } from 'services/hooks/rfq/useRfqStatusUpdateMutation'
import { useQueryClient } from '@tanstack/react-query'

const TabQuote = ({ quotedCorrespondence }) => {
  const { id: urlParamId }: any = useParams()
  const { data: rfqData } = useRfqReadQuery(urlParamId, true)
  const [quotedItems, setQuotedItems] = useState(null)
  const [nonBidItems, setNonBidItems] = useState(null)
  const queryClient = useQueryClient()

  const {
    data: quoteData,
    isLoading: isLoadingQuoteData,
    refetch: refetchQuoteData,
  } = useRfqQuoteReadQuery(rfqData.uuid, !!rfqData.uuid)
  const { mutate: rfqStatusUpdateMutation } = useRfqStatusUpdateMutation()

  useEffect(() => {
    if (rfqData && quoteData) {
      if (rfqData.permissions.can_view_quote) {
        const rfqItems = quoteData.quoted_items.rfq_items || []
        const customItems = quoteData.quoted_items.custom_items || []

        rfqItems.map((item) => {
          const x = item
          x.isCustomItem = false
          return x
        })

        customItems.map((item) => {
          const x = item
          x.isCustomItem = true
          return x
        })

        setQuotedItems([...rfqItems, ...customItems])
        setNonBidItems(quoteData.non_bid_items)
      }
    }
  }, [rfqData, quoteData])

  useEffect(() => {
    refetchQuoteData()
  }, [rfqData.permissions.can_view_quote])

  const onDisplayMessageButtonClicked = async (statusId) => {
    rfqStatusUpdateMutation(
      {
        uuid: rfqData.uuid,
        reqBody: { status_id: statusId },
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({ queryKey: ['rfq', 'read'] })
        },
      }
    )
  }

  return (
    <TabQuoteContainer>
      {rfqData.permissions.can_view_quote ? (
        <Grid container spacing={3}>
          <Grid item xs={!quotedCorrespondence ? 12 : 8}>
            <Typography variant='h6' gutterBottom>
              Quoted Items
            </Typography>
            {quotedItems && rfqData && (
              <QuotedItemsTable
                rows={quotedItems}
                permissions={rfqData.permissions}
                rfqUUID={rfqData.uuid}
                rfqId={rfqData.id}
                supplierId={rfqData.supplier_id}
              />
            )}
            {!quotedItems && !isLoadingQuoteData && (
              <Alert severity='warning'>No Items</Alert>
            )}
            {!quotedItems && isLoadingQuoteData && <LoadingSpinner />}
            <Typography variant='h5' gutterBottom>
              Non-Bid Items
            </Typography>
            {nonBidItems && nonBidItems.length > 0 ? (
              <NonBidItemsTable rows={nonBidItems} />
            ) : (
              <Alert severity='warning'>No Items</Alert>
            )}
          </Grid>
          {quotedCorrespondence && (
            <Grid item xs={4} className='saved-quote'>
              <div className='subject'>
                <h2>{quotedCorrespondence.thread.subject}</h2>
              </div>
              <div className='messages'>
                {quotedCorrespondence.messages.map((message, i) => (
                  <div className='message' key={'message-tab-qhoted' + i}>
                    <div className='sender'>
                      <p className='name'>{message.display_name}</p>
                      <p className='date'>
                        {message.date_added.formatted_date}
                      </p>
                    </div>
                    {message.html_body && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: message.html_body,
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </Grid>
          )}
        </Grid>
      ) : (
        <div className='nothing-content'>
          {quoteData && (
            <div>
              <p>
                {quoteData.display_message && quoteData.display_message.message}
              </p>
              {quoteData.display_message &&
                quoteData.display_message.button.display && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() =>
                      onDisplayMessageButtonClicked(
                        quoteData.display_message.button.status_id
                      )
                    }
                  >
                    {quoteData.display_message.button.language}
                  </Button>
                )}
            </div>
          )}
        </div>
      )}
    </TabQuoteContainer>
  )
}

export default TabQuote
