import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ManufacturerDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

async function fetchManufacturerList(
  partNumber?: string
): Promise<ManufacturerDTO[]> {
  try {
    const res = await client.get(
      partNumber
        ? `/manufacturer/list?part_number=${partNumber}`
        : `/manufacturer/list`
    )
    return res.data.data
  } catch (err) {
    throw err.response.data
  }
}

const useManufacturersListQuery = (
  partNumber?: string
): UseQueryResult<ManufacturerDTO[]> => {
  if (partNumber) {
    return useQuery({
      queryKey: ['manufactuer', 'list', partNumber],
      queryFn: () => fetchManufacturerList(partNumber),
    })
  }

  return useQuery({
    queryKey: ['manufacturerList', 'list'],
    queryFn: () => fetchManufacturerList(),
  })
}

export { useManufacturersListQuery }
