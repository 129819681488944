import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { red } from '@mui/material/colors'

const EditableInputContainer = styled(Box)`
  width: 100%;
  p.field-error {
    color: ${red[500]};
    font-size: 0.8em;
  }
`

export default EditableInputContainer
