import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import _isEmpty from 'lodash/isEmpty'

import { useRfqLineUpdateMutation } from 'services/hooks/rfq/useRfqLineUpdateMutation'
import { useQueryClient } from '@tanstack/react-query'

const ManufacturerSelector = ({ list, line, fieldType }) => {
  const queryClient = useQueryClient()
  const { mutate: rfqLineUpdateMutation } = useRfqLineUpdateMutation()
  const [selectedManufacturer, setSelectedManufacturer] = React.useState(
    !_isEmpty(line.manufacturer) ? line.manufacturer.id : ''
  )

  const handleChange = async (event) => {
    const prevValue = selectedManufacturer
    setSelectedManufacturer(event.target.value)
    rfqLineUpdateMutation(
      {
        uuid: line.uuid,
        reqBody: {
          manufacturer_id: event.target.value,
        },
      },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: ['rfq', 'read'],
          })
        },
        onError() {
          setSelectedManufacturer(prevValue)
        },
      }
    )
  }

  return (
    <>
      {fieldType === 'text' && <span>{line.manufacturer.name}</span>}
      {fieldType !== 'text' && (
        <FormControl variant='outlined' fullWidth>
          <InputLabel id='manufacturer-select-outlined-label'>
            Manufacturer
          </InputLabel>
          <Select
            labelId='manufacturer-select-outlined-label'
            id='manufacturer-select-outlined'
            value={selectedManufacturer}
            onChange={handleChange}
            label='Manufacturer'
          >
            {list.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  )
}

export default ManufacturerSelector
