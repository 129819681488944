import React, { useState } from 'react'
import { Box, Link } from '@mui/material'
import { Done, Close } from '@mui/icons-material'
import { Link as RouterLink } from 'react-router-dom'

import { useUserListQuery } from 'services/hooks/user/useUserListQuery'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid'

import TableActions from './components/tableActions'

const UserList = () => {
  const { data: userListData } = useUserListQuery()
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25,
  })
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
    },
    {
      field: 'user_type',
      headerName: 'User Type',
      flex: 1,
    },
    {
      field: 'last_login',
      headerName: 'Last Login',
      valueGetter: (value, row) => row.last_login.formatted_date,
      flex: 1,
    },
    {
      field: 'active',
      headerName: 'Active',
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          {params.row.active ? <Done /> : <Close />}
        </Box>
      ),
    },
    {
      field: 'edit',
      headerName: '',
      renderCell: (params: GridRenderCellParams) => {
        const linkTo = params.row.self
          ? '/admin/user/update/self'
          : `${
              params.row.can_edit
                ? `/admin/user/update/${params.row.username}`
                : `/admin/user/read/${params.row.username}`
            }`

        return (
          <Link
            component={RouterLink}
            variant='body2'
            to={linkTo}
            style={{ color: params.row.self ? 'white' : '' }}
          >
            {params.row.can_edit ? 'Edit' : 'View'}
          </Link>
        )
      },
    },
  ]

  console.log(rowSelectionModel)

  return (
    <Box>
      <Box sx={{ height: 500, mb: 2 }}>
        <DataGrid
          rows={userListData?.users || []}
          columns={columns}
          paginationModel={paginationModel}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          onPaginationModelChange={setPaginationModel}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </Box>

      <TableActions
        checkedUserIds={rowSelectionModel}
        addUser={userListData?.addUser}
      />
    </Box>
  )
}

export default UserList
