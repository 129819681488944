import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { RfqDTO, RfqLineDTO, StatusDTO, TimelineDTO } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type RespnoseDTO = {
  non_bid_items: RfqLineDTO[]
  quoted_items: {
    custom_items: any
    rfq_items: any
  }
  rfq: RfqDTO
  status_list: StatusDTO[]
  timeline: TimelineDTO[]
  display_message?: any
}

const useRfqQuoteReadQuery = (
  uuid: string,
  enabled = true
): UseQueryResult<RespnoseDTO> => {
  return useQuery({
    queryKey: ['rfq', 'quote', 'read', uuid],
    queryFn: async () => {
      try {
        const res = await client.get(`/rfq/quote/read/${uuid}`)

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
    enabled,
  })
}

export { useRfqQuoteReadQuery }
