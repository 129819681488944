import { Box, Typography } from '@mui/material'
import { HistoryDTO } from 'declarations'
import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import _uniq from 'lodash/uniq'
import _map from 'lodash/map'

type PurchaseOrderHistoryProps = {
  histories: HistoryDTO[]
}

const PurchaseOrderHistory = ({ histories }: PurchaseOrderHistoryProps) => {
  const [historyDates, setHistoryDates] = useState([])
  const [historyObjs, setHistoryObjs] = useState([])

  useEffect(() => {
    const formattedHistories = histories.map((history) => {
      const date = moment(history.datetime.timestamp).tz(
        history.datetime.timezone_name
      )
      return {
        date: date.format('MMMM DD YYYY'),
        time: date.format('h:m a z'),
        message: history.message,
      }
    })

    setHistoryDates(_uniq(formattedHistories.map(({ date }) => date)))
    setHistoryObjs(formattedHistories)
  }, [histories])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {historyDates.length > 0 &&
        historyDates.map((date, i) => (
          <div key={'purchase-history-date' + i}>
            <Typography variant='h5' gutterBottom>
              {date}
            </Typography>
            {historyObjs.length > 0 &&
              historyObjs.map(
                (history, j) =>
                  history.date === date && (
                    <Typography key={j}>
                      {history.date === date ? (
                        <span>
                          {history.time}.{''}
                          {''} {history.message}
                        </span>
                      ) : null}
                    </Typography>
                  )
              )}
          </div>
        ))}
      {historyDates.length === 0 && <Typography>No history found</Typography>}
    </Box>
  )
}

export default PurchaseOrderHistory
