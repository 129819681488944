import React from 'react'
import { Box, Button, TextField } from '@mui/material'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { useUpdateUserMutation } from 'services/hooks/user/useUpdateUserMutation'

interface IFormInput {
  first_name: string
  last_name: string
  email: string
  phone: number
}

type ProfileFormProps = {
  initialValues: {
    first_name: string
    last_name: string
    email: string
    phone: number
  }
}

const ProfileForm = ({ initialValues }: ProfileFormProps) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      first_name: initialValues.first_name,
      last_name: initialValues.last_name,
      email: initialValues.email,
      phone: initialValues.phone,
    },
  })
  const { mutate: updateUserMutate } = useUpdateUserMutation()
  const params: any = useParams()

  const onSubmit: SubmitHandler<IFormInput> = (formData) => {
    const { userName } = params
    // Self user update hadnling is missing
    updateUserMutate(
      { userName, reqBody: formData },
      {
        onSuccess(data) {
          toast.success('User Updated Successfully!')
        },
        onError(error) {
          toast.error(error.message)
        },
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Controller
          name='first_name'
          control={control}
          rules={{ required: 'Required' }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='outlined'
              label='First Name'
              fullWidth
              error={Boolean(errors.first_name)}
              helperText={errors.first_name?.message}
            />
          )}
        />
        <Controller
          name='last_name'
          control={control}
          rules={{ required: 'Required' }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='outlined'
              label='Last Name'
              fullWidth
              error={Boolean(errors.last_name)}
              helperText={errors.last_name?.message}
            />
          )}
        />
        <Controller
          name='email'
          control={control}
          rules={{
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid email address',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='outlined'
              label='Email'
              fullWidth
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
          )}
        />
        <Controller
          name='phone'
          control={control}
          rules={{ required: 'Required' }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='outlined'
              label='Phone'
              fullWidth
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
            />
          )}
        />

        <Box sx={{ marginTop: '10px', textAlign: 'right' }}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className='btn-submit'
            disabled={!isDirty}
          >
            Update
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default ProfileForm
