import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type RfqLinesCreateParams = {
  rfqId: number
  requisitionUUID: string
  lines: {
    quantity: number
    part_number: string
    manufacturer_id: number
  }[]
}

type ApiResponseDTO = {
  code: number
  data: any
  api_metadata: ApiMetaDataDTO
  message?: string
}

export const useRfqLinesCreateMutation = () =>
  useMutation<any, ErrorDTO, RfqLinesCreateParams>({
    mutationFn: async (params: RfqLinesCreateParams) => {
      try {
        let createdRfqLineIds = []
        for (const line of params.lines) {
          const reqBody = {
            quantity: line.quantity,
            part_number: line.part_number,
            manufacturer_id: line.manufacturer_id,
            rfq_id: params.rfqId,
            requisition_uuid: params.requisitionUUID,
          }

          const res = await client.post('/rfq/line/create', reqBody)
          createdRfqLineIds = [...createdRfqLineIds, res.data.data.rfq_line_id]
        }

        return createdRfqLineIds
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
