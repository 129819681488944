import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ApiMetaDataDTO, StringEvaluationType } from 'declarations'
import { toast } from 'react-toastify'

import { client } from 'services/helpers/apiConfig'

type ApiResponseDTO = {
  code: number
  data: StringEvaluationType[]
  api_metadata: ApiMetaDataDTO
}

const useSprStringEvaluationTypesQuery = (): UseQueryResult<
  StringEvaluationType[]
> => {
  return useQuery({
    queryKey: ['spr', 'string_evaluation_types'],
    queryFn: async () => {
      try {
        const res = await client.get<ApiResponseDTO>(
          '/supplier_preference_rules/string_evaluation_types/list'
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
}

export { useSprStringEvaluationTypesQuery }
