import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'

import { usePasswordSetMutation } from 'services/hooks/user/usePasswordSetMutation'
import { useUpdateUserMutation } from 'services/hooks/user/useUpdateUserMutation'

interface IFormInput {
  newPassword: string
  oldPassword: string
  confirmPassword: string
  forceChangePassword: boolean
  emailUserNewPassword: boolean
}

type ChangePasswordFormProps = {
  userId: number
  isSelf: boolean
}

const ChangePasswordForm = ({ userId, isSelf }: ChangePasswordFormProps) => {
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      newPassword: '',
      oldPassword: '',
      confirmPassword: '',
      forceChangePassword: false,
      emailUserNewPassword: false,
    },
  })
  const newPassword = watch('newPassword')
  const oldPassword = watch('oldPassword')
  const { mutate: setPasswordMutate } = usePasswordSetMutation()
  const { mutate: updateUserMutate } = useUpdateUserMutation()

  const onSubmit: SubmitHandler<IFormInput> = (formData) => {
    if (isSelf) {
      const passwords = {
        old_password: formData.oldPassword,
        new_password: formData.newPassword,
      }

      updateUserMutate(
        { userName: 'self', reqBody: passwords },
        {
          onSuccess(data) {},
          onError(err) {},
        }
      )
    } else {
      const updateData = {
        user_id: userId,
        password: formData.newPassword,
        send_email: formData.emailUserNewPassword,
        force_password_change: formData.forceChangePassword,
      }

      setPasswordMutate(updateData, {
        onSuccess(data) {
          toast.success('Password updated successfully!')
        },
        onError(error) {},
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {isSelf && (
          <Controller
            name='oldPassword'
            control={control}
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <TextField
                {...field}
                variant='outlined'
                label='Old Password'
                fullWidth
                error={Boolean(errors.oldPassword)}
                helperText={errors.oldPassword?.message}
                type='password'
              />
            )}
          />
        )}

        <Controller
          name='newPassword'
          control={control}
          rules={{
            required: 'Required',
            pattern: {
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              message:
                'The password does not meet the minimum password requirements',
            },
            validate: (value) =>
              value !== oldPassword ||
              'New password must be different than old password',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant='outlined'
              label='New Password'
              fullWidth
              error={Boolean(errors.newPassword)}
              helperText={errors.newPassword?.message}
              type='password'
            />
          )}
        />
        {isSelf && (
          <Controller
            name='confirmPassword'
            control={control}
            rules={{
              required: 'Required',
              validate: (value) =>
                value === newPassword || 'Confirm password does not match',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant='outlined'
                label='Confirm Password'
                fullWidth
                error={Boolean(errors.confirmPassword)}
                helperText={errors.confirmPassword?.message}
                type='password'
              />
            )}
          />
        )}
        {!isSelf && (
          <Controller
            name='forceChangePassword'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label='Force User to Change Password'
              />
            )}
          />
        )}

        {!isSelf && (
          <Controller
            name='emailUserNewPassword'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label='Email user new password'
              />
            )}
          />
        )}

        <Box sx={{ marginTop: '10px', textAlign: 'right' }}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className='btn-submit'
            disabled={!isDirty}
          >
            Change
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default ChangePasswordForm
