import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { StatusDTO } from 'declarations'

import { client } from 'services/helpers/apiConfig'

const usePoStatusListQuery = (): UseQueryResult<StatusDTO[]> => {
  return useQuery({
    queryKey: ['po', 'status', 'list'],
    queryFn: async () => {
      const res = await client.get('po/status/list')

      return res.data.data
    },
  })
}

export { usePoStatusListQuery }
