import { ApiMetaDataDTO, ErrorDTO } from 'declarations'
import { useMutation } from '@tanstack/react-query'
import { client } from 'services/helpers/apiConfig'
import { toast } from 'react-toastify'

export type Params = {
  orderIds: string
}

export const useRequisitionDeleteMutation = () =>
  useMutation<any, ErrorDTO, Params>({
    mutationFn: async (params) => {
      try {
        const res = await client.delete(
          `/requisitions/delete/${params.orderIds.toString()}`
        )

        return res.data.data
      } catch (err) {
        toast.error(err.response.data.message)
        throw err.response.data
      }
    },
  })
